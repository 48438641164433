"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var app_model_1 = require("../../app/models/app.model");
var HeaderComponent = (function () {
    function HeaderComponent(translate, elRef, app) {
        this.translate = translate;
        this.elRef = elRef;
        this.app = app;
        this.headerLogo = '';
        this.headerString = '';
        this.lang = 'ja';
        this.selectLanguageEnabled = false;
        this.selectLangs = [];
        this.warning = false;
        this.langs = {
            'ja': '日本語',
            'en': 'English',
            'zh-tw': '繁體中文',
            'vi': 'Tiếng Việt'
        };
        this.hLang = 'jp';
        this.showLang = false;
        this.hClass = '';
        this.lbClass = '';
        this.hCalc = true;
    }
    HeaderComponent.prototype.ngOnInit = function () {
        if (this.lang === 'ja') {
            this.hLang = 'jp';
        }
        else {
            this.hLang = this.lang;
        }
    };
    HeaderComponent.prototype.ngAfterViewInit = function () {
        this.hCalc = true;
    };
    HeaderComponent.prototype.ngAfterViewChecked = function () {
        if (this.app.isBrowser() && this.hCalc) {
            this.headerResize();
        }
    };
    HeaderComponent.prototype.headerResize = function () {
        var ch = this.elRef.nativeElement.querySelector('#headerStringSpan').offsetHeight;
        console.log(ch);
        console.dir(this.elRef.nativeElement.querySelector('#headerStringSpan'));
        if (ch > 0) {
            this.hCalc = false;
        }
        if (ch < 25) {
            this.hClass = '';
            this.lbClass = '';
        }
        else {
            if (this.selectLanguageEnabled) {
                this.hClass = 'times enableLang';
            }
            else {
                this.hClass = 'times';
            }
        }
        if (ch >= 25 && ch < 45) {
            this.lbClass = 'double';
        }
        if (ch >= 45 && ch < 65) {
            this.lbClass = 'triple';
        }
        if (ch >= 65) {
            this.lbClass = 'quadruple';
        }
    };
    HeaderComponent.prototype.selectLang = function (lang) {
        if (this.lang !== lang) {
            this.lang = lang;
            if (lang === 'ja') {
                this.hLang = 'jp';
            }
            else {
                console.log(lang);
                this.hLang = lang;
            }
            this.translate.use(lang);
        }
        this.showLang = false;
    };
    HeaderComponent.prototype.touchStart = function (evt) {
        evt.srcElement.classList.add('hover');
    };
    HeaderComponent.prototype.touchEnd = function (evt) {
        evt.srcElement.classList.remove('hover');
    };
    HeaderComponent.prototype.closeWarning = function (evt) {
        this.warning = false;
    };
    return HeaderComponent;
}());
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], HeaderComponent.prototype, "headerLogo", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], HeaderComponent.prototype, "headerString", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], HeaderComponent.prototype, "lang", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], HeaderComponent.prototype, "selectLanguageEnabled", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Array)
], HeaderComponent.prototype, "selectLangs", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], HeaderComponent.prototype, "warning", void 0);
HeaderComponent = __decorate([
    core_1.Component({
        selector: 'trt-header',
        styles: [require('./header.component.css')],
        template: require('./header.component.html')
    }),
    __metadata("design:paramtypes", [core_2.TranslateService, core_1.ElementRef, app_model_1.AppType])
], HeaderComponent);
exports.HeaderComponent = HeaderComponent;
var PCHeaderComponent = (function (_super) {
    __extends(PCHeaderComponent, _super);
    function PCHeaderComponent(translate, elRef, app) {
        var _this = _super.call(this, translate, elRef, app) || this;
        _this.translate = translate;
        _this.elRef = elRef;
        _this.app = app;
        return _this;
    }
    return PCHeaderComponent;
}(HeaderComponent));
PCHeaderComponent = __decorate([
    core_1.Component({
        selector: 'trt-header',
        styles: [require('./header.pc.component.css')],
        template: require('./header.component.html')
    }),
    __metadata("design:paramtypes", [core_2.TranslateService, core_1.ElementRef, app_model_1.AppType])
], PCHeaderComponent);
exports.PCHeaderComponent = PCHeaderComponent;
