"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./term.component");
var i2 = require("@ngx-translate/core/src/translate.service");
var i3 = require("../services/term.service");
var styles_TermComponent = ['dd[_ngcontent-%COMP%] {\n      margin-top: 1em;\n      margin-bottom: 1em;\n    }'];
exports.RenderType_TermComponent = i0.ɵcrt({ encapsulation: 0,
    styles: styles_TermComponent, data: {} });
function View_TermComponent_0(_l) {
    return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, null, null, 0, 'div', [], [[8, 'innerHTML', 1]], null, null, null, null))], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _co.termHtml;
        _ck(_v, 0, 0, currVal_0);
    });
}
exports.View_TermComponent_0 = View_TermComponent_0;
function View_TermComponent_Host_0(_l) {
    return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, null, null, 1, 'trt-term', [], null, null, null, View_TermComponent_0, exports.RenderType_TermComponent)),
        i0.ɵdid(245760, null, 0, i1.TermComponent, [i2.TranslateService, i3.TermService,
            i0.ChangeDetectorRef], null, null)], function (_ck, _v) {
        _ck(_v, 1, 0);
    }, null);
}
exports.View_TermComponent_Host_0 = View_TermComponent_Host_0;
exports.TermComponentNgFactory = i0.ɵccf('trt-term', i1.TermComponent, View_TermComponent_Host_0, { type: 'type', country: 'country', restaurantLang: 'restaurantLang' }, {}, []);
