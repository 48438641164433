"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var PersonalComponent = (function () {
    function PersonalComponent(translate) {
        this.translate = translate;
    }
    PersonalComponent.prototype.ngOnInit = function () {
        this.lang = this.translate.currentLang;
    };
    return PersonalComponent;
}());
__decorate([
    core_1.Input(),
    __metadata("design:type", Object)
], PersonalComponent.prototype, "lastName", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Object)
], PersonalComponent.prototype, "firstName", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Object)
], PersonalComponent.prototype, "lastNameReading", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Object)
], PersonalComponent.prototype, "firstNameReading", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Object)
], PersonalComponent.prototype, "phone", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Object)
], PersonalComponent.prototype, "email", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Object)
], PersonalComponent.prototype, "pc", void 0);
PersonalComponent = __decorate([
    core_1.Component({
        selector: 'trt-personal',
        template: "\n  <div [ngClass]=\"{'pc':pc}\">\n  <div>\n    <label>{{ 'NAME' | translate }}</label>\n    <p *ngIf=\"lang === 'ja'\" class=\"personal_name\">{{lastName}} {{firstName}} <span *ngIf=\"lastNameReading || firstNameReading\">( {{lastNameReading}} {{firstNameReading}} )</span></p>\n    <p *ngIf=\"lang !== 'zh-tw' && lang !== 'ja' \" class=\"personal_name\">{{firstName}} {{lastName}}</p>\n    <p *ngIf=\"lang === 'zh-tw'\" class=\"personal_name\">{{lastName}} {{firstName}}</p>\n    </div>\n    <div>\n    <label>{{'MOBILE' | translate}}</label>\n    <p class=\"personal_phone\">{{phone}}</p>\n    </div>\n    <div>\n    <label>{{'EMAIL' | translate}}</label>\n    <p class=\"personal_email\">{{email}}</p>\n    </div>\n  </div>\n  ",
        styles: [require('./personal.component.css')]
    }),
    __metadata("design:paramtypes", [core_2.TranslateService])
], PersonalComponent);
exports.PersonalComponent = PersonalComponent;
