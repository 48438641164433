"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./header.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core/src/translate.pipe");
var i3 = require("@ngx-translate/core/src/translate.service");
var i4 = require("@angular/common");
var i5 = require("./header.component");
var i6 = require("../../app/models/app.model");
var i7 = require("./header.pc.component.css.shim.ngstyle");
var styles_HeaderComponent = [i0.styles];
exports.RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_HeaderComponent, data: {} });
function View_HeaderComponent_1(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 7, 'div', [['class',
                'warning']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵeld(0, null, null, 1, 'div', [], null, [[null, 'click']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('click' === en)) {
                var pd_0 = (_co.closeWarning() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), (_l()(), i1.ɵeld(0, null, null, 0, 'i', [['class', 'icon icon_close']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(),
            i1.ɵeld(0, null, null, 1, 'p', [], [[8, 'innerHTML',
                    1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n']))], null, function (_ck, _v) {
        var currVal_0 = i1.ɵinlineInterpolate(1, '', i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform('BROWSER_WARNING')), '');
        _ck(_v, 5, 0, currVal_0);
    });
}
function View_HeaderComponent_2(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 2, 'span', [], null, [[null, 'click']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('click' === en)) {
                var pd_0 = ((_co.showLang = true) !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), (_l()(), i1.ɵeld(0, null, null, 0, 'i', [['class', 'icon icon_web']], null, null, null, null, null)), (_l()(), i1.ɵeld(0, null, null, 0, 'i', [['class', 'icon icon_arrow_under']], null, null, null, null, null))], null, null);
}
function View_HeaderComponent_5(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 0, 'i', [['class',
                'icon icon_check']], null, null, null, null, null))], null, null);
}
function View_HeaderComponent_4(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 3, 'li', [], null, [[null, 'click'], [null, 'touchstart'], [null,
                'touchend']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('click' === en)) {
                var pd_0 = (_co.selectLang(_v.context.$implicit) !== false);
                ad = (pd_0 && ad);
            }
            if (('touchstart' === en)) {
                var pd_1 = (_co.touchStart($event) !== false);
                ad = (pd_1 && ad);
            }
            if (('touchend' === en)) {
                var pd_2 = (_co.touchEnd($event) !== false);
                ad = (pd_2 && ad);
            }
            return ad;
        }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_5)), i1.ɵdid(16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null), (_l()(),
            i1.ɵted(null, ['', '']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = (_v.context.$implicit == _co.lang);
        _ck(_v, 2, 0, currVal_0);
    }, function (_ck, _v) {
        var _co = _v.component;
        var currVal_1 = _co.langs[_v.context.$implicit];
        _ck(_v, 3, 0, currVal_1);
    });
}
function View_HeaderComponent_3(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 7, 'div', [['class',
                'langList']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 4, 'ul', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_4)), i1.ɵdid(802816, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, 'ngForOf'] }, null), (_l()(), i1.ɵted(null, ['\n    '])),
        (_l()(), i1.ɵted(null, ['\n  ']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _co.selectLangs;
        _ck(_v, 5, 0, currVal_0);
    }, null);
}
function View_HeaderComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(16384, null, 0, i4.NgIf, [i1.ViewContainerRef,
            i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n'])), (_l()(), i1.ɵeld(0, null, null, 16, 'header', [], null, [['window', 'resize']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('window:resize' === en)) {
                var pd_0 = (_co.headerResize() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(),
            i1.ɵeld(0, null, null, 0, 'img', [], [[2, 'hidden', null],
                [8, 'src', 4]], null, null, null, null)), (_l()(),
            i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵeld(0, null, null, 3, 'p', [['class', 'headerString']], [[2, 'hidden', null]], null, null, null, null)), i1.ɵdid(278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer], { klass: [0,
                'klass'], ngClass: [1, 'ngClass'] }, null), (_l()(), i1.ɵeld(0, null, null, 1, 'span', [['id', 'headerStringSpan']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['',
            ''])), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵeld(0, null, null, 3, 'p', [['class', 'langButton']], null, null, null, null, null)), i1.ɵdid(278528, null, 0, i4.NgClass, [i1.IterableDiffers,
            i1.KeyValueDiffers, i1.ElementRef, i1.Renderer], { klass: [0, 'klass'], ngClass: [1,
                'ngClass'] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null), (_l()(),
            i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_3)), i1.ɵdid(16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null), (_l()(),
            i1.ɵted(null, ['\n']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _co.warning;
        _ck(_v, 1, 0, currVal_0);
        var currVal_4 = 'headerString';
        var currVal_5 = _co.hClass;
        _ck(_v, 8, 0, currVal_4, currVal_5);
        var currVal_7 = 'langButton';
        var currVal_8 = _co.lbClass;
        _ck(_v, 13, 0, currVal_7, currVal_8);
        var currVal_9 = _co.selectLanguageEnabled;
        _ck(_v, 15, 0, currVal_9);
        var currVal_10 = _co.showLang;
        _ck(_v, 18, 0, currVal_10);
    }, function (_ck, _v) {
        var _co = _v.component;
        var currVal_1 = !!!_co.headerLogo;
        var currVal_2 = _co.headerLogo;
        _ck(_v, 5, 0, currVal_1, currVal_2);
        var currVal_3 = !!_co.headerLogo;
        _ck(_v, 7, 0, currVal_3);
        var currVal_6 = _co.headerString;
        _ck(_v, 10, 0, currVal_6);
    });
}
exports.View_HeaderComponent_0 = View_HeaderComponent_0;
function View_HeaderComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'trt-header', [], null, null, null, View_HeaderComponent_0, exports.RenderType_HeaderComponent)),
        i1.ɵdid(12697600, null, 0, i5.HeaderComponent, [i3.TranslateService, i1.ElementRef,
            i6.AppType], null, null)], function (_ck, _v) {
        _ck(_v, 1, 0);
    }, null);
}
exports.View_HeaderComponent_Host_0 = View_HeaderComponent_Host_0;
exports.HeaderComponentNgFactory = i1.ɵccf('trt-header', i5.HeaderComponent, View_HeaderComponent_Host_0, { headerLogo: 'headerLogo', headerString: 'headerString',
    lang: 'lang', selectLanguageEnabled: 'selectLanguageEnabled', selectLangs: 'selectLangs',
    warning: 'warning' }, {}, []);
var styles_PCHeaderComponent = [i7.styles];
exports.RenderType_PCHeaderComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_PCHeaderComponent, data: {} });
function View_PCHeaderComponent_1(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 7, 'div', [['class',
                'warning']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵeld(0, null, null, 1, 'div', [], null, [[null, 'click']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('click' === en)) {
                var pd_0 = (_co.closeWarning() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), (_l()(), i1.ɵeld(0, null, null, 0, 'i', [['class', 'icon icon_close']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(),
            i1.ɵeld(0, null, null, 1, 'p', [], [[8, 'innerHTML',
                    1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n']))], null, function (_ck, _v) {
        var currVal_0 = i1.ɵinlineInterpolate(1, '', i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform('BROWSER_WARNING')), '');
        _ck(_v, 5, 0, currVal_0);
    });
}
function View_PCHeaderComponent_2(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 2, 'span', [], null, [[null, 'click']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('click' === en)) {
                var pd_0 = ((_co.showLang = true) !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), (_l()(), i1.ɵeld(0, null, null, 0, 'i', [['class', 'icon icon_web']], null, null, null, null, null)), (_l()(), i1.ɵeld(0, null, null, 0, 'i', [['class', 'icon icon_arrow_under']], null, null, null, null, null))], null, null);
}
function View_PCHeaderComponent_5(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 0, 'i', [['class',
                'icon icon_check']], null, null, null, null, null))], null, null);
}
function View_PCHeaderComponent_4(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 3, 'li', [], null, [[null, 'click'], [null, 'touchstart'], [null,
                'touchend']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('click' === en)) {
                var pd_0 = (_co.selectLang(_v.context.$implicit) !== false);
                ad = (pd_0 && ad);
            }
            if (('touchstart' === en)) {
                var pd_1 = (_co.touchStart($event) !== false);
                ad = (pd_1 && ad);
            }
            if (('touchend' === en)) {
                var pd_2 = (_co.touchEnd($event) !== false);
                ad = (pd_2 && ad);
            }
            return ad;
        }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PCHeaderComponent_5)), i1.ɵdid(16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null), (_l()(),
            i1.ɵted(null, ['', '']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = (_v.context.$implicit == _co.lang);
        _ck(_v, 2, 0, currVal_0);
    }, function (_ck, _v) {
        var _co = _v.component;
        var currVal_1 = _co.langs[_v.context.$implicit];
        _ck(_v, 3, 0, currVal_1);
    });
}
function View_PCHeaderComponent_3(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 7, 'div', [['class',
                'langList']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 4, 'ul', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PCHeaderComponent_4)), i1.ɵdid(802816, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, 'ngForOf'] }, null), (_l()(), i1.ɵted(null, ['\n    '])),
        (_l()(), i1.ɵted(null, ['\n  ']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _co.selectLangs;
        _ck(_v, 5, 0, currVal_0);
    }, null);
}
function View_PCHeaderComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PCHeaderComponent_1)), i1.ɵdid(16384, null, 0, i4.NgIf, [i1.ViewContainerRef,
            i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n'])), (_l()(), i1.ɵeld(0, null, null, 16, 'header', [], null, [['window', 'resize']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('window:resize' === en)) {
                var pd_0 = (_co.headerResize() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(),
            i1.ɵeld(0, null, null, 0, 'img', [], [[2, 'hidden', null],
                [8, 'src', 4]], null, null, null, null)), (_l()(),
            i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵeld(0, null, null, 3, 'p', [['class', 'headerString']], [[2, 'hidden', null]], null, null, null, null)), i1.ɵdid(278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer], { klass: [0,
                'klass'], ngClass: [1, 'ngClass'] }, null), (_l()(), i1.ɵeld(0, null, null, 1, 'span', [['id', 'headerStringSpan']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['',
            ''])), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵeld(0, null, null, 3, 'p', [['class', 'langButton']], null, null, null, null, null)), i1.ɵdid(278528, null, 0, i4.NgClass, [i1.IterableDiffers,
            i1.KeyValueDiffers, i1.ElementRef, i1.Renderer], { klass: [0, 'klass'], ngClass: [1,
                'ngClass'] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PCHeaderComponent_2)), i1.ɵdid(16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null), (_l()(),
            i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PCHeaderComponent_3)), i1.ɵdid(16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null), (_l()(),
            i1.ɵted(null, ['\n']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _co.warning;
        _ck(_v, 1, 0, currVal_0);
        var currVal_4 = 'headerString';
        var currVal_5 = _co.hClass;
        _ck(_v, 8, 0, currVal_4, currVal_5);
        var currVal_7 = 'langButton';
        var currVal_8 = _co.lbClass;
        _ck(_v, 13, 0, currVal_7, currVal_8);
        var currVal_9 = _co.selectLanguageEnabled;
        _ck(_v, 15, 0, currVal_9);
        var currVal_10 = _co.showLang;
        _ck(_v, 18, 0, currVal_10);
    }, function (_ck, _v) {
        var _co = _v.component;
        var currVal_1 = !!!_co.headerLogo;
        var currVal_2 = _co.headerLogo;
        _ck(_v, 5, 0, currVal_1, currVal_2);
        var currVal_3 = !!_co.headerLogo;
        _ck(_v, 7, 0, currVal_3);
        var currVal_6 = _co.headerString;
        _ck(_v, 10, 0, currVal_6);
    });
}
exports.View_PCHeaderComponent_0 = View_PCHeaderComponent_0;
function View_PCHeaderComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'trt-header', [], null, null, null, View_PCHeaderComponent_0, exports.RenderType_PCHeaderComponent)),
        i1.ɵdid(12697600, null, 0, i5.PCHeaderComponent, [i3.TranslateService, i1.ElementRef,
            i6.AppType], null, null)], function (_ck, _v) {
        _ck(_v, 1, 0);
    }, null);
}
exports.View_PCHeaderComponent_Host_0 = View_PCHeaderComponent_Host_0;
exports.PCHeaderComponentNgFactory = i1.ɵccf('trt-header', i5.PCHeaderComponent, View_PCHeaderComponent_Host_0, { headerLogo: 'headerLogo', headerString: 'headerString',
    lang: 'lang', selectLanguageEnabled: 'selectLanguageEnabled', selectLangs: 'selectLangs',
    warning: 'warning' }, {}, []);
