"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./checkbox.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./checkbox.component");
var i3 = require("@angular/forms");
var i4 = require("@angular/common");
var i5 = require("../services/form.service");
var styles_CheckboxComponent = [i0.styles];
exports.RenderType_CheckboxComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_CheckboxComponent, data: {} });
function View_CheckboxComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 13, 'span', [], null, null, null, null, null)), (_l()(),
            i1.ɵeld(0, [['box', 1]], null, 5, 'input', [['type', 'checkbox']], [[8, 'id',
                    0], [2, 'ng-untouched', null], [2, 'ng-touched', null], [2, 'ng-pristine',
                    null], [2, 'ng-dirty', null], [2, 'ng-valid', null],
                [2, 'ng-invalid', null], [2, 'ng-pending', null]], [[null,
                    'change'], [null, 'blur']], function (_v, en, $event) {
                var ad = true;
                var _co = _v.component;
                if (('change' === en)) {
                    var pd_0 = (i1.ɵnov(_v, 2).onChange($event.target.checked) !== false);
                    ad = (pd_0 && ad);
                }
                if (('blur' === en)) {
                    var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
                    ad = (pd_1 && ad);
                }
                if (('change' === en)) {
                    var pd_2 = (_co.setData(i1.ɵnov(_v, 1).checked) !== false);
                    ad = (pd_2 && ad);
                }
                return ad;
            }, null, null)), i1.ɵdid(16384, null, 0, i3.CheckboxControlValueAccessor, [i1.Renderer, i1.ElementRef], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) {
            return [p0_0];
        }, [i3.CheckboxControlValueAccessor]), i1.ɵdid(671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [2, i3.NG_VALUE_ACCESSOR]], { name: [0, 'name'], model: [1, 'model'] }, null), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(16384, null, 0, i3.NgControlStatus, [i3.NgControl], null, null), (_l()(), i1.ɵted(null, ['\n      '])),
        (_l()(), i1.ɵeld(0, null, null, 5, 'label', [], [[8, 'htmlFor',
                0]], null, null, null, null)), i1.ɵdid(278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef,
            i1.Renderer], { ngClass: [0, 'ngClass'] }, null), i1.ɵpod(['small']),
        (_l()(), i1.ɵeld(0, null, null, 0, 'span', [['class', 'check']], null, null, null, null, null)), (_l()(), i1.ɵeld(0, null, null, 1, 'span', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', '']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_8 = _co.tyName;
        var currVal_9 = _co.tyData;
        _ck(_v, 4, 0, currVal_8, currVal_9);
        var currVal_11 = _ck(_v, 10, 0, _co.small);
        _ck(_v, 9, 0, currVal_11);
    }, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = i1.ɵinlineInterpolate(1, 'checkButton', _co.tyName, '');
        var currVal_1 = i1.ɵnov(_v, 6).ngClassUntouched;
        var currVal_2 = i1.ɵnov(_v, 6).ngClassTouched;
        var currVal_3 = i1.ɵnov(_v, 6).ngClassPristine;
        var currVal_4 = i1.ɵnov(_v, 6).ngClassDirty;
        var currVal_5 = i1.ɵnov(_v, 6).ngClassValid;
        var currVal_6 = i1.ɵnov(_v, 6).ngClassInvalid;
        var currVal_7 = i1.ɵnov(_v, 6).ngClassPending;
        _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7);
        var currVal_10 = i1.ɵinlineInterpolate(1, 'checkButton', _co.tyName, '');
        _ck(_v, 8, 0, currVal_10);
        var currVal_12 = _co.tyLabel;
        _ck(_v, 13, 0, currVal_12);
    });
}
exports.View_CheckboxComponent_0 = View_CheckboxComponent_0;
function View_CheckboxComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 2, 'ty-checkbox', [], null, null, null, View_CheckboxComponent_0, exports.RenderType_CheckboxComponent)), i1.ɵprd(512, null, i5.FormService, i5.FormService, []), i1.ɵdid(114688, null, 0, i2.CheckboxComponent, [i5.FormService,
            i1.ElementRef], null, null)], function (_ck, _v) {
        _ck(_v, 2, 0);
    }, null);
}
exports.View_CheckboxComponent_Host_0 = View_CheckboxComponent_Host_0;
exports.CheckboxComponentNgFactory = i1.ɵccf('ty-checkbox', i2.CheckboxComponent, View_CheckboxComponent_Host_0, { tyLabel: 'tyLabel', tyName: 'tyName',
    tyData: 'tyData', tyOnLabel: 'tyOnLabel', tyOffLabel: 'tyOffLabel', small: 'small' }, { changeData: 'changeData' }, []);
