"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@ngx-translate/core");
var core_2 = require("@angular/core");
var Observable_1 = require("rxjs/Observable");
var TableCategoriesComponent = (function () {
    function TableCategoriesComponent(translate, cdRef) {
        var _this = this;
        this.translate = translate;
        this.cdRef = cdRef;
        this.tableCategories = [];
        this.selectCategory = new core_2.EventEmitter();
        this.isPc = false;
        this.showSelect = false;
        this.selectedCategoryStr = '';
        this.freeTimes = [];
        this.categories = Observable_1.Observable.create(function (obs) {
            _this._categoriesObserver = obs;
        });
        this.translate.onLangChange.subscribe(function (data) {
            console.log(data);
            if (_this.selectedCategory) {
                _this.translate.get("TABLE_CATEGORIES." + _this.selectedCategory).toPromise().then(function (str) {
                    _this.selectedCategoryStr = str;
                });
            }
        });
    }
    TableCategoriesComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log(this.obs);
        this.obs = this.freeTable.collection$.subscribe(function (freeTables) {
            console.log(freeTables);
            _this.freeTimes = [];
            _this.tableCategories.map(function (table) {
                var freeTable = freeTables.filter(function (ft) {
                    return ft.keyNames.indexOf(table.key_name) >= 0;
                });
                table.free = freeTable.length >= 1;
                if (freeTable.length >= 1) {
                    console.log(freeTable);
                    _this.freeTimes.push({
                        keyName: table.key_name,
                        endTime: freeTable[0].endTime,
                        endAt: freeTable[0].endAt
                    });
                }
            });
            console.log(_this.freeTimes);
            _this._categoriesObserver.next(_this.tableCategories);
            console.log(_this.selectedCategory);
            if (_this.selectedCategory) {
                _this.translate.get("TABLE_CATEGORIES." + _this.selectedCategory).toPromise().then(function (str) {
                    _this.selectedCategoryStr = str;
                });
            }
            else {
                _this.selectedCategoryStr = '';
            }
        });
    };
    TableCategoriesComponent.prototype.select = function (category) {
        var _this = this;
        console.log(category.free);
        if (category.free) {
            this.translate.get("TABLE_CATEGORIES." + category.key_name).toPromise().then(function (str) {
                _this.selectedCategoryStr = str;
            });
            this.selectedCategory = category.key_name;
            var endTime = this.freeTimes.filter(function (ft) {
                return ft.keyName === category.key_name;
            });
            this.selectCategory.next({
                keyName: category.key_name,
                endTime: endTime[0].endTime,
                endAt: endTime[0].endAt
            });
            this.showSelect = false;
        }
    };
    TableCategoriesComponent.prototype.toggle = function () {
        this.showSelect = !this.showSelect;
    };
    TableCategoriesComponent.prototype.ngOnDestroy = function () {
        this.obs.unsubscribe();
    };
    return TableCategoriesComponent;
}());
__decorate([
    core_2.Input(),
    __metadata("design:type", Object)
], TableCategoriesComponent.prototype, "tableCategories", void 0);
__decorate([
    core_2.Input(),
    __metadata("design:type", Object)
], TableCategoriesComponent.prototype, "freeTable", void 0);
__decorate([
    core_2.Output(),
    __metadata("design:type", core_2.EventEmitter)
], TableCategoriesComponent.prototype, "selectCategory", void 0);
__decorate([
    core_2.Input(),
    __metadata("design:type", Boolean)
], TableCategoriesComponent.prototype, "isPc", void 0);
__decorate([
    core_2.Input(),
    __metadata("design:type", String)
], TableCategoriesComponent.prototype, "selectedCategory", void 0);
TableCategoriesComponent = __decorate([
    core_2.Component({
        selector: 'table-categories',
        template: require('./table-categories.component.html'),
        styles: [require('./table-categories.component.css')]
    }),
    __metadata("design:paramtypes", [core_1.TranslateService, core_2.ChangeDetectorRef])
], TableCategoriesComponent);
exports.TableCategoriesComponent = TableCategoriesComponent;
