"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var GoogleCalendarComponent = (function () {
    function GoogleCalendarComponent() {
    }
    GoogleCalendarComponent.prototype.ngOnInit = function () { };
    return GoogleCalendarComponent;
}());
__decorate([
    core_1.Input(),
    __metadata("design:type", Object)
], GoogleCalendarComponent.prototype, "href", void 0);
GoogleCalendarComponent = __decorate([
    core_1.Component({
        selector: 'trt-google-calendar',
        template: "<a [attr.href]=\"href\" target=\"_blank\">\n    <p><i class=\"icon icon_calendar\"></i><span>{{ 'FINISH.ADD_GOOGLE_CALENDAR' | translate }}</span></p>\n    </a>",
        styles: [require('./google-calendar.component.css')]
    }),
    __metadata("design:paramtypes", [])
], GoogleCalendarComponent);
exports.GoogleCalendarComponent = GoogleCalendarComponent;
