"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var api_service_1 = require("../services/api.service");
var Observable_1 = require("rxjs/Observable");
require("rxjs/add/operator/share");
var ReserveService = (function () {
    function ReserveService(api) {
        var _this = this;
        this.api = api;
        this.api$ = new Observable_1.Observable(function (obs) {
            _this._observer = obs;
        }).share();
    }
    ReserveService.prototype.createTempReservationInfo = function (data) {
        var _this = this;
        this.api.postTempReservationInfo(data).subscribe(function (data) {
            _this._observer.next(data.temporary_reservation);
        }, function (err) {
            _this._observer.error(err);
        });
    };
    ReserveService.prototype.updateTempReservationInfo = function (tmp_id, data) {
        var _this = this;
        this.api.putTempReservationInfo(tmp_id, data).subscribe(function (data) {
            _this._observer.next(data.temporary_reservation);
        }, function (err) {
            _this._observer.error(err);
        });
    };
    ReserveService.prototype.deleteTempReservationInfo = function (tmp_id) {
        var _this = this;
        this.api.deleteTempReservationInfo(tmp_id).subscribe(function (data) {
            _this._observer.next(data);
        }, function (err) {
            _this._observer.error(err);
        });
    };
    ReserveService.prototype.deleteTempReservationInfoSync = function (tmp_id) {
        this.api.deleteTempReservationInfoSync(tmp_id);
    };
    ReserveService.prototype.createTempReservationCustomer = function (temp_id, data) {
        var _this = this;
        this.api.postTempReservationCustomer(temp_id, data).subscribe(function (data) {
            _this._observer.next(data);
        }, function (err) {
            _this._observer.error(err);
        });
    };
    ReserveService.prototype.updateTempReservationCustomer = function (temp_id, data) {
        var _this = this;
        this.api.putTempReservationCustomer(temp_id, data).subscribe(function (data) {
            _this._observer.next(data);
        }, function (err) {
            _this._observer.error(err);
        });
    };
    ReserveService.prototype.commitTempReservation = function (temp_id, restaurant_id) {
        var _this = this;
        this.api.postTempReservationCommit(temp_id, restaurant_id).subscribe(function (data) {
            console.log(data);
            _this._observer.next(data);
        }, function (err) {
            _this._observer.error(err);
        });
    };
    return ReserveService;
}());
ReserveService = __decorate([
    core_1.Injectable(),
    __metadata("design:paramtypes", [api_service_1.ApiService])
], ReserveService);
exports.ReserveService = ReserveService;
