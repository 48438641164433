"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./personal.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("@ngx-translate/core/src/translate.pipe");
var i4 = require("@ngx-translate/core/src/translate.service");
var i5 = require("./personal.component");
var styles_PersonalComponent = [i0.styles];
exports.RenderType_PersonalComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_PersonalComponent, data: {} });
function View_PersonalComponent_2(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'span', [], null, null, null, null, null)), (_l()(),
            i1.ɵted(null, ['( ', ' ', ' )']))], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _co.lastNameReading;
        var currVal_1 = _co.firstNameReading;
        _ck(_v, 1, 0, currVal_0, currVal_1);
    });
}
function View_PersonalComponent_1(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 3, 'p', [['class',
                'personal_name']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['', ' ', ' '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonalComponent_2)), i1.ɵdid(16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null)], function (_ck, _v) {
        var _co = _v.component;
        var currVal_2 = (_co.lastNameReading || _co.firstNameReading);
        _ck(_v, 3, 0, currVal_2);
    }, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _co.lastName;
        var currVal_1 = _co.firstName;
        _ck(_v, 1, 0, currVal_0, currVal_1);
    });
}
function View_PersonalComponent_3(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'p', [['class',
                'personal_name']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['', ' ', '']))], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _co.firstName;
        var currVal_1 = _co.lastName;
        _ck(_v, 1, 0, currVal_0, currVal_1);
    });
}
function View_PersonalComponent_4(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'p', [['class',
                'personal_name']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['', ' ', '']))], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _co.lastName;
        var currVal_1 = _co.firstName;
        _ck(_v, 1, 0, currVal_0, currVal_1);
    });
}
function View_PersonalComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵeld(0, null, null, 39, 'div', [], null, null, null, null, null)), i1.ɵdid(278528, null, 0, i2.NgClass, [i1.IterableDiffers,
            i1.KeyValueDiffers, i1.ElementRef, i1.Renderer], { ngClass: [0, 'ngClass'] }, null),
        i1.ɵpod(['pc']), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵeld(0, null, null, 14, 'div', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n    '])),
        (_l()(), i1.ɵeld(0, null, null, 2, 'label', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i3.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]),
        (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonalComponent_1)), i1.ɵdid(16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null),
        (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonalComponent_3)), i1.ɵdid(16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null),
        (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonalComponent_4)), i1.ɵdid(16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null),
        (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵted(null, ['\n    '])),
        (_l()(), i1.ɵeld(0, null, null, 8, 'div', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 2, 'label', [], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i3.TranslatePipe, [i4.TranslateService,
            i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(),
            i1.ɵeld(0, null, null, 1, 'p', [['class', 'personal_phone']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['', ''])), (_l()(), i1.ɵted(null, ['\n    '])),
        (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 8, 'div', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 2, 'label', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i3.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]),
        (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 1, 'p', [['class', 'personal_email']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), (_l()(),
            i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵted(null, ['\n  '])),
        (_l()(), i1.ɵted(null, ['\n  ']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _ck(_v, 3, 0, _co.pc);
        _ck(_v, 2, 0, currVal_0);
        var currVal_2 = (_co.lang === 'ja');
        _ck(_v, 12, 0, currVal_2);
        var currVal_3 = ((_co.lang !== 'zh-tw') && (_co.lang !== 'ja'));
        _ck(_v, 15, 0, currVal_3);
        var currVal_4 = (_co.lang === 'zh-tw');
        _ck(_v, 18, 0, currVal_4);
    }, function (_ck, _v) {
        var _co = _v.component;
        var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform('NAME'));
        _ck(_v, 8, 0, currVal_1);
        var currVal_5 = i1.ɵunv(_v, 24, 0, i1.ɵnov(_v, 25).transform('MOBILE'));
        _ck(_v, 24, 0, currVal_5);
        var currVal_6 = _co.phone;
        _ck(_v, 28, 0, currVal_6);
        var currVal_7 = i1.ɵunv(_v, 34, 0, i1.ɵnov(_v, 35).transform('EMAIL'));
        _ck(_v, 34, 0, currVal_7);
        var currVal_8 = _co.email;
        _ck(_v, 38, 0, currVal_8);
    });
}
exports.View_PersonalComponent_0 = View_PersonalComponent_0;
function View_PersonalComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'trt-personal', [], null, null, null, View_PersonalComponent_0, exports.RenderType_PersonalComponent)), i1.ɵdid(114688, null, 0, i5.PersonalComponent, [i4.TranslateService], null, null)], function (_ck, _v) {
        _ck(_v, 1, 0);
    }, null);
}
exports.View_PersonalComponent_Host_0 = View_PersonalComponent_Host_0;
exports.PersonalComponentNgFactory = i1.ɵccf('trt-personal', i5.PersonalComponent, View_PersonalComponent_Host_0, { lastName: 'lastName', firstName: 'firstName',
    lastNameReading: 'lastNameReading', firstNameReading: 'firstNameReading', phone: 'phone',
    email: 'email', pc: 'pc' }, {}, []);
