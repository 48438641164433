"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var http_1 = require("@angular/http");
var Observable_1 = require("rxjs/Observable");
require("rxjs/add/observable/of");
require("rxjs/add/operator/share");
require("rxjs/add/operator/map");
require("rxjs/add/operator/merge");
require("rxjs/add/operator/toArray");
var api_service_1 = require("../../../services/api.service");
var app_model_1 = require("../../../../app/models/app.model");
var RestaurantLoader = (function () {
    function RestaurantLoader() {
    }
    return RestaurantLoader;
}());
exports.RestaurantLoader = RestaurantLoader;
var RestaurantStaticLoader = (function () {
    function RestaurantStaticLoader(http, public_key, restaurantData) {
        this.http = http;
        this.data = restaurantData;
    }
    RestaurantStaticLoader.prototype.setRestaurant = function (restaurantData) {
        this.data = restaurantData;
    };
    RestaurantStaticLoader.prototype.getRestaurant = function (public_key, force) {
        var _this = this;
        if (force === void 0) { force = false; }
        this.public_key = public_key || this.public_key;
        console.log('pubkey', this.public_key);
        console.log(this.data);
        if (this.data && !force) {
            return Observable_1.Observable.create(function (obs) {
                obs.next(_this.data);
                obs.complete();
            });
        }
        else {
            return Observable_1.Observable.create(function (obs) {
                _this.http.get(API_URL + "/web/v1/web_reservations/" + _this.public_key + "/contents.json").subscribe(function (d) {
                    obs.next(d.json());
                    obs.complete();
                });
            });
        }
    };
    return RestaurantStaticLoader;
}());
exports.RestaurantStaticLoader = RestaurantStaticLoader;
var RestaurantService = (function () {
    function RestaurantService(http, currentLoader, api, appType) {
        var _this = this;
        this.http = http;
        this.currentLoader = currentLoader;
        this.api = api;
        this.appType = appType;
        this.res$ = new Observable_1.Observable(function (obs) {
            _this._resObs = obs;
        }).share();
    }
    RestaurantService.prototype.setPublicKey = function (public_key) {
        this.public_key = public_key;
    };
    RestaurantService.prototype.use = function (public_key) {
        var _this = this;
        var pending;
        console.log(this._restaurant);
        if (typeof this._restaurant === 'undefined') {
            pending = this.getRestaurant(public_key);
        }
        if (typeof pending !== 'undefined') {
            var penobs_1 = pending.subscribe(function (data) {
                penobs_1.unsubscribe();
                _this.public_key = public_key;
                _this.api.key = data.restaurant.key;
                _this.api.client_key = data.restaurant.client_key;
                console.log(_this.api.key);
            });
            return pending;
        }
        else {
            this.public_key = public_key;
            return Observable_1.Observable.of(this._restaurant);
        }
    };
    RestaurantService.prototype.set = function (key, value) {
        this._restaurant.restaurant[key] = value;
    };
    RestaurantService.prototype.get = function (public_key) {
        console.log(public_key);
        var _restaurant = (this.appType.useLocalStorage()) ? window.localStorage.getItem(public_key) : undefined;
        console.log(_restaurant);
        if (_restaurant) {
            var restaurant = JSON.parse(_restaurant);
            this.currentLoader.setRestaurant(restaurant);
            this.public_key = public_key;
            console.log(restaurant);
            this.api.key = restaurant.restaurant.key;
            this.api.client_key = restaurant.restaurant.client_key;
            this._restaurant = restaurant;
        }
        else if (this._restaurant) {
            this.api.key = this._restaurant.restaurant.key;
            this.api.client_key = this._restaurant.restaurant.client_key;
        }
        else if (this.currentLoader.data) {
            this._restaurant = this.currentLoader.data;
            this.api.key = this._restaurant.restaurant.key;
            this.api.client_key = this._restaurant.restaurant.client_key;
        }
        return this._restaurant;
    };
    RestaurantService.prototype.clear = function () {
        this._restaurant = undefined;
    };
    RestaurantService.prototype.store = function () {
        if (this.appType.useLocalStorage()) {
            console.log(this.public_key);
            window.localStorage.setItem(this.public_key, JSON.stringify(this._restaurant));
        }
    };
    RestaurantService.prototype.release = function () {
        if (this.appType.useLocalStorage()) {
            window.localStorage.removeItem(this.public_key);
        }
    };
    RestaurantService.prototype.getRestaurant = function (public_key, force) {
        var _this = this;
        if (force === void 0) { force = false; }
        this.loader = this.currentLoader.getRestaurant(public_key, force).share();
        var loaderobs = this.loader.subscribe(function (res) {
            if (!!loaderobs) {
                loaderobs.unsubscribe();
            }
            _this._restaurant = res;
            if (_this._resObs) {
                _this._resObs.next(res);
            }
        }, function (err) {
            throw err;
        }, function () {
            _this.loader = undefined;
        });
        return this.loader;
    };
    RestaurantService.prototype.setRestaurant = function (data) {
        this.currentLoader.setRestaurant({
            restaurant: data
        });
    };
    return RestaurantService;
}());
RestaurantService = __decorate([
    core_1.Injectable(),
    __metadata("design:paramtypes", [http_1.Http, RestaurantLoader, api_service_1.ApiService, app_model_1.AppType])
], RestaurantService);
exports.RestaurantService = RestaurantService;
