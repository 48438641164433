"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var app_model_1 = require("../../app/models/app.model");
var restaurant_module_1 = require("./restaurant/restaurant.module");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var CanActivateGuard = (function () {
    function CanActivateGuard(restaurant, appType, router, route) {
        this.restaurant = restaurant;
        this.appType = appType;
        this.router = router;
        this.route = route;
    }
    CanActivateGuard.prototype.canActivate = function (route, state) {
        console.log(this.appType.useProvider());
        if (!(this.appType.useProvider() || this.appType.started) || !this.restaurant.get()) {
            this.router.navigate(['!']).then(function (evt) {
                return true;
            }).catch(function (e) {
                console.log(e);
            });
        }
        else {
            return true;
        }
    };
    return CanActivateGuard;
}());
CanActivateGuard = __decorate([
    core_1.Injectable(),
    __metadata("design:paramtypes", [restaurant_module_1.RestaurantService, app_model_1.AppType, router_1.Router, router_1.ActivatedRoute])
], CanActivateGuard);
exports.CanActivateGuard = CanActivateGuard;
