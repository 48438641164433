"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var http_1 = require("@angular/http");
var Observable_1 = require("rxjs/Observable");
require("rxjs/add/operator/share");
require("rxjs/add/operator/map");
var restaurant_module_1 = require("../../common/services/restaurant/restaurant.module");
var api_service_1 = require("../../common/services/api.service");
var DaysService = (function () {
    function DaysService(http, restaurantService, api) {
        var _this = this;
        this.http = http;
        this.restaurantService = restaurantService;
        this.api = api;
        this._daysCollection = {};
        this.collection$ = new Observable_1.Observable(function (obs) {
            _this._observer = obs;
        }).share();
        this._api$ = new Observable_1.Observable(function (obs) {
            _this._apiObserver = obs;
        }).share();
    }
    DaysService.prototype.ngOnInit = function () {
        var restaurant = this.restaurantService.get().restaurant;
        this.key = restaurant.key;
        console.log(this.key);
    };
    DaysService.prototype.fromApi = function (year, month, seats, reservation_id) {
        var _this = this;
        this.api.getDays(year, month, seats, reservation_id).subscribe({
            next: function (value) { return _this._apiObserver.next(value); },
            error: function (e) { return _this._apiObserver.error(e); },
        });
    };
    DaysService.prototype.getAll = function (start_year, start_month, seats, reservation_id) {
        var _this = this;
        this._daysCollection = {};
        return Observable_1.Observable.create(function (obs) {
            var month = start_month;
            var year = start_year;
            _this._api$.subscribe(function (data) {
                _this._daysCollection[year + "/" + month + ":" + seats] = data;
                if (data.has_next_month) {
                    month++;
                    if (month === 13) {
                        year += 1;
                        month = 1;
                    }
                    _this.fromApi(year, month, seats, reservation_id);
                }
                else {
                    obs.next(true);
                }
            });
            _this.fromApi(start_year, start_month, seats, reservation_id);
        });
    };
    DaysService.prototype.getApi = function (start_year, start_month, seats, cnt, reservation_id, cache, forceOnceCache) {
        var _this = this;
        if (cnt === void 0) { cnt = 1; }
        if (cache === void 0) { cache = true; }
        if (forceOnceCache === void 0) { forceOnceCache = false; }
        console.log(cnt);
        console.log(forceOnceCache, this.forceOnceCache);
        this.forceOnceCache = forceOnceCache || this.forceOnceCache;
        return Observable_1.Observable.create(function (obs) {
            cache = cache || _this.forceOnceCache;
            var apiobs = _this._api$.subscribe({
                next: function (data) {
                    console.log(data);
                    var tz = new Date().getTimezoneOffset() * 60;
                    var d = new Date((data.start_at + data.timezone_offset + tz) * 1000);
                    var month = d.getMonth() + 1;
                    var year = d.getFullYear();
                    _this._daysCollection[year + "/" + month + ":" + seats] = data;
                    if (data.has_next_month && cnt > 1) {
                        if (month === 12) {
                            year += 1;
                            month = 0;
                        }
                        if (_this._daysCollection[year + "/" + (month + 1) + ":" + seats] &&
                            cache) {
                            setTimeout(function () {
                                _this._apiObserver.next(_this._daysCollection[year + "/" + (month + 1) + ":" + seats]);
                            }, 0);
                        }
                        else {
                            setTimeout(function () {
                                _this.fromApi(year, month + 1, seats, reservation_id);
                            }, 0);
                        }
                        cnt--;
                    }
                    else {
                        setTimeout(function () {
                            obs.next(true);
                        }, 0);
                        setTimeout(function () {
                            apiobs.unsubscribe();
                            _this.forceOnceCache = forceOnceCache;
                        }, 0);
                    }
                },
                error: function (e) {
                    _this._observer.error(e);
                    console.log(e);
                },
            });
            console.log(_this._daysCollection[start_year + "/" + start_month + ":" + seats] && cache);
            if (_this._daysCollection[start_year + "/" + start_month + ":" + seats] &&
                cache) {
                _this._apiObserver.next(_this._daysCollection[start_year + "/" + start_month + ":" + seats]);
            }
            else {
                _this.fromApi(start_year, start_month, seats, reservation_id);
            }
        });
    };
    DaysService.prototype.get = function (year, month, seats, cnt) {
        var _this = this;
        if (year === void 0) { year = null; }
        if (month === void 0) { month = null; }
        if (seats === void 0) { seats = 1; }
        if (cnt === void 0) { cnt = 1; }
        console.log(year, month);
        if (year && month) {
            var dc = [];
            for (var i = 0; i < cnt; i++) {
                month += i;
                if (month === 13) {
                    year += 1;
                    month = 1;
                }
                if (this._daysCollection[year + "/" + month + ":" + seats]) {
                    dc.push(this._daysCollection[year + "/" + month + ":" + seats]);
                }
            }
            console.log(dc);
            this._observer.next(dc);
        }
        else {
            this._observer.next(Object.keys(this._daysCollection).map(function (k) { return _this._daysCollection[k]; }));
        }
    };
    DaysService.prototype.haveDates = function (year, month, seats) {
        console.log(this._daysCollection[year + "/" + month + ":" + seats]);
        return this._daysCollection[year + "/" + month + ":" + seats];
    };
    return DaysService;
}());
DaysService = __decorate([
    core_1.Injectable(),
    __metadata("design:paramtypes", [http_1.Http,
        restaurant_module_1.RestaurantService,
        api_service_1.ApiService])
], DaysService);
exports.DaysService = DaysService;
