"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("rxjs/add/operator/map");
var TranslateHttpLoader = (function () {
    function TranslateHttpLoader(http, prefix, suffix, overrideTranslation) {
        if (prefix === void 0) { prefix = '/assets/i18n/'; }
        if (suffix === void 0) { suffix = '.json'; }
        if (overrideTranslation === void 0) { overrideTranslation = {}; }
        this.http = http;
        this.prefix = prefix;
        this.suffix = suffix;
        this.overrideTranslation = overrideTranslation;
    }
    TranslateHttpLoader.prototype.getTranslation = function (lang) {
        var _this = this;
        return this.http.get("" + this.prefix + lang + this.suffix)
            .map(function (res) {
            var _res = res.json();
            var overrideTranslation = _this.overrideTranslation[lang];
            if (overrideTranslation) {
                _this.overrideTranslate(_res, overrideTranslation);
            }
            return _res;
        });
    };
    TranslateHttpLoader.prototype.overrideTranslate = function (translation, overrideTranslation) {
        var _this = this;
        Object.keys(overrideTranslation).forEach(function (_ot) {
            if (typeof (overrideTranslation[_ot]) === 'string') {
                translation[_ot] = overrideTranslation[_ot];
            }
            else if (typeof (overrideTranslation[_ot]) === 'number') {
                translation[_ot] = overrideTranslation[_ot];
            }
            else {
                _this.overrideTranslate(translation[_ot], overrideTranslation[_ot]);
            }
        });
        return overrideTranslation;
    };
    return TranslateHttpLoader;
}());
exports.TranslateHttpLoader = TranslateHttpLoader;
