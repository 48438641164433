"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var FooterComponent = (function () {
    function FooterComponent(translate, cdRef) {
        var _this = this;
        this.translate = translate;
        this.cdRef = cdRef;
        this.country = "jp";
        this.isPay = false;
        this.translate.onLangChange.subscribe(function (d) {
            _this.setUrl(_this.country, d.lang);
        });
    }
    FooterComponent.prototype.ngOnInit = function () {
        this.setUrl(this.country, this.translate.currentLang);
    };
    FooterComponent.prototype.ngOnChange = function () {
        this.setUrl(this.country, this.translate.currentLang);
    };
    FooterComponent.prototype.setUrl = function (country, lang) {
        console.log(country);
        var toreta_asia = ["sg", "vn", "th", "my", "id", "ph", "kh", "hk"];
        var toreta_taiwan = ["tw"];
        if (toreta_asia.indexOf(country) > -1) {
            this.privacyUrl = "http://toretaasia.com/privacy-policy/";
            this.termsUrl = "http://toretaasia.com/terms-of-service/";
            this.siteUrl = "http://toretaasia.com";
        }
        else if (toreta_taiwan.indexOf(country) > -1) {
            this.privacyUrl = "http://toretaasia.com/tw/privacy-policy/";
            this.termsUrl = "http://toretaasia.com/tw/terms-of-service/";
            this.siteUrl = "http://toretaasia.com/tw/";
        }
        else {
            if (lang === "ja") {
                this.privacyUrl = "https://corp.toreta.in/privacy";
                this.termsUrl = "https://toreta.in/terms/web_reservation/";
            }
            else {
                this.privacyUrl = "https://corp.toreta.in/privacy-en";
                this.termsUrl = "https://toreta.in/terms/web_reservation_en/";
            }
            this.siteUrl = "https://toreta.in/jp/";
        }
    };
    return FooterComponent;
}());
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], FooterComponent.prototype, "country", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], FooterComponent.prototype, "isPay", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Number)
], FooterComponent.prototype, "inquiryId", void 0);
FooterComponent = __decorate([
    core_1.Component({
        selector: "trt-footer",
        styles: [require('./footer.component.css')],
        template: require('./footer.component.html'),
    }),
    __metadata("design:paramtypes", [core_2.TranslateService,
        core_1.ChangeDetectorRef])
], FooterComponent);
exports.FooterComponent = FooterComponent;
var PCFooterComponent = (function (_super) {
    __extends(PCFooterComponent, _super);
    function PCFooterComponent(translate, cdRef) {
        var _this = _super.call(this, translate, cdRef) || this;
        _this.translate = translate;
        _this.cdRef = cdRef;
        return _this;
    }
    return PCFooterComponent;
}(FooterComponent));
PCFooterComponent = __decorate([
    core_1.Component({
        selector: "trt-footer",
        styles: [require('./footer.pc.component.css')],
        template: require('./footer.component.html'),
    }),
    __metadata("design:paramtypes", [core_2.TranslateService,
        core_1.ChangeDetectorRef])
], PCFooterComponent);
exports.PCFooterComponent = PCFooterComponent;
