"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var can_activate_guard_service_1 = require("../common/services/can-activate-guard.service");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var payment_component_1 = require("./components/payment.component");
var can_deactivate_guard_service_1 = require("../common/services/can-deactivate-guard.service");
var routes = [
    { path: 'payment',
        component: payment_component_1.PaymentComponent,
        canActivate: [can_activate_guard_service_1.CanActivateGuard],
        canDeactivate: [can_deactivate_guard_service_1.CanDeactivateGuard] }
];
var PaymentRoutingModule = (function () {
    function PaymentRoutingModule() {
    }
    return PaymentRoutingModule;
}());
PaymentRoutingModule = __decorate([
    core_1.NgModule({
        imports: [router_1.RouterModule.forChild(routes)]
    })
], PaymentRoutingModule);
exports.PaymentRoutingModule = PaymentRoutingModule;
var pcroutes = [
    { path: 'payment',
        component: payment_component_1.PCPaymentComponent,
        canActivate: [can_activate_guard_service_1.CanActivateGuard],
        canDeactivate: [can_deactivate_guard_service_1.CanDeactivateGuard] }
];
var PCPaymentRoutingModule = (function () {
    function PCPaymentRoutingModule() {
    }
    return PCPaymentRoutingModule;
}());
PCPaymentRoutingModule = __decorate([
    core_1.NgModule({
        imports: [router_1.RouterModule.forChild(pcroutes)]
    })
], PCPaymentRoutingModule);
exports.PCPaymentRoutingModule = PCPaymentRoutingModule;
