"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var table_categories_component_1 = require("../reserve-info/components/table-categories.component");
var table_smoking_component_1 = require("../reserve-info/components/table-smoking.component");
var time_select_component_1 = require("../reserve-info/components/time-select.component");
var policy_component_1 = require("../reserve-confirmation/components/policy.component");
var credit_card_pipe_1 = require("../payment/pipes/credit-card.pipe");
var linebutton_component_1 = require("../finish/components/linebutton.component");
var google_calendar_component_1 = require("../finish/components/google-calendar.component");
var can_activate_guard_service_1 = require("./services/can-activate-guard.service");
var can_deactivate_guard_service_1 = require("./services/can-deactivate-guard.service");
var restaurant_module_1 = require("./services/restaurant/restaurant.module");
var reserve_form_service_1 = require("./services/reserve-form.service");
var form_module_1 = require("../form/form.module");
var week_day_service_1 = require("./services/week-day.service");
var web_hooks_service_1 = require("./services/web-hooks.service");
var toreta_time_service_1 = require("./services/toreta-time.service");
var toreta_date_service_1 = require("./services/toreta-date.service");
var restaurant_resolver_1 = require("./services/restaurant.resolver");
var reserve_service_1 = require("./services/reserve.service");
var params_service_1 = require("./services/params.service");
var intl_tel_service_1 = require("./services/intl-tel.service");
var from_service_1 = require("./services/from.service");
var countries_service_1 = require("./services/countries.service");
var api_service_1 = require("./services/api.service");
var ssl_seal_component_1 = require("./components/ssl-seal.component");
var reservation_component_1 = require("./components/reservation.component");
var personal_component_1 = require("./components/personal.component");
var calendar_component_1 = require("./components/calendar.component");
var optin_confirm_component_1 = require("./components/optin-confirm.component");
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var mask_component_1 = require("./components/mask.component");
var index_1 = require("./pipes/index");
var core_2 = require("@ngx-translate/core");
exports._ua = function () {
    var u = '';
    if (true) {
        u = window.navigator.userAgent.toLowerCase();
    }
    else {
    }
    console.log(u);
    return {
        Tablet: (u.indexOf('windows') !== -1 && u.indexOf('touch') !== -1 && u.indexOf('tablet pc') == -1)
            || u.indexOf('ipad') !== -1
            || (u.indexOf('android') !== -1 && u.indexOf('mobile') === -1)
            || (u.indexOf('firefox') !== -1 && u.indexOf('tablet') !== -1)
            || u.indexOf('kindle') !== -1
            || u.indexOf('silk') !== -1
            || u.indexOf('playbook') !== -1,
        Mobile: (u.indexOf('windows') !== -1 && u.indexOf('phone') !== -1)
            || u.indexOf('iphone') !== -1
            || u.indexOf('ipod') !== -1
            || (u.indexOf('android') !== -1 && u.indexOf('mobile') !== -1)
            || (u.indexOf('firefox') !== -1 && u.indexOf('mobile') !== -1)
            || u.indexOf('blackberry') !== -1
    };
};
exports.isPc = !exports._ua().Mobile;
exports.isMobile = exports._ua().Mobile;
var TRTCommonModule = TRTCommonModule_1 = (function () {
    function TRTCommonModule() {
    }
    TRTCommonModule.forRoot = function () {
        return {
            ngModule: TRTCommonModule_1,
            providers: [
                api_service_1.ApiService,
                countries_service_1.CountriesService,
                from_service_1.FromService,
                intl_tel_service_1.IntlTelService,
                params_service_1.ParamsService,
                reserve_service_1.ReserveService,
                restaurant_resolver_1.RestaurantResolver,
                toreta_date_service_1.TRTDateService,
                toreta_time_service_1.TRTTimeService,
                web_hooks_service_1.WebHooksService,
                week_day_service_1.WeekDayService,
                reserve_form_service_1.ReserveFormService,
                restaurant_module_1.RestaurantService,
                can_deactivate_guard_service_1.CanDeactivateGuard,
                can_activate_guard_service_1.CanActivateGuard,
            ]
        };
    };
    return TRTCommonModule;
}());
TRTCommonModule = TRTCommonModule_1 = __decorate([
    core_1.NgModule({
        imports: [
            common_1.CommonModule,
            form_module_1.TRTFormModule,
            core_2.TranslateModule
        ],
        exports: index_1.TRT_COMMON_PIPES.concat([
            mask_component_1.MaskComponent,
            calendar_component_1.CalendarComponent,
            personal_component_1.PersonalComponent,
            reservation_component_1.ReservationComponent,
            ssl_seal_component_1.SSLSealComponent,
            google_calendar_component_1.GoogleCalendarComponent,
            linebutton_component_1.LineButtonComponent,
            credit_card_pipe_1.CreditCardNoPipe,
            credit_card_pipe_1.CreditCardExpirePipe,
            policy_component_1.PolicyComponent,
            time_select_component_1.TimeSelectComponent,
            table_categories_component_1.TableCategoriesComponent,
            table_smoking_component_1.TableSmokingComponent,
            optin_confirm_component_1.OptinConfirmComponent
        ]),
        declarations: index_1.TRT_COMMON_PIPES.concat([
            mask_component_1.MaskComponent,
            calendar_component_1.CalendarComponent,
            personal_component_1.PersonalComponent,
            reservation_component_1.ReservationComponent,
            ssl_seal_component_1.SSLSealComponent,
            google_calendar_component_1.GoogleCalendarComponent,
            linebutton_component_1.LineButtonComponent,
            credit_card_pipe_1.CreditCardNoPipe,
            credit_card_pipe_1.CreditCardExpirePipe,
            policy_component_1.PolicyComponent,
            time_select_component_1.TimeSelectComponent,
            table_categories_component_1.TableCategoriesComponent,
            table_smoking_component_1.TableSmokingComponent,
            optin_confirm_component_1.OptinConfirmComponent
        ])
    })
], TRTCommonModule);
exports.TRTCommonModule = TRTCommonModule;
var TRTCommonModule_1;
