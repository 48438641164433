"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var term_service_1 = require("../services/term.service");
var core_2 = require("@ngx-translate/core");
var TermComponent = (function () {
    function TermComponent(translate, termService, cdRef) {
        var _this = this;
        this.translate = translate;
        this.termService = termService;
        this.cdRef = cdRef;
        this._trans = translate.onLangChange.subscribe(function (r) {
            console.log(r);
            _this.setTerm();
        });
    }
    TermComponent.prototype.ngOnInit = function () {
        this.setTerm();
    };
    TermComponent.prototype.ngOnDestroy = function () {
        this._trans.unsubscribe();
    };
    TermComponent.prototype.setTerm = function (globalLang) {
        var _this = this;
        if (globalLang === void 0) { globalLang = null; }
        var lang = (this.country === 'tw') ? 'zh-tw' : globalLang || this.translate.currentLang;
        var country = (this.country === 'tw' || this.country === 'jp' || this.country === 'vn') ? this.country : 'sg';
        this.termService.get(this.type, country, lang).subscribe(function (html) {
            _this.termHtml = html;
        }, function (error) {
            console.log(error);
            _this.setTerm(_this.restaurantLang || 'en');
        }, function () {
            _this.cdRef.detectChanges();
        });
    };
    return TermComponent;
}());
__decorate([
    core_1.Input(),
    __metadata("design:type", Object)
], TermComponent.prototype, "type", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Object)
], TermComponent.prototype, "country", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Object)
], TermComponent.prototype, "restaurantLang", void 0);
TermComponent = __decorate([
    core_1.Component({
        selector: 'trt-term',
        template: require('./term.component.html'),
        styles: ["\n    dd {\n      margin-top: 1em;\n      margin-bottom: 1em;\n    }\n  "]
    }),
    __metadata("design:paramtypes", [core_2.TranslateService, term_service_1.TermService, core_1.ChangeDetectorRef])
], TermComponent);
exports.TermComponent = TermComponent;
