"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var payment_docomo_module_1 = require("../payment-docomo/payment-docomo.module");
var payment_select_module_1 = require("../payment-select/payment-select.module");
var dist_1 = require("angulartics2/dist");
var common_1 = require("@angular/common");
var router_1 = require("@angular/router");
var finish_module_1 = require("../finish/finish.module");
var payment_module_1 = require("../payment/payment.module");
var reserve_confirmation_module_1 = require("../reserve-confirmation/reserve-confirmation.module");
var personal_info_module_1 = require("../personal-info/personal-info.module");
var reserve_info_module_1 = require("../reserve-info/reserve-info.module");
var core_1 = require("@angular/core");
var home_module_1 = require("../home/home.module");
var header_module_1 = require("../header/header.module");
var footer_module_1 = require("../footer/footer.module");
var app_component_1 = require("./components/app.component");
var app_routes_1 = require("./app.routes");
var core_2 = require("@ngx-translate/core");
var AppModule = (function () {
    function AppModule() {
    }
    return AppModule;
}());
AppModule = __decorate([
    core_1.NgModule({
        imports: [
            router_1.RouterModule,
            common_1.CommonModule,
            core_2.TranslateModule,
            header_module_1.HeaderModule,
            footer_module_1.FooterModule,
            home_module_1.HomeModule,
            reserve_info_module_1.ReserveInfoModule,
            personal_info_module_1.PersonalInfoModule,
            reserve_confirmation_module_1.ReserveConfirmationModule,
            payment_select_module_1.PaymentSelectModule,
            payment_docomo_module_1.PaymentDocomoModule,
            payment_module_1.PaymentModule,
            finish_module_1.FinishModule,
            app_routes_1.AppRoutingModule,
            dist_1.Angulartics2Module.forRoot([dist_1.Angulartics2GoogleAnalytics])
        ],
        exports: [app_component_1.AppComponent],
        declarations: [app_component_1.AppComponent],
    })
], AppModule);
exports.AppModule = AppModule;
var app_component_2 = require("./components/app.component");
exports.AppComponent = app_component_2.AppComponent;
var PCAppModule = (function () {
    function PCAppModule() {
    }
    return PCAppModule;
}());
PCAppModule = __decorate([
    core_1.NgModule({
        imports: [
            router_1.RouterModule,
            common_1.CommonModule,
            core_2.TranslateModule,
            header_module_1.PCHeaderModule,
            footer_module_1.PCFooterModule,
            home_module_1.PCHomeModule,
            reserve_info_module_1.PCReserveInfoModule,
            personal_info_module_1.PCPersonalInfoModule,
            reserve_confirmation_module_1.PCReserveConfirmationModule,
            payment_docomo_module_1.PCPaymentDocomoModule,
            payment_select_module_1.PCPaymentSelectModule,
            payment_module_1.PCPaymentModule,
            finish_module_1.PCFinishModule,
            app_routes_1.AppRoutingModule,
            dist_1.Angulartics2Module.forRoot([dist_1.Angulartics2GoogleAnalytics])
        ],
        exports: [app_component_1.PCAppComponent],
        declarations: [app_component_1.PCAppComponent],
    })
], PCAppModule);
exports.PCAppModule = PCAppModule;
var app_component_3 = require("./components/app.component");
exports.PCAppComponent = app_component_3.PCAppComponent;
