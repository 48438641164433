"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var form_service_1 = require("../services/form.service");
var input_component_1 = require("./input.component");
var ToggleComponent = (function (_super) {
    __extends(ToggleComponent, _super);
    function ToggleComponent(_formService) {
        var _this = _super.call(this, _formService) || this;
        _this._formService = _formService;
        _this.changeData = new core_1.EventEmitter();
        return _this;
    }
    return ToggleComponent;
}(input_component_1.InputComponent));
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], ToggleComponent.prototype, "tyLabel", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], ToggleComponent.prototype, "tyName", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], ToggleComponent.prototype, "tyData", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], ToggleComponent.prototype, "tyOnLabel", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], ToggleComponent.prototype, "tyOffLabel", void 0);
__decorate([
    core_1.Output(),
    __metadata("design:type", core_1.EventEmitter)
], ToggleComponent.prototype, "changeData", void 0);
ToggleComponent = __decorate([
    core_1.Component({
        selector: 'ty-toggle',
        template: "<div class=\"ty-toggle\">\n    <label>\n      <span>{{tyLabel}}</span>\n      <div class=\"toggle-button toggle-button--aava\">\n          <input id=\"toggleButton{{tyName}}\" type=\"checkbox\" #box [name]=\"tyName\" [ngModel]=\"tyData\" (change)=\"setData(box.checked)\">\n          <label htmlFor=\"toggleButton{{tyName}}\" [attr.data-on-text]=\"tyOnLabel\" [attr.data-off-text]=\"tyOffLabel\"></label>\n          <div class=\"toggle-button__icon\"></div>\n      </div>\n    </label>\n  </div>",
        styles: [require('./toggle.component.css')],
        providers: [form_service_1.FormService]
    }),
    __metadata("design:paramtypes", [form_service_1.FormService])
], ToggleComponent);
exports.ToggleComponent = ToggleComponent;
