"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var angulartics2_1 = require("angulartics2");
var core_1 = require("@angular/core");
var TimeSelectComponent = (function () {
    function TimeSelectComponent(elementRef, cdRef, angulartics2) {
        this.elementRef = elementRef;
        this.cdRef = cdRef;
        this.angulartics2 = angulartics2;
        this.times = [];
        this.clickTime = new core_1.EventEmitter();
        this.isScroll = true;
        this.initTime = 68400;
    }
    TimeSelectComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.timeWrapEle = this.elementRef.nativeElement.querySelector('#timeWrap');
        this.timeBarEle = this.elementRef.nativeElement.querySelector('#timeBar');
        var pLen = this.timeWrapEle.querySelectorAll('p').length;
        this.barWidth = pLen * 60 + 60 + 'px';
        this.times.forEach(function (time, idx) {
            if (time.start_time < _this.initTime) {
                _this.positionTime = idx + 1;
            }
            if (time.start_time === _this.initTime) {
                _this.positionTime = idx;
            }
        });
        var wrap50 = this.timeWrapEle.clientWidth / 2;
        var timeWidth = pLen * 60 + 27 - this.timeWrapEle.clientWidth;
        var scrollLeft = this.positionTime * 60 + 30 - wrap50;
        this.isScroll = timeWidth > 0;
        this.cdRef.detectChanges();
        setTimeout(function () {
            _this.isScroll =
                _this.timeBarEle.clientWidth - _this.timeWrapEle.clientWidth > 0;
            _this.timeWrapEle.scrollLeft = scrollLeft;
        }, 0);
    };
    TimeSelectComponent.prototype.scrollLeftClick = function () {
        var _this = this;
        var cnt = 1;
        var moveScroll = setInterval(function () {
            _this.timeWrapEle.scrollLeft -= 1;
            if (cnt++ > 60) {
                clearInterval(moveScroll);
            }
        }, 1);
    };
    TimeSelectComponent.prototype.scrollRightClick = function () {
        var _this = this;
        var cnt = 1;
        var moveScroll = setInterval(function () {
            _this.timeWrapEle.scrollLeft += 1;
            if (cnt++ > 60) {
                clearInterval(moveScroll);
            }
        }, 1);
    };
    TimeSelectComponent.prototype.setTime = function (time) {
        this.angulartics2.eventTrack.next({
            action: 'click',
            properties: { category: 'search', label: 'TimeChoice' },
        });
        this.clickTime.next({
            date: this.date,
            time: time,
        });
    };
    return TimeSelectComponent;
}());
__decorate([
    core_1.Input(),
    __metadata("design:type", Object)
], TimeSelectComponent.prototype, "times", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Object)
], TimeSelectComponent.prototype, "date", void 0);
__decorate([
    core_1.Output(),
    __metadata("design:type", core_1.EventEmitter)
], TimeSelectComponent.prototype, "clickTime", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], TimeSelectComponent.prototype, "isPc", void 0);
TimeSelectComponent = __decorate([
    core_1.Component({
        selector: 'trt-time-select',
        template: require('./time-select.component.html'),
        styles: [require('./time-select.component.css')],
    }),
    __metadata("design:paramtypes", [core_1.ElementRef,
        core_1.ChangeDetectorRef,
        angulartics2_1.Angulartics2])
], TimeSelectComponent);
exports.TimeSelectComponent = TimeSelectComponent;
