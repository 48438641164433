"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var toreta_time_service_1 = require("../services/toreta-time.service");
var core_2 = require("@ngx-translate/core");
var TimePipe = (function () {
    function TimePipe(trtTime, translate) {
        this.trtTime = trtTime;
        this.translate = translate;
    }
    TimePipe.prototype.transform = function (value, args) {
        var _this = this;
        if (args === void 0) { args = []; }
        if (this.lastValue === value && this.lastLang === this.translate.currentLang) {
            return this.value;
        }
        else {
            this.lastValue = value;
            this.lastLang = this.translate.currentLang;
            this.trtTime.transform(value).then(function (v) {
                _this.value = v;
            });
            return this.value;
        }
    };
    TimePipe.prototype._dispose = function () {
        this.lastLang = null;
        this.lastValue = null;
        this.value = null;
    };
    TimePipe.prototype.ngOnDestroy = function () {
        this._dispose();
    };
    return TimePipe;
}());
TimePipe = __decorate([
    core_1.Pipe({
        name: 'trtTime',
        pure: false
    }),
    __metadata("design:paramtypes", [toreta_time_service_1.TRTTimeService, core_2.TranslateService])
], TimePipe);
exports.TimePipe = TimePipe;
