"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var Observable_1 = require("rxjs/Observable");
require("rxjs/add/operator/share");
var app_model_1 = require("../../app/models/app.model");
var ReserveForm = (function () {
    function ReserveForm() {
    }
    return ReserveForm;
}());
var ReserveFormService = (function () {
    function ReserveFormService(appType) {
        var _this = this;
        this.appType = appType;
        this._reserveForm = new ReserveForm();
        this.reserveFormCollection$ = new Observable_1.Observable(function (observer) {
            _this._observer = observer;
        }).share();
    }
    ReserveFormService.prototype.set = function (key, val, idx, idx2) {
        console.log(key, val, idx, idx2);
        if (idx !== undefined) {
            if (idx2 !== undefined) {
                if (!this._reserveForm[key]) {
                    this._reserveForm[key] = {};
                }
                if (!this._reserveForm[key][idx]) {
                    this._reserveForm[key][idx] = {};
                }
                this._reserveForm[key][idx][idx2] = val;
            }
            else {
                if (!this._reserveForm[key]) {
                    this._reserveForm[key] = [];
                }
                this._reserveForm[key][idx] = val;
            }
        }
        else {
            this._reserveForm[key] = val;
        }
        console.log(this._reserveForm);
        if (this._observer) {
            this._observer.next(this._reserveForm);
        }
        return val;
    };
    ReserveFormService.prototype.get = function () {
        var _this = this;
        var obj = new ReserveForm();
        Object.keys(this._reserveForm).forEach(function (key) {
            if (_this._reserveForm[key] !== null && _this._reserveForm[key] !== undefined) {
                obj[key] = _this._reserveForm[key];
            }
        });
        if (this._observer) {
            this._observer.next(obj);
        }
        return obj;
    };
    ReserveFormService.prototype.getApiFormat = function () {
        var _this = this;
        var data = {};
        Object.keys(this._reserveForm).forEach(function (key) {
            if (_this._reserveForm[key] !== null && _this._reserveForm[key] !== undefined) {
                data[key] = _this._reserveForm[key];
            }
        });
        var reserveData = {
            restaurant_id: data.restaurant_id,
            seats_adults: data.seats_adults || data.seats,
            seats_children: data.seats_children,
            start_at: data.start_at,
            is_deposit: data.is_deposit || false
        };
        if (data.note) {
            reserveData.note = data.note;
        }
        if (data.end_at) {
            reserveData.end_at = data.end_at;
        }
        if (data.start_time) {
            reserveData.start_time = data.start_time;
        }
        if (data.end_time) {
            reserveData.end_time = data.end_time;
        }
        if (data.courses) {
            var courses_1 = [];
            var _c_1 = {};
            data.courses.forEach(function (c) {
                console.log(c);
                if (!_c_1[c.course_id]) {
                    _c_1[c.course_id] = +c.count;
                }
                else {
                    _c_1[c.course_id] += +c.count;
                }
            });
            Object.keys(_c_1).forEach(function (key) {
                if (_c_1[key] > 0 && key) {
                    courses_1.push({
                        course_id: key,
                        count: _c_1[key]
                    });
                }
            });
            if (courses_1.length) {
                reserveData.courses = courses_1;
            }
        }
        if (data.ref) {
            reserveData.contact_from = data.ref.contact_from;
            reserveData.contact_from_key = data.ref.contact_from_key;
            reserveData.referrer = data.ref.referrer;
        }
        if (data.table_category) {
            reserveData.table_category = data.table_category;
        }
        if (data.table_smoking) {
            reserveData.smoking = data.table_smoking;
        }
        return reserveData;
    };
    ReserveFormService.prototype.store = function () {
        if (this.appType.useLocalStorage()) {
            window.localStorage.setItem(this._reserveForm.restaurant_id + ":reserveForm", JSON.stringify(this._reserveForm));
        }
    };
    ReserveFormService.prototype.read = function (restaurant_id) {
        var _this = this;
        var reserveData;
        if (this.appType.useLocalStorage()) {
            var _reserveData = window.localStorage && window.localStorage.getItem(restaurant_id + ":reserveForm");
            if (_reserveData) {
                reserveData = JSON.parse(_reserveData);
                Object.keys(reserveData).forEach(function (key) {
                    if (!_this._reserveForm[key]) {
                        _this._reserveForm[key] = reserveData[key];
                    }
                });
            }
        }
        return reserveData;
    };
    ReserveFormService.prototype.release = function () {
        if (this.appType.useLocalStorage()) {
            window.localStorage.removeItem(this._reserveForm.restaurant_id + ":reserveForm");
        }
    };
    ReserveFormService.prototype.clear = function () {
        this._reserveForm = new ReserveForm();
    };
    return ReserveFormService;
}());
ReserveFormService = __decorate([
    core_1.Injectable(),
    __metadata("design:paramtypes", [app_model_1.AppType])
], ReserveFormService);
exports.ReserveFormService = ReserveFormService;
