"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./mask.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./mask.component");
var styles_MaskComponent = [i0.styles];
exports.RenderType_MaskComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_MaskComponent, data: {} });
function View_MaskComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'div', [], [[4, 'backgroundColor', null], [4, 'opacity', null]], [[null,
                'click']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('click' === en)) {
                var pd_0 = (_co.onClick($event) !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _co.backgroundColor;
        var currVal_1 = _co.opacity;
        _ck(_v, 0, 0, currVal_0, currVal_1);
    });
}
exports.View_MaskComponent_0 = View_MaskComponent_0;
function View_MaskComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'trt-mask', [], null, null, null, View_MaskComponent_0, exports.RenderType_MaskComponent)),
        i1.ɵdid(49152, null, 0, i2.MaskComponent, [], null, null)], null, null);
}
exports.View_MaskComponent_Host_0 = View_MaskComponent_Host_0;
exports.MaskComponentNgFactory = i1.ɵccf('trt-mask', i2.MaskComponent, View_MaskComponent_Host_0, { backgroundColor: 'backgroundColor',
    opacity: 'opacity' }, { clickEvent: 'clickEvent' }, ['*']);
