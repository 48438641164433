"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var TRTTimeService = (function () {
    function TRTTimeService(translate) {
        this.translate = translate;
    }
    TRTTimeService.prototype.transform = function (value) {
        var _this = this;
        console.log(value);
        if (value === null || value === undefined) {
            return Promise.resolve('');
        }
        if (this.lang !== this.translate.currentLang) {
            console.log(this.translate);
            this.lang = this.translate.currentLang;
        }
        var h = '0';
        var m = '0';
        return this.translate.get(['AM', 'PM']).forEach(function (v) {
            _this.am = v.AM;
            _this.pm = v.PM;
            console.log(v);
        }).then(function () {
            if (_this.lang === 'ja') {
                h = ('0' + Math.floor(value / 3600)).slice(-2);
                m = ('0' + Math.floor(value % 3600 / 60)).slice(-2);
                return h + ":" + m;
            }
            else {
                var _h = Math.floor(value / 3600);
                var cf = (_h < 12) ? _this.am : (_h < 24) ? _this.pm : _this.am;
                if (_h === 0 || _h === 12 || _h === 24) {
                    h = '12';
                }
                else if (_h < 12) {
                    h = ('0' + _h).slice(-2);
                }
                else if (_h < 24) {
                    h = ('0' + (_h - 12)).slice(-2);
                }
                else {
                    h = ('0' + (_h - 24)).slice(-2);
                }
                m = ('0' + (value % 3600 / 60)).slice(-2);
                var hh = (_this.lang === 'zh-tw') ? "" + cf + h + ":" + m : h + ":" + m + cf;
                return hh;
            }
        });
    };
    return TRTTimeService;
}());
TRTTimeService = __decorate([
    core_1.Injectable(),
    __metadata("design:paramtypes", [core_2.TranslateService])
], TRTTimeService);
exports.TRTTimeService = TRTTimeService;
