"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var common_1 = require("@angular/common");
var core_1 = require("@angular/core");
var footer_component_1 = require("./components/footer.component");
var common_module_1 = require("../common/common.module");
var core_2 = require("@ngx-translate/core");
var FooterModule = (function () {
    function FooterModule() {
    }
    return FooterModule;
}());
FooterModule = __decorate([
    core_1.NgModule({
        imports: [
            router_1.RouterModule,
            common_1.CommonModule,
            core_2.TranslateModule,
            common_module_1.TRTCommonModule
        ],
        exports: [footer_component_1.FooterComponent],
        declarations: [footer_component_1.FooterComponent],
        providers: [],
    })
], FooterModule);
exports.FooterModule = FooterModule;
var PCFooterModule = (function () {
    function PCFooterModule() {
    }
    return PCFooterModule;
}());
PCFooterModule = __decorate([
    core_1.NgModule({
        imports: [
            router_1.RouterModule,
            common_1.CommonModule,
            core_2.TranslateModule,
            common_module_1.TRTCommonModule
        ],
        exports: [footer_component_1.PCFooterComponent],
        declarations: [footer_component_1.PCFooterComponent],
        providers: [],
    })
], PCFooterModule);
exports.PCFooterModule = PCFooterModule;
