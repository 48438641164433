"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./app.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../header/components/header.component.ngfactory");
var i3 = require("../../header/components/header.component");
var i4 = require("@ngx-translate/core/src/translate.service");
var i5 = require("../models/app.model");
var i6 = require("@angular/router");
var i7 = require("@angular/common");
var i8 = require("../../footer/components/footer.component.ngfactory");
var i9 = require("../../footer/components/footer.component");
var i10 = require("./app.component");
var i11 = require("../../common/services/restaurant/src/restaurant.service");
var i12 = require("../../common/services/reserve-form.service");
var i13 = require("../../common/services/params.service");
var i14 = require("../../common/services/api.service");
var i15 = require("../../common/services/reserve.service");
var i16 = require("@angular/platform-browser");
var i17 = require("../../common/services/from.service");
var i18 = require("angulartics2/dist/providers/ga/angulartics2-ga");
var i19 = require("./app.pc.component.css.shim.ngstyle");
var styles_AppComponent = [i0.styles];
exports.RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent,
    data: {} });
function View_AppComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 1, 'trt-header', [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(12697600, null, 0, i3.HeaderComponent, [i4.TranslateService, i1.ElementRef, i5.AppType], { headerString: [0,
                'headerString'], lang: [1, 'lang'], selectLanguageEnabled: [2, 'selectLanguageEnabled'],
            selectLangs: [3, 'selectLangs'], warning: [4, 'warning'] }, null), (_l()(),
            i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(16777216, null, null, 3, 'router-outlet', [], null, null, null, null, null)), i1.ɵdid(212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts,
            i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), i1.ɵdid(278528, null, 0, i7.NgClass, [i1.IterableDiffers,
            i1.KeyValueDiffers, i1.ElementRef, i1.Renderer], { ngClass: [0, 'ngClass'] }, null),
        i1.ɵpod(['asc']), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 1, 'trt-footer', [], null, null, null, i8.View_FooterComponent_0, i8.RenderType_FooterComponent)),
        i1.ɵdid(114688, null, 0, i9.FooterComponent, [i4.TranslateService, i1.ChangeDetectorRef], { country: [0, 'country'], isPay: [1, 'isPay'], inquiryId: [2, 'inquiryId'] }, null),
        (_l()(), i1.ɵted(null, ['\n  ']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _co.headerString;
        var currVal_1 = _co.userLang;
        var currVal_2 = _co.selectLanguageEnabled;
        var currVal_3 = _co.langs;
        var currVal_4 = _co.browserWarning;
        _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4);
        _ck(_v, 5, 0);
        var currVal_5 = _ck(_v, 7, 0, _co.isPay);
        _ck(_v, 6, 0, currVal_5);
        var currVal_6 = _co.country;
        var currVal_7 = _co.isPay;
        var currVal_8 = _co.inquiry_id;
        _ck(_v, 10, 0, currVal_6, currVal_7, currVal_8);
    }, null);
}
exports.View_AppComponent_0 = View_AppComponent_0;
function View_AppComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'app', [], null, null, null, View_AppComponent_0, exports.RenderType_AppComponent)),
        i1.ɵdid(4308992, null, 0, i10.AppComponent, [i4.TranslateService, i6.Router,
            i11.RestaurantService, i1.ChangeDetectorRef, i5.AppType, i1.ApplicationRef,
            i12.ReserveFormService, i13.ParamsService, i14.ApiService, i15.ReserveService,
            i1.ElementRef, i16.DOCUMENT, i1.Renderer, i16.DomSanitizer, i17.FromService,
            i6.ActivatedRoute, i18.Angulartics2GoogleAnalytics, i7.PlatformLocation], null, null)], function (_ck, _v) {
        _ck(_v, 1, 0);
    }, null);
}
exports.View_AppComponent_Host_0 = View_AppComponent_Host_0;
exports.AppComponentNgFactory = i1.ɵccf('app', i10.AppComponent, View_AppComponent_Host_0, {}, {}, []);
var styles_PCAppComponent = [i19.styles];
exports.RenderType_PCAppComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_PCAppComponent, data: {} });
function View_PCAppComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 1, 'trt-header', [], null, null, null, i2.View_PCHeaderComponent_0, i2.RenderType_PCHeaderComponent)), i1.ɵdid(12697600, null, 0, i3.PCHeaderComponent, [i4.TranslateService, i1.ElementRef, i5.AppType], { headerString: [0, 'headerString'], lang: [1, 'lang'], selectLanguageEnabled: [2, 'selectLanguageEnabled'],
            selectLangs: [3, 'selectLangs'], warning: [4, 'warning'] }, null), (_l()(),
            i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(16777216, null, null, 1, 'router-outlet', [], null, null, null, null, null)), i1.ɵdid(212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts,
            i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(),
            i1.ɵeld(0, null, null, 1, 'trt-footer', [], null, null, null, i8.View_PCFooterComponent_0, i8.RenderType_PCFooterComponent)),
        i1.ɵdid(114688, null, 0, i9.PCFooterComponent, [i4.TranslateService, i1.ChangeDetectorRef], { country: [0, 'country'], isPay: [1, 'isPay'], inquiryId: [2, 'inquiryId'] }, null),
        (_l()(), i1.ɵted(null, ['\n  ']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _co.headerString;
        var currVal_1 = _co.userLang;
        var currVal_2 = _co.selectLanguageEnabled;
        var currVal_3 = _co.langs;
        var currVal_4 = _co.browserWarning;
        _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4);
        _ck(_v, 5, 0);
        var currVal_5 = _co.country;
        var currVal_6 = _co.isPay;
        var currVal_7 = _co.inquiry_id;
        _ck(_v, 8, 0, currVal_5, currVal_6, currVal_7);
    }, null);
}
exports.View_PCAppComponent_0 = View_PCAppComponent_0;
function View_PCAppComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'app', [], null, null, null, View_PCAppComponent_0, exports.RenderType_PCAppComponent)),
        i1.ɵdid(4308992, null, 0, i10.PCAppComponent, [i4.TranslateService, i6.Router,
            i11.RestaurantService, i1.ChangeDetectorRef, i5.AppType, i1.ApplicationRef,
            i12.ReserveFormService, i13.ParamsService, i14.ApiService, i15.ReserveService,
            i1.ElementRef, i16.DOCUMENT, i1.Renderer, i16.DomSanitizer, i17.FromService,
            i6.ActivatedRoute, i18.Angulartics2GoogleAnalytics, i7.PlatformLocation], null, null)], function (_ck, _v) {
        _ck(_v, 1, 0);
    }, null);
}
exports.View_PCAppComponent_Host_0 = View_PCAppComponent_Host_0;
exports.PCAppComponentNgFactory = i1.ɵccf('app', i10.PCAppComponent, View_PCAppComponent_Host_0, {}, {}, []);
