"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var timers_1 = require("timers");
var Subject_1 = require("rxjs/Subject");
var table_category_service_1 = require("../services/table-category.service");
var angulartics2_1 = require("angulartics2");
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var router_1 = require("@angular/router");
var reserve_form_service_1 = require("../../common/services/reserve-form.service");
var calendar_component_1 = require("../../common/components/calendar.component");
var core_2 = require("@ngx-translate/core");
var restaurant_module_1 = require("../../common/services/restaurant/restaurant.module");
var days_service_1 = require("../services/days.service");
require("rxjs/add/operator/share");
require("rxjs/add/operator/map");
var Rx_1 = require("rxjs/Rx");
var week_day_service_1 = require("../../common/services/week-day.service");
var course_service_1 = require("../services/course.service");
var reserve_service_1 = require("../../common/services/reserve.service");
var app_model_1 = require("../../app/models/app.model");
var toreta_time_service_1 = require("../../common/services/toreta-time.service");
var toreta_date_service_1 = require("../../common/services/toreta-date.service");
var table_smoking_service_1 = require("../services/table-smoking.service");
var ReserveInfoComponent = (function () {
    function ReserveInfoComponent(reserveForm, router, restaurantService, daysService, weekday, translate, courseService, reserveService, cdRef, appType, activatedRoute, trtTime, trtDate, angulartics2, tableCategoriesService, tableSmokingService) {
        this.reserveForm = reserveForm;
        this.router = router;
        this.restaurantService = restaurantService;
        this.daysService = daysService;
        this.weekday = weekday;
        this.translate = translate;
        this.courseService = courseService;
        this.reserveService = reserveService;
        this.cdRef = cdRef;
        this.appType = appType;
        this.activatedRoute = activatedRoute;
        this.trtTime = trtTime;
        this.trtDate = trtDate;
        this.angulartics2 = angulartics2;
        this.tableCategoriesService = tableCategoriesService;
        this.tableSmokingService = tableSmokingService;
        this.timeRecommend = false;
        this.invalid = true;
        this.add_course = true;
        this.accept_children = true;
        this.accept_courses = false;
        this.accept_multi_courses = false;
        this.courses = [];
        this.coursesOptions = [];
        this.courseSeatsOptions = [];
        this.coursesData = {};
        this.timeOptions = [];
        this.endTimeOptions = [];
        this.seatsOptions = [];
        this.childrenSeatsOptions = [];
        this.seats = 0;
        this.adults_seats = 0;
        this.children_seats = 0;
        this.selectedCourses = [];
        this.selectedCourseSeats = [];
        this.hiddenCalendar = true;
        this.depositDate = [];
        this.startYear = 1970;
        this.endYear = 2200;
        this.startMonth = 0;
        this.endMonth = 13;
        this.others = [];
        this.noSeat = false;
        this.timeDisabled = true;
        this.childrenDisabled = true;
        this.deposit_enabled = false;
        this.deposit_amount_per = 0;
        this.is_deposit = false;
        this.isPc = false;
        this.errorMessage = "";
        this.reservation_id = "";
        this.show_request_form = true;
        this.othersList = false;
        this.tz = new Date().getTimezoneOffset() * 60;
        this.courseList = [];
        this.noDate = false;
        this.select_reservation_slot_enabled = false;
        this.selected_reservation_slot = false;
        this.course_required = false;
        this.isNoTime = false;
        this._cliecked = false;
        this.observers = [];
        this.calendarLoading = false;
        this.endTimes = [];
        this.startTime = 0;
        this.tableCategories = [];
        this._tableCategories = [];
        this.tableSmoking = [];
        this._tableSmoking = [];
        this.isTest = false;
        this.isTest2 = false;
        this.sendRsvInfo = new Subject_1.Subject();
        this.errorState = false;
        this.calendar_month = 0;
        this.calendar_year = 2000;
    }
    ReserveInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.isTest2) {
            this.rsvObs = this.sendRsvInfo.subscribe(function (r) {
                if (r.type === "send") {
                    _this.onClick();
                }
            });
            this.observers.push(this.rsvObs);
        }
        this.translate
            .get([
            "RESERVE_INFO.CURRENT_DATE_TIME",
            "RESERVE_INFO.TIME_RECOMMEND_DATE_TIME",
            "RESERVE_INFO.SELECT_COURSE_OPTIONS",
            "RESERVE_INFO.SELECT_PARTY_SIZE_OPTIONS",
            "RESERVE_INFO.SELECT_PARTY_SIZE_OF_COURSE_OPTIONS",
            "PEOPLE",
            "RESERVE_INFO.END_TIME_OPTIONS"
        ])
            .subscribe(function (v) {
            _this.currentDate = v["RESERVE_INFO.CURRENT_DATE_TIME"];
            _this.arroundDate = v["RESERVE_INFO.TIME_RECOMMEND_DATE_TIME"];
            _this.partySizeLabel = v["RESERVE_INFO.SELECT_PARTY_SIZE_OPTIONS"];
            _this.courseLabel = v["RESERVE_INFO.SELECT_COURSE_OPTIONS"];
            _this.coursePartyLabel =
                v["RESERVE_INFO.SELECT_PARTY_SIZE_OF_COURSE_OPTIONS"];
            _this.people = v["PEOPLE"];
            _this.slotLabel = v["RESERVE_INFO.END_TIME_OPTIONS"];
        });
        var obs = this.translate.onLangChange.subscribe(function (d) {
            var v = d.translations;
            console.log(v);
            _this.isNoTime = _this.time === _this.currentDate;
            _this.currentDate = v["RESERVE_INFO"]["CURRENT_DATE_TIME"];
            _this.arroundDate = v["RESERVE_INFO"]["TIME_RECOMMEND_DATE_TIME"];
            _this.partySizeLabel = v["RESERVE_INFO"]["SELECT_PARTY_SIZE_OPTIONS"];
            _this.courseLabel = v["RESERVE_INFO"]["SELECT_COURSE_OPTIONS"];
            _this.coursePartyLabel =
                v["RESERVE_INFO"]["SELECT_PARTY_SIZE_OF_COURSE_OPTIONS"];
            _this.people = v["PEOPLE"];
            _this.slotLabel = v["RESERVE_INFO"]["END_TIME_OPTIONS"];
            var selectedCourses = [];
            var selectedCourseSeats = [];
            _this.selectedCourses.forEach(function (v) {
                selectedCourses.push(v);
            });
            _this.selectedCourseSeats.forEach(function (v) {
                selectedCourseSeats.push(v);
            });
            _this.createCourseOptions();
            _this.selectedCourses = selectedCourses;
            _this.selectedCourseSeats = selectedCourseSeats;
            _this.createCourseSeatsOptions();
            _this.createSeatsOptions();
            if (/^[0-9]+$/.test(_this.time)) {
                console.log(_this.time);
                _this.time2Str(_this.time).then(function (timeStr) {
                    _this.timeStr = timeStr;
                });
            }
            else {
                if (_this.time === _this.currentDate) {
                    _this.time = _this.currentDate;
                }
                _this.timeStr = _this.time;
            }
            console.log(_this.timeStr);
            if (_this.select_reservation_slot_enabled && _this.time) {
                var endTimes = _this.timesAllCreate(_this.month, _this.date, _this.time);
                _this.createEndTime(endTimes).subscribe(function (k) { return console.log(k); });
            }
            if (_this.year && _this.month && _this.date) {
                _this.trtDate
                    .getDate(_this.year + "/" + _this.month + "/" + _this.date)
                    .then(function (dateStr) {
                    _this.dateStr = dateStr.date;
                });
            }
            _this.cdRef.detectChanges();
        });
        this.observers.push(obs);
        var now = new Date();
        this.year = now.getFullYear();
        this.month = now.getMonth() + 1;
        this.calendar_year = now.getFullYear();
        this.calendar_month = now.getMonth() + 1;
        this.selectedCategory = "";
        this.selectedSmoking = "";
        this.courses = [{}];
        this.dayService = new Rx_1.Observable(function (obs) {
            _this._dayObserver = obs;
        }).share();
        var restaurant = this.restaurantService.get().restaurant;
        this.daysService.collection$.subscribe({
            next: function (dd) {
                if (dd.length == 0) {
                    _this.showCalendar();
                    return;
                }
                console.log(dd);
                dd.forEach(function (d) {
                    var date = new Date((d.start_at + d.timezone_offset + _this.tz) * 1000);
                    var month = date.getMonth() + 1;
                    _this.days[month] = d;
                });
                console.log(dd);
                console.log(_this.select_reservation_slot_enabled && _this.time);
                if (_this.select_reservation_slot_enabled &&
                    !!_this.time &&
                    !!_this.endTimeObj) {
                    var seats = _this.timesAllCreate(_this.month, _this.date, _this.time);
                    _this.createEndTime(seats).subscribe(function (l) {
                        console.log(l);
                    });
                    console.log(_this.endTimeObj.end_at);
                    if (!_this.endTimeObj.end_at) {
                        _this.endTime = _this.time;
                    }
                    else {
                        _this.endTime = "" + _this.endTimeObj.end_time;
                    }
                    if (+_this.endTime < 21600) {
                        _this.endTime = "" + (+_this.endTime + 86400);
                    }
                    console.log(_this.endTime);
                }
                _this._dayObserver.next(dd);
            },
            error: function (e) {
                _this.hiddenCalendar = true;
                _this.errorState = true;
                _this.errorMessage = "ERROR.DEFAULT";
            }
        });
        this.accept_children = restaurant.web_reservation_config.accept_children;
        this.max_seats = +restaurant.restaurant_max_seats;
        this.min_seats = this.accept_children
            ? 1
            : +restaurant.restaurant_min_seats;
        this.requestPlaceholder = restaurant.web_reservation_config.note_message;
        this.requestMessage = restaurant.web_reservation_config.note_message;
        this.courseMessage = restaurant.web_reservation_config.course_message;
        this.accept_courses = restaurant.web_reservation_config.accept_courses;
        this.accept_multi_courses =
            restaurant.web_reservation_config.accept_multi_courses;
        this.course_required =
            restaurant.web_reservation_config.course_required || false;
        this.deposit_enabled = restaurant.deposit_enabled || false;
        this.show_request_form =
            restaurant.web_reservation_config.show_request_form;
        this.key = restaurant.restaurant_key;
        this.days = restaurant.days || [];
        this.bStart = restaurant.restaurant_business_hour_start_at_first;
        this.bLast = restaurant.restaurant_business_hour_end_at_last;
        this._tableCategories = restaurant.table_categories.sort(function (a, b) {
            if (a.order_index > b.order_index) {
                return 1;
            }
            else {
                return -1;
            }
        });
        this._tableSmoking = restaurant.web_reservation_table_smoking.map(function (smoking) {
            return {
                key_name: smoking
            };
        });
        this.createBusinessTime();
        this.createSeatsOptions();
        if (this.deposit_enabled) {
            this.deposit_amount_per =
                restaurant.restaurant_deposit_default_config.amount;
            this.deposit_per =
                restaurant.restaurant_deposit_default_config.deposit_per;
        }
        this.select_reservation_slot_enabled =
            restaurant.web_reservation_config.select_reservation_slot_enabled ||
                false;
        this.currency = restaurant.restaurant_currency || "jpy";
        var locale = restaurant.restaurant_language;
        var currencyLang = restaurant.restaurant_language + "-" + restaurant.restaurant_country;
        if (locale.length === 2) {
            locale =
                restaurant.restaurant_language +
                    "_" +
                    restaurant.restaurant_country.toUpperCase();
        }
        else {
            currencyLang = restaurant.restaurant_language;
        }
        this.cur = new common_1.CurrencyPipe(currencyLang);
        var formData = this.reserveForm.reserveFormCollection$.subscribe(function (data) {
            formData.unsubscribe();
            console.log(data);
            _this.addInfo = data.addInfo || "";
            if (data.start_at) {
                _this.seats = data.seats;
                _this.adults_seats = data.seats_adults;
                _this.children_seats = data.seats_children;
                _this.createCourseSeatsOptions();
                _this.request = data.note;
                _this.courses = data.courses;
                _this.selectedCategory = data.table_category;
                _this.selectedSmoking = data.table_smoking;
                console.log(_this.courses);
                console.log(_this.selectedCategory);
                _this.is_deposit = data.is_deposit;
                _this.reservation_id = data.payment && data.payment.reservation_id;
                if (_this.is_deposit) {
                    var depo = void 0;
                    if (_this.deposit_per === "per_person") {
                        depo = _this.deposit_amount_per * _this.seats;
                    }
                    else {
                        depo = _this.deposit_amount_per;
                    }
                    if (depo) {
                        _this.deposit_amount = _this.cur.transform(depo, _this.currency, true);
                    }
                }
                var d = new Date((data.start_at + data.timezone_offset + _this.tz) * 1000);
                var ds = data.go_date.split("/");
                _this.year = ds[0] * 1;
                _this.month = ds[1] * 1;
                _this.date = ds[2] * 1;
                _this.trtDate.getDate(data.go_date).then(function (dateStr) {
                    console.log(dateStr);
                    _this.dateStr = dateStr.date;
                    _this.timeDisabled = !!_this.dateStr && !!_this.seats ? null : true;
                });
                _this.childrenDisabled = !!_this.seats ? null : true;
                console.log(_this.childrenDisabled);
                _this.time = data.start_time;
                _this.startTime = data.start_time;
                _this.endTime = data.end_time;
                _this.endAt = data.end_at;
                console.log("this.createCategories");
                _this.createTableCategories();
                _this.createTableSmoking();
                console.log(d.getHours() * 3600 + d.getMinutes() * 60, data.start_time);
                console.log(_this.time);
                if (+_this.time < 21600) {
                    _this.time = "" + (+_this.time + 86400);
                }
                _this.time2Str(_this.time).then(function (timeStr) {
                    _this.timeStr = timeStr;
                });
                _this.endTimeObj = {
                    end_at: data.end_at,
                    timezone_offset: data.timezone_offset,
                    end_time: data.end_time
                };
                var dayService_1 = _this.daysService
                    .getApi(_this.year, _this.month, _this.seats, 2, _this.reservation_id)
                    .subscribe(function (data) {
                    dayService_1.unsubscribe();
                    _this.daysService.get(_this.year, _this.month, _this.seats, 2);
                });
                _this.validation();
                var cc_1 = _this.courseService.collection$.subscribe(function (courses) {
                    cc_1.unsubscribe();
                    _this.courseList = courses;
                    _this.createCourseOptions();
                    _this.createCourseSeatsOptions();
                    if (_this.courses && _this.courses.length > 0) {
                        _this.courses.forEach(function (c, i) {
                            _this.selectedCourses[i] = c.course_id;
                            _this.selectedCourseSeats[i] = c.count;
                        });
                    }
                    else {
                        _this.courses = [{}];
                    }
                    console.log(_this.courses);
                });
                _this.courseService.get(_this.year, _this.month, _this.date, _this.time);
            }
            else {
                _this.seats = data.seats;
                _this.adults_seats = data.seats_adults || data.seats;
                _this.children_seats = data.seats_children;
                _this.createCourseSeatsOptions();
                _this.childrenDisabled = !!_this.seats ? null : true;
                console.log(_this.childrenDisabled);
                console.log(data.start_time);
                if (data.go_date) {
                    var ds = data.go_date.split("/");
                    _this.year = ds[0] * 1;
                    _this.month = ds[1] * 1;
                    _this.date = ds[2] * 1;
                    console.log(data);
                    var startTime_1 = data.start_time;
                    var endTime_1 = data.end_time;
                    _this.setDate({
                        year: _this.year,
                        month: _this.month,
                        date: _this.date
                    });
                    _this.timeDisabled = true;
                    var dayService_2 = _this.daysService
                        .getApi(_this.year, _this.month, _this.seats, 2, _this.reservation_id)
                        .subscribe(function (data) {
                        dayService_2.unsubscribe();
                        console.log(_this.year);
                        var dss = _this.dayService.subscribe(function () {
                            dss.unsubscribe();
                            var data = _this.days[_this.month];
                            console.log(data);
                            if (data.days[_this.date - 1] && data.days[_this.date - 1][0]) {
                                _this.is_deposit =
                                    data.days[_this.date - 1][0].deposit_accepted;
                                var depo = void 0;
                                if (_this.deposit_per === "per_person") {
                                    depo = _this.deposit_amount_per * _this.seats;
                                }
                                else {
                                    depo = _this.deposit_amount_per;
                                }
                                if (depo) {
                                    _this.deposit_amount = _this.cur.transform(depo, _this.currency, true);
                                }
                                console.log("form");
                                console.log(_this.time);
                                if (startTime_1 !== undefined) {
                                    _this.startTime = startTime_1;
                                    _this.endTime = endTime_1;
                                    console.log("this.createCategories");
                                    _this.createTableCategories();
                                    _this.createTableSmoking();
                                    _this.setDateTime({
                                        date: {
                                            year: _this.year,
                                            month: _this.month,
                                            date: _this.date
                                        },
                                        time: {
                                            start_time: startTime_1
                                        },
                                        deposit_accepted: _this.is_deposit
                                    });
                                }
                                else {
                                    _this.setDate({
                                        year: _this.year,
                                        month: _this.month,
                                        date: _this.date,
                                        deposit_accepted: _this.is_deposit
                                    });
                                }
                            }
                            else {
                                _this.noDate = true;
                                var now_1 = new Date();
                                _this.year = now_1.getFullYear();
                                _this.month = now_1.getMonth() + 1;
                            }
                        });
                        _this.daysService.get(_this.year, _this.month, _this.seats, 2);
                    });
                }
                else if (data.seats) {
                    _this.seats = data.seats;
                    _this.adults_seats = data.seats_adults || data.seats;
                    if (_this.appType.isMobile() && _this.seats === 2) {
                        timers_1.setTimeout(function () {
                            _this.showCalendar();
                        }, 100);
                    }
                }
            }
        });
        this.courseService.collection$.subscribe(function (courses) {
            _this.courseList = courses;
            _this.createCourseOptions();
            _this.validation();
        });
        this.reserveForm.get();
    };
    ReserveInfoComponent.prototype.createSeatsOptions = function () {
        this.seatsOptions = [];
        this.childrenSeatsOptions = [];
        this.seatsOptions.push({
            label: this.partySizeLabel,
            value: "",
            disabled: true
        });
        this.childrenSeatsOptions.push({
            label: this.partySizeLabel,
            value: 0
        });
        for (var i = this.min_seats; i <= this.max_seats; i++) {
            var seat = {
                label: "" + i + this.people,
                value: i
            };
            this.seatsOptions.push(seat);
            this.childrenSeatsOptions.push(seat);
        }
    };
    ReserveInfoComponent.prototype.createCourseOptions = function () {
        var _this = this;
        var coursesOptions = [];
        if (this.courseList.length > 0) {
            coursesOptions.push({
                label: this.courseLabel,
                value: "",
                price: "",
                name: ""
            });
            this.courseList.forEach(function (course) {
                course.formated_price = _this.cur.transform(course.price, _this.currency, true);
                _this.coursesData[course.key] = course;
                var price = course.price > 0 ? course.formated_price : "";
                coursesOptions.push({
                    label: course.name + " " + price,
                    value: course.key,
                    name: course.name,
                    price: price
                });
            });
            this.reserveForm.set("accept_courses", true);
        }
        else {
            this.reserveForm.set("accept_courses", false);
        }
        this.coursesOptions = coursesOptions;
        console.log("teste");
        if (this.courses && this.courses.length > 0) {
            this.courses.forEach(function (c, i) {
                _this.selectedCourses[i] = c.course_id;
                _this.selectedCourseSeats[i] = c.count;
            });
        }
        else {
            this.courses = [{}];
            console.log("sss");
            this.reserveForm.set("courses", null);
            this.selectedCourses = [];
            this.selectedCourseSeats = [0];
        }
    };
    ReserveInfoComponent.prototype.createCourseSeatsOptions = function () {
        this.courseSeatsOptions = [];
        this.courseSeatsOptions.push({
            label: this.partySizeLabel,
            value: ""
        });
        console.log(this.seats);
        for (var i = 1; i <= this.seats; i++) {
            this.courseSeatsOptions.push({
                label: "" + i,
                value: i
            });
        }
    };
    ReserveInfoComponent.prototype.createTableCategories = function () {
        var _this = this;
        console.log(this.selectedCategory);
        if (this._tableCategories.length > 1 ||
            (this._tableCategories.length === 1 &&
                this._tableCategories[0].key_name !== "none")) {
            this.tableCategories = this._tableCategories;
            console.log(this.startTime, this.endTime);
            var endTime = +this.startTime === +this.endTime ? null : this.endTime;
            console.log(this.endAt);
            var endAt = +this.startTime === +this.endTime ? null : this.endAt;
            if (this.select_reservation_slot_enabled) {
                this.tableCategoriesService.get(this.year, this.month, this.date, this.seats, this.startTime, endTime, endAt, this.reservation_id);
            }
            else {
                console.log(!this.days[this.month]);
                if (!this.days[this.month]) {
                    console.log(this.year, this.month, this.seats);
                    this.days[this.month] = this.daysService.haveDates(this.year, this.month, this.seats);
                }
                console.log(this.days);
                var timesSlot = this.days[this.month].days[this.date - 1];
                console.log(timesSlot);
                timesSlot = timesSlot.filter(function (timeSlot) {
                    console.log(timeSlot.start_time, _this.startTime);
                    return timeSlot.start_time === _this.startTime;
                });
                this.tableCategoriesService.getTimes(this.year, this.month, this.date, this.seats, this.startTime, timesSlot, this.reservation_id);
            }
        }
        else {
            this.createTableSmoking();
        }
    };
    ReserveInfoComponent.prototype.createTableSmoking = function () {
        var _this = this;
        console.log(this.selectedSmoking);
        if (this._tableSmoking.length > 1 ||
            (this._tableSmoking.length === 1 && this._tableSmoking[0] !== "none")) {
            this.tableSmoking = this._tableSmoking;
            console.log(this.startTime, this.endTime);
            var endTime = +this.startTime === +this.endTime ? null : this.endTime;
            console.log(this.endAt);
            var endAt = +this.startTime === +this.endTime ? null : this.endAt;
            if (this.select_reservation_slot_enabled) {
                this.tableSmokingService.get(this.year, this.month, this.date, this.seats, this.selectedCategory, this.startTime, endTime, endAt, this.reservation_id);
            }
            else {
                console.log(!this.days[this.month]);
                if (!this.days[this.month]) {
                    console.log(this.year, this.month, this.seats);
                    this.days[this.month] = this.daysService.haveDates(this.year, this.month, this.seats);
                }
                console.log(this.days);
                var timesSlot = this.days[this.month].days[this.date - 1];
                console.log(timesSlot);
                timesSlot = timesSlot.filter(function (timeSlot) {
                    console.log(timeSlot.start_time, _this.startTime);
                    return timeSlot.start_time === _this.startTime;
                });
                console.log(this.selectedCategory);
                this.tableSmokingService.getTimes(this.year, this.month, this.date, this.seats, this.selectedCategory, this.startTime, timesSlot, this.reservation_id);
            }
        }
    };
    ReserveInfoComponent.prototype.canDeactivate = function () {
        this.observers.forEach(function (obs) {
            console.log(obs);
            obs.unsubscribe();
        });
        if (this.calendar) {
            this.calendar.disp = false;
        }
        return true;
    };
    ReserveInfoComponent.prototype.validation = function () {
        var aSeats = this.adults_seats || this.seats;
        var tcategory = this._tableCategories.length > 1 ||
            (this._tableCategories.length === 1 &&
                this._tableCategories[0].key_name !== "none");
        var categoryValid = tcategory ? this.selectedCategory !== "" : true;
        console.error(!!this.selectedCategory);
        if (this.select_reservation_slot_enabled) {
            this.invalid = !(categoryValid &&
                this.seats > 0 &&
                this.dateStr &&
                this.time &&
                this.endTime &&
                this.noSeat === false &&
                (this.course_required && this.courseList.length > 0
                    ? !!(this.selectedCourses.length > 0 &&
                        this.selectedCourseSeats.reduce(function (p, c) {
                            return +p + +c;
                        }) >= aSeats)
                    : true));
        }
        else {
            this.invalid = !(categoryValid &&
                this.seats > 0 &&
                this.dateStr &&
                this.time &&
                this.noSeat === false &&
                (this.course_required && this.courseList.length > 0
                    ? !!(this.selectedCourses.length > 0 &&
                        this.selectedCourseSeats.reduce(function (p, c) {
                            return +p + +c;
                        }) >= aSeats)
                    : true));
        }
        if (this.isTest2) {
            this.sendRsvInfo.next({
                type: "validation",
                invalid: this.invalid
            });
        }
    };
    ReserveInfoComponent.prototype.createBusinessTime = function () {
        this.timeOptions = [
            {
                label: "時間をお選びください",
                value: ""
            }
        ];
        this.businessStart = Math.floor(this.bStart / 3600 || 5);
        this.businessLast = Math.floor(this.bLast / 3600 || 29);
        for (var h = this.businessStart; h <= this.businessLast; h++) {
            for (var m = 0; m < 60; m += 15) {
                var hour = ("0" + h).slice(-2);
                var min = ("0" + m).slice(-2);
                var t = h * 3600 + m * 60;
                this.timeOptions.push({
                    label: hour + ":" + min,
                    value: "" + t
                });
            }
        }
    };
    ReserveInfoComponent.prototype.showCalendar = function () {
        var _this = this;
        console.log(this.seats);
        if (this.seats > 0 && !this.errorState) {
            this.calendarLoading = true;
            this._dayObserver.next({
                loading: true
            });
            if (this.deposit_per === "per_person") {
                this.deposit_amount = this.cur.transform(this.deposit_amount_per * this.seats, this.currency, true);
            }
            else {
                this.deposit_amount = this.cur.transform(this.deposit_amount_per, this.currency, true);
            }
            if (!this.seatDayService) {
                var now = new Date();
                var startYear = now.getFullYear();
                var startMonth = now.getMonth() + 1;
                this.startYear = startYear;
                this.startMonth = startMonth;
                this.seatDayService = this.daysService
                    .getApi(this.calendar_year, this.calendar_month, this.seats, 2, this.reservation_id, false)
                    .subscribe(function (data) {
                    _this.seatDayService.unsubscribe();
                    _this.seatDayService = null;
                    console.log(_this.seatDayService);
                    _this.calendarLoading = false;
                    console.log(_this.daysService);
                    _this.daysService.get(_this.calendar_year, _this.calendar_month, _this.seats, 2);
                });
            }
            console.log(this.seats, this.adults_seats, this.children_seats);
            this.hiddenCalendar =
                (this.seats || this.adults_seats || this.children_seats) == 0;
            console.log(this.hiddenCalendar);
            this.calendar.disp = true;
        }
    };
    ReserveInfoComponent.prototype.onMoveMonth = function (d) {
        var _this = this;
        this.calendar_year = d.year;
        this.calendar_month = d.month;
        var month = this.calendar_month + 1;
        var year = this.calendar_year;
        if (month === 13) {
            year = year + 1;
            month = 1;
        }
        var dayService = this.daysService
            .getApi(this.calendar_year, this.calendar_month, this.seats, 2, this.reservation_id)
            .subscribe(function (data) {
            console.log(typeof dayService);
            dayService.unsubscribe();
            _this.daysService.get(_this.calendar_year, _this.calendar_month, _this.seats, 2);
        });
    };
    ReserveInfoComponent.prototype.setSeats = function (d) {
        this.noDate = false;
        if (this.seats === d || this.errorState) {
            return false;
        }
        this.seats = d;
        this.createCourseSeatsOptions();
        var now = new Date();
        this.year = now.getFullYear();
        this.month = now.getMonth() + 1;
        this.calendar_year = now.getFullYear();
        this.calendar_month = now.getMonth() + 1;
        this.reserveForm.set("seats", +d);
        this.time = "";
        this.timeStr = "";
        this.endTime = "";
        this.endTimeStr = "";
        this.endAt = "";
        this.timeDisabled = true;
        this.reserveForm.set("go_date", null);
        this.reserveForm.set("start_at", null);
        this.reserveForm.set("start_time", null);
        this.reserveForm.set("end_at", null);
        this.reserveForm.set("end_time", null);
        this.reserveForm.set("time_label", null);
        this.others = [].concat([]);
        this.othersList = false;
        this.current = null;
        this.date = undefined;
        this.dateStr = "";
        this.noSeat = null;
        this.is_deposit = false;
        this.reserveForm.set("courses", null);
        this.reserveForm.set("table_category", null);
        this.reserveForm.set("table_smoking", null);
        this.selectedCategory = "";
        this.tableCategories = [];
        this.tableSmoking = [];
        this.coursesOptions = [];
        this.courses = [{}];
        console.log("setseats");
        this.selectedCourses = [];
        this.selectedCourseSeats = [0];
        console.log(d);
        this.childrenDisabled = !!this.seats ? null : true;
        console.log(this.childrenDisabled);
        console.log(this.appType);
        console.log(!this.hiddenCalendar && !this.calendarLoading && this.appType.isPc);
        if ((!this.hiddenCalendar && !this.calendarLoading && this.appType.isPc()) ||
            this.appType.isMobile()) {
            this.showCalendar();
        }
        this.errorMessage = null;
        this.validation();
    };
    ReserveInfoComponent.prototype.setDate = function (d, fromSetDateTime) {
        var _this = this;
        if (fromSetDateTime === void 0) { fromSetDateTime = false; }
        this.noDate = false;
        this.month = d.month;
        this.year = d.year;
        var month = ("0" + d.month).slice(-2);
        var date = ("0" + d.date).slice(-2);
        this.date = d.date;
        this.trtDate.getDate(this.year + "/" + month + "/" + date).then(function (dateStr) {
            _this.dateStr = dateStr.date;
        });
        this.others = [].concat([]);
        this.current = null;
        this.is_deposit = d.deposit_accepted;
        this.reserveForm.set("go_date", this.year + "/" + month + "/" + date);
        this.reserveForm.set("is_deposit", d.deposit_accepted);
        this.reserveForm.set("deposit_amount", this.deposit_amount);
        this.reserveForm.set("start_at", null);
        this.reserveForm.set("start_time", null);
        this.reserveForm.set("time_label", null);
        this.reserveForm.set("end_at", null);
        this.reserveForm.set("end_time", null);
        this.time = "";
        this.timeStr = "";
        this.endTime = "";
        this.endTimeStr = "";
        this.endAt = "";
        this.noSeat = null;
        this.reserveForm.set("courses", null);
        this.reserveForm.set("table_category", null);
        this.reserveForm.set("table_smoking", null);
        this.selectedCategory = "";
        this.tableCategories = [];
        this.tableSmoking = [];
        this.courses = [{}];
        this.coursesOptions = [];
        this.selectedCourses = [];
        this.selectedCourseSeats = [0];
        this.timeDisabled = null;
        console.log("setDate", d);
        this.hiddenCalendar = true;
        this.validation();
        this.othersList = false;
        this.errorMessage = null;
        if (this.appType.isMobile()) {
            console.log(this.currentDate);
            if (fromSetDateTime) {
                console.log("this.setTime");
                this.setTime(this.currentDate);
            }
            else {
                console.log("this.setTime");
                this.setTime();
            }
        }
        this.cdRef.detectChanges();
    };
    ReserveInfoComponent.prototype.setTime = function (time, end_time) {
        var _this = this;
        console.log(this.endTime);
        if (!this.month || !this.date) {
            return false;
        }
        var times = this.timesCreate(this.month, this.date);
        console.log(times);
        if (!time && !end_time) {
            this.angulartics2.eventTrack.next({
                action: "click",
                properties: { category: "search", label: "TimeList" }
            });
            var timeEvent_1 = true;
            times.forEach(function (t) {
                if (timeEvent_1 &&
                    t.start_time / 3600 >= 18 &&
                    t.start_time / 3600 <= 21) {
                    _this.angulartics2.eventTrack.next({
                        action: "status",
                        properties: { category: "search", label: "GoldenTimeAvailable" }
                    });
                    timeEvent_1 = false;
                }
            });
            if (this.appType.isMobile()) {
                console.log(this.currentDate);
                time = this.currentDate;
            }
        }
        var n = new Date(this.year, this.month - 1, this.date);
        var w;
        console.log(this.month, this.date);
        console.log(n);
        this.translate
            .get(this.weekday.get(n.getDay()))
            .subscribe(function (data) { return (w = data); });
        this.current = {
            date: {
                year: this.year,
                month: this.month,
                date: this.date,
                wday: w,
                deposit_accepted: times[0] && times[0].deposit_accepted
            },
            times: times
        };
        console.log(this.current);
        this.others = [].concat([]);
        console.log(time);
        var seats = times.filter(function (t) { return +t.start_time === +time; });
        console.log(seats);
        this.noSeat = seats.length === 0;
        if (seats.length) {
            this.time2Str(time).then(function (timeStr) {
                _this.timeStr = timeStr;
            });
        }
        else if (!(+time >= 0)) {
            if (typeof time === "undefined") {
                time = "";
            }
            this.timeStr = time;
        }
        else {
            var stime = time;
            this.time2Str(stime).then(function (timeStr) {
                _this.timeStr = timeStr;
                _this.noDate = true;
            });
            time = "";
        }
        if (time) {
            this.time = time;
            this.endTime = end_time;
            this.othersList = false;
            console.log("setTime");
            if (!end_time) {
                this.tableCategories = [];
                this.tableSmoking = [];
                this.coursesOptions = [];
                this.courses = [{}];
            }
            if (!this.noSeat) {
                var seat = void 0;
                if (end_time) {
                    var endTimes = this.timesAllCreate(this.month, this.date, this.time, this.endTime);
                    seat = endTimes[0];
                }
                else {
                    seat = seats[0];
                }
                this.reserveForm.set("start_at", seat.start_at);
                this.startTime = seat.start_time;
                this.reserveForm.set("start_time", seat.start_time);
                this.reserveForm.set("timezone_offset", this.days[this.month].timezone_offset);
                this.reserveForm.set("time_label", seat.label);
                console.log(seat);
                if (seat.end_time) {
                    if (!this.select_reservation_slot_enabled) {
                        this.endTime = seat.end_time;
                    }
                    this.endAt = seat.end_at;
                    this.reserveForm.set("end_at", seat.end_at);
                    this.reserveForm.set("end_time", seat.end_time);
                }
                else {
                    this.reserveForm.set("end_at", null);
                    this.reserveForm.set("end_time", null);
                }
                console.log(this.endTime);
                console.log(end_time);
                if (!end_time) {
                    this.getCourse();
                    console.log(this.select_reservation_slot_enabled);
                    if (!this.select_reservation_slot_enabled) {
                        console.log("this.createCategories");
                        this.createTableCategories();
                    }
                }
                else {
                    console.log("this.createCategories");
                    this.createTableCategories();
                }
            }
            this.validation();
            if (this.select_reservation_slot_enabled && !end_time) {
                var endTimes = this.timesAllCreate(this.month, this.date, this.time);
                this.createEndTime(endTimes).subscribe(function (k) { return console.log(k); });
            }
        }
        console.log((!time || !+time) && !end_time);
        if ((!time || !+time) && !end_time) {
            this.createOthers();
            if (!this.accept_courses) {
                this.validation();
            }
        }
        this.errorMessage = null;
    };
    ReserveInfoComponent.prototype.createEndTime = function (seats) {
        var _this = this;
        this.endTimeOptions = [
            {
                label: this.slotLabel,
                value: ""
            }
        ];
        console.log(seats);
        return Rx_1.Observable.create(function (obs) {
            Rx_1.Observable.from(seats).subscribe(function (s) {
                console.log(s);
                return Rx_1.Observable.zip(_this.time2Str(s.start_time), _this.time2Str(s.end_time)).subscribe(function (v) {
                    var endTime = s.end_time ? s.end_time : s.start_time;
                    _this.endTimeOptions.push({
                        label: v[0] + " ~ " + v[1],
                        value: endTime
                    });
                    console.log(v);
                    return true;
                });
            });
        });
    };
    ReserveInfoComponent.prototype.createOthers = function () {
        this.noDate = false;
        var others = [Object.assign({}, this.current)];
        var prevOthers = [];
        var nextOthers = [];
        console.log(others);
        var _loop_1 = function (i) {
            var n = new Date(this_1.year, this_1.month - 1, this_1.date + i);
            var y = n.getFullYear();
            var m = n.getMonth() + 1;
            var d = n.getDate();
            var w;
            this_1.translate
                .get(this_1.weekday.get(n.getDay()))
                .subscribe(function (data) { return (w = data); });
            if (i < 0 && this_1.days[m]) {
                if (this_1.days[m].days[d]) {
                    var times = this_1.timesCreate(m, d);
                    prevOthers.unshift({
                        date: {
                            year: y,
                            month: m,
                            date: d,
                            wday: w,
                            deposit_accepted: times[0] && times[0].deposit_accepted
                        },
                        times: this_1.timesCreate(m, d)
                    });
                }
                else {
                    prevOthers.unshift(null);
                }
            }
            else if (i > 0 && this_1.days[m]) {
                if (this_1.days[m].days[d]) {
                    var times = this_1.timesCreate(m, d);
                    nextOthers.push({
                        date: {
                            year: y,
                            month: m,
                            date: d,
                            wday: w,
                            deposit_accepted: times[0] && times[0].deposit_accepted
                        },
                        times: times
                    });
                }
                else {
                    nextOthers.push(null);
                }
            }
        };
        var this_1 = this;
        for (var i = -6; i < 7; i++) {
            _loop_1(i);
        }
        for (var i = 0; i < 6; i++) {
            if (prevOthers[i] !== null && prevOthers[i] !== undefined) {
                if (prevOthers[i].times.length > 0) {
                    others.unshift(prevOthers[i]);
                }
            }
            if (nextOthers[i] !== null && nextOthers[i] !== undefined) {
                if (nextOthers[i].times.length > 0) {
                    others.push(nextOthers[i]);
                }
            }
            if (others.length > 5) {
                i = 6;
                break;
            }
        }
        this.others = [].concat(others.filter(function (o) {
            return o && o.times && o.times.length > 0;
        }));
        console.log(this.others);
    };
    ReserveInfoComponent.prototype.showRecommend = function () {
        this.timeRecommend = true;
    };
    ReserveInfoComponent.prototype.showOthers = function () {
        if (this.appType.isMobile()) {
            this.timeStr = this.arroundDate;
        }
        this.angulartics2.eventTrack.next({
            action: "click",
            properties: { category: "search", label: "Other day's time" }
        });
        this.othersList = !this.othersList;
    };
    ReserveInfoComponent.prototype.setTableCategory = function (category) {
        console.log(category);
        this.selectedCategory = category.keyName;
        this.reserveForm.set("table_category", category.keyName);
        this.reserveForm.set("end_at", category.endAt);
        this.reserveForm.set("end_time", category.endTime);
        this.createTableSmoking();
        this.validation();
    };
    ReserveInfoComponent.prototype.setTableSmoking = function (smoking) {
        console.log(smoking);
        this.selectedCategory = smoking.keyName;
        this.reserveForm.set("table_smoking", smoking.keyName);
        this.reserveForm.set("end_at", smoking.endAt);
        this.reserveForm.set("end_time", smoking.endTime);
        this.validation();
    };
    ReserveInfoComponent.prototype.setDateTime = function (data) {
        var _this = this;
        var d = data.date;
        this.month = d.month;
        this.year = d.year;
        var month = ("0" + d.month).slice(-2);
        var date = ("0" + d.date).slice(-2);
        this.date = d.date;
        this.trtDate.getDate(this.year + "/" + month + "/" + date).then(function (dateStr) {
            _this.dateStr = dateStr.date;
        });
        this.setDate(d, true);
        console.log("this.setTime");
        this.setTime(data.time.start_time + "");
        this.others = [].concat([]);
        this.current = null;
        this.is_deposit = d.deposit_accepted;
        this.reserveForm.set("is_deposit", d.deposit_accepted);
        this.reserveForm.set("deposit_amount", this.deposit_amount);
    };
    ReserveInfoComponent.prototype.setCourse = function (idx, d) {
        this.selectedCourses[idx] = d;
        var seats = this.seats;
        for (var i = 0; i < idx; i++) {
            seats -= this.selectedCourseSeats[i] || 0;
        }
        seats = seats < 0 ? 0 : seats;
        this.selectedCourseSeats[idx] = this.selectedCourseSeats[idx] || seats;
        var course = this.coursesData[d] || {};
        var count = this.selectedCourseSeats[idx] || 0;
        var price = course.price > 0 ? course.formated_price : "";
        if (!course.key) {
            this.selectedCourseSeats[idx] = 0;
            this.selectedCourseSeats = [].concat(this.selectedCourseSeats);
            count = null;
        }
        console.log(count);
        this.reserveForm.set("courses", { course_id: course.key, count: count, name: course.name, price: price }, idx);
        this.validation();
    };
    ReserveInfoComponent.prototype.setCourseSeats = function (idx, d) {
        this.selectedCourseSeats[idx] = d;
        var key = this.selectedCourses[idx];
        var course = this.coursesData[key];
        if (course) {
            var price = course.price > 0 ? course.formated_price : "";
            console.log(price);
            this.reserveForm.set("courses", { course_id: key, count: d, name: course.name, price: price }, idx);
            this.validation();
        }
    };
    ReserveInfoComponent.prototype.setAdultsSeats = function (d) {
        this.adults_seats = d;
        this.reserveForm.set("seats_adults", +d);
        if (d * 1 === 0) {
            this.setChildrenSeats(0);
        }
        this.setSeats(this.adults_seats * 1 + (this.children_seats * 1 || 0));
    };
    ReserveInfoComponent.prototype.setChildrenSeats = function (d) {
        this.children_seats = d;
        this.reserveForm.set("seats_children", +d);
        this.setSeats(this.adults_seats * 1 + (this.children_seats * 1 || 0));
    };
    ReserveInfoComponent.prototype.setRequest = function (d) {
        this.reserveForm.set("note", d);
    };
    ReserveInfoComponent.prototype.getCourse = function () {
        if (!this.noSeat && this.accept_courses) {
            console.log(this.year, this.month, this.date, this.time);
            this.courseService.get(this.year, this.month, this.date, this.time);
        }
    };
    ReserveInfoComponent.prototype.addCourse = function () {
        this.courses.push({});
    };
    ReserveInfoComponent.prototype.timesAllCreate = function (month, date, start_time, end_time) {
        var times = [];
        if (this.days[month]) {
            var _times = [].concat(this.days[month].days[date - 1] ? this.days[month].days[date - 1] : []);
            times = _times.filter(function (t) {
                if (!!end_time) {
                    console.log(start_time, end_time);
                    if (start_time == end_time) {
                        return +t.start_time === +start_time && !t.end_time;
                    }
                    else {
                        return +t.start_time === +start_time && +t.end_time === +end_time;
                    }
                }
                else {
                    return +t.start_time === +start_time;
                }
            });
        }
        return times;
    };
    ReserveInfoComponent.prototype.timesCreate = function (month, date) {
        var times = [];
        if (this.days[month]) {
            var _times_1 = [].concat(this.days[month].days[date - 1] ? this.days[month].days[date - 1] : []);
            _times_1.forEach(function (time) {
                var tlen = times.length - 1;
                if (_times_1[tlen]) {
                    if (times.filter(function (t) {
                        return t.start_time === time.start_time;
                    }).length === 0) {
                        times.push(time);
                    }
                }
                else {
                    times.push(time);
                }
            });
        }
        return times;
    };
    ReserveInfoComponent.prototype.time2Str = function (time) {
        return this.trtTime.transform(time);
    };
    ReserveInfoComponent.prototype.closeCalendar = function () {
        this.hiddenCalendar = true;
    };
    ReserveInfoComponent.prototype.onClick = function () {
        var _this = this;
        var reserveData;
        if (this._cliecked) {
            return false;
        }
        else {
            this._cliecked = true;
        }
        var ctr = this.reserveService.api$.subscribe(function (data) {
            console.log(data);
            ctr.unsubscribe();
            var rfs = _this.reserveForm.reserveFormCollection$.subscribe(function (d) {
                rfs.unsubscribe();
                _this._cliecked = false;
                if (reserveData.contact_from === "rakuten" &&
                    !(d.customer &&
                        d.customer.temporary_reservation_customer_information &&
                        d.customer.temporary_reservation_customer_information.fromRakuten)) {
                    console.log(d);
                    _this.restaurantService.set("days", _this.days);
                    _this.restaurantService.store();
                    _this.reserveForm.store();
                    var url = location.origin + "/" + _this.key;
                    window.location.replace(API_URL + "/oauth2/providers/rakuten?continue_uri=" + encodeURIComponent(url));
                }
                else {
                    if (_this.isTest2) {
                        _this.sendRsvInfo.next({
                            type: "return",
                            status: true
                        });
                    }
                    else {
                        _this.router.navigate(["/personal-info"]);
                    }
                }
            });
            _this.reserveForm.set("payment", { reservation_id: data.key });
        }, function (err) {
            var e = err.json();
            if (e.error_code) {
                _this.errorMessage = "ERROR.OTHER";
            }
            else {
                _this.errorMessage = "ERROR.DEFAULT";
            }
            _this.time = "";
            _this.timeStr = "";
            _this.endTime = "";
            _this.endTimeStr = "";
            _this.endAt = "";
            _this.timeDisabled = true;
            _this.reserveForm.set("go_date", null);
            _this.reserveForm.set("start_at", null);
            _this.reserveForm.set("start_time", null);
            _this.reserveForm.set("timezone_offset", null);
            _this.reserveForm.set("end_at", null);
            _this.reserveForm.set("end_time", null);
            _this.reserveForm.set("time_label", null);
            _this.dateStr = "";
            _this.noSeat = null;
            _this.is_deposit = false;
            _this.reserveForm.set("table_category", null);
            _this.reserveForm.set("table_smoking", null);
            _this.reserveForm.set("courses", null);
            _this.selectedCategory = "";
            _this.tableCategories = [];
            _this.tableSmoking = [];
            _this.coursesOptions = [];
            _this.courses = [{}];
            _this.selectedCourses = [];
            _this.selectedCourseSeats = [];
            _this._cliecked = false;
            if (_this.isTest2) {
                _this.sendRsvInfo.next({
                    type: "return",
                    status: false,
                    errorMessage: _this.errorMessage
                });
            }
        });
        var rf = this.reserveForm.reserveFormCollection$.subscribe(function (data) {
            rf.unsubscribe();
            console.log(data);
            reserveData = {
                restaurant_id: data.restaurant_id,
                seats_adults: data.seats_adults || data.seats,
                seats_children: data.seats_children,
                start_at: data.start_at,
                is_deposit: data.is_deposit || false,
                table_category: data.table_category || "none",
                smoking: data.table_smoking || "none"
            };
            if (data.note) {
                reserveData["note"] = data.note;
            }
            if (data.end_at) {
                reserveData["end_at"] = data.end_at;
            }
            if (data.courses) {
                var courses_1 = [];
                var _c_1 = {};
                data.courses.forEach(function (c) {
                    console.log(c);
                    if (!_c_1[c.course_id]) {
                        _c_1[c.course_id] = +c.count;
                    }
                    else {
                        _c_1[c.course_id] += +c.count;
                    }
                });
                Object.keys(_c_1).forEach(function (key) {
                    if (_c_1[key] > 0 && key) {
                        courses_1.push({
                            course_id: key,
                            count: _c_1[key]
                        });
                    }
                });
                if (courses_1.length) {
                    reserveData["courses"] = courses_1;
                }
            }
            if (data.ref) {
                reserveData["contact_from"] = data.ref.contact_from;
                reserveData["contact_from_key"] = data.ref.contact_from_key;
                reserveData["referrer"] = data.ref.referrer;
            }
            if (data.payment && data.payment.reservation_id) {
                _this.reserveService.updateTempReservationInfo(data.payment.reservation_id, reserveData);
            }
            else {
                try {
                    var v = _this.reserveForm.get()["start_time"];
                    var h = ("0" + Math.floor(v / 3600)).slice(-2);
                    var m = ("0" + Math.floor((v % 3600) / 60)).slice(-2);
                    var hm = h + ":" + m;
                    console.log(_this.timeStr, hm);
                    if (hm != _this.timeStr) {
                        Bugsnag.notify("invlidTime", hm + " != " + _this.timeStr, _this.reserveForm.get());
                    }
                }
                catch (e) { }
                _this.reserveService.createTempReservationInfo(reserveData);
            }
        });
        this.reserveForm.get();
    };
    return ReserveInfoComponent;
}());
__decorate([
    core_1.ViewChild(calendar_component_1.CalendarComponent),
    __metadata("design:type", calendar_component_1.CalendarComponent)
], ReserveInfoComponent.prototype, "calendar", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], ReserveInfoComponent.prototype, "isTest", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], ReserveInfoComponent.prototype, "isTest2", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Subject_1.Subject)
], ReserveInfoComponent.prototype, "sendRsvInfo", void 0);
ReserveInfoComponent = __decorate([
    core_1.Component({
        selector: "trt-reserve-info",
        template: require('./reserve-info.component.html'),
        styles: [require('./reserve-info.component.css')]
    }),
    __metadata("design:paramtypes", [reserve_form_service_1.ReserveFormService,
        router_1.Router,
        restaurant_module_1.RestaurantService,
        days_service_1.DaysService,
        week_day_service_1.WeekDayService,
        core_2.TranslateService,
        course_service_1.CourseService,
        reserve_service_1.ReserveService,
        core_1.ChangeDetectorRef,
        app_model_1.AppType,
        router_1.ActivatedRoute,
        toreta_time_service_1.TRTTimeService,
        toreta_date_service_1.TRTDateService,
        angulartics2_1.Angulartics2,
        table_category_service_1.TableCategoriesService,
        table_smoking_service_1.TableSmokingService])
], ReserveInfoComponent);
exports.ReserveInfoComponent = ReserveInfoComponent;
var PCReserveInfoComponent = (function (_super) {
    __extends(PCReserveInfoComponent, _super);
    function PCReserveInfoComponent(reserveForm, router, restaurantService, daysService, _weekday, translate, courseService, reserveService, appType, cdRef, activatedRoute, trtTime, trtDate, angulartics2, tableCategoriesService, tableSmokingService) {
        var _this = _super.call(this, reserveForm, router, restaurantService, daysService, _weekday, translate, courseService, reserveService, cdRef, appType, activatedRoute, trtTime, trtDate, angulartics2, tableCategoriesService, tableSmokingService) || this;
        _this.reserveForm = reserveForm;
        _this.router = router;
        _this.restaurantService = restaurantService;
        _this.daysService = daysService;
        _this._weekday = _weekday;
        _this.translate = translate;
        _this.courseService = courseService;
        _this.reserveService = reserveService;
        _this.appType = appType;
        _this.cdRef = cdRef;
        _this.activatedRoute = activatedRoute;
        _this.trtTime = trtTime;
        _this.trtDate = trtDate;
        _this.angulartics2 = angulartics2;
        _this.tableCategoriesService = tableCategoriesService;
        _this.tableSmokingService = tableSmokingService;
        return _this;
    }
    return PCReserveInfoComponent;
}(ReserveInfoComponent));
PCReserveInfoComponent = __decorate([
    core_1.Component({
        selector: "trt-reserve-info",
        template: require('./reserve-info.pc.component.html'),
        styles: [require('./reserve-info.pc.component.css')]
    }),
    __metadata("design:paramtypes", [reserve_form_service_1.ReserveFormService,
        router_1.Router,
        restaurant_module_1.RestaurantService,
        days_service_1.DaysService,
        week_day_service_1.WeekDayService,
        core_2.TranslateService,
        course_service_1.CourseService,
        reserve_service_1.ReserveService,
        app_model_1.AppType,
        core_1.ChangeDetectorRef,
        router_1.ActivatedRoute,
        toreta_time_service_1.TRTTimeService,
        toreta_date_service_1.TRTDateService,
        angulartics2_1.Angulartics2,
        table_category_service_1.TableCategoriesService,
        table_smoking_service_1.TableSmokingService])
], PCReserveInfoComponent);
exports.PCReserveInfoComponent = PCReserveInfoComponent;
