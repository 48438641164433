"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Observable_1 = require("rxjs/Observable");
require("rxjs/add/operator/share");
var FormDataClass = (function () {
    function FormDataClass(v) {
        this.value = v;
    }
    FormDataClass.prototype.get = function () {
        return this.value;
    };
    FormDataClass.prototype.set = function (v) {
        this.value = v;
    };
    return FormDataClass;
}());
var FormService = (function () {
    function FormService() {
        var _this = this;
        this._collection = this._collection || new Map();
        this.collection$ = new Observable_1.Observable(function (observer) {
            _this._collectionObserver = observer;
        }).share();
        this.changeCollection$ = new Observable_1.Observable(function (observer) {
            _this._changeCollectionObserver = observer;
        }).share();
    }
    FormService.prototype.add = function (k, v) {
        var data = new FormDataClass(v);
        this._collection.set(k, data);
        this._collectionObserver.next(this._collection);
    };
    FormService.prototype.set = function (k, v) {
        console.log(this._collection);
        var data = this._collection.get(k);
        if (data && data.value !== v) {
            data.set(v);
            this._changeCollectionObserver.next(this._collection);
        }
        else {
            this.add(k, v);
        }
    };
    FormService.prototype.remove = function (k) {
        this._collection.delete(k);
        this._changeCollectionObserver.next(this._collection);
    };
    FormService.prototype.load = function () {
        this._collectionObserver.next(this._collection);
    };
    return FormService;
}());
exports.FormService = FormService;
