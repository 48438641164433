"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var http_1 = require("@angular/http");
var Observable_1 = require("rxjs/Observable");
require("rxjs/add/observable/of");
require("rxjs/add/operator/share");
require("rxjs/add/operator/map");
require("rxjs/add/operator/merge");
require("rxjs/add/operator/toArray");
var ApiService = (function () {
    function ApiService(http) {
        this.http = http;
        this.API_PATH = API_URL + "/web/v1";
        this.D_API_PATH = D_API_URL + "/web/v1";
    }
    ApiService.prototype.getContents = function (public_key) {
        return this.http
            .get(this.API_PATH + "/web_reservations/" + public_key + "/contents.json")
            .map(function (res) { return res.json(); });
    };
    ApiService.prototype.getDays = function (year, month, seats, reservation_id) {
        var query = "year=" + year + "&month=" + month + "&seats=" + seats;
        query += reservation_id
            ? "&options[except_temporary_reservation_ids][]=" + reservation_id
            : '';
        return this.http
            .get(this.API_PATH + "/web_reservations/" + this.key + "/days.json?" + query)
            .map(function (res) { return res.json().result; });
    };
    ApiService.prototype.getCourses = function (year, month, date, start_time, end_time) {
        var endTimeParam = '';
        if (end_time) {
            endTimeParam = "&end_time=" + end_time;
        }
        return this.http
            .get(this.API_PATH + "/web_reservations/" + this.key + "/courses.json?year=" + year + "&month=" + month + "&day=" + date + "&start_time=" + start_time + endTimeParam)
            .map(function (res) { return res.json(); });
    };
    ApiService.prototype.getFreeTableCategories = function (year, month, date, seats, start_time, end_time, reservation_id) {
        var query = "year=" + year + "&month=" + month + "&day=" + date + "&seats=" + seats + "&start_time=" + start_time;
        query += end_time ? "&end_time=" + end_time : '';
        query += reservation_id
            ? "&options[except_temporary_reservation_ids][]=" + reservation_id
            : '';
        return this.http
            .get(this.API_PATH + "/web_reservations/" + this.key + "/table_categories.json?" + query)
            .map(function (res) { return res.json(); });
    };
    ApiService.prototype.getFreeTableSmoking = function (year, month, date, seats, table_category, start_time, end_time, reservation_id) {
        var query = "year=" + year + "&month=" + month + "&day=" + date + "&seats=" + seats + "&table_category=" + table_category + "&start_time=" + start_time;
        query += end_time ? "&end_time=" + end_time : '';
        query += reservation_id
            ? "&options[except_temporary_reservation_ids][]=" + reservation_id
            : '';
        return this.http
            .get(this.API_PATH + "/web_reservations/" + this.key + "/smoking.json?" + query)
            .map(function (res) { return res.json(); });
    };
    ApiService.prototype.postTempReservationInfo = function (reserveData) {
        var url = this.API_PATH + "/temporary_web_reservations.json";
        return this.post(url, reserveData);
    };
    ApiService.prototype.postTempReservationCustomer = function (temp_id, customerData) {
        var url = this.API_PATH + "/temporary_web_reservations/" + temp_id + "/customer_informations.json";
        return this.post(url, customerData);
    };
    ApiService.prototype.postTempReservationCommit = function (temp_id, restaurant_id) {
        var url = this.API_PATH + "/temporary_web_reservations/" + temp_id + "/commit.json";
        return this.post(url, {
            restaurant_id: restaurant_id,
        });
    };
    ApiService.prototype.putTempReservationInfo = function (temp_id, reserveData) {
        var url = this.API_PATH + "/temporary_web_reservations/" + temp_id + ".json";
        return this.put(url, reserveData);
    };
    ApiService.prototype.deleteTempReservationInfo = function (temp_id) {
        var url = this.API_PATH + "/temporary_web_reservations/" + temp_id + ".json";
        return this.delete(url, { restaurant_id: this.key });
    };
    ApiService.prototype.deleteTempReservationInfoSync = function (temp_id) {
        var url = this.API_PATH + "/temporary_web_reservations/" + temp_id + ".json";
        var _xhr = new XMLHttpRequest();
        _xhr.open('delete', url, false);
        _xhr.setRequestHeader('content-type', 'application/json');
        _xhr.addEventListener('load', function () {
            var body = _xhr.response === undefined ? _xhr.responseText : _xhr.response;
            var headers = http_1.Headers.fromResponseHeaderString(_xhr.getAllResponseHeaders());
            var url = '';
            if ('responseURL' in _xhr) {
                url = _xhr.responseURL;
            }
            if (/^X-Request-URL:/m.test(_xhr.getAllResponseHeaders())) {
                url = _xhr.getResponseHeader('X-Request-URL');
            }
            var status = _xhr.status === 1223 ? 204 : _xhr.status;
            if (status === 0) {
                status = body ? 200 : 0;
            }
            var statusText = _xhr.statusText || 'OK';
            var responseOptions = new http_1.ResponseOptions({
                body: body,
                status: status,
                headers: headers,
                statusText: statusText,
                url: url,
            });
            var response = new http_1.Response(responseOptions);
            response.ok = status >= 200 && status < 300;
            return response;
        });
        _xhr.send(JSON.stringify({ restaurant_id: this.key }));
    };
    ApiService.prototype.putTempReservationCustomer = function (temp_id, customerData) {
        var url = this.API_PATH + "/temporary_web_reservations/" + temp_id + "/customer_informations.json";
        return this.put(url, customerData);
    };
    ApiService.prototype.postReserveCommit = function (temp_id) {
        var url = this.API_PATH + "/temporary_web_reservations/" + temp_id + ".json";
        return this.post(url, {
            restaurant_key: this.key,
        });
    };
    ApiService.prototype.postDeposit = function (restaurant_id, reservation_id, payment_token) {
        var url = this.API_PATH + "/temporary_web_reservations/" + reservation_id + "/deposits.json";
        return this.post(url, {
            restaurant_id: restaurant_id,
            payment_token: payment_token,
        });
    };
    ApiService.prototype.createDepositCheck = function () {
        var _this = this;
        var _obs;
        var observer = Observable_1.Observable.create(function (obs) {
            _obs = obs;
        });
        console.log(observer);
        return {
            observer: observer,
            check: function (restaurant_id, reservation_id, transaction_id) {
                var d = new Date();
                var url = _this.API_PATH + "/temporary_web_reservations/" + reservation_id + "/deposits/" + transaction_id + ".json?restaurant_id=" + restaurant_id + "&n=" + d.getTime();
                console.log(url);
                _this.get(url).subscribe(function (data) { return _obs.next(data); }, function (err) { return _obs.error(err); });
            },
        };
    };
    ApiService.prototype.createDepositDocomo = function (restaurant_id, reservation_id, continue_uri) {
        var url = this.D_API_PATH + "/temporary_web_reservations/" + reservation_id + "/deposits/docomo.json";
        return this.post(url, {
            restaurant_id: restaurant_id,
            continue_uri: continue_uri,
        });
    };
    ApiService.prototype.getCsrfToken = function () {
        var meta = document.querySelector('meta[name=csrf-token]');
        return (meta && meta.getAttribute('content')) || '';
    };
    ApiService.prototype.post = function (url, data) {
        var headers = new http_1.Headers();
        headers.append('Content-Type', 'application/json;charset=UTF-8');
        this.csrf_token = this.csrf_token || this.getCsrfToken();
        if (this.csrf_token) {
            headers.append('X-CSRF-Token', this.csrf_token);
        }
        return this.http
            .post(url, data, { headers: headers, withCredentials: true })
            .map(function (res) { return res.json(); });
    };
    ApiService.prototype.put = function (url, data) {
        var headers = new http_1.Headers();
        headers.append('Content-Type', 'application/json;charset=UTF-8');
        this.csrf_token = this.csrf_token || this.getCsrfToken();
        if (this.csrf_token) {
            headers.append('X-CSRF-Token', this.csrf_token);
        }
        return this.http
            .put(url, data, { headers: headers })
            .map(function (res) { return res.json(); });
    };
    ApiService.prototype.get = function (url) {
        var headers = new http_1.Headers();
        headers.append('Content-Type', 'application/json;charset=UTF-8');
        if (this.csrf_token) {
            headers.append('X-CSRF-Token', this.csrf_token);
        }
        return this.http
            .get(url, { headers: headers })
            .map(function (res) { return res.json(); });
    };
    ApiService.prototype.delete = function (url, data) {
        var headers = new http_1.Headers();
        this.csrf_token = this.csrf_token || this.getCsrfToken();
        if (this.csrf_token) {
            headers.append('X-CSRF-Token', this.csrf_token);
        }
        headers.append('Content-Type', 'application/json;charset=UTF-8');
        return this.http
            .delete(url, { headers: headers, body: data })
            .map(function (res) { return res.json(); });
    };
    return ApiService;
}());
ApiService = __decorate([
    core_1.Injectable(),
    __metadata("design:paramtypes", [http_1.Http])
], ApiService);
exports.ApiService = ApiService;
