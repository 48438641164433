"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./reserve-confirmation.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core/src/translate.pipe");
var i3 = require("@ngx-translate/core/src/translate.service");
var i4 = require("@angular/router");
var i5 = require("@angular/common");
var i6 = require("../../common/components/reservation.component.ngfactory");
var i7 = require("../../common/components/reservation.component");
var i8 = require("../../common/components/personal.component.ngfactory");
var i9 = require("../../common/components/personal.component");
var i10 = require("./reserve-confirmation.component");
var i11 = require("../../common/components/optin-confirm.component.ngfactory");
var i12 = require("../../common/components/optin-confirm.component");
var i13 = require("../../term/components/term.component.ngfactory");
var i14 = require("../../term/components/term.component");
var i15 = require("../../term/services/term.service");
var i16 = require("../../form/components/checkbox.component.ngfactory");
var i17 = require("../../form/services/form.service");
var i18 = require("../../form/components/checkbox.component");
var i19 = require("../../form/components/button.component.ngfactory");
var i20 = require("../../form/components/button.component");
var i21 = require("../../common/services/restaurant/src/restaurant.service");
var i22 = require("../../common/services/reserve-form.service");
var i23 = require("../../common/services/reserve.service");
var i24 = require("../../common/services/intl-tel.service");
var i25 = require("../../app/models/app.model");
var i26 = require("./reserve-confirmation.pc.component.css.shim.ngstyle");
var styles_ReserveConfirmationComponent = [i0.styles];
exports.RenderType_ReserveConfirmationComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_ReserveConfirmationComponent, data: {} });
function View_ReserveConfirmationComponent_1(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 2, 'p', [], null, null, null, null, null)), (_l()(),
            i1.ɵted(null, ['\n      ', '\n    '])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) {
        var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform('RESERVE_CONFIRMATION.ATTENTION'));
        _ck(_v, 1, 0, currVal_0);
    });
}
function View_ReserveConfirmationComponent_2(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 8, 'p', [['class',
                'deposit']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['\n      ', '\n      '])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(0, null, null, 0, 'br', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n      ',
            '\n      '])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]),
        (_l()(), i1.ɵeld(0, null, null, 0, 'br', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n      ', '\n    '])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService,
            i1.ChangeDetectorRef])], null, function (_ck, _v) {
        var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform('RESERVE_CONFIRMATION.DEPOSIT.ATTENTION'));
        _ck(_v, 1, 0, currVal_0);
        var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform('DEPOSIT_CANCEL'));
        _ck(_v, 4, 0, currVal_1);
        var currVal_2 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform('RESERVE_CONFIRMATION.DEPOSIT.TERM'));
        _ck(_v, 7, 0, currVal_2);
    });
}
function View_ReserveConfirmationComponent_3(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 5, 'div', [['class',
                'error']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 2, 'p', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n  ']))], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.errorMessage));
        _ck(_v, 3, 0, currVal_0);
    });
}
function View_ReserveConfirmationComponent_4(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 7, 'p', [], null, null, null, null, null)), (_l()(),
            i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵeld(0, null, null, 4, 'a', [], [[1, 'target', 0], [8, 'href', 4]], [[null, 'click']], function (_v, en, $event) {
            var ad = true;
            if (('click' === en)) {
                var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), i1.ɵdid(671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, 'routerLink'] }, null), i1.ɵpad(1), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n    ']))], function (_ck, _v) {
        var currVal_2 = _ck(_v, 4, 0, '/');
        _ck(_v, 3, 0, currVal_2);
    }, function (_ck, _v) {
        var currVal_0 = i1.ɵnov(_v, 3).target;
        var currVal_1 = i1.ɵnov(_v, 3).href;
        _ck(_v, 2, 0, currVal_0, currVal_1);
        var currVal_3 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform('ERROR.TO_TOP'));
        _ck(_v, 5, 0, currVal_3);
    });
}
function View_ReserveConfirmationComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 91, 'div', [], null, null, null, null, null)), (_l()(),
            i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵeld(0, null, null, 2, 'header', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n  '])),
        (_l()(), i1.ɵeld(0, null, null, 35, 'div', [['class', 'confirm']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 11, 'div', [['class', 'back']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵeld(0, null, null, 2, 'p', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]),
        (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵeld(0, null, null, 4, 'a', [], [[1, 'target', 0], [8, 'href', 4]], [[null,
                'click']], function (_v, en, $event) {
            var ad = true;
            if (('click' === en)) {
                var pd_0 = (i1.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), i1.ɵdid(671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, 'routerLink'] }, null), i1.ɵpad(1), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 1, 'trt-reservation', [], null, null, null, i6.View_ReservationComponent_0, i6.RenderType_ReservationComponent)),
        i1.ɵdid(180224, null, 0, i7.ReservationComponent, [i3.TranslateService,
            i1.ChangeDetectorRef], { restaurantName: [0, 'restaurantName'], seats: [1, 'seats'],
            adults_seats: [2, 'adults_seats'], children_seats: [3, 'children_seats'], courses: [4,
                'courses'], goDate: [5, 'goDate'], time_label: [6, 'time_label'], request: [7,
                'request'], accept_children: [8, 'accept_children'], accept_courses: [9, 'accept_courses'],
            show_request_form: [10, 'show_request_form'], is_deposit: [11, 'is_deposit'],
            amount: [12, 'amount'], start_at: [13, 'start_at'], end_at: [14, 'end_at'], start_time: [15,
                'start_time'], end_time: [16, 'end_time'], timezone_offset: [17, 'timezone_offset'],
            selected_table_category: [18, 'selected_table_category'], addInfo: [19, 'addInfo'],
            selected_table_smoking: [20, 'selected_table_smoking'] }, null), (_l()(),
            i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 0, 'hr', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 11, 'div', [['class', 'back']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵeld(0, null, null, 2, 'p', [], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService,
            i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(),
            i1.ɵeld(0, null, null, 4, 'a', [], [[1, 'target', 0],
                [8, 'href', 4]], [[null, 'click']], function (_v, en, $event) {
                var ad = true;
                if (('click' === en)) {
                    var pd_0 = (i1.ɵnov(_v, 33).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
                    ad = (pd_0 && ad);
                }
                return ad;
            }, null, null)), i1.ɵdid(671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, 'routerLink'] }, null), i1.ɵpad(1), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 1, 'trt-personal', [], null, null, null, i8.View_PersonalComponent_0, i8.RenderType_PersonalComponent)),
        i1.ɵdid(114688, null, 0, i9.PersonalComponent, [i3.TranslateService], { lastName: [0,
                'lastName'], firstName: [1, 'firstName'], lastNameReading: [2, 'lastNameReading'],
            firstNameReading: [3, 'firstNameReading'], phone: [4, 'phone'], email: [5, 'email'] }, null), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵeld(0, null, null, 4, 'div', [['class',
                'optinConfirm']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 1, 'trt-optin-confirm', [], null, [[null, 'checkOptin']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('checkOptin' === en)) {
                var pd_0 = (_co.checkOptin($event) !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i11.View_OptinConfirmComponent_0, i11.RenderType_OptinConfirmComponent)),
        i1.ɵdid(245760, null, 0, i12.OptinConfirmComponent, [i3.TranslateService,
            i1.ChangeDetectorRef], { country: [0, 'country'], optinStatus: [1, 'optinStatus'] }, { checkOptin: 'checkOptin' }), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(),
            i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵeld(0, null, null, 11, 'div', [['class', 'attention']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n    '])),
        (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReserveConfirmationComponent_1)),
        i1.ɵdid(16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0,
                'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(),
            i1.ɵand(16777216, null, null, 1, null, View_ReserveConfirmationComponent_2)),
        i1.ɵdid(16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0,
                'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(),
            i1.ɵeld(0, null, null, 2, 'p', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]),
        (_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵted(null, ['\n  '])),
        (_l()(), i1.ɵeld(0, null, null, 4, 'div', [['class', 'term']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 1, 'trt-term', [['type', 'privacy_policy']], null, null, null, i13.View_TermComponent_0, i13.RenderType_TermComponent)), i1.ɵdid(245760, null, 0, i14.TermComponent, [i3.TranslateService, i15.TermService, i1.ChangeDetectorRef], { type: [0, 'type'],
            country: [1, 'country'], restaurantLang: [2, 'restaurantLang'] }, null),
        (_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵted(null, ['\n  '])),
        (_l()(), i1.ɵeld(0, null, null, 6, 'div', [['class', 'termConfirm']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 3, 'ty-checkbox', [['tyName', 'termConfirm']], null, [[null,
                'changeData']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('changeData' === en)) {
                var pd_0 = (_co.checkConfirm($event) !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i16.View_CheckboxComponent_0, i16.RenderType_CheckboxComponent)), i1.ɵprd(512, null, i17.FormService, i17.FormService, []), i1.ɵdid(114688, null, 0, i18.CheckboxComponent, [i17.FormService, i1.ElementRef], { tyLabel: [0,
                'tyLabel'], tyName: [1, 'tyName'] }, { changeData: 'changeData' }), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReserveConfirmationComponent_3)),
        i1.ɵdid(16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0,
                'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(),
            i1.ɵeld(0, null, null, 11, 'div', [], null, null, null, null, null)), i1.ɵdid(278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef,
            i1.Renderer], { ngClass: [0, 'ngClass'] }, null), i1.ɵpod(['error']),
        (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 3, 'ty-button', [], null, [[null, 'tyClick']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('tyClick' === en)) {
                var pd_0 = (_co.onClick($event) !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i19.View_ButtonComponent_0, i19.RenderType_ButtonComponent)), i1.ɵdid(114688, null, 0, i20.ButtonComponent, [], { tyDisabledText: [0, 'tyDisabledText'],
            tyText: [1, 'tyText'], tyDisabled: [2, 'tyDisabled'] }, { tyClick: 'tyClick' }),
        i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]),
        i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]),
        (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReserveConfirmationComponent_4)), i1.ɵdid(16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵted(null, ['\n'])), (_l()(), i1.ɵted(null, ['\n']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_4 = _ck(_v, 16, 0, '/reserve-info');
        _ck(_v, 15, 0, currVal_4);
        var currVal_6 = _co.restaurantName;
        var currVal_7 = _co.seats;
        var currVal_8 = _co.adultsSeats;
        var currVal_9 = _co.childrenSeats;
        var currVal_10 = _co.courses;
        var currVal_11 = _co.goDate;
        var currVal_12 = _co.timeLabel;
        var currVal_13 = _co.request;
        var currVal_14 = _co.accept_children;
        var currVal_15 = _co.accept_courses;
        var currVal_16 = _co.show_request_form;
        var currVal_17 = _co.is_deposit;
        var currVal_18 = _co.deposit_amount;
        var currVal_19 = _co.start_at;
        var currVal_20 = _co.end_at;
        var currVal_21 = _co.start_time;
        var currVal_22 = _co.end_time;
        var currVal_23 = _co.timezone_offset;
        var currVal_24 = _co.selected_table_category;
        var currVal_25 = _co.addInfo;
        var currVal_26 = _co.selected_table_smoking;
        _ck(_v, 22, 1, [currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12,
            currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19,
            currVal_20, currVal_21, currVal_22, currVal_23, currVal_24, currVal_25, currVal_26]);
        var currVal_30 = _ck(_v, 34, 0, '/personal-info');
        _ck(_v, 33, 0, currVal_30);
        var currVal_32 = _co.lastName;
        var currVal_33 = _co.firstName;
        var currVal_34 = _co.lastNameReading;
        var currVal_35 = _co.firstNameReading;
        var currVal_36 = _co.phone;
        var currVal_37 = _co.email;
        _ck(_v, 40, 0, currVal_32, currVal_33, currVal_34, currVal_35, currVal_36, currVal_37);
        var currVal_38 = _co.country;
        var currVal_39 = _co.optinStatus;
        _ck(_v, 46, 0, currVal_38, currVal_39);
        var currVal_40 = !_co.is_deposit;
        _ck(_v, 52, 0, currVal_40);
        var currVal_41 = _co.is_deposit;
        _ck(_v, 55, 0, currVal_41);
        var currVal_43 = 'privacy_policy';
        var currVal_44 = _co.country;
        var currVal_45 = _co.restaurantLang;
        _ck(_v, 65, 0, currVal_43, currVal_44, currVal_45);
        var currVal_46 = i1.ɵinlineInterpolate(1, '', i1.ɵunv(_v, 72, 0, i1.ɵnov(_v, 73).transform('RESERVE_CONFIRMATION.CHECKBOX_LABEL')), '');
        var currVal_47 = 'termConfirm';
        _ck(_v, 72, 0, currVal_46, currVal_47);
        var currVal_48 = _co.errorMessage;
        _ck(_v, 77, 0, currVal_48);
        var currVal_49 = _ck(_v, 81, 0, _co.errorMessage);
        _ck(_v, 80, 0, currVal_49);
        var currVal_50 = i1.ɵinlineInterpolate(1, '', i1.ɵunv(_v, 84, 0, i1.ɵnov(_v, 85).transform('RESERVE_CONFIRMATION.BUTTON_DISABLED_TEXT')), '');
        var currVal_51 = i1.ɵinlineInterpolate(1, '', i1.ɵunv(_v, 84, 1, i1.ɵnov(_v, 86).transform(_co.buttonText)), '');
        var currVal_52 = !!!_co.conf;
        _ck(_v, 84, 0, currVal_50, currVal_51, currVal_52);
        var currVal_53 = _co.errorMessage;
        _ck(_v, 89, 0, currVal_53);
    }, function (_ck, _v) {
        var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform('RESERVE_CONFIRMATION.EXPLAIN'));
        _ck(_v, 3, 0, currVal_0);
        var currVal_1 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform('RESERVE_CONFIRM'));
        _ck(_v, 11, 0, currVal_1);
        var currVal_2 = i1.ɵnov(_v, 15).target;
        var currVal_3 = i1.ɵnov(_v, 15).href;
        _ck(_v, 14, 0, currVal_2, currVal_3);
        var currVal_5 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform('RESERVE_CONFIRMATION.CHANGE'));
        _ck(_v, 17, 0, currVal_5);
        var currVal_27 = i1.ɵunv(_v, 29, 0, i1.ɵnov(_v, 30).transform('CONTACT_INFO'));
        _ck(_v, 29, 0, currVal_27);
        var currVal_28 = i1.ɵnov(_v, 33).target;
        var currVal_29 = i1.ɵnov(_v, 33).href;
        _ck(_v, 32, 0, currVal_28, currVal_29);
        var currVal_31 = i1.ɵunv(_v, 35, 0, i1.ɵnov(_v, 36).transform('RESERVE_CONFIRMATION.CHANGE'));
        _ck(_v, 35, 0, currVal_31);
        var currVal_42 = i1.ɵunv(_v, 58, 0, i1.ɵnov(_v, 59).transform('RESERVE_CONFIRMATION.MAIL'));
        _ck(_v, 58, 0, currVal_42);
    });
}
exports.View_ReserveConfirmationComponent_0 = View_ReserveConfirmationComponent_0;
function View_ReserveConfirmationComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'trt-reserve-confirmation', [], null, null, null, View_ReserveConfirmationComponent_0, exports.RenderType_ReserveConfirmationComponent)), i1.ɵdid(114688, null, 0, i10.ReserveConfirmationComponent, [i4.Router, i21.RestaurantService, i22.ReserveFormService, i23.ReserveService, i1.ChangeDetectorRef,
            i3.TranslateService, i24.IntlTelService, i25.AppType], null, null)], function (_ck, _v) {
        _ck(_v, 1, 0);
    }, null);
}
exports.View_ReserveConfirmationComponent_Host_0 = View_ReserveConfirmationComponent_Host_0;
exports.ReserveConfirmationComponentNgFactory = i1.ɵccf('trt-reserve-confirmation', i10.ReserveConfirmationComponent, View_ReserveConfirmationComponent_Host_0, {}, {}, []);
var styles_PCReserveConfirmationComponent = [i26.styles];
exports.RenderType_PCReserveConfirmationComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_PCReserveConfirmationComponent, data: {} });
function View_PCReserveConfirmationComponent_1(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 2, 'p', [], null, null, null, null, null)), (_l()(),
            i1.ɵted(null, ['\n        ', '\n      '])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) {
        var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform('RESERVE_CONFIRMATION.ATTENTION'));
        _ck(_v, 1, 0, currVal_0);
    });
}
function View_PCReserveConfirmationComponent_2(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 8, 'p', [['class',
                'deposit']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['\n        ', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(0, null, null, 0, 'br', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(0, null, null, 0, 'br', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', '\n      '])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) {
        var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform('RESERVE_CONFIRMATION.DEPOSIT.ATTENTION'));
        _ck(_v, 1, 0, currVal_0);
        var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform('DEPOSIT_CANCEL'));
        _ck(_v, 4, 0, currVal_1);
        var currVal_2 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform('RESERVE_CONFIRMATION.DEPOSIT.TERM'));
        _ck(_v, 7, 0, currVal_2);
    });
}
function View_PCReserveConfirmationComponent_3(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 5, 'div', [['class',
                'error']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵeld(0, null, null, 2, 'p', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n    ']))], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.errorMessage));
        _ck(_v, 3, 0, currVal_0);
    });
}
function View_PCReserveConfirmationComponent_4(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 3, 'ty-button', [], null, [[null, 'tyClick']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('tyClick' === en)) {
                var pd_0 = (_co.onClick($event) !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i19.View_ButtonComponent_0, i19.RenderType_ButtonComponent)), i1.ɵdid(114688, null, 0, i20.ButtonComponent, [], { tyDisabledText: [0, 'tyDisabledText'],
            tyText: [1, 'tyText'], tyDisabled: [2, 'tyDisabled'] }, { tyClick: 'tyClick' }), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = i1.ɵinlineInterpolate(1, '', i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform('RESERVE_CONFIRMATION.BUTTON_DISABLED_TEXT')), '');
        var currVal_1 = i1.ɵinlineInterpolate(1, '', i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 3).transform(_co.buttonText)), '');
        var currVal_2 = !!!_co.conf;
        _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2);
    }, null);
}
function View_PCReserveConfirmationComponent_5(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 7, 'p', [], null, null, null, null, null)), (_l()(),
            i1.ɵted(null, ['\n        '])), (_l()(), i1.ɵeld(0, null, null, 4, 'a', [], [[1, 'target', 0], [8, 'href', 4]], [[null, 'click']], function (_v, en, $event) {
            var ad = true;
            if (('click' === en)) {
                var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), i1.ɵdid(671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, 'routerLink'] }, null), i1.ɵpad(1), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n      ']))], function (_ck, _v) {
        var currVal_2 = _ck(_v, 4, 0, '/');
        _ck(_v, 3, 0, currVal_2);
    }, function (_ck, _v) {
        var currVal_0 = i1.ɵnov(_v, 3).target;
        var currVal_1 = i1.ɵnov(_v, 3).href;
        _ck(_v, 2, 0, currVal_0, currVal_1);
        var currVal_3 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform('ERROR.TO_TOP'));
        _ck(_v, 5, 0, currVal_3);
    });
}
function View_PCReserveConfirmationComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 90, 'div', [['class',
                'content']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵeld(0, null, null, 2, 'header', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵeld(0, null, null, 83, 'div', [['class',
                'wrap']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 33, 'div', [['class', 'confirm']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n      '])),
        (_l()(), i1.ɵeld(0, null, null, 11, 'div', [['class', 'back']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n        '])), (_l()(), i1.ɵeld(0, null, null, 2, 'p', [], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService,
            i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n        '])), (_l()(),
            i1.ɵeld(0, null, null, 4, 'a', [], [[1, 'target', 0],
                [8, 'href', 4]], [[null, 'click']], function (_v, en, $event) {
                var ad = true;
                if (('click' === en)) {
                    var pd_0 = (i1.ɵnov(_v, 17).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
                    ad = (pd_0 && ad);
                }
                return ad;
            }, null, null)), i1.ɵdid(671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, 'routerLink'] }, null), i1.ɵpad(1), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵeld(0, null, null, 1, 'trt-reservation', [['pc', 'true']], null, null, null, i6.View_ReservationComponent_0, i6.RenderType_ReservationComponent)),
        i1.ɵdid(180224, null, 0, i7.ReservationComponent, [i3.TranslateService,
            i1.ChangeDetectorRef], { restaurantName: [0, 'restaurantName'], seats: [1, 'seats'],
            adults_seats: [2, 'adults_seats'], children_seats: [3, 'children_seats'], courses: [4,
                'courses'], goDate: [5, 'goDate'], time_label: [6, 'time_label'], request: [7,
                'request'], accept_children: [8, 'accept_children'], pc: [9, 'pc'], accept_courses: [10,
                'accept_courses'], show_request_form: [11, 'show_request_form'], is_deposit: [12,
                'is_deposit'], amount: [13, 'amount'], start_at: [14, 'start_at'], end_at: [15,
                'end_at'], start_time: [16, 'start_time'], end_time: [17, 'end_time'], timezone_offset: [18,
                'timezone_offset'], selected_table_category: [19, 'selected_table_category'],
            addInfo: [20, 'addInfo'], selected_table_smoking: [21, 'selected_table_smoking'] }, null), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵeld(0, null, null, 11, 'div', [['class', 'back']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n        '])), (_l()(), i1.ɵeld(0, null, null, 2, 'p', [], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService,
            i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n        '])), (_l()(),
            i1.ɵeld(0, null, null, 4, 'a', [], [[1, 'target', 0],
                [8, 'href', 4]], [[null, 'click']], function (_v, en, $event) {
                var ad = true;
                if (('click' === en)) {
                    var pd_0 = (i1.ɵnov(_v, 33).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
                    ad = (pd_0 && ad);
                }
                return ad;
            }, null, null)), i1.ɵdid(671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, 'routerLink'] }, null), i1.ɵpad(1), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵeld(0, null, null, 1, 'trt-personal', [['pc', 'true']], null, null, null, i8.View_PersonalComponent_0, i8.RenderType_PersonalComponent)),
        i1.ɵdid(114688, null, 0, i9.PersonalComponent, [i3.TranslateService], { lastName: [0,
                'lastName'], firstName: [1, 'firstName'], lastNameReading: [2, 'lastNameReading'],
            firstNameReading: [3, 'firstNameReading'], phone: [4, 'phone'], email: [5, 'email'],
            pc: [6, 'pc'] }, null), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(),
            i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 4, 'div', [['class', 'optinConfirm']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n      '])),
        (_l()(), i1.ɵeld(0, null, null, 1, 'trt-optin-confirm', [], null, [[null, 'checkOptin']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('checkOptin' === en)) {
                var pd_0 = (_co.checkOptin($event) !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i11.View_OptinConfirmComponent_0, i11.RenderType_OptinConfirmComponent)),
        i1.ɵdid(245760, null, 0, i12.OptinConfirmComponent, [i3.TranslateService,
            i1.ChangeDetectorRef], { country: [0, 'country'], optinStatus: [1, 'optinStatus'] }, { checkOptin: 'checkOptin' }), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(),
            i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 11, 'div', [['class', 'attention']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n      '])),
        (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PCReserveConfirmationComponent_1)),
        i1.ɵdid(16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0,
                'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(),
            i1.ɵand(16777216, null, null, 1, null, View_PCReserveConfirmationComponent_2)),
        i1.ɵdid(16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0,
                'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(),
            i1.ɵeld(0, null, null, 2, 'p', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]),
        (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵted(null, ['\n    '])),
        (_l()(), i1.ɵeld(0, null, null, 4, 'div', [['class', 'term']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵeld(0, null, null, 1, 'trt-term', [['type', 'privacy_policy']], null, null, null, i13.View_TermComponent_0, i13.RenderType_TermComponent)), i1.ɵdid(245760, null, 0, i14.TermComponent, [i3.TranslateService, i15.TermService, i1.ChangeDetectorRef], { type: [0, 'type'],
            country: [1, 'country'], restaurantLang: [2, 'restaurantLang'] }, null),
        (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵted(null, ['\n    '])),
        (_l()(), i1.ɵeld(0, null, null, 6, 'div', [['class', 'termConfirm']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵeld(0, null, null, 3, 'ty-checkbox', [['tyName', 'termConfirm']], null, [[null,
                'changeData']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('changeData' === en)) {
                var pd_0 = ((_co.conf = $event) !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i16.View_CheckboxComponent_0, i16.RenderType_CheckboxComponent)), i1.ɵprd(512, null, i17.FormService, i17.FormService, []), i1.ɵdid(114688, null, 0, i18.CheckboxComponent, [i17.FormService, i1.ElementRef], { tyLabel: [0,
                'tyLabel'], tyName: [1, 'tyName'] }, { changeData: 'changeData' }), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PCReserveConfirmationComponent_3)),
        i1.ɵdid(16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0,
                'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(),
            i1.ɵeld(0, null, null, 9, 'div', [['class', 'button']], null, null, null, null, null)), i1.ɵdid(278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef,
            i1.Renderer], { klass: [0, 'klass'], ngClass: [1, 'ngClass'] }, null),
        i1.ɵpod(['error']), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PCReserveConfirmationComponent_4)),
        i1.ɵdid(16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0,
                'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(),
            i1.ɵand(16777216, null, null, 1, null, View_PCReserveConfirmationComponent_5)),
        i1.ɵdid(16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0,
                'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(),
            i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵted(null, ['\n'])),
        (_l()(), i1.ɵted(null, ['\n']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_4 = _ck(_v, 18, 0, '/reserve-info');
        _ck(_v, 17, 0, currVal_4);
        var currVal_6 = _co.restaurantName;
        var currVal_7 = _co.seats;
        var currVal_8 = _co.adultsSeats;
        var currVal_9 = _co.childrenSeats;
        var currVal_10 = _co.courses;
        var currVal_11 = _co.goDate;
        var currVal_12 = _co.timeLabel;
        var currVal_13 = _co.request;
        var currVal_14 = _co.accept_children;
        var currVal_15 = 'true';
        var currVal_16 = _co.accept_courses;
        var currVal_17 = _co.show_request_form;
        var currVal_18 = _co.is_deposit;
        var currVal_19 = _co.deposit_amount;
        var currVal_20 = _co.start_at;
        var currVal_21 = _co.end_at;
        var currVal_22 = _co.start_time;
        var currVal_23 = _co.end_time;
        var currVal_24 = _co.timezone_offset;
        var currVal_25 = _co.selected_table_category;
        var currVal_26 = _co.addInfo;
        var currVal_27 = _co.selected_table_smoking;
        _ck(_v, 24, 1, [currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12,
            currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19,
            currVal_20, currVal_21, currVal_22, currVal_23, currVal_24, currVal_25, currVal_26,
            currVal_27]);
        var currVal_31 = _ck(_v, 34, 0, '/personal-info');
        _ck(_v, 33, 0, currVal_31);
        var currVal_33 = _co.lastName;
        var currVal_34 = _co.firstName;
        var currVal_35 = _co.lastNameReading;
        var currVal_36 = _co.firstNameReading;
        var currVal_37 = _co.phone;
        var currVal_38 = _co.email;
        var currVal_39 = 'true';
        _ck(_v, 40, 0, currVal_33, currVal_34, currVal_35, currVal_36, currVal_37, currVal_38, currVal_39);
        var currVal_40 = _co.country;
        var currVal_41 = _co.optinStatus;
        _ck(_v, 46, 0, currVal_40, currVal_41);
        var currVal_42 = !_co.is_deposit;
        _ck(_v, 52, 0, currVal_42);
        var currVal_43 = _co.is_deposit;
        _ck(_v, 55, 0, currVal_43);
        var currVal_45 = 'privacy_policy';
        var currVal_46 = _co.country;
        var currVal_47 = _co.restaurantLang;
        _ck(_v, 65, 0, currVal_45, currVal_46, currVal_47);
        var currVal_48 = i1.ɵinlineInterpolate(1, '', i1.ɵunv(_v, 72, 0, i1.ɵnov(_v, 73).transform('RESERVE_CONFIRMATION.CHECKBOX_LABEL')), '');
        var currVal_49 = 'termConfirm';
        _ck(_v, 72, 0, currVal_48, currVal_49);
        var currVal_50 = _co.errorMessage;
        _ck(_v, 77, 0, currVal_50);
        var currVal_51 = 'button';
        var currVal_52 = _ck(_v, 81, 0, _co.errorMessage);
        _ck(_v, 80, 0, currVal_51, currVal_52);
        var currVal_53 = !_co.errorMessage;
        _ck(_v, 84, 0, currVal_53);
        var currVal_54 = _co.errorMessage;
        _ck(_v, 87, 0, currVal_54);
    }, function (_ck, _v) {
        var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform('RESERVE_CONFIRMATION.EXPLAIN'));
        _ck(_v, 3, 0, currVal_0);
        var currVal_1 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform('RESERVE_CONFIRM'));
        _ck(_v, 13, 0, currVal_1);
        var currVal_2 = i1.ɵnov(_v, 17).target;
        var currVal_3 = i1.ɵnov(_v, 17).href;
        _ck(_v, 16, 0, currVal_2, currVal_3);
        var currVal_5 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform('RESERVE_CONFIRMATION.CHANGE'));
        _ck(_v, 19, 0, currVal_5);
        var currVal_28 = i1.ɵunv(_v, 29, 0, i1.ɵnov(_v, 30).transform('CONTACT_INFO'));
        _ck(_v, 29, 0, currVal_28);
        var currVal_29 = i1.ɵnov(_v, 33).target;
        var currVal_30 = i1.ɵnov(_v, 33).href;
        _ck(_v, 32, 0, currVal_29, currVal_30);
        var currVal_32 = i1.ɵunv(_v, 35, 0, i1.ɵnov(_v, 36).transform('RESERVE_CONFIRMATION.CHANGE'));
        _ck(_v, 35, 0, currVal_32);
        var currVal_44 = i1.ɵunv(_v, 58, 0, i1.ɵnov(_v, 59).transform('RESERVE_CONFIRMATION.MAIL'));
        _ck(_v, 58, 0, currVal_44);
    });
}
exports.View_PCReserveConfirmationComponent_0 = View_PCReserveConfirmationComponent_0;
function View_PCReserveConfirmationComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'trt-reserve-confirmation', [], null, null, null, View_PCReserveConfirmationComponent_0, exports.RenderType_PCReserveConfirmationComponent)), i1.ɵdid(114688, null, 0, i10.PCReserveConfirmationComponent, [i4.Router, i21.RestaurantService, i22.ReserveFormService, i23.ReserveService, i1.ChangeDetectorRef,
            i3.TranslateService, i24.IntlTelService, i25.AppType], null, null)], function (_ck, _v) {
        _ck(_v, 1, 0);
    }, null);
}
exports.View_PCReserveConfirmationComponent_Host_0 = View_PCReserveConfirmationComponent_Host_0;
exports.PCReserveConfirmationComponentNgFactory = i1.ɵccf('trt-reserve-confirmation', i10.PCReserveConfirmationComponent, View_PCReserveConfirmationComponent_Host_0, {}, {}, []);
