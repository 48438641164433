"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./ssl-seal.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core/src/translate.pipe");
var i3 = require("@ngx-translate/core/src/translate.service");
var i4 = require("./ssl-seal.component");
var styles_SSLSealComponent = [i0.styles];
exports.RenderType_SSLSealComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_SSLSealComponent, data: {} });
function View_SSLSealComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵeld(0, null, null, 7, 'div', [['class', 'seal sp']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(),
            i1.ɵeld(0, null, null, 4, 'p', [], null, null, null, null, null)), (_l()(), i1.ɵeld(0, null, null, 0, 'img', [], [[8, 'src', 4]], null, null, null, null)), (_l()(), i1.ɵeld(0, null, null, 2, 'span', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n  '])),
        (_l()(), i1.ɵted(null, ['\n']))], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _co.sslSeal;
        _ck(_v, 4, 0, currVal_0);
        var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform('PAYMENT.SSL'));
        _ck(_v, 6, 0, currVal_1);
    });
}
exports.View_SSLSealComponent_0 = View_SSLSealComponent_0;
function View_SSLSealComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'ssl-seal', [], null, null, null, View_SSLSealComponent_0, exports.RenderType_SSLSealComponent)),
        i1.ɵdid(49152, null, 0, i4.SSLSealComponent, [], null, null)], null, null);
}
exports.View_SSLSealComponent_Host_0 = View_SSLSealComponent_Host_0;
exports.SSLSealComponentNgFactory = i1.ɵccf('ssl-seal', i4.SSLSealComponent, View_SSLSealComponent_Host_0, {}, {}, []);
