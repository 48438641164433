"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Subject_1 = require("rxjs/Subject");
var core_1 = require("@angular/core");
var ABTestComponent = (function () {
    function ABTestComponent() {
        this.isTest = false;
        this.isTest2 = false;
        this.errorMessage = '';
        this.rsvInvalid = true;
        this.personalInvalid = true;
        this.invalid = true;
        this.sendRsvInfoSubject = new Subject_1.Subject();
        this.sendPersonalInfoSubject = new Subject_1.Subject();
    }
    ABTestComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sendRsvInfoSubject.subscribe(function (res) {
            if (res.type === 'validation') {
                _this.rsvInvalid = res.invalid;
            }
        });
        this.sendPersonalInfoSubject.subscribe(function (res) {
            if (res.type === 'validation') {
                _this.personalInvalid = res.invalid;
            }
        });
    };
    ABTestComponent.prototype.onClick = function () {
        var _this = this;
        this.sendRsvInfoSubject.next({
            type: 'send'
        });
        this.sendRsvInfoSubject.subscribe(function (res) {
            console.log(res);
            if (res.status) {
                _this.sendPersonalInfoSubject.next({
                    type: 'send'
                });
            }
            else {
                _this.errorMessage = res.errorMessage;
            }
        });
    };
    ABTestComponent.prototype.ngOnDesctory = function () {
        this.sendRsvInfoSubject.unsubscribe();
        this.sendPersonalInfoSubject.unsubscribe();
    };
    return ABTestComponent;
}());
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], ABTestComponent.prototype, "isTest", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], ABTestComponent.prototype, "isTest2", void 0);
ABTestComponent = __decorate([
    core_1.Component({
        selector: 'trt-ab-test',
        template: require('./ab-test.component.html'),
        styles: [require('./ab-test.component.css')]
    }),
    __metadata("design:paramtypes", [])
], ABTestComponent);
exports.ABTestComponent = ABTestComponent;
var PCABTestComponent = (function (_super) {
    __extends(PCABTestComponent, _super);
    function PCABTestComponent() {
        return _super.call(this) || this;
    }
    return PCABTestComponent;
}(ABTestComponent));
PCABTestComponent = __decorate([
    core_1.Component({
        selector: 'trt-ab-test',
        template: require('./ab-test.component.html'),
        styles: [require('./ab-test.component.css')]
    }),
    __metadata("design:paramtypes", [])
], PCABTestComponent);
exports.PCABTestComponent = PCABTestComponent;
