"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var http_1 = require("@angular/http");
var Observable_1 = require("rxjs/Observable");
var TermService = (function () {
    function TermService(http) {
        this.http = http;
    }
    TermService.prototype.get = function (type, country, lang) {
        var _this = this;
        if (country === void 0) { country = 'jp'; }
        if (lang === void 0) { lang = 'en'; }
        var url = S3_URL + "/dist/client/assets/term/" + country + "/" + type + "/" + lang + ".html";
        return Observable_1.Observable.create(function (_observer) {
            _this.http.get(url).subscribe(function (d) {
                console.log(d);
                _observer.next(d.text());
            }, function (err) {
                console.log(err);
                _observer.error(err);
            }, function () {
                _observer.complete();
            });
        });
    };
    return TermService;
}());
TermService = __decorate([
    core_1.Injectable(),
    __metadata("design:paramtypes", [http_1.Http])
], TermService);
exports.TermService = TermService;
