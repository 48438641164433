"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AppType = (function () {
    function AppType() {
    }
    return AppType;
}());
exports.AppType = AppType;
var AppTypeLoad = (function () {
    function AppTypeLoad(type, key, isMobile, lang, provider) {
        if (isMobile === void 0) { isMobile = null; }
        if (lang === void 0) { lang = 'ja'; }
        if (provider === void 0) { provider = false; }
        this.started = false;
        this.type = type;
        this.publicKey = key;
        this.device = isMobile ? 'mobile' : 'pc';
        this.lang = lang;
        this.provider = provider;
        try {
            this.uLocalStorage = !!window.localStorage;
        }
        catch (e) {
            this.uLocalStorage = false;
        }
    }
    AppTypeLoad.prototype.isServer = function () {
        return this.type === 'server';
    };
    AppTypeLoad.prototype.isBrowser = function () {
        return this.type !== 'server';
    };
    AppTypeLoad.prototype.setPublicKey = function (public_key) {
        this.publicKey = public_key;
    };
    AppTypeLoad.prototype.getPublicKey = function () {
        return this.publicKey;
    };
    AppTypeLoad.prototype.isPc = function () {
        return this.device === 'pc';
    };
    AppTypeLoad.prototype.isMobile = function () {
        return this.device === 'mobile';
    };
    AppTypeLoad.prototype.getLang = function () {
        return this.lang;
    };
    AppTypeLoad.prototype.setProvider = function (bool) {
        console.log(bool);
        this.provider = bool;
    };
    AppTypeLoad.prototype.useProvider = function () {
        return this.provider;
    };
    AppTypeLoad.prototype.useLocalStorage = function () {
        return this.uLocalStorage;
    };
    return AppTypeLoad;
}());
exports.AppTypeLoad = AppTypeLoad;
