"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var common_1 = require("@angular/common");
var form_module_1 = require("../form/form.module");
var personal_info_routes_1 = require("./personal-info.routes");
var common_module_1 = require("../common/common.module");
var core_1 = require("@angular/core");
var person_validation_service_1 = require("./services/person-validation.service");
var personal_info_component_1 = require("./components/personal-info.component");
var core_2 = require("@ngx-translate/core");
var PersonalInfoModule = (function () {
    function PersonalInfoModule() {
    }
    return PersonalInfoModule;
}());
PersonalInfoModule = __decorate([
    core_1.NgModule({
        imports: [
            form_module_1.TRTFormModule,
            common_1.CommonModule,
            router_1.RouterModule,
            core_2.TranslateModule,
            common_module_1.TRTCommonModule,
            personal_info_routes_1.PersonalInfoRoutingModule
        ],
        exports: [personal_info_component_1.PersonalInfoComponent],
        declarations: [personal_info_component_1.PersonalInfoComponent],
        providers: [person_validation_service_1.PersonValidationService],
    })
], PersonalInfoModule);
exports.PersonalInfoModule = PersonalInfoModule;
var PCPersonalInfoModule = (function () {
    function PCPersonalInfoModule() {
    }
    return PCPersonalInfoModule;
}());
PCPersonalInfoModule = __decorate([
    core_1.NgModule({
        imports: [
            form_module_1.TRTFormModule,
            common_1.CommonModule,
            router_1.RouterModule,
            core_2.TranslateModule,
            common_module_1.TRTCommonModule,
            personal_info_routes_1.PCPersonalInfoRoutingModule
        ],
        exports: [personal_info_component_1.PCPersonalInfoComponent],
        declarations: [personal_info_component_1.PCPersonalInfoComponent],
        providers: [person_validation_service_1.PersonValidationService],
    })
], PCPersonalInfoModule);
exports.PCPersonalInfoModule = PCPersonalInfoModule;
