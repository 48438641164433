"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Subject_1 = require("rxjs/Subject");
var core_1 = require("@angular/core");
var Observable_1 = require("rxjs/Observable");
require("rxjs/add/operator/share");
require("rxjs/add/operator/map");
var api_service_1 = require("../../common/services/api.service");
var TableCategoriesService = (function () {
    function TableCategoriesService(api) {
        var _this = this;
        this.api = api;
        this.prev = {};
        this.collection$ = new Observable_1.Observable(function (obs) {
            console.log(obs);
            _this._observer = obs;
        }).share();
    }
    TableCategoriesService.prototype.get = function (year, month, date, seats, startTime, endTime, endAt, reservationId) {
        var _this = this;
        var apiObservable = this._getApi(year, month, date, seats, startTime, endTime, endAt, reservationId).subscribe(function (data) {
            apiObservable.unsubscribe();
            _this._observer.next([data]);
        });
    };
    TableCategoriesService.prototype.getAll = function () {
        var _this = this;
        var prevs = [];
        console.log(this.prev);
        Object.keys(this.prev).forEach(function (k) {
            prevs.push(_this.prev[k]);
        });
        var prevSubject = new Subject_1.Subject();
        var prevSubscription = prevSubject.subscribe(function (i) {
            if (i < prevs.length) {
                console.log(prevs[i]);
                _this._observer.next(prevs[i]);
                prevSubject.next(++i);
            }
            else {
                prevSubscription.unsubscribe();
            }
        });
        prevSubject.next(0);
    };
    TableCategoriesService.prototype.getTimes = function (year, month, date, seats, startTime, timeSlot, reservationId) {
        var _this = this;
        console.log(timeSlot);
        var datas = [];
        var timeSubject = new Subject_1.Subject();
        var timeSubscriotion = timeSubject.subscribe(function (i) {
            if (i < timeSlot.length) {
                _this._getApi(year, month, date, seats, startTime, timeSlot[i].end_time, timeSlot[i].end_at, reservationId).subscribe(function (data) {
                    datas.push(data);
                    timeSubject.next(++i);
                });
            }
            else {
                _this._observer.next(datas);
                timeSubscriotion.unsubscribe();
            }
        });
        timeSubject.next(0);
    };
    TableCategoriesService.prototype._getApi = function (year, month, date, seats, startTime, endTime, endAt, reservationId) {
        var _this = this;
        return Observable_1.Observable.create(function (obs) {
            var prev = _this.prev["" + year + month + date + seats + startTime + endTime + reservationId];
            if (prev) {
                if (prev !== 1) {
                    setTimeout(function () {
                        obs.next({
                            keyNames: prev,
                            endTime: endTime,
                            endAt: endAt
                        });
                    }, 0);
                }
            }
            else {
                _this.prev["" + year + month + date + seats + startTime + endTime + reservationId] = 1;
                _this.api.getFreeTableCategories(year, month, date, seats, startTime, endTime, reservationId).subscribe(function (data) {
                    _this.prev = {};
                    _this.prev["" + year + month + date + seats + startTime + endTime + reservationId] = data;
                    obs.next({
                        keyNames: data,
                        endTime: endTime,
                        endAt: endAt
                    });
                });
            }
        });
    };
    return TableCategoriesService;
}());
TableCategoriesService = __decorate([
    core_1.Injectable(),
    __metadata("design:paramtypes", [api_service_1.ApiService])
], TableCategoriesService);
exports.TableCategoriesService = TableCategoriesService;
