"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ParamsService = (function () {
    function ParamsService() {
        this.params = {};
    }
    ParamsService.prototype.searchParamsPromise = function (params) {
        this.searchParams(params);
        return Promise.resolve(this.params);
    };
    ParamsService.prototype.hashParamsPromise = function (params) {
        this.searchParams(params);
        return Promise.resolve(this.params);
    };
    ParamsService.prototype.searchParams = function (params) {
        var match, re;
        re = /\??(.*?)=([^\&]*)&?/gi;
        var save = false;
        while (match = re.exec(params)) {
            console.log(match);
            save = true;
            this.params[match[1]] = match[2];
        }
        if (save) {
            this.params.contact_from = this.params.from;
            this.params.contact_from_key = this.params.affiid || this.params.stamp;
            if (this.params.seats) {
                this.params.seats = this.params.seats * 1;
                this.params.adults_seats = this.params.seats * 1;
            }
            if (this.params.adults_seats) {
                this.params.seats = this.params.adults_seats * 1 || 0;
                this.params.adults_seats = this.params.adults_seats * 1;
            }
            if (this.params.children_seats) {
                this.params.seats += this.params.children_seats * 1;
                this.params.children_seats = this.params.children_seats * 1;
            }
            if (this.params.date) {
                this.params.date = this.params.date.replace(/\-/g, '/');
            }
            if (this.params.start_time) {
                this.params.start_time = +this.params.start_time;
            }
            console.log(this.params);
        }
    };
    ParamsService.prototype.hashParams = function (params) {
        var match, re;
        re = /\#?(.*?)=([^\&]*)&?/gi;
        var save = false;
        while (match = re.exec(params)) {
            console.log(match);
            save = true;
            this.params[match[1]] = match[2];
        }
        if (save) {
            this.params.contact_from = this.params.from;
            this.params.contact_from_key = this.params.affiid || this.params.stamp;
        }
    };
    return ParamsService;
}());
ParamsService = __decorate([
    core_1.Injectable(),
    __metadata("design:paramtypes", [])
], ParamsService);
exports.ParamsService = ParamsService;
