"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var Observable_1 = require("rxjs/Observable");
require("rxjs/add/operator/share");
require("rxjs/add/operator/map");
var restaurant_module_1 = require("../../common/services/restaurant/restaurant.module");
var api_service_1 = require("../../common/services/api.service");
var CourseService = (function () {
    function CourseService(restaurantService, api) {
        var _this = this;
        this.restaurantService = restaurantService;
        this.api = api;
        this.collection$ = new Observable_1.Observable(function (obs) {
            _this._observer = obs;
        }).share();
    }
    CourseService.prototype.ngOnInit = function () {
        var restaurant = this.restaurantService.get().restaurant;
        this.key = restaurant.key;
    };
    CourseService.prototype.fromApi = function (year, month, date, startTime, endTime) {
        return this.api.getCourses(year, month, date, startTime, endTime);
    };
    CourseService.prototype.get = function (year, month, date, startTime, endTime) {
        var _this = this;
        this.fromApi(year, month, date, startTime, endTime).subscribe(function (res) {
            _this.courses = [];
            res.courses.forEach(function (course) {
                _this.courses.push(course);
            });
            _this._observer.next(_this.courses);
        });
    };
    return CourseService;
}());
CourseService = __decorate([
    core_1.Injectable(),
    __metadata("design:paramtypes", [restaurant_module_1.RestaurantService, api_service_1.ApiService])
], CourseService);
exports.CourseService = CourseService;
