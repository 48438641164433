"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var MaskComponent = (function () {
    function MaskComponent() {
        this.clickEvent = new core_1.EventEmitter();
        this.backgroundColor = '#fff';
        this.opacity = '0.1';
    }
    MaskComponent.prototype.onClick = function ($event) {
        this.clickEvent.next($event);
    };
    return MaskComponent;
}());
__decorate([
    core_1.Output(),
    __metadata("design:type", core_1.EventEmitter)
], MaskComponent.prototype, "clickEvent", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], MaskComponent.prototype, "backgroundColor", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], MaskComponent.prototype, "opacity", void 0);
MaskComponent = __decorate([
    core_1.Component({
        selector: 'trt-mask',
        template: '<div (click)="onClick($event)" [style.backgroundColor]="backgroundColor" [style.opacity]="opacity"><ng-content></ng-content></div>',
        styles: [require('./mask.component.css')]
    }),
    __metadata("design:paramtypes", [])
], MaskComponent);
exports.MaskComponent = MaskComponent;
