"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
function __export(m) {
    for (var p in m) if (!exports.hasOwnProperty(p)) exports[p] = m[p];
}
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var http_1 = require("@angular/http");
var restaurant_service_1 = require("./src/restaurant.service");
__export(require("./src/restaurant.service"));
function restaurantFactory(http) {
    return new restaurant_service_1.RestaurantStaticLoader(http);
}
exports.restaurantFactory = restaurantFactory;
exports.RESTAURANT_PROVIDERS = [
    { provide: restaurant_service_1.RestaurantLoader,
        useFactory: restaurantFactory,
        deps: [http_1.Http]
    },
    restaurant_service_1.RestaurantService
];
function restaurantLoaderFactory(http) {
    return new restaurant_service_1.RestaurantStaticLoader(http);
}
exports.restaurantLoaderFactory = restaurantLoaderFactory;
var RestaurantModule = RestaurantModule_1 = (function () {
    function RestaurantModule() {
    }
    RestaurantModule.forRoot = function (providedLoader) {
        if (providedLoader === void 0) { providedLoader = {
            provide: restaurant_service_1.RestaurantLoader,
            useFactory: restaurantLoaderFactory,
            deps: [http_1.Http]
        }; }
        return {
            ngModule: RestaurantModule_1,
            providers: [providedLoader, restaurant_service_1.RestaurantService]
        };
    };
    return RestaurantModule;
}());
RestaurantModule = RestaurantModule_1 = __decorate([
    core_1.NgModule({
        imports: []
    })
], RestaurantModule);
exports.RestaurantModule = RestaurantModule;
var RestaurantModule_1;
