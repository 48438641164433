"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var angulartics2_1 = require("angulartics2");
var platform_browser_1 = require("@angular/platform-browser");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var core_2 = require("@ngx-translate/core");
var restaurant_module_1 = require("../../common/services/restaurant/restaurant.module");
var app_model_1 = require("../models/app.model");
var reserve_service_1 = require("../../common/services/reserve.service");
var reserve_form_service_1 = require("../../common/services/reserve-form.service");
var params_service_1 = require("../../common/services/params.service");
var api_service_1 = require("../../common/services/api.service");
var from_service_1 = require("../../common/services/from.service");
var AppComponent = (function () {
    function AppComponent(translate, router, restaurantService, cdRef, appType, applicationRef, reserveForm, paramsService, api, reserveService, elRef, document, renderer, sanitizer, fromService, activedRoute, angulartics2GoogleAnalytics, location) {
        var _this = this;
        this.translate = translate;
        this.router = router;
        this.restaurantService = restaurantService;
        this.cdRef = cdRef;
        this.appType = appType;
        this.applicationRef = applicationRef;
        this.reserveForm = reserveForm;
        this.paramsService = paramsService;
        this.api = api;
        this.reserveService = reserveService;
        this.elRef = elRef;
        this.document = document;
        this.renderer = renderer;
        this.sanitizer = sanitizer;
        this.fromService = fromService;
        this.activedRoute = activedRoute;
        this.angulartics2GoogleAnalytics = angulartics2GoogleAnalytics;
        this.location = location;
        this.headerString = "";
        this.restaurant_key = "";
        this.restaurantLang = "";
        this.browserWarning = false;
        this.langs = [];
        this.noIndex = false;
        var navigator = window.navigator;
        this.browserWarning = navigator.userAgent.indexOf("wv") !== -1;
        var langs = navigator.languages || [];
        var userLang = (navigator.browserLanguage ||
            langs[0] ||
            navigator.language ||
            navigator.userLanguage).substr(0, 2);
        if (userLang === "zh" &&
            (navigator.browserLanguage ||
                langs[0] ||
                navigator.language ||
                navigator.userLanguage).toLowerCase() === "zh-tw") {
            userLang = "zh-tw";
        }
        this.userLang = /(ja|zh\-tw|vi)/gi.test(userLang) ? userLang : "en";
        this.paramsService.hashParams(this.location.hash);
        this.paramsService.searchParams(this.location.search);
        var canMove = false;
        var key = this.location.pathname.replace(/\//g, "");
        window.addEventListener("beforeunload", function (e) {
            if (canMove) {
                e.returnValue = "";
            }
            else {
                if (_this.appType.useLocalStorage) {
                    var restaurant = _this.restaurantService.get();
                    if (restaurant) {
                        var key_1 = restaurant.restaurant.key;
                        window.localStorage.setItem(key_1 + ":userLang", _this.translate.currentLang);
                    }
                }
            }
        });
        window.addEventListener("unload", function (e) {
            var rsv = _this.reserveForm.get();
            if (canMove && rsv && rsv.payment && rsv.payment.reservation_id) {
                _this.reserveService.deleteTempReservationInfoSync(rsv.payment.reservation_id);
            }
        });
        console.log(this.appType.useProvider());
        this.router.events
            .distinctUntilChanged(function (prev, cur) {
            if (cur instanceof router_1.NavigationEnd) {
                return prev.url === cur.url;
            }
            return true;
        })
            .subscribe(function (u) {
            try {
                gtag("event", "page_view", {
                    page_title: window.document.title,
                    page_location: window.location.href,
                    page_path: window.location.pathname +
                        window.location.search +
                        window.location.hash,
                    page_search: location.search,
                    page_hash: location.hash,
                });
                var allTrackers = (ga && ga.getAll && ga.getAll()) || [];
                if (allTrackers.length > 1) {
                    allTrackers.forEach(function (tracker) {
                        if (tracker.get("trackingId") !== "UA-45153728-9") {
                            var path = "/" + key + "/#" + u.url.replace("!", "");
                            tracker.send("pageview", path);
                        }
                    });
                }
            }
            catch (e) {
                console.error(e);
            }
            window.scrollTo(0, 0);
        });
        this.translate.onLangChange.subscribe(function (l) {
            var htmlNode = document.body.parentNode;
            htmlNode.lang = l.lang;
        });
        key = key.replace("web_reservations/", "");
        var r = this.restaurantService.get(key);
        this.appType.setPublicKey(key);
        this.restaurantService.setPublicKey(key);
        fromService.init(key);
        this.appType.setProvider(this.appType.useProvider() ||
            fromService.get("contact_from") === "rakuten" ||
            !!paramsService.params.docomo_login ||
            !!paramsService.params.docomo_payment);
        console.log(this.appType.useProvider() ||
            fromService.get("contact_from") === "rakuten");
        console.log(this.appType.useProvider());
        console.log(r);
        if (r) {
            this.setTranslateLang(r);
            var rd = this.reserveForm.read(r.restaurant.key);
            if (rd &&
                rd.ref &&
                rd.ref.contact_from === "rakuten" &&
                this.paramsService.params.contact_from === "rakuten" &&
                this.paramsService.params.last_name) {
                this.appType.setProvider(rd && rd.ref && rd.ref.contact_from === "rakuten");
                console.log(rd);
                this.appType.started = true;
                this.api.csrf_token = decodeURIComponent(this.paramsService.params.csrf_token);
                this.reserveForm.set("customer", decodeURIComponent(this.paramsService.params.last_name || ""), "temporary_reservation_customer_information", "last_name");
                this.reserveForm.set("customer", decodeURIComponent(this.paramsService.params.first_name || ""), "temporary_reservation_customer_information", "first_name");
                this.reserveForm.set("customer", decodeURIComponent(this.paramsService.params.last_name_reading || ""), "temporary_reservation_customer_information", "last_name_reading");
                this.reserveForm.set("customer", decodeURIComponent(this.paramsService.params.first_name_reading || ""), "temporary_reservation_customer_information", "first_name_reading");
                this.reserveForm.set("customer", decodeURIComponent(this.paramsService.params.phone || ""), "temporary_reservation_customer_information", "phone");
                this.reserveForm.set("customer", decodeURIComponent(this.paramsService.params.email || ""), "temporary_reservation_customer_information", "email");
                this.reserveForm.set("customer", true, "temporary_reservation_customer_information", "fromRakuten");
                this.router.navigate(["/personal-info"]);
            }
        }
        var docomoLogin = paramsService.params.docomo_login;
        console.log(docomoLogin);
        if (docomoLogin === "succeeded") {
            this.appType.started = true;
            this.api.csrf_token = decodeURIComponent(paramsService.params.csrf_token);
            this.location.replaceState(null, "", "/#/payment-select");
            this.router.navigate(["/payment-docomo"]);
        }
        if (docomoLogin === "failed") {
            this.appType.started = true;
            this.location.replaceState(null, "", "/#/reserve-confirm");
            this.router.navigate(["/payment-select"]);
        }
        var docomoPayment = paramsService.params.docomo_payment;
        if (docomoPayment === "succeeded") {
            this.appType.started = true;
            var restaurant = this.restaurantService.get();
            this.reserveForm.read(restaurant.restaurant.key);
            this.router.navigate(["/finish"]);
        }
        if (docomoPayment === "failed") {
            this.appType.started = true;
            this.location.replaceState(null, "", "/#/reserve-confirm");
            this.router.navigate(["/payment-select"]);
        }
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        var sdatas = this.activedRoute.snapshot.data;
        console.log(sdatas);
        console.log(this.restaurantService.get());
        if (this.restaurantService.get()) {
            this.initApp(this.restaurantService.get());
        }
        else {
            var h_1 = this.restaurantService.res$.subscribe(function (data) {
                console.log(data);
                h_1.unsubscribe();
                _this.initApp(data);
                _this.cdRef.detectChanges();
            });
        }
    };
    AppComponent.prototype.ngAfterViewInit = function () {
        console.log(this.restaurantService.get());
    };
    AppComponent.prototype.createHead = function (data) {
        if (true) {
            if (this.appType.isPc()) {
                try {
                    this.elRef.nativeElement.classList.add("pc");
                }
                catch (e) { }
            }
        }
    };
    AppComponent.prototype.initApp = function (data) {
        var restaurant = data.restaurant;
        this.restaurant = data;
        this.headerString = restaurant.restaurant_name;
        this.restaurant_key = restaurant.restaurant_key;
        this.inquiry_id = restaurant.inquiry_id;
        this.isPay = data.restaurant.deposit_enabled;
        this.setTranslateLang(data);
        this.createHead(data);
        this.country = restaurant.restaurant_country;
    };
    AppComponent.prototype.setTranslateLang = function (data) {
        var restaurant = data.restaurant;
        this.selectLanguageEnabled =
            restaurant.web_reservation_config.select_language_enabled;
        this.restaurantLang =
            restaurant.restaurant_language.toLocaleLowerCase() || "ja";
        this.translate.setDefaultLang(this.restaurantLang);
        if (!this.selectLanguageEnabled) {
            this.translateLang = this.restaurantLang.toLowerCase();
            return this.translate.use(this.translateLang);
        }
        else {
            var country = restaurant.restaurant_country;
            if (country === "jp") {
                this.langs = ["ja", "en"];
                this.userLang = /ja|en/.test(this.userLang) ? this.userLang : "en";
            }
            else if (country === "tw") {
                this.langs = ["zh-tw", "en", "ja", "vi"];
                this.userLang = /ja|zh\-tw|en|vi/.test(this.userLang)
                    ? this.userLang
                    : "zh-tw";
            }
            else if (country === "vn") {
                this.langs = ["vi", "en", "ja", "zh-tw"];
                this.userLang = /ja|zh\-tw|en|vi/.test(this.userLang)
                    ? this.userLang
                    : "vi";
            }
            else {
                this.langs = ["en", "ja", "zh-tw", "vi"];
                this.userLang = /ja|zh\-tw|en|vi/.test(this.userLang)
                    ? this.userLang
                    : "en";
            }
            var cLang = null;
            if (this.appType.useLocalStorage) {
                cLang = window.localStorage.getItem(restaurant.key + ":userLang");
                console.log(cLang);
                if (/ja|en|zh\-tw|vi/.test(cLang)) {
                    this.userLang = cLang;
                }
            }
            this.translateLang = this.userLang;
            return this.translate.use(this.userLang);
        }
    };
    AppComponent.prototype.isMac = function () {
        if (true && navigator.userAgent.indexOf("Mac") > -1) {
            return true;
        }
        return false;
    };
    return AppComponent;
}());
AppComponent = __decorate([
    core_1.Component({
        selector: "app",
        styles: [require('./app.component.css')],
        template: "\n    <trt-header\n      [selectLangs]=\"langs\"\n      [headerString]=\"headerString\"\n      [lang]=\"userLang\"\n      [selectLanguageEnabled]=\"selectLanguageEnabled\"\n      [warning]=\"browserWarning\"\n    ></trt-header>\n    <router-outlet [ngClass]=\"{ asc: isPay }\"></router-outlet>\n    <trt-footer\n      [country]=\"country\"\n      [isPay]=\"isPay\"\n      [inquiryId]=\"inquiry_id\"\n    ></trt-footer>\n  ",
    }),
    __param(11, core_1.Inject(platform_browser_1.DOCUMENT)),
    __metadata("design:paramtypes", [core_2.TranslateService,
        router_1.Router,
        restaurant_module_1.RestaurantService,
        core_1.ChangeDetectorRef,
        app_model_1.AppType,
        core_1.ApplicationRef,
        reserve_form_service_1.ReserveFormService,
        params_service_1.ParamsService,
        api_service_1.ApiService,
        reserve_service_1.ReserveService,
        core_1.ElementRef, Object, core_1.Renderer,
        platform_browser_1.DomSanitizer,
        from_service_1.FromService,
        router_1.ActivatedRoute,
        angulartics2_1.Angulartics2GoogleAnalytics,
        common_1.PlatformLocation])
], AppComponent);
exports.AppComponent = AppComponent;
var PCAppComponent = (function (_super) {
    __extends(PCAppComponent, _super);
    function PCAppComponent(translate, router, restaurantService, cdRef, appType, applicationRef, reserveForm, paramsService, api, reserveService, elRef, document, renderer, sanitizer, fromService, activedRoute, angulartics2GoogleAnalytics, location) {
        var _this = _super.call(this, translate, router, restaurantService, cdRef, appType, applicationRef, reserveForm, paramsService, api, reserveService, elRef, document, renderer, sanitizer, fromService, activedRoute, angulartics2GoogleAnalytics, location) || this;
        _this.translate = translate;
        _this.router = router;
        _this.restaurantService = restaurantService;
        _this.cdRef = cdRef;
        _this.appType = appType;
        _this.applicationRef = applicationRef;
        _this.reserveForm = reserveForm;
        _this.paramsService = paramsService;
        _this.api = api;
        _this.reserveService = reserveService;
        _this.elRef = elRef;
        _this.document = document;
        _this.renderer = renderer;
        _this.sanitizer = sanitizer;
        _this.fromService = fromService;
        _this.activedRoute = activedRoute;
        _this.angulartics2GoogleAnalytics = angulartics2GoogleAnalytics;
        _this.location = location;
        return _this;
    }
    return PCAppComponent;
}(AppComponent));
PCAppComponent = __decorate([
    core_1.Component({
        selector: "app",
        styles: [require('./app.pc.component.css')],
        template: "\n    <trt-header\n      [selectLangs]=\"langs\"\n      [headerString]=\"headerString\"\n      [lang]=\"userLang\"\n      [selectLanguageEnabled]=\"selectLanguageEnabled\"\n      [warning]=\"browserWarning\"\n    ></trt-header>\n    <router-outlet></router-outlet>\n    <trt-footer\n      [country]=\"country\"\n      [isPay]=\"isPay\"\n      [inquiryId]=\"inquiry_id\"\n    ></trt-footer>\n  ",
    }),
    __param(11, core_1.Inject(platform_browser_1.DOCUMENT)),
    __metadata("design:paramtypes", [core_2.TranslateService,
        router_1.Router,
        restaurant_module_1.RestaurantService,
        core_1.ChangeDetectorRef,
        app_model_1.AppType,
        core_1.ApplicationRef,
        reserve_form_service_1.ReserveFormService,
        params_service_1.ParamsService,
        api_service_1.ApiService,
        reserve_service_1.ReserveService,
        core_1.ElementRef, Object, core_1.Renderer,
        platform_browser_1.DomSanitizer,
        from_service_1.FromService,
        router_1.ActivatedRoute,
        angulartics2_1.Angulartics2GoogleAnalytics,
        common_1.PlatformLocation])
], PCAppComponent);
exports.PCAppComponent = PCAppComponent;
