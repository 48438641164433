"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var toreta_date_service_1 = require("../services/toreta-date.service");
var core_2 = require("@ngx-translate/core");
var DayPipe = (function () {
    function DayPipe(trtDate, translate) {
        this.trtDate = trtDate;
        this.translate = translate;
    }
    DayPipe.prototype.transform = function (value, args) {
        var _this = this;
        if (args === void 0) { args = {}; }
        if (!value || (this.lastArgs === args && this.lastValue === value && this.lastLang === this.translate.currentLang)) {
            return this.value;
        }
        else {
            this.lastValue = value;
            this.lastLang = this.translate.currentLang;
            this.lastArgs = args;
            this.value = null;
            this.trtDate.getDate(value, args.time).then(function (d) {
                if (d.time) {
                    _this.value = d.date + " " + d.time;
                }
                else {
                    _this.value = "" + d.date;
                }
            });
            return this.value;
        }
    };
    DayPipe.prototype._dispose = function () {
        this.lastLang = null;
        this.lastValue = null;
        this.value = null;
    };
    DayPipe.prototype.ngOnDestroy = function () {
        this._dispose();
    };
    return DayPipe;
}());
DayPipe = __decorate([
    core_1.Pipe({
        name: 'trtDay',
        pure: false
    }),
    __metadata("design:paramtypes", [toreta_date_service_1.TRTDateService, core_2.TranslateService])
], DayPipe);
exports.DayPipe = DayPipe;
