"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var Observable_1 = require("rxjs/Observable");
var form_service_1 = require("../services/form.service");
var input_component_1 = require("./input.component");
var countries_service_1 = require("../../common/services/countries.service");
var InputCountryComponent = (function (_super) {
    __extends(InputCountryComponent, _super);
    function InputCountryComponent(_formService, _cdRef, countriesService, _elRef, renderer) {
        var _this = _super.call(this, _formService) || this;
        _this._formService = _formService;
        _this._cdRef = _cdRef;
        _this._elRef = _elRef;
        _this.renderer = renderer;
        _this.type = 'text';
        _this.changeData = new core_1.EventEmitter();
        _this.hiddenCountries = true;
        _this.allCountries = {
            group: [],
            all: []
        };
        _this.countriesService = countriesService;
        return _this;
    }
    InputCountryComponent.prototype.setCountry = function (iso2) {
        this.setData(iso2);
        this.setHiddenCountries();
    };
    InputCountryComponent.prototype.setHiddenCountries = function () {
        this.hiddenCountries = true;
    };
    InputCountryComponent.prototype.removeHiddenCountries = function () {
        this.hiddenCountries = false;
        var $init = this._elRef.nativeElement.querySelector('.init');
        var $u = this._elRef.nativeElement.querySelector('ul');
        setTimeout(function () {
            console.log($init.offsetTop);
            if ($init && $u) {
                $u.scrollTop = $init.offsetTop;
            }
        }, 10);
    };
    InputCountryComponent.prototype.changeInit = function () {
        var $init = this._elRef.nativeElement.querySelector('.init');
        console.log($init);
        if ($init) {
            $init.classList.remove('init');
        }
    };
    InputCountryComponent.prototype.ngOnInit = function () {
        var _this = this;
        document.body.addEventListener('click', function () {
            _this.setHiddenCountries();
        }, true);
    };
    InputCountryComponent.prototype.ngOnDestroy = function () {
        var _this = this;
        document.body.removeEventListener('click', function () {
            _this.setHiddenCountries();
        }, true);
    };
    InputCountryComponent.prototype.ngAfterViewInit = function () {
        this._elRef.nativeElement.querySelector('#countries').style.width = this._elRef.nativeElement.parentElement.clientWidth - 1 + "px";
        this.allCountries = this.countriesService.getCountryList(this.tyInitCountry);
    };
    InputCountryComponent.prototype.ngOnChanges = function (changes) {
        for (var propName in changes) {
            var prop = changes[propName];
            var cur = prop.currentValue;
            if (propName === 'tyData' && cur) {
                console.log(cur);
                this.setData(cur);
            }
        }
    };
    return InputCountryComponent;
}(input_component_1.InputComponent));
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], InputCountryComponent.prototype, "tyName", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], InputCountryComponent.prototype, "tyPlaceholder", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], InputCountryComponent.prototype, "tyData", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Observable_1.Observable)
], InputCountryComponent.prototype, "tyChange", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], InputCountryComponent.prototype, "tyInitCountry", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], InputCountryComponent.prototype, "type", void 0);
__decorate([
    core_1.Output(),
    __metadata("design:type", core_1.EventEmitter)
], InputCountryComponent.prototype, "changeData", void 0);
InputCountryComponent = __decorate([
    core_1.Component({
        selector: 'ty-input-country',
        template: "<div class=\"ty-input\">\n    <ty-input-placeholder [labelTitle]=\"tyPlaceholder\" [tyInputName]=\"tyName\" [tyInputData]=\"tyData\"></ty-input-placeholder>\n    <div id=\"wrap\" (click)=\"removeHiddenCountries()\">\n      <div class=\"iti-flag\" [ngClass]=\"tyData\"></div><p id=\"flag\" class=\"flag\" [ngClass]=\"tyData\">{{tyData | uppercase }} </p>\n    </div>\n    <div id=\"countries\" [hidden]=\"hiddenCountries\">\n      <ul>\n        <li *ngFor=\"let c of allCountries.group\" class=\"group\" (click)=\"setCountry(c.iso2)\" [ngClass]=\"{init: tyData == c.iso2}\">\n          <div class=\"flag-box\">\n            <div class=\"iti-flag\" [ngClass]=\"c.iso2\"></div>\n          </div>\n          <span class=\"name\">{{c.name}}</span>\n          <span class=\"number\">+{{c.dialCode}}</span>\n        </li>\n        <div class=\"line\" *ngIf=\"allCountries.group.length > 0\"></div>\n        <li *ngFor=\"let c of allCountries.all\" (click)=\"setCountry(c.iso2)\" [ngClass]=\"{init: tyData == c.iso2}\">\n          <div class=\"flag-box\">\n            <div class=\"iti-flag\" [ngClass]=\"c.iso2\"></div>\n          </div>\n          <span class=\"name\">{{c.name}}</span>\n          <span class=\"number\">+{{c.dialCode}}</span>\n        </li>\n      </ul>\n    </div>\n  </div>",
        styles: [require('./input.component.css'), require('./input-country.component.css')],
        providers: [form_service_1.FormService]
    }),
    __metadata("design:paramtypes", [form_service_1.FormService, core_1.ChangeDetectorRef, countries_service_1.CountriesService, core_1.ElementRef, core_1.Renderer])
], InputCountryComponent);
exports.InputCountryComponent = InputCountryComponent;
