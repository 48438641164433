"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./time-select.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../common/pipes/time.pipe");
var i3 = require("../../common/services/toreta-time.service");
var i4 = require("@ngx-translate/core/src/translate.service");
var i5 = require("./time-select.component");
var i6 = require("@angular/common");
var i7 = require("angulartics2/dist/core/angulartics2");
var styles_TimeSelectComponent = [i0.styles];
exports.RenderType_TimeSelectComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_TimeSelectComponent, data: {} });
function View_TimeSelectComponent_1(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 3, 'p', [], null, [[null, 'click']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('click' === en)) {
                var pd_0 = (_co.setTime(_v.context.$implicit) !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), (_l()(), i1.ɵeld(0, null, null, 2, 'span', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TimePipe, [i3.TRTTimeService, i4.TranslateService])], null, function (_ck, _v) {
        var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_v.context.$implicit.start_time));
        _ck(_v, 2, 0, currVal_0);
    });
}
function View_TimeSelectComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 25, 'div', [], null, null, null, null, null)), (_l()(),
            i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵeld(0, null, null, 2, 'div', [['class', 'arrow left']], [[2, 'hidden', null]], [[null,
                'click']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('click' === en)) {
                var pd_0 = (_co.scrollLeftClick() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), (_l()(), i1.ɵeld(0, null, null, 1, 'span', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['<'])), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵeld(0, null, null, 12, 'div', [['id', 'timeContent']], null, null, null, null, null)), (_l()(),
            i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 9, 'div', [['id', 'timeWrap']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵeld(0, null, null, 6, 'div', [['id', 'timeBar']], null, null, null, null, null)), i1.ɵdid(278528, null, 0, i6.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer], { ngStyle: [0, 'ngStyle'] }, null),
        i1.ɵpod(['width']), (_l()(), i1.ɵted(null, ['\n        '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimeSelectComponent_1)),
        i1.ɵdid(802816, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef,
            i1.IterableDiffers], { ngForOf: [0, 'ngForOf'] }, null), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵeld(0, null, null, 4, 'div', [['class', 'arrow right']], [[2, 'hidden', null]], [[null, 'click']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('click' === en)) {
                var pd_0 = (_co.scrollRightClick() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), i1.ɵdid(278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer], { klass: [0,
                'klass'], ngClass: [1, 'ngClass'] }, null), i1.ɵpod(['pc']), (_l()(),
            i1.ɵeld(0, null, null, 1, 'span', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['>'])), (_l()(), i1.ɵted(null, ['\n']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_1 = _ck(_v, 12, 0, _co.barWidth);
        _ck(_v, 11, 0, currVal_1);
        var currVal_2 = _co.times;
        _ck(_v, 15, 0, currVal_2);
        var currVal_4 = 'arrow right';
        var currVal_5 = _ck(_v, 22, 0, _co.isPc);
        _ck(_v, 21, 0, currVal_4, currVal_5);
    }, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = !_co.isScroll;
        _ck(_v, 2, 0, currVal_0);
        var currVal_3 = !_co.isScroll;
        _ck(_v, 20, 0, currVal_3);
    });
}
exports.View_TimeSelectComponent_0 = View_TimeSelectComponent_0;
function View_TimeSelectComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'trt-time-select', [], null, null, null, View_TimeSelectComponent_0, exports.RenderType_TimeSelectComponent)), i1.ɵdid(4243456, null, 0, i5.TimeSelectComponent, [i1.ElementRef, i1.ChangeDetectorRef, i7.Angulartics2], null, null)], null, null);
}
exports.View_TimeSelectComponent_Host_0 = View_TimeSelectComponent_Host_0;
exports.TimeSelectComponentNgFactory = i1.ɵccf('trt-time-select', i5.TimeSelectComponent, View_TimeSelectComponent_Host_0, { times: 'times', date: 'date',
    isPc: 'isPc' }, { clickTime: 'clickTime' }, []);
