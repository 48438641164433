"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./input.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./input-placeholder.component.ngfactory");
var i4 = require("./input-placeholder.component");
var i5 = require("../services/form.service");
var i6 = require("./input-text.component");
var i7 = require("@angular/forms");
var i8 = require("../../common/interfaces/parent.interface");
var i9 = require("angulartics2/dist/core/angulartics2");
var styles_InputTextComponent = [i0.styles];
exports.RenderType_InputTextComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_InputTextComponent, data: {} });
function View_InputTextComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 15, 'div', [['class',
                'ty-input']], null, null, null, null, null)),
        i1.ɵdid(278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers,
            i1.ElementRef, i1.Renderer], { klass: [0, 'klass'], ngClass: [1, 'ngClass'] }, null),
        i1.ɵpod(['disabled']), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 1, 'ty-input-placeholder', [], null, [[null, 'click']], function (_v, en, $event) {
            var ad = true;
            if (('click' === en)) {
                var pd_0 = (i1.ɵnov(_v, 7).focus() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i3.View_InputPlaceholderComponent_0, i3.RenderType_InputPlaceholderComponent)),
        i1.ɵdid(573440, null, 0, i4.InputPlaceholderComponent, [i5.FormService,
            i1.ElementRef], { labelTitle: [0, 'labelTitle'], tyInputName: [1, 'tyInputName'],
            tyInputData: [2, 'tyInputData'], disabled: [3, 'disabled'] }, null), (_l()(),
            i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, [['box', 1]], null, 7, 'input', [], [[8, 'type', 0], [1, 'maxlength', 0], [2, 'ng-untouched',
                null], [2, 'ng-touched', null], [2, 'ng-pristine', null],
            [2, 'ng-dirty', null], [2, 'ng-valid', null], [2, 'ng-invalid',
                null], [2, 'ng-pending', null]], [[null, 'ngModelChange'],
            [null, 'blur'], [null, 'focus'], [null, 'input'],
            [null, 'compositionstart'], [null, 'compositionend']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('input' === en)) {
                var pd_0 = (i1.ɵnov(_v, 10)._handleInput($event.target.value) !== false);
                ad = (pd_0 && ad);
            }
            if (('blur' === en)) {
                var pd_1 = (i1.ɵnov(_v, 10).onTouched() !== false);
                ad = (pd_1 && ad);
            }
            if (('compositionstart' === en)) {
                var pd_2 = (i1.ɵnov(_v, 10)._compositionStart() !== false);
                ad = (pd_2 && ad);
            }
            if (('compositionend' === en)) {
                var pd_3 = (i1.ɵnov(_v, 10)._compositionEnd($event.target.value) !== false);
                ad = (pd_3 && ad);
            }
            if (('ngModelChange' === en)) {
                var pd_4 = ((_co.tyData = $event) !== false);
                ad = (pd_4 && ad);
            }
            if (('ngModelChange' === en)) {
                var pd_5 = (_co.setData(i1.ɵnov(_v, 7).value) !== false);
                ad = (pd_5 && ad);
            }
            if (('blur' === en)) {
                var pd_6 = (_co.onBlur(i1.ɵnov(_v, 7).value) !== false);
                ad = (pd_6 && ad);
            }
            if (('focus' === en)) {
                var pd_7 = (_co.onFocus() !== false);
                ad = (pd_7 && ad);
            }
            return ad;
        }, null, null)), i1.ɵdid(278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer], { ngClass: [0,
                'ngClass'] }, null), i1.ɵpod(['ondata', 'no-placeholder']), i1.ɵdid(16384, null, 0, i7.DefaultValueAccessor, [i1.Renderer, i1.ElementRef, [2, i7.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) {
            return [p0_0];
        }, [i7.DefaultValueAccessor]), i1.ɵdid(671744, null, 0, i7.NgModel, [[8,
                null], [8, null], [8, null], [2, i7.NG_VALUE_ACCESSOR]], { name: [0, 'name'], model: [1, 'model'] }, { update: 'ngModelChange' }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(16384, null, 0, i7.NgControlStatus, [i7.NgControl], null, null), (_l()(),
            i1.ɵted(null, ['\n    ']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = 'ty-input';
        var currVal_1 = _ck(_v, 2, 0, _co.disabled);
        _ck(_v, 1, 0, currVal_0, currVal_1);
        var currVal_2 = _co.tyPlaceholder;
        var currVal_3 = _co.tyName;
        var currVal_4 = _co.tyData;
        var currVal_5 = _co.disabled;
        _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5);
        var currVal_15 = _ck(_v, 9, 0, !!_co.tyData, !!!_co.tyPlaceholder);
        _ck(_v, 8, 0, currVal_15);
        var currVal_16 = _co.tyName;
        var currVal_17 = _co.tyData;
        _ck(_v, 12, 0, currVal_16, currVal_17);
    }, function (_ck, _v) {
        var _co = _v.component;
        var currVal_6 = _co.type;
        var currVal_7 = _co.tyMaxlength;
        var currVal_8 = i1.ɵnov(_v, 14).ngClassUntouched;
        var currVal_9 = i1.ɵnov(_v, 14).ngClassTouched;
        var currVal_10 = i1.ɵnov(_v, 14).ngClassPristine;
        var currVal_11 = i1.ɵnov(_v, 14).ngClassDirty;
        var currVal_12 = i1.ɵnov(_v, 14).ngClassValid;
        var currVal_13 = i1.ɵnov(_v, 14).ngClassInvalid;
        var currVal_14 = i1.ɵnov(_v, 14).ngClassPending;
        _ck(_v, 7, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14);
    });
}
exports.View_InputTextComponent_0 = View_InputTextComponent_0;
function View_InputTextComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 2, 'ty-input-text', [], null, null, null, View_InputTextComponent_0, exports.RenderType_InputTextComponent)), i1.ɵprd(512, null, i5.FormService, i5.FormService, []), i1.ɵdid(638976, null, 0, i6.InputTextComponent, [i5.FormService,
            i1.ChangeDetectorRef, [2, i8.Parent], i9.Angulartics2], null, null)], function (_ck, _v) {
        _ck(_v, 2, 0);
    }, null);
}
exports.View_InputTextComponent_Host_0 = View_InputTextComponent_Host_0;
exports.InputTextComponentNgFactory = i1.ɵccf('ty-input-text', i6.InputTextComponent, View_InputTextComponent_Host_0, { tyName: 'tyName', tyPlaceholder: 'tyPlaceholder',
    tyData: 'tyData', tyMaxlength: 'tyMaxlength', tyChange: 'tyChange', type: 'type',
    disabled: 'disabled' }, { changeData: 'changeData', tyBlur: 'tyBlur' }, []);
