"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var app_model_1 = require("../../app/models/app.model");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var core_2 = require("@ngx-translate/core");
var restaurant_module_1 = require("../../common/services/restaurant/restaurant.module");
var reserve_form_service_1 = require("../../common/services/reserve-form.service");
var reserve_service_1 = require("../../common/services/reserve.service");
var intl_tel_service_1 = require("../../common/services/intl-tel.service");
var ReserveConfirmationComponent = (function () {
    function ReserveConfirmationComponent(router, restaurantService, reserveFormService, reserveService, cdRef, translate, intl, appType) {
        this.router = router;
        this.restaurantService = restaurantService;
        this.reserveFormService = reserveFormService;
        this.reserveService = reserveService;
        this.cdRef = cdRef;
        this.translate = translate;
        this.intl = intl;
        this.appType = appType;
        this.restaurantName = '';
        this.seats = 0;
        this.adultsSeats = 0;
        this.childrenSeats = 0;
        this.courses = [{}];
        this.goDate = '';
        this.timeLabel = '';
        this.request = '';
        this.lastName = '';
        this.firstName = '';
        this.lastNameReading = '';
        this.firstNameReading = '';
        this.phone = '';
        this.email = '';
        this.buttonText = 'RESERVE_CONFIRMATION.BUTTON_TEXT_TO_PAYMENT';
        this.reservationNo = '';
        this.accept_children = false;
        this.is_deposit = false;
        this.deposit_amount = '0';
        this.errorMessage = null;
        this.country = 'jp';
        this.restaurantLang = 'ja';
        this.conf = false;
        this.paymentServices = [];
        this.selected_table_category = '';
        this.selected_table_smoking = '';
        this.addInfo = '';
        this.accept_optin = false;
        this.optinStatus = false;
        this._clicked = false;
    }
    ReserveConfirmationComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log(this.restaurantService.get());
        if (typeof this.restaurantService.get() === 'undefined') {
            this.router.navigate(['/']);
            return true;
        }
        var isJa = this.translate.currentLang === 'ja';
        var restaurant = this.restaurantService.get().restaurant;
        var isJp = restaurant.restaurant_country === 'jp';
        var isGlobal = !/jp/.test(restaurant.restaurant_country);
        var ss = this.reserveFormService.reserveFormCollection$.subscribe(function (data) {
            ss.unsubscribe();
            console.log(data);
            console.log(isJa);
            var ci = data.customer.temporary_reservation_customer_information;
            console.log((ci.last_name_reading && ci.first_name_reading) || !isJa);
            var courseRequired = restaurant.web_reservation_config.course_required;
            var aSeats = data.seats_adults || data.seats;
            if (courseRequired &&
                data.accept_courses &&
                !(data.courses &&
                    data.courses.length > 0 &&
                    data.courses.filter(function (c) { return !!c.course_id; }).length > 0 &&
                    data.courses.filter(function (c) { return !!c.count; }).length > 0 &&
                    data.courses.map(function (c) { return c.count; }).reduce(function (p, c) {
                        return +p + +c;
                    }) >= aSeats)) {
                _this.router.navigate(['/reserve-info']);
            }
            if (data.customer &&
                ci.last_name &&
                ci.first_name &&
                ((ci.last_name_reading && ci.first_name_reading) || !(isJa && isJp)) &&
                ci.phone &&
                ci.email) {
                _this.goDate = data.go_date;
                _this.start_at = data.start_at;
                _this.end_at = data.end_at;
                _this.start_time = data.start_time;
                _this.end_time = data.end_time;
                _this.timezone_offset = data.timezone_offset;
                _this.timeLabel = data.time_label;
                _this.request = data.request;
                _this.is_deposit = data.is_deposit;
                _this.deposit_amount = data.deposit_amount;
                _this.buttonText = _this.is_deposit
                    ? 'RESERVE_CONFIRMATION.BUTTON_TEXT_TO_PAYMENT'
                    : 'RESERVE_CONFIRMATION.BUTTON_TEXT';
                _this.request = data.note;
                _this.selected_table_category = data.table_category;
                _this.selected_table_smoking = data.table_smoking;
                _this.addInfo = data.addInfo;
                if (data.customer &&
                    data.customer.temporary_reservation_customer_information) {
                    var ci_1 = data.customer.temporary_reservation_customer_information;
                    _this.lastName = ci_1.last_name;
                    _this.firstName = ci_1.first_name;
                    _this.lastNameReading = ci_1.last_name_reading;
                    _this.firstNameReading = ci_1.first_name_reading;
                    if (ci_1.iso2 && isGlobal) {
                        var phonenumber = _this.intl.util.parseAndKeepRawInput(ci_1.phone, ci_1.iso2);
                        if (ci_1.iso2 !== restaurant.restaurant_country) {
                            _this.phone = "+" + phonenumber.getCountryCode() + " " + ci_1.phone.replace(/^0{1}/, '');
                        }
                        else {
                            _this.phone = ci_1.phone;
                        }
                    }
                    else {
                        _this.phone = ci_1.phone;
                    }
                    _this.email = ci_1.email;
                }
                _this.courses = [];
                if (data.courses) {
                    var courses_1 = [];
                    var _c_1 = {};
                    data.courses.forEach(function (c) {
                        if (!_c_1[c.course_id]) {
                            console.log(c);
                            _c_1[c.course_id] = {
                                name: c.name,
                                price: c.price,
                                seats: 0,
                            };
                        }
                        _c_1[c.course_id].seats += +c.count;
                    });
                    Object.keys(_c_1).forEach(function (key) {
                        if (_c_1[key].seats > 0) {
                            courses_1.push(_c_1[key]);
                        }
                    });
                    if (courses_1.length) {
                        _this.courses = courses_1;
                    }
                }
                _this.seats = data.seats;
                _this.adultsSeats = data.seats_adults;
                _this.childrenSeats = data.seats_children || 0;
                _this.reservationId = data.payment.reservation_id;
                _this.accept_courses = data.accept_courses;
            }
            else {
                _this.router.navigate(['/personal-info']);
            }
        });
        var optinStatus = this.appType.useLocalStorage()
            ? localStorage.getItem(restaurant.restaurant_key + "_optin_dm")
            : null;
        this.optinStatus = optinStatus === null ? true : JSON.parse(optinStatus);
        this.reserveFormService.set('customer', this.optinStatus, 'temporary_reservation_customer_information', 'optin_dm');
        this.accept_children = restaurant.web_reservation_config.accept_children;
        this.restaurantName = restaurant.restaurant_name;
        this.key = restaurant.restaurant_key;
        this.restaurantId = restaurant.key;
        this.show_request_form =
            restaurant.web_reservation_config.show_request_form;
        this.country = restaurant.restaurant_country;
        this.restaurantLang = restaurant.restaurant_language;
        this.paymentServices =
            restaurant.restaurant_deposit_default_config.payment_services || [];
        this.reserveFormService.get();
    };
    ReserveConfirmationComponent.prototype.checkOptin = function (checked) {
        if (this.appType.useLocalStorage()) {
            localStorage.setItem(this.key + "_optin_dm", checked);
        }
        this.reserveFormService.set('customer', checked, 'temporary_reservation_customer_information', 'optin_dm');
    };
    ReserveConfirmationComponent.prototype.checkConfirm = function (checked) {
        this.conf = checked;
    };
    ReserveConfirmationComponent.prototype.optinDm = function () {
        var rdata = this.reserveFormService.get();
        console.log(rdata);
        var data = rdata.customer.temporary_reservation_customer_information;
        var customerData = {
            restaurant_id: rdata.restaurant_id,
            temporary_reservation_customer_information: {
                last_name: data.last_name,
                first_name: data.first_name,
                last_name_reading: data.last_name_reading,
                first_name_reading: data.first_name_reading,
                phone: data.phone,
                email: data.email,
                optin_dm: data.optin_dm,
            },
        };
        this.reserveService.updateTempReservationCustomer(rdata.payment.reservation_id, customerData);
    };
    ReserveConfirmationComponent.prototype.moveNext = function () {
        var _this = this;
        if (this.is_deposit) {
            this._clicked = false;
            if (this.paymentServices.length > 1) {
                var d = new Date();
                var utcTime = d.getTime() + d.getTimezoneOffset() * 60000 + 540 * 60000;
                var usTime = this.start_at * 1000 - utcTime;
                console.log(this.start_at * 1000, utcTime, usTime);
                if (usTime > 3888000000) {
                    this.router.navigate(['/payment']);
                }
                else {
                    this.router.navigate(['/payment-select']);
                }
            }
            else {
                this.router.navigate(['/payment']);
            }
        }
        else {
            var setLang_1 = false;
            var rs_1 = this.reserveService.api$.subscribe(function (data) {
                if (setLang_1) {
                    rs_1.unsubscribe();
                    var rf_1 = _this.reserveFormService.reserveFormCollection$.subscribe(function (data) {
                        rf_1.unsubscribe();
                        _this._clicked = false;
                        _this.router.navigate(['/finish']);
                    });
                    _this.reserveFormService.set('reserve_no', data.reservation.no);
                }
                else {
                    setLang_1 = true;
                    _this.reserveService.commitTempReservation(_this.reservationId, _this.restaurantId);
                }
            }, function (err) {
                var e = err.json();
                if (e.error_code) {
                    _this.errorMessage = 'ERROR.OTHER';
                }
                else {
                    _this.errorMessage = 'ERROR.DEFAULT';
                }
            });
            var reserveData = this.reserveFormService.getApiFormat();
            var lang = this.translate.currentLang;
            var langMatch = lang.match(/([a-z]+)(\-|\_)(.*)/);
            if (langMatch) {
                lang = langMatch[1] + "-" + langMatch[3].toUpperCase();
            }
            reserveData.language = lang;
            if (this.addInfo) {
                reserveData.note = this.addInfo + "\n" + (reserveData.note || '');
            }
            this.reserveService.updateTempReservationInfo(this.reservationId, reserveData);
        }
    };
    ReserveConfirmationComponent.prototype.onClick = function (evt) {
        var _this = this;
        if (this._clicked) {
            return false;
        }
        else {
            this._clicked = true;
        }
        var rf = this.reserveService.api$.subscribe(function (r) {
            rf.unsubscribe();
            console.log(r);
            _this.moveNext();
        });
        this.optinDm();
    };
    return ReserveConfirmationComponent;
}());
ReserveConfirmationComponent = __decorate([
    core_1.Component({
        selector: 'trt-reserve-confirmation',
        template: require('./reserve-confirmation.component.html'),
        styles: [require('./reserve-confirmation.component.css')],
    }),
    __metadata("design:paramtypes", [router_1.Router,
        restaurant_module_1.RestaurantService,
        reserve_form_service_1.ReserveFormService,
        reserve_service_1.ReserveService,
        core_1.ChangeDetectorRef,
        core_2.TranslateService,
        intl_tel_service_1.IntlTelService,
        app_model_1.AppType])
], ReserveConfirmationComponent);
exports.ReserveConfirmationComponent = ReserveConfirmationComponent;
var PCReserveConfirmationComponent = (function (_super) {
    __extends(PCReserveConfirmationComponent, _super);
    function PCReserveConfirmationComponent(router, restaurantService, reserveFormService, reserveService, cdRef, translate, intl, appType) {
        var _this = _super.call(this, router, restaurantService, reserveFormService, reserveService, cdRef, translate, intl, appType) || this;
        _this.router = router;
        _this.restaurantService = restaurantService;
        _this.reserveFormService = reserveFormService;
        _this.reserveService = reserveService;
        _this.cdRef = cdRef;
        _this.translate = translate;
        _this.intl = intl;
        _this.appType = appType;
        return _this;
    }
    return PCReserveConfirmationComponent;
}(ReserveConfirmationComponent));
PCReserveConfirmationComponent = __decorate([
    core_1.Component({
        selector: 'trt-reserve-confirmation',
        template: require('./reserve-confirmation.pc.component.html'),
        styles: [require('./reserve-confirmation.pc.component.css')],
    }),
    __metadata("design:paramtypes", [router_1.Router,
        restaurant_module_1.RestaurantService,
        reserve_form_service_1.ReserveFormService,
        reserve_service_1.ReserveService,
        core_1.ChangeDetectorRef,
        core_2.TranslateService,
        intl_tel_service_1.IntlTelService,
        app_model_1.AppType])
], PCReserveConfirmationComponent);
exports.PCReserveConfirmationComponent = PCReserveConfirmationComponent;
