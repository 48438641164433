"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var api_service_1 = require("../../common/services/api.service");
var restaurant_module_1 = require("../../common/services/restaurant/restaurant.module");
var app_model_1 = require("../../app/models/app.model");
var reserve_form_service_1 = require("../../common/services/reserve-form.service");
var core_1 = require("@angular/core");
var PaymentDocomoComponent = (function () {
    function PaymentDocomoComponent(reserveFormService, appType, restaurantService, api) {
        this.reserveFormService = reserveFormService;
        this.appType = appType;
        this.restaurantService = restaurantService;
        this.api = api;
        this.siteId = '';
        this.temporaryReservationId = '';
        this.deposit_amount = '';
        this.conf = false;
        this.country = 'jp';
        this.restaurantLang = 'ja';
        this.docomoPayment = D_PAYMENT;
    }
    PaymentDocomoComponent.prototype.ngOnInit = function () {
        var _this = this;
        var restaurant = this.restaurantService.get();
        console.log(restaurant);
        var data = this.reserveFormService.read(restaurant.restaurant.key);
        console.log(data);
        this.country = restaurant.restaurant.restaurant_country;
        this.restaurantLang = restaurant.restaurant.restaurant_language;
        this.deposit_amount = data.deposit_amount;
        this.api.createDepositDocomo(restaurant.restaurant.key, data.payment.reservation_id, PAGE_URL + "/" + this.appType.getPublicKey() + "/#/finish").subscribe(function (token) {
            _this.siteId = token.spcd;
            _this.temporaryReservationId = token.cptok;
        });
    };
    PaymentDocomoComponent.prototype.setCheck = function (evt) {
        this.conf = evt;
    };
    PaymentDocomoComponent.prototype.onSubmit = function (evt) {
        console.log('submit');
        console.log(evt);
        try {
            evt.target.submit();
        }
        catch (e) {
            throw new Error(e);
        }
    };
    return PaymentDocomoComponent;
}());
PaymentDocomoComponent = __decorate([
    core_1.Component({
        selector: 'trt-payment-docomo',
        template: require('./payment-docomo.component.html'),
        styles: [require('./payment-docomo.component.css')]
    }),
    __metadata("design:paramtypes", [reserve_form_service_1.ReserveFormService, app_model_1.AppType, restaurant_module_1.RestaurantService, api_service_1.ApiService])
], PaymentDocomoComponent);
exports.PaymentDocomoComponent = PaymentDocomoComponent;
var PCPaymentDocomoComponent = (function (_super) {
    __extends(PCPaymentDocomoComponent, _super);
    function PCPaymentDocomoComponent(reserveFormService, appType, restaurantService, api) {
        var _this = _super.call(this, reserveFormService, appType, restaurantService, api) || this;
        _this.reserveFormService = reserveFormService;
        _this.appType = appType;
        _this.restaurantService = restaurantService;
        _this.api = api;
        return _this;
    }
    return PCPaymentDocomoComponent;
}(PaymentDocomoComponent));
PCPaymentDocomoComponent = __decorate([
    core_1.Component({
        selector: 'trt-payment-docomo',
        template: require('./payment-docomo.pc.component.html'),
        styles: [require('./payment-docomo.pc.component.css')]
    }),
    __metadata("design:paramtypes", [reserve_form_service_1.ReserveFormService, app_model_1.AppType, restaurant_module_1.RestaurantService, api_service_1.ApiService])
], PCPaymentDocomoComponent);
exports.PCPaymentDocomoComponent = PCPaymentDocomoComponent;
