"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var dist_1 = require("angulartics2/dist");
var core_1 = require("@angular/core");
var form_service_1 = require("../services/form.service");
var input_component_1 = require("./input.component");
var TextComponent = (function (_super) {
    __extends(TextComponent, _super);
    function TextComponent(_formService, angulartics2) {
        var _this = _super.call(this, _formService) || this;
        _this._formService = _formService;
        _this.angulartics2 = angulartics2;
        _this.tyName = "input" + module.id;
        _this.open = false;
        _this.date = false;
        _this.clickText = new core_1.EventEmitter();
        _this.isMark = false;
        _this.disabled = false;
        _this.noBackground = false;
        _this.eventLabel = '';
        return _this;
    }
    TextComponent.prototype.ngOnChanges = function (changes) {
        for (var propName in changes) {
            var prop = changes[propName];
            var cur = prop.currentValue;
            var prev = prop.previousValue;
            if (propName === 'tyData' && (cur || (prev != '{}' && cur == '')) && (prev != '{}')) {
                this.setData(cur);
            }
        }
    };
    TextComponent.prototype.onClick = function ($event) {
        this.clickText.next($event);
    };
    return TextComponent;
}(input_component_1.InputComponent));
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], TextComponent.prototype, "tyName", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], TextComponent.prototype, "tyPlaceholder", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], TextComponent.prototype, "tyData", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], TextComponent.prototype, "open", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], TextComponent.prototype, "date", void 0);
__decorate([
    core_1.Output(),
    __metadata("design:type", core_1.EventEmitter)
], TextComponent.prototype, "clickText", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], TextComponent.prototype, "isMark", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], TextComponent.prototype, "disabled", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], TextComponent.prototype, "noBackground", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], TextComponent.prototype, "eventLabel", void 0);
TextComponent = __decorate([
    core_1.Component({
        selector: 'ty-text',
        template: "<div class=\"ty-text\" [ngClass]=\"{disabled: disabled}\">\n    <ty-input-placeholder [labelTitle]=\"tyPlaceholder\" [disabled]=\"disabled\" [tyInputName]=\"tyName\" [tyInputData]=\"tyData\"></ty-input-placeholder>\n    <input type=\"text\" #input (focus)=\"input.blur();onClick($event)\" [disabled]=\"disabled\">\n    <p [ngClass]=\"{'no-placeholder': !!!tyPlaceholder, 'open': open, date: date, 'no-background': noBackground}\" (click)=\"onClick($event)\"><span [ngClass]=\"{mark: isMark}\">{{tyData}}</span></p>\n    </div>",
        styles: [require('./text.component.css')],
        providers: [form_service_1.FormService],
    }),
    __metadata("design:paramtypes", [form_service_1.FormService, dist_1.Angulartics2])
], TextComponent);
exports.TextComponent = TextComponent;
