"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var parent_interface_1 = require("../../common/interfaces/parent.interface");
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var router_1 = require("@angular/router");
var core_2 = require("@ngx-translate/core");
var restaurant_module_1 = require("../../common/services/restaurant/restaurant.module");
var reserve_form_service_1 = require("../../common/services/reserve-form.service");
var payment_service_1 = require("../services/payment.service");
var Observable_1 = require("rxjs/Observable");
var credit_card_pipe_1 = require("../pipes/credit-card.pipe");
var reserve_service_1 = require("../../common/services/reserve.service");
var PaymentComponent = PaymentComponent_1 = (function () {
    function PaymentComponent(router, restaurantService, reserveFormService, paymentService, cdRef, translate, reserveService) {
        this.router = router;
        this.restaurantService = restaurantService;
        this.reserveFormService = reserveFormService;
        this.paymentService = paymentService;
        this.cdRef = cdRef;
        this.translate = translate;
        this.reserveService = reserveService;
        this.name = 'PaymentCreditCard';
        this.deposit_message = 'デポジットには、ご予約人数分のウェルカムドリンクとデザートの料金が含まれたものになっています。';
        this.per_amount = 0;
        this.validate = {};
        this.conf = false;
        this.fail = false;
        this.country = 'jp';
        this.restaurantLang = 'ja';
        this.loading = false;
        this.goodThru = '';
        this.no = '';
        this.securityCode = '';
    }
    PaymentComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (typeof this.restaurantService.get() === 'undefined') {
            this.router.navigate(['/']);
            return true;
        }
        var restaurant = this.restaurantService.get().restaurant;
        this.restaurant_id = restaurant.key;
        this.key = restaurant.restaurant_key;
        this.deposit_message = restaurant.web_reservation_config.deposit_description_message;
        this.country = restaurant.restaurant_country;
        this.restaurantLang = restaurant.restaurant_language;
        this.currency = restaurant.restaurant_currency || 'jpy';
        this.cur = new common_1.CurrencyPipe(this.country);
        Veritrans.client_key = restaurant.client_key;
        this.deposit_per = restaurant.restaurant_deposit_default_config.deposit_per;
        this.per_amount = restaurant.restaurant_deposit_default_config.amount;
        var rs = this.reserveFormService.reserveFormCollection$.subscribe(function (rdata) {
            rs.unsubscribe();
            if (!rdata.is_deposit) {
                _this.router.navigate(['/reserve-confirm']);
                return;
            }
            if (_this.deposit_per === 'per_person') {
                _this.deposit = _this.cur.transform(_this.per_amount * rdata.seats, _this.currency, true);
            }
            else {
                _this.deposit = _this.cur.transform(_this.per_amount, _this.currency, true);
            }
            _this.reservation_id = rdata.payment.reservation_id;
            _this.cdRef.detectChanges();
        });
        this.reserveFormService.get();
        this.date$ = new Observable_1.Observable(function (obs) {
            _this._dateObs = obs;
        }).share();
        this.code$ = new Observable_1.Observable(function (obs) {
            _this._codeObs = obs;
        }).share();
        this.no$ = new Observable_1.Observable(function (obs) {
            _this._noObs = obs;
        }).share();
        this.cex = new credit_card_pipe_1.CreditCardExpirePipe();
        this.cno = new credit_card_pipe_1.CreditCardNoPipe();
        return true;
    };
    PaymentComponent.prototype.checkVal = function () {
        var _this = this;
        var _val = true;
        ['date', 'no', 'code', 'check'].forEach(function (k) {
            _val = _val && _this.validate[k];
        });
        this.conf = _val;
    };
    PaymentComponent.prototype.setDate = function (d) {
        var k = d;
        if (d.length > 0 && d.length > this.goodThru.length) {
            k = this.cex.transform(d, []);
        }
        this.validate['date'] = /^[0-9]{2} \/ [0-9]{2}$/.test(d);
        this.checkVal();
        this.goodThru = k;
        if (k != d) {
            this._dateObs.next(k);
        }
    };
    PaymentComponent.prototype.setNo = function (d) {
        var k = d;
        if (d.length > 0 && d.length > this.no.length) {
            k = this.cno.transform(d, []);
        }
        this.validate['no'] = /^[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}$/.test(k);
        if (/^5[1-5]/.test(d)) {
            this.cardType = 'master';
        }
        else if (/^4/.test(d)) {
            this.cardType = 'visa';
        }
        else {
            this.cardType = '';
        }
        this.checkVal();
        this.no = k;
        if (k !== d) {
            this._noObs.next(k);
        }
    };
    PaymentComponent.prototype.setCode = function (d) {
        var k = d;
        if (/[^0-9]/.test(d)) {
            k = this.securityCode;
        }
        this.validate['code'] = /^[0-9]+$/.test(k);
        this.checkVal();
        this.securityCode = k;
        if (k !== d) {
            this._codeObs.next(k);
        }
    };
    PaymentComponent.prototype.setCheck = function (d) {
        this.validate['check'] = d;
        this.checkVal();
        this.cdRef.detectChanges();
    };
    PaymentComponent.prototype.getCardSet = function () {
        var date = this.goodThru.split('/'), m = '', y = '';
        if (+date[0] && +date[1]) {
            m = ('00' + (+date[0])).slice(-2);
            y = (+date[1] + 2000) + '';
        }
        var card_number = this.no.replace(/ /g, '');
        var card = {
            'card_number': card_number,
            'card_exp_month': m,
            'card_exp_year': y,
            'card_cvv': this.securityCode
        };
        return card;
    };
    PaymentComponent.prototype.onError = function (error) {
        this.errorMessage = '';
        if (error == 'FORM.TIMEOUT') {
            this.errorMessage = 'ERROR.TIMEOUT';
            this.fail = true;
        }
        else {
            this.errorMessage = "PAYMENT.ERROR." + error;
        }
        if (error == 'FAIL') {
            this.fail = true;
        }
        this.loading = false;
        this.cdRef.detectChanges();
    };
    PaymentComponent.prototype.onClick = function () {
        var _this = this;
        this.loading = true;
        var setLang = this.reserveService.api$.subscribe(function (data) {
            setLang.unsubscribe();
            Veritrans.tokenGet(function () {
                return _this.getCardSet();
            }, function (d) {
                var token_id = d.data.token_id;
                var createT = _this.paymentService.createTransaction(_this.restaurant_id, _this.reservation_id, token_id).subscribe(function (das) {
                    var transaction_id = das.transaction_id;
                    var checkT = _this.paymentService.checkTransaction(transaction_id).subscribe(function (d) {
                        createT.unsubscribe();
                        checkT.unsubscribe();
                        _this.loading = false;
                        _this.reserveFormService.set('reserve_no', d.reservation.no);
                        _this.router.navigate(['/finish']);
                    }, function (err) {
                        var error = '';
                        if (err.status == 4) {
                            error = 'R_ERROR';
                        }
                        else if (err.status) {
                            error = 'OTHER';
                        }
                        else {
                            error = 'FAIL';
                        }
                        _this.onError(error);
                    });
                }, function (err) {
                    var error = '';
                    if (err.status) {
                        error = 'OTHER';
                    }
                    else {
                        error = 'FAIL';
                    }
                    _this.onError(error);
                });
            }, function (err) {
                _this.onError('CC_ERROR');
            });
        });
        var reserveData = this.reserveFormService.getApiFormat();
        var lang = this.translate.currentLang;
        var langMatch = lang.match(/([a-z]+)(\-|\_)(.*)/);
        if (langMatch) {
            lang = langMatch[1] + "-" + langMatch[3].toUpperCase();
        }
        reserveData.language = lang;
        this.reserveService.updateTempReservationInfo(this.reservation_id, reserveData);
    };
    return PaymentComponent;
}());
PaymentComponent = PaymentComponent_1 = __decorate([
    core_1.Component({
        selector: 'trt-payment',
        template: require('./payment.component.html'),
        styles: [require('./payment.component.css')],
        providers: [
            payment_service_1.PaymentService,
            { provide: parent_interface_1.Parent, useExisting: core_1.forwardRef(function () { return PaymentComponent_1; }) }
        ]
    }),
    __metadata("design:paramtypes", [router_1.Router,
        restaurant_module_1.RestaurantService,
        reserve_form_service_1.ReserveFormService,
        payment_service_1.PaymentService,
        core_1.ChangeDetectorRef,
        core_2.TranslateService,
        reserve_service_1.ReserveService])
], PaymentComponent);
exports.PaymentComponent = PaymentComponent;
var PCPaymentComponent = PCPaymentComponent_1 = (function (_super) {
    __extends(PCPaymentComponent, _super);
    function PCPaymentComponent(router, restaurantService, reserveFormService, paymentService, cdRef, translate, reserveService) {
        var _this = _super.call(this, router, restaurantService, reserveFormService, paymentService, cdRef, translate, reserveService) || this;
        _this.router = router;
        _this.restaurantService = restaurantService;
        _this.reserveFormService = reserveFormService;
        _this.paymentService = paymentService;
        _this.cdRef = cdRef;
        _this.translate = translate;
        _this.reserveService = reserveService;
        return _this;
    }
    return PCPaymentComponent;
}(PaymentComponent));
PCPaymentComponent = PCPaymentComponent_1 = __decorate([
    core_1.Component({
        selector: 'trt-payment',
        template: require('./payment.pc.component.html'),
        styles: [require('./payment.pc.component.css')],
        providers: [
            payment_service_1.PaymentService,
            { provide: parent_interface_1.Parent, useExisting: core_1.forwardRef(function () { return PCPaymentComponent_1; }) }
        ]
    }),
    __metadata("design:paramtypes", [router_1.Router,
        restaurant_module_1.RestaurantService,
        reserve_form_service_1.ReserveFormService,
        payment_service_1.PaymentService,
        core_1.ChangeDetectorRef,
        core_2.TranslateService,
        reserve_service_1.ReserveService])
], PCPaymentComponent);
exports.PCPaymentComponent = PCPaymentComponent;
var PaymentComponent_1, PCPaymentComponent_1;
