"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var term_module_1 = require("../term/term.module");
var forms_1 = require("@angular/forms");
var payment_docomo_routes_1 = require("./payment-docomo.routes");
var router_1 = require("@angular/router");
var common_1 = require("@angular/common");
var form_module_1 = require("../form/form.module");
var core_1 = require("@ngx-translate/core");
var common_module_1 = require("../common/common.module");
var core_2 = require("@angular/core");
var payment_docomo_component_1 = require("./components/payment-docomo.component");
var PaymentDocomoModule = (function () {
    function PaymentDocomoModule() {
    }
    return PaymentDocomoModule;
}());
PaymentDocomoModule = __decorate([
    core_2.NgModule({
        imports: [
            forms_1.FormsModule,
            core_1.TranslateModule,
            form_module_1.TRTFormModule,
            common_1.CommonModule,
            common_module_1.TRTCommonModule,
            router_1.RouterModule,
            term_module_1.TermModule,
            payment_docomo_routes_1.PaymentDocomoRoutingModule
        ],
        exports: [payment_docomo_component_1.PaymentDocomoComponent],
        declarations: [payment_docomo_component_1.PaymentDocomoComponent],
        providers: [],
    })
], PaymentDocomoModule);
exports.PaymentDocomoModule = PaymentDocomoModule;
var PCPaymentDocomoModule = (function () {
    function PCPaymentDocomoModule() {
    }
    return PCPaymentDocomoModule;
}());
PCPaymentDocomoModule = __decorate([
    core_2.NgModule({
        imports: [
            forms_1.FormsModule,
            core_1.TranslateModule,
            form_module_1.TRTFormModule,
            common_1.CommonModule,
            common_module_1.TRTCommonModule,
            router_1.RouterModule,
            term_module_1.TermModule,
            payment_docomo_routes_1.PCPaymentDocomoRoutingModule
        ],
        exports: [payment_docomo_component_1.PCPaymentDocomoComponent],
        declarations: [payment_docomo_component_1.PCPaymentDocomoComponent],
        providers: [],
    })
], PCPaymentDocomoModule);
exports.PCPaymentDocomoModule = PCPaymentDocomoModule;
