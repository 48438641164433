"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var CreditCardNoPipe = (function () {
    function CreditCardNoPipe() {
    }
    CreditCardNoPipe.prototype.transform = function (value, args) {
        var no = '';
        var reg = /[ \D]/g;
        if (value.length < 5) {
            no = value.replace(reg, '');
        }
        else if (value.length < 10) {
            var nv = value.replace(reg, '');
            no = nv.slice(0, 4) + " " + nv.slice(4, 8);
        }
        else if (value.length < 15) {
            var nv = value.replace(reg, '');
            no = nv.slice(0, 4) + " " + nv.slice(4, 8) + " " + nv.slice(8, 12);
        }
        else {
            var nv = value.replace(reg, '');
            no = nv.slice(0, 4) + " " + nv.slice(4, 8) + " " + nv.slice(8, 12) + " " + nv.slice(12, 16);
        }
        return no;
    };
    return CreditCardNoPipe;
}());
CreditCardNoPipe = __decorate([
    core_1.Pipe({
        name: 'trtCreditCardNo'
    })
], CreditCardNoPipe);
exports.CreditCardNoPipe = CreditCardNoPipe;
var CreditCardExpirePipe = (function () {
    function CreditCardExpirePipe() {
    }
    CreditCardExpirePipe.prototype.transform = function (value, args) {
        var no = '';
        var reg = /[ \D\/]/g;
        var nv = value.replace(reg, '');
        if (nv.length < 2) {
            if (nv > 1) {
                nv = "0" + nv;
            }
            no = nv;
        }
        if (nv.length > 1) {
            no = nv.slice(0, 2) + " / " + nv.slice(2, 4);
        }
        return no;
    };
    return CreditCardExpirePipe;
}());
CreditCardExpirePipe = __decorate([
    core_1.Pipe({
        name: 'trtCreditCardExpire'
    })
], CreditCardExpirePipe);
exports.CreditCardExpirePipe = CreditCardExpirePipe;
