"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var platform_browser_1 = require("@angular/platform-browser");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var common_1 = require("@angular/common");
var http_1 = require("@angular/http");
var restaurant_module_1 = require("../common/services/restaurant/restaurant.module");
var app_module_1 = require("../app/app.module");
var app_model_1 = require("../app/models/app.model");
var common_module_1 = require("../common/common.module");
var translate_http_loader_1 = require("../common/services/translate-http-loader");
var core_2 = require("@ngx-translate/core");
var config_service_1 = require("../admin/services/config.service");
if (ENV === 'production') {
    core_1.enableProdMode();
}
var TRExceptionHandler = (function () {
    function TRExceptionHandler() {
    }
    TRExceptionHandler.prototype.handleError = function (error, stackTrace, reason) {
        if (stackTrace === void 0) { stackTrace = null; }
        if (reason === void 0) { reason = null; }
        console.error(error);
        try {
            Bugsnag.releaseStage = ENV;
            Bugsnag.notifyException(error);
        }
        catch (e) { }
    };
    return TRExceptionHandler;
}());
exports.TRExceptionHandler = TRExceptionHandler;
var prefix = S3_URL + "/dist/client/assets/i18n/";
var suffix = '.json';
var provider = /from=rakuten/.test(location.search) || /from=rakuten/.test(location.hash);
function appFactory() {
    return new app_model_1.AppTypeLoad('client', null, true, null, provider);
}
exports.appFactory = appFactory;
function restaurantLoaderFactory(http) {
    var data = (window['restaurantData']) ? {
        restaurant: window['restaurantData']
    } : null;
    return new restaurant_module_1.RestaurantStaticLoader(http, null, data);
}
exports.restaurantLoaderFactory = restaurantLoaderFactory;
function translateFactory(http) {
    return new translate_http_loader_1.TranslateHttpLoader(http, prefix, suffix, window['overrideTranslation']);
}
exports.translateFactory = translateFactory;
var ClientModule = (function () {
    function ClientModule() {
    }
    return ClientModule;
}());
ClientModule = __decorate([
    core_1.NgModule({
        bootstrap: [app_module_1.AppComponent],
        imports: [
            http_1.HttpModule,
            platform_browser_1.BrowserModule,
            core_2.TranslateModule.forRoot({
                loader: {
                    provide: core_2.TranslateLoader,
                    useFactory: translateFactory,
                    deps: [http_1.Http]
                }
            }),
            restaurant_module_1.RestaurantModule.forRoot({
                provide: restaurant_module_1.RestaurantLoader,
                useFactory: restaurantLoaderFactory,
                deps: [http_1.Http]
            }),
            common_module_1.TRTCommonModule.forRoot(),
            router_1.RouterModule.forRoot([]),
            app_module_1.AppModule
        ],
        providers: [
            {
                provide: app_model_1.AppType,
                useFactory: appFactory,
                deps: []
            },
            { provide: common_1.LocationStrategy, useClass: common_1.HashLocationStrategy },
            { provide: config_service_1.ConfigService, useClass: config_service_1.ConfigService },
            { provide: core_1.ErrorHandler, useClass: TRExceptionHandler }
        ]
    }),
    __metadata("design:paramtypes", [])
], ClientModule);
exports.ClientModule = ClientModule;
