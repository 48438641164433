"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./input-placeholder.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./input-placeholder.component");
var i4 = require("../services/form.service");
var styles_InputPlaceholderComponent = [i0.styles];
exports.RenderType_InputPlaceholderComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_InputPlaceholderComponent, data: {} });
function View_InputPlaceholderComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 3, 'label', [['class',
                'ty-input-label']], null, null, null, null, null)),
        i1.ɵdid(278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers,
            i1.ElementRef, i1.Renderer], { klass: [0, 'klass'], ngClass: [1, 'ngClass'] }, null),
        i1.ɵpod(['disabled', 'on', 'off']), (_l()(), i1.ɵted(null, ['', '']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = 'ty-input-label';
        var currVal_1 = _ck(_v, 2, 0, _co.disabled, _co._on, !_co._on);
        _ck(_v, 1, 0, currVal_0, currVal_1);
    }, function (_ck, _v) {
        var _co = _v.component;
        var currVal_2 = _co.labelTitle;
        _ck(_v, 3, 0, currVal_2);
    });
}
exports.View_InputPlaceholderComponent_0 = View_InputPlaceholderComponent_0;
function View_InputPlaceholderComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'ty-input-placeholder', [], null, null, null, View_InputPlaceholderComponent_0, exports.RenderType_InputPlaceholderComponent)), i1.ɵdid(573440, null, 0, i3.InputPlaceholderComponent, [i4.FormService, i1.ElementRef], null, null)], null, null);
}
exports.View_InputPlaceholderComponent_Host_0 = View_InputPlaceholderComponent_Host_0;
exports.InputPlaceholderComponentNgFactory = i1.ɵccf('ty-input-placeholder', i3.InputPlaceholderComponent, View_InputPlaceholderComponent_Host_0, { labelTitle: 'labelTitle',
    tyInputName: 'tyInputName', tyInputData: 'tyInputData', disabled: 'disabled' }, {}, []);
