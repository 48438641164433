"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./input.component.css.shim.ngstyle");
var i1 = require("./input-country.component.css.shim.ngstyle");
var i2 = require("@angular/core");
var i3 = require("@angular/common");
var i4 = require("./input-placeholder.component.ngfactory");
var i5 = require("./input-placeholder.component");
var i6 = require("../services/form.service");
var i7 = require("./input-country.component");
var i8 = require("../../common/services/countries.service");
var styles_InputCountryComponent = [i0.styles, i1.styles];
exports.RenderType_InputCountryComponent = i2.ɵcrt({ encapsulation: 0,
    styles: styles_InputCountryComponent, data: {} });
function View_InputCountryComponent_1(_l) {
    return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, null, null, 15, 'li', [['class',
                'group']], null, [[null, 'click']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('click' === en)) {
                var pd_0 = (_co.setCountry(_v.context.$implicit.iso2) !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), i2.ɵdid(278528, null, 0, i3.NgClass, [i2.IterableDiffers,
            i2.KeyValueDiffers, i2.ElementRef, i2.Renderer], { klass: [0, 'klass'], ngClass: [1,
                'ngClass'] }, null), i2.ɵpod(['init']), (_l()(), i2.ɵted(null, ['\n          '])),
        (_l()(), i2.ɵeld(0, null, null, 4, 'div', [['class', 'flag-box']], null, null, null, null, null)),
        (_l()(), i2.ɵted(null, ['\n            '])), (_l()(), i2.ɵeld(0, null, null, 1, 'div', [['class', 'iti-flag']], null, null, null, null, null)), i2.ɵdid(278528, null, 0, i3.NgClass, [i2.IterableDiffers, i2.KeyValueDiffers, i2.ElementRef, i2.Renderer], { klass: [0, 'klass'], ngClass: [1, 'ngClass'] }, null), (_l()(), i2.ɵted(null, ['\n          '])), (_l()(), i2.ɵted(null, ['\n          '])), (_l()(),
            i2.ɵeld(0, null, null, 1, 'span', [['class', 'name']], null, null, null, null, null)), (_l()(), i2.ɵted(null, ['', ''])), (_l()(), i2.ɵted(null, ['\n          '])), (_l()(), i2.ɵeld(0, null, null, 1, 'span', [['class', 'number']], null, null, null, null, null)), (_l()(), i2.ɵted(null, ['+', ''])), (_l()(), i2.ɵted(null, ['\n        ']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = 'group';
        var currVal_1 = _ck(_v, 2, 0, (_co.tyData == _v.context.$implicit.iso2));
        _ck(_v, 1, 0, currVal_0, currVal_1);
        var currVal_2 = 'iti-flag';
        var currVal_3 = _v.context.$implicit.iso2;
        _ck(_v, 7, 0, currVal_2, currVal_3);
    }, function (_ck, _v) {
        var currVal_4 = _v.context.$implicit.name;
        _ck(_v, 11, 0, currVal_4);
        var currVal_5 = _v.context.$implicit.dialCode;
        _ck(_v, 14, 0, currVal_5);
    });
}
function View_InputCountryComponent_2(_l) {
    return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, null, null, 0, 'div', [['class',
                'line']], null, null, null, null, null))], null, null);
}
function View_InputCountryComponent_3(_l) {
    return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, null, null, 15, 'li', [], null, [[null, 'click']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('click' === en)) {
                var pd_0 = (_co.setCountry(_v.context.$implicit.iso2) !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), i2.ɵdid(278528, null, 0, i3.NgClass, [i2.IterableDiffers,
            i2.KeyValueDiffers, i2.ElementRef, i2.Renderer], { ngClass: [0, 'ngClass'] }, null),
        i2.ɵpod(['init']), (_l()(), i2.ɵted(null, ['\n          '])), (_l()(), i2.ɵeld(0, null, null, 4, 'div', [['class', 'flag-box']], null, null, null, null, null)), (_l()(), i2.ɵted(null, ['\n            '])), (_l()(), i2.ɵeld(0, null, null, 1, 'div', [['class', 'iti-flag']], null, null, null, null, null)), i2.ɵdid(278528, null, 0, i3.NgClass, [i2.IterableDiffers,
            i2.KeyValueDiffers, i2.ElementRef, i2.Renderer], { klass: [0, 'klass'], ngClass: [1,
                'ngClass'] }, null), (_l()(), i2.ɵted(null, ['\n          '])),
        (_l()(), i2.ɵted(null, ['\n          '])), (_l()(), i2.ɵeld(0, null, null, 1, 'span', [['class', 'name']], null, null, null, null, null)), (_l()(), i2.ɵted(null, ['', ''])), (_l()(),
            i2.ɵted(null, ['\n          '])), (_l()(), i2.ɵeld(0, null, null, 1, 'span', [['class', 'number']], null, null, null, null, null)), (_l()(), i2.ɵted(null, ['+', ''])), (_l()(), i2.ɵted(null, ['\n        ']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _ck(_v, 2, 0, (_co.tyData == _v.context.$implicit.iso2));
        _ck(_v, 1, 0, currVal_0);
        var currVal_1 = 'iti-flag';
        var currVal_2 = _v.context.$implicit.iso2;
        _ck(_v, 7, 0, currVal_1, currVal_2);
    }, function (_ck, _v) {
        var currVal_3 = _v.context.$implicit.name;
        _ck(_v, 11, 0, currVal_3);
        var currVal_4 = _v.context.$implicit.dialCode;
        _ck(_v, 14, 0, currVal_4);
    });
}
function View_InputCountryComponent_0(_l) {
    return i2.ɵvid(0, [i2.ɵpid(0, i3.UpperCasePipe, []), (_l()(), i2.ɵeld(0, null, null, 29, 'div', [['class', 'ty-input']], null, null, null, null, null)), (_l()(), i2.ɵted(null, ['\n    '])), (_l()(),
            i2.ɵeld(0, null, null, 1, 'ty-input-placeholder', [], null, null, null, i4.View_InputPlaceholderComponent_0, i4.RenderType_InputPlaceholderComponent)), i2.ɵdid(573440, null, 0, i5.InputPlaceholderComponent, [i6.FormService, i2.ElementRef], { labelTitle: [0, 'labelTitle'],
            tyInputName: [1, 'tyInputName'], tyInputData: [2, 'tyInputData'] }, null),
        (_l()(), i2.ɵted(null, ['\n    '])), (_l()(), i2.ɵeld(0, null, null, 8, 'div', [['id', 'wrap']], null, [[null, 'click']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('click' === en)) {
                var pd_0 = (_co.removeHiddenCountries() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), (_l()(), i2.ɵted(null, ['\n      '])),
        (_l()(), i2.ɵeld(0, null, null, 1, 'div', [['class', 'iti-flag']], null, null, null, null, null)),
        i2.ɵdid(278528, null, 0, i3.NgClass, [i2.IterableDiffers, i2.KeyValueDiffers,
            i2.ElementRef, i2.Renderer], { klass: [0, 'klass'], ngClass: [1, 'ngClass'] }, null),
        (_l()(), i2.ɵeld(0, null, null, 3, 'p', [['class', 'flag'], ['id',
                'flag']], null, null, null, null, null)),
        i2.ɵdid(278528, null, 0, i3.NgClass, [i2.IterableDiffers, i2.KeyValueDiffers,
            i2.ElementRef, i2.Renderer], { klass: [0, 'klass'], ngClass: [1, 'ngClass'] }, null),
        (_l()(), i2.ɵted(null, ['', ' '])), i2.ɵppd(1), (_l()(), i2.ɵted(null, ['\n    '])), (_l()(), i2.ɵted(null, ['\n    '])), (_l()(), i2.ɵeld(0, null, null, 13, 'div', [['id', 'countries']], [[8, 'hidden', 0]], null, null, null, null)), (_l()(), i2.ɵted(null, ['\n      '])), (_l()(), i2.ɵeld(0, null, null, 10, 'ul', [], null, null, null, null, null)),
        (_l()(), i2.ɵted(null, ['\n        '])), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_InputCountryComponent_1)), i2.ɵdid(802816, null, 0, i3.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, 'ngForOf'] }, null), (_l()(), i2.ɵted(null, ['\n        '])),
        (_l()(), i2.ɵand(16777216, null, null, 1, null, View_InputCountryComponent_2)),
        i2.ɵdid(16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0,
                'ngIf'] }, null), (_l()(), i2.ɵted(null, ['\n        '])), (_l()(),
            i2.ɵand(16777216, null, null, 1, null, View_InputCountryComponent_3)),
        i2.ɵdid(802816, null, 0, i3.NgForOf, [i2.ViewContainerRef, i2.TemplateRef,
            i2.IterableDiffers], { ngForOf: [0, 'ngForOf'] }, null), (_l()(), i2.ɵted(null, ['\n      '])), (_l()(), i2.ɵted(null, ['\n    '])), (_l()(), i2.ɵted(null, ['\n  ']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _co.tyPlaceholder;
        var currVal_1 = _co.tyName;
        var currVal_2 = _co.tyData;
        _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2);
        var currVal_3 = 'iti-flag';
        var currVal_4 = _co.tyData;
        _ck(_v, 9, 0, currVal_3, currVal_4);
        var currVal_5 = 'flag';
        var currVal_6 = _co.tyData;
        _ck(_v, 11, 0, currVal_5, currVal_6);
        var currVal_9 = _co.allCountries.group;
        _ck(_v, 21, 0, currVal_9);
        var currVal_10 = (_co.allCountries.group.length > 0);
        _ck(_v, 24, 0, currVal_10);
        var currVal_11 = _co.allCountries.all;
        _ck(_v, 27, 0, currVal_11);
    }, function (_ck, _v) {
        var _co = _v.component;
        var currVal_7 = i2.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i2.ɵnov(_v, 0), _co.tyData));
        _ck(_v, 12, 0, currVal_7);
        var currVal_8 = _co.hiddenCountries;
        _ck(_v, 16, 0, currVal_8);
    });
}
exports.View_InputCountryComponent_0 = View_InputCountryComponent_0;
function View_InputCountryComponent_Host_0(_l) {
    return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, null, null, 2, 'ty-input-country', [], null, null, null, View_InputCountryComponent_0, exports.RenderType_InputCountryComponent)), i2.ɵprd(512, null, i6.FormService, i6.FormService, []), i2.ɵdid(4964352, null, 0, i7.InputCountryComponent, [i6.FormService, i2.ChangeDetectorRef, i8.CountriesService, i2.ElementRef, i2.Renderer], null, null)], function (_ck, _v) {
        _ck(_v, 2, 0);
    }, null);
}
exports.View_InputCountryComponent_Host_0 = View_InputCountryComponent_Host_0;
exports.InputCountryComponentNgFactory = i2.ɵccf('ty-input-country', i7.InputCountryComponent, View_InputCountryComponent_Host_0, { tyName: 'tyName', tyPlaceholder: 'tyPlaceholder',
    tyData: 'tyData', tyChange: 'tyChange', tyInitCountry: 'tyInitCountry', type: 'type' }, { changeData: 'changeData' }, []);
