"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./reservation.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core/src/translate.pipe");
var i3 = require("@ngx-translate/core/src/translate.service");
var i4 = require("../pipes/day.pipe");
var i5 = require("../services/toreta-date.service");
var i6 = require("../pipes/time.pipe");
var i7 = require("../services/toreta-time.service");
var i8 = require("@angular/common");
var i9 = require("../pipes/br.pipe");
var i10 = require("./reservation.component");
var styles_ReservationComponent = [i0.styles];
exports.RenderType_ReservationComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_ReservationComponent, data: {} });
function View_ReservationComponent_1(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 8, 'div', [], null, null, null, null, null)), (_l()(),
            i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵeld(0, null, null, 2, 'label', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n      '])),
        (_l()(), i1.ɵeld(0, null, null, 1, 'p', [['class', 'reservation_restaurantName']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['', ''])), (_l()(), i1.ɵted(null, ['\n    ']))], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform('RESTAURANT_NAME'));
        _ck(_v, 3, 0, currVal_0);
        var currVal_1 = _co.restaurantName;
        _ck(_v, 7, 0, currVal_1);
    });
}
function View_ReservationComponent_2(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 2, 'p', [['class',
                'reservation_seats']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', '', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _co.seats;
        var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform('PEOPLE'));
        _ck(_v, 1, 0, currVal_0, currVal_1);
    });
}
function View_ReservationComponent_3(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 10, 'p', [], null, null, null, null, null)), (_l()(),
            i1.ɵted(null, ['\n        '])), (_l()(), i1.ɵeld(0, null, null, 3, 'span', [['class', 'reservation_adults_seats']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['',
            ' ', '', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]),
        i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]),
        (_l()(), i1.ɵeld(0, null, null, 3, 'span', [['class', 'reservation_children_seats']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, [', ', ' ', '', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n      ']))], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform('ADULTS'));
        var currVal_1 = _co.adults_seats;
        var currVal_2 = i1.ɵunv(_v, 3, 2, i1.ɵnov(_v, 5).transform('PEOPLE'));
        _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2);
        var currVal_3 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform('CHILDREN'));
        var currVal_4 = _co.children_seats;
        var currVal_5 = i1.ɵunv(_v, 7, 2, i1.ɵnov(_v, 9).transform('PEOPLE'));
        _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5);
    });
}
function View_ReservationComponent_4(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 6, 'p', [['class',
                'reservation_date_time']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ' ', ' ', ' ', ''])), i1.ɵpod(['time']),
        i1.ɵpid(131072, i4.DayPipe, [i5.TRTDateService, i3.TranslateService]), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i6.TimePipe, [i7.TRTTimeService, i3.TranslateService]), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(_co.goDate, _ck(_v, 2, 0, _co.start_time)));
        var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 4).transform('IN'));
        var currVal_2 = i1.ɵunv(_v, 1, 2, i1.ɵnov(_v, 5).transform(_co.end_time));
        var currVal_3 = i1.ɵunv(_v, 1, 3, i1.ɵnov(_v, 6).transform('OUT'));
        _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3);
    });
}
function View_ReservationComponent_5(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 4, 'p', [['class',
                'reservation_date_time']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ' ', ''])), i1.ɵpod(['time']),
        i1.ɵpid(131072, i4.DayPipe, [i5.TRTDateService, i3.TranslateService]), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(_co.goDate, _ck(_v, 2, 0, _co.start_time)));
        var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 4).transform('IN'));
        _ck(_v, 1, 0, currVal_0, currVal_1);
    });
}
function View_ReservationComponent_6(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 10, 'div', [], null, null, null, null, null)), (_l()(),
            i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 2, 'label', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n    '])),
        (_l()(), i1.ɵeld(0, null, null, 3, 'p', [['class', 'reservation_deposit']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpod(['amount']), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n    ']))], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform('PAYMENT.DEPOSIT'));
        _ck(_v, 3, 0, currVal_0);
        var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 9).transform('AMOUNT', _ck(_v, 8, 0, _co.amount)));
        _ck(_v, 7, 0, currVal_1);
    });
}
function View_ReservationComponent_7(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 9, 'div', [], null, null, null, null, null)), (_l()(),
            i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵeld(0, null, null, 2, 'label', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n      '])),
        (_l()(), i1.ɵeld(0, null, null, 2, 'p', [['class', 'reservation_table']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService,
            i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n    ']))], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform('TABLE_CAETGORY'));
        _ck(_v, 3, 0, currVal_0);
        var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(('TABLE_CATEGORIES.' + _co.selected_table_category)));
        _ck(_v, 7, 0, currVal_1);
    });
}
function View_ReservationComponent_8(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 9, 'div', [], null, null, null, null, null)), (_l()(),
            i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵeld(0, null, null, 2, 'label', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n      '])),
        (_l()(), i1.ɵeld(0, null, null, 2, 'p', [['class', 'reservation_smoking']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService,
            i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n    ']))], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform('SELECT_SMOKING'));
        _ck(_v, 3, 0, currVal_0);
        var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(('TABLE_SMOKING.' + _co.selected_table_smoking)));
        _ck(_v, 7, 0, currVal_1);
    });
}
function View_ReservationComponent_10(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 2, 'p', [], null, null, null, null, null)), (_l()(),
            i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService,
            i1.ChangeDetectorRef])], null, function (_ck, _v) {
        var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform('NO_COURSES'));
        _ck(_v, 1, 0, currVal_0);
    });
}
function View_ReservationComponent_11(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 3, 'p', [['class',
                'reservation_courses']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ' ', ''])), i1.ɵpod(['price',
            'seats']), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) {
        var currVal_0 = _v.context.$implicit.name;
        var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 3).transform('RESERVE_INFO.IN_TAX', _ck(_v, 2, 0, _v.context.$implicit.price, _v.context.$implicit.seats)));
        _ck(_v, 1, 0, currVal_0, currVal_1);
    });
}
function View_ReservationComponent_9(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 14, 'div', [], null, null, null, null, null)), (_l()(),
            i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 2, 'label', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n    '])),
        (_l()(), i1.ɵeld(0, null, null, 7, 'div', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReservationComponent_10)), i1.ɵdid(16384, null, 0, i8.NgIf, [i1.ViewContainerRef,
            i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReservationComponent_11)), i1.ɵdid(802816, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, 'ngForOf'] }, null), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵted(null, ['\n    ']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_1 = (_co.courses.length == 0);
        _ck(_v, 9, 0, currVal_1);
        var currVal_2 = _co.courses;
        _ck(_v, 12, 0, currVal_2);
    }, function (_ck, _v) {
        var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform('COURSE'));
        _ck(_v, 3, 0, currVal_0);
    });
}
function View_ReservationComponent_12(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 8, 'div', [], null, null, null, null, null)), (_l()(),
            i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵeld(0, null, null, 2, 'label', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n      '])),
        (_l()(), i1.ɵeld(0, null, null, 1, 'p', [['class', 'reservation_add_info']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['', ''])), (_l()(), i1.ɵted(null, ['\n    ']))], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform('ADD_INFO'));
        _ck(_v, 3, 0, currVal_0);
        var currVal_1 = _co.addInfo;
        _ck(_v, 7, 0, currVal_1);
    });
}
function View_ReservationComponent_13(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 9, 'div', [], null, null, null, null, null)), (_l()(),
            i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 2, 'label', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n    '])),
        (_l()(), i1.ɵeld(0, null, null, 2, 'div', [], null, null, null, null, null)), (_l()(), i1.ɵeld(0, null, null, 1, 'p', [['class', 'reservation_request']], [[8,
                'innerHTML', 1]], null, null, null, null)),
        i1.ɵppd(1), (_l()(), i1.ɵted(null, ['\n    ']))], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform('REQUEST'));
        _ck(_v, 3, 0, currVal_0);
        var currVal_1 = i1.ɵinlineInterpolate(1, '', i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), _co.request)), '');
        _ck(_v, 7, 0, currVal_1);
    });
}
function View_ReservationComponent_0(_l) {
    return i1.ɵvid(0, [i1.ɵpid(0, i9.BrPipe, []), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵeld(0, null, null, 50, 'div', [], null, null, null, null, null)), i1.ɵdid(278528, null, 0, i8.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef,
            i1.Renderer], { ngClass: [0, 'ngClass'] }, null), i1.ɵpod(['pc', 'noborder']),
        (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReservationComponent_1)), i1.ɵdid(16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 11, 'div', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵeld(0, null, null, 2, 'label', [], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService,
            i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(),
            i1.ɵand(16777216, null, null, 1, null, View_ReservationComponent_2)),
        i1.ɵdid(16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0,
                'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(),
            i1.ɵand(16777216, null, null, 1, null, View_ReservationComponent_3)),
        i1.ɵdid(16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0,
                'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(),
            i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 11, 'div', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 2, 'label', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]),
        (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReservationComponent_4)), i1.ɵdid(16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReservationComponent_5)),
        i1.ɵdid(16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0,
                'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(),
            i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReservationComponent_6)), i1.ɵdid(16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReservationComponent_7)),
        i1.ɵdid(16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0,
                'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(),
            i1.ɵand(16777216, null, null, 1, null, View_ReservationComponent_8)),
        i1.ɵdid(16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0,
                'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(),
            i1.ɵand(16777216, null, null, 1, null, View_ReservationComponent_9)),
        i1.ɵdid(16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0,
                'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(),
            i1.ɵand(16777216, null, null, 1, null, View_ReservationComponent_12)),
        i1.ɵdid(16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0,
                'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(),
            i1.ɵand(16777216, null, null, 1, null, View_ReservationComponent_13)),
        i1.ɵdid(16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0,
                'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(),
            i1.ɵted(null, ['\n  ']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _ck(_v, 4, 0, _co.pc, _co.noborder);
        _ck(_v, 3, 0, currVal_0);
        var currVal_1 = _co.restaurantName;
        _ck(_v, 7, 0, currVal_1);
        var currVal_3 = !_co.accept_children;
        _ck(_v, 16, 0, currVal_3);
        var currVal_4 = _co.accept_children;
        _ck(_v, 19, 0, currVal_4);
        var currVal_6 = _co.end_at;
        _ck(_v, 29, 0, currVal_6);
        var currVal_7 = !_co.end_at;
        _ck(_v, 32, 0, currVal_7);
        var currVal_8 = _co.is_deposit;
        _ck(_v, 36, 0, currVal_8);
        var currVal_9 = _co.selected_table_category;
        _ck(_v, 39, 0, currVal_9);
        var currVal_10 = _co.selected_table_smoking;
        _ck(_v, 42, 0, currVal_10);
        var currVal_11 = _co.accept_courses;
        _ck(_v, 45, 0, currVal_11);
        var currVal_12 = _co.addInfo;
        _ck(_v, 48, 0, currVal_12);
        var currVal_13 = _co.show_request_form;
        _ck(_v, 51, 0, currVal_13);
    }, function (_ck, _v) {
        var currVal_2 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform('PARTY_SIZE'));
        _ck(_v, 12, 0, currVal_2);
        var currVal_5 = i1.ɵunv(_v, 25, 0, i1.ɵnov(_v, 26).transform('DATE_TIME'));
        _ck(_v, 25, 0, currVal_5);
    });
}
exports.View_ReservationComponent_0 = View_ReservationComponent_0;
function View_ReservationComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'trt-reservation', [], null, null, null, View_ReservationComponent_0, exports.RenderType_ReservationComponent)), i1.ɵdid(180224, null, 0, i10.ReservationComponent, [i3.TranslateService, i1.ChangeDetectorRef], null, null)], null, null);
}
exports.View_ReservationComponent_Host_0 = View_ReservationComponent_Host_0;
exports.ReservationComponentNgFactory = i1.ɵccf('trt-reservation', i10.ReservationComponent, View_ReservationComponent_Host_0, { restaurantName: 'restaurantName',
    seats: 'seats', adults_seats: 'adults_seats', children_seats: 'children_seats',
    courses: 'courses', goDate: 'goDate', time_label: 'time_label', request: 'request',
    accept_children: 'accept_children', pc: 'pc', accept_courses: 'accept_courses',
    show_request_form: 'show_request_form', noborder: 'noborder', is_deposit: 'is_deposit',
    amount: 'amount', start_at: 'start_at', end_at: 'end_at', start_time: 'start_time',
    end_time: 'end_time', timezone_offset: 'timezone_offset', lang: 'lang', selected_table_category: 'selected_table_category',
    addInfo: 'addInfo', selected_table_smoking: 'selected_table_smoking' }, {}, []);
