"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var can_activate_guard_service_1 = require("../common/services/can-activate-guard.service");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var reserve_confirmation_component_1 = require("./components/reserve-confirmation.component");
var can_deactivate_guard_service_1 = require("../common/services/can-deactivate-guard.service");
var routes = [
    { path: 'reserve-confirm',
        component: reserve_confirmation_component_1.ReserveConfirmationComponent,
        canActivate: [can_activate_guard_service_1.CanActivateGuard],
        canDeactivate: [can_deactivate_guard_service_1.CanDeactivateGuard] }
];
var ReserveConfirmationRoutingModule = (function () {
    function ReserveConfirmationRoutingModule() {
    }
    return ReserveConfirmationRoutingModule;
}());
ReserveConfirmationRoutingModule = __decorate([
    core_1.NgModule({
        imports: [router_1.RouterModule.forChild(routes)]
    })
], ReserveConfirmationRoutingModule);
exports.ReserveConfirmationRoutingModule = ReserveConfirmationRoutingModule;
var pcroutes = [
    { path: 'reserve-confirm',
        component: reserve_confirmation_component_1.PCReserveConfirmationComponent,
        canActivate: [can_activate_guard_service_1.CanActivateGuard],
        canDeactivate: [can_deactivate_guard_service_1.CanDeactivateGuard] }
];
var PCReserveConfirmationRoutingModule = (function () {
    function PCReserveConfirmationRoutingModule() {
    }
    return PCReserveConfirmationRoutingModule;
}());
PCReserveConfirmationRoutingModule = __decorate([
    core_1.NgModule({
        imports: [router_1.RouterModule.forChild(pcroutes)]
    })
], PCReserveConfirmationRoutingModule);
exports.PCReserveConfirmationRoutingModule = PCReserveConfirmationRoutingModule;
