"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Subject_1 = require("rxjs/Subject");
var parent_interface_1 = require("../../common/interfaces/parent.interface");
require("rxjs/add/operator/count");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var reserve_form_service_1 = require("../../common/services/reserve-form.service");
var core_2 = require("@ngx-translate/core");
var reserve_service_1 = require("../../common/services/reserve.service");
var restaurant_module_1 = require("../../common/services/restaurant/restaurant.module");
var person_validation_service_1 = require("../services/person-validation.service");
var app_model_1 = require("../../app/models/app.model");
var intl_tel_service_1 = require("../../common/services/intl-tel.service");
var countries_service_1 = require("../../common/services/countries.service");
var objectAssign = require("object-assign");
var PersonalInfoComponent = PersonalInfoComponent_1 = (function () {
    function PersonalInfoComponent(reserveForm, router, reserveService, restaurantService, personValidationService, cdRef, translate, appType, intl, countriesService) {
        this.reserveForm = reserveForm;
        this.router = router;
        this.reserveService = reserveService;
        this.restaurantService = restaurantService;
        this.personValidationService = personValidationService;
        this.cdRef = cdRef;
        this.translate = translate;
        this.appType = appType;
        this.intl = intl;
        this.countriesService = countriesService;
        this.name = 'PersonalInfo';
        this.saveBrowser = false;
        this.disabled = true;
        this.createdCustomer = false;
        this.errorMessage = '';
        this.seats = 0;
        this.adultsSeats = 0;
        this.childrenSeats = 0;
        this.goDate = '';
        this.timeLabel = '';
        this.accept_children = false;
        this.storeEnabled = true;
        this.isJp = true;
        this.isJa = true;
        this.isZh = false;
        this.observers = [];
        this.fromRakuten = false;
        this.customer = {};
        this.isGlobal = false;
        this.courses = [{}];
        this.accept_courses = false;
        this.selected_table_category = '';
        this.selected_table_smoking = '';
        this.isTest2 = false;
        this.sendPersonalInfo = new Subject_1.Subject();
        this._clicked = false;
        var dummy_key = 'e89bj&84^9jg2qfi@nvuw#$!$';
        var dummy_val = '' + Math.random();
        try {
            localStorage.setItem(dummy_key, dummy_val);
            this.storeEnabled = (localStorage.getItem(dummy_key) === dummy_val);
            localStorage.removeItem(dummy_key);
        }
        catch (e) {
            this.storeEnabled = false;
        }
    }
    PersonalInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (typeof this.restaurantService.get() === 'undefined') {
            this.router.navigate(['/']);
            return true;
        }
        if (this.isTest2) {
            this.sendPersonalInfo.subscribe(function (event) {
                if (event.type === 'send') {
                    _this.onClick(event);
                }
            });
        }
        this.isJa = (this.translate.currentLang === 'ja');
        this.isZh = (this.translate.currentLang === 'zh-tw');
        var obs = this.translate.onLangChange.subscribe(function (d) {
            _this.isJa = (d.lang === 'ja');
            _this.isZh = (d.lang === 'zh-tw');
            _this.disabled = !_this.personValidationService.isValid(_this.customer, _this.isJa, _this.isJp);
            if (_this.isTest2) {
                _this.sendPersonalInfo.next({
                    type: 'validation',
                    invalaid: _this.disabled
                });
            }
        });
        this.observers.push(obs);
        var restaurant = this.restaurantService.get().restaurant;
        this.isJp = restaurant.restaurant_country === 'jp';
        this.restaurantCountry = restaurant.restaurant_country;
        this.isGlobal = !/jp/.test(restaurant.restaurant_country);
        var ci;
        var rf = this.reserveForm.reserveFormCollection$.subscribe(function (d) {
            rf.unsubscribe();
            console.log(d);
            console.log(restaurant);
            var data = d;
            var courseRequired = restaurant.web_reservation_config.course_required;
            var aSeats = d.seats_adults || d.seats;
            if (data.ref) {
                _this.fromRakuten = data.ref.contact_from === 'rakuten';
                console.log(_this.fromRakuten);
                if (_this.storeEnabled) {
                    var customerInfo = localStorage.getItem('customer_info');
                    if (customerInfo && !_this.fromRakuten) {
                        console.log(_this.fromRakuten);
                        ci = JSON.parse(customerInfo);
                        ci.save_browser = true;
                        _this.reserveForm.set('customer', ci, 'temporary_reservation_customer_information');
                        _this.setCustomerInfo(ci, restaurant);
                    }
                }
            }
            if (d.seats &&
                d.start_at &&
                ((courseRequired && d.accept_courses) ? d.courses && d.courses.length > 0 && d.courses.filter(function (c) { return !!c.course_id; }).length > 0 && d.courses.filter(function (c) { return !!c.count; }).length > 0 && d.courses.map(function (c) { return c.count; }).reduce(function (p, c) { return +p + +c; }) >= aSeats : true)) {
                _this.goDate = data.go_date;
                _this.timeLabel = d.time_label;
                _this.start_at = d.start_at;
                _this.end_at = d.end_at;
                _this.start_time = d.start_time;
                _this.end_time = d.end_time;
                _this.timezone_offset = d.timezone_offset;
                _this.seats = data.seats;
                _this.adultsSeats = data.seats_adults || 0;
                _this.childrenSeats = data.seats_children || 0;
                _this.accept_courses = data.accept_courses || false;
                _this.selected_table_category = data.table_category || '';
                _this.selected_table_smoking = data.table_smoking || '';
                _this.courses = [];
                console.log(data.courses);
                if (data.courses) {
                    var courses_1 = [];
                    var _c_1 = {};
                    data.courses.forEach(function (c) {
                        if (!_c_1[c.course_id]) {
                            console.log(c);
                            _c_1[c.course_id] = {
                                name: c.name,
                                price: c.price,
                                seats: 0
                            };
                        }
                        _c_1[c.course_id].seats += +c.count;
                    });
                    Object.keys(_c_1).forEach(function (key) {
                        if (_c_1[key].seats > 0) {
                            courses_1.push(_c_1[key]);
                        }
                    });
                    console.log(courses_1);
                    if (courses_1.length) {
                        _this.courses = courses_1;
                    }
                }
                if (d.customer && d.customer.temporary_reservation_customer_information.last_name) {
                    ci = d.customer.temporary_reservation_customer_information;
                }
                _this.setCustomerInfo(ci, restaurant);
                _this.createdCustomer = d.created_customer;
                _this.reserveForm.release();
                _this.restaurantService.release();
                _this.cdRef.detectChanges();
            }
            else if (!_this.isTest2) {
                _this.router.navigate(['/reserve-info']);
            }
        });
        var rsub = this.reserveForm.reserveFormCollection$.subscribe(function (data) {
            if (data.customer && data.customer.temporary_reservation_customer_information) {
                var customer = data.customer.temporary_reservation_customer_information;
                _this.customer = customer;
                _this.disabled = !_this.personValidationService.isValid(customer, _this.isJa, _this.isJp);
                if (_this.isTest2) {
                    _this.sendPersonalInfo.next({
                        type: 'validation',
                        invalaid: _this.disabled
                    });
                }
            }
        });
        this.observers.push(rsub);
        this.key = restaurant.restaurant_key;
        this.accept_children = restaurant.web_reservation_config.accept_children;
        this.reserveForm.read(this.key);
        this.reserveForm.get();
    };
    PersonalInfoComponent.prototype.canDeactivate = function () {
        this.observers.forEach(function (obs) { return obs.unsubscribe(); });
        return true;
    };
    PersonalInfoComponent.prototype.setCustomerInfo = function (ci, restaurant) {
        if (ci) {
            this.lastName = ci.last_name;
            this.firstName = ci.first_name;
            this.lastNameReading = ci.last_name_reading;
            this.firstNameReading = ci.first_name_reading;
            this.phone = ci.phone;
            this.email = ci.email;
            this.phoneCountry = ci.iso2 || restaurant.restaurant_country;
            this.saveBrowser = ci.save_browser;
        }
        else {
            this.phoneCountry = restaurant.restaurant_country;
        }
    };
    PersonalInfoComponent.prototype.setLastName = function (d) {
        this.reserveForm.set('customer', d, 'temporary_reservation_customer_information', 'last_name');
    };
    PersonalInfoComponent.prototype.setFirstName = function (d) {
        this.reserveForm.set('customer', d, 'temporary_reservation_customer_information', 'first_name');
    };
    PersonalInfoComponent.prototype.setLastNameReading = function (d) {
        this.reserveForm.set('customer', d, 'temporary_reservation_customer_information', 'last_name_reading');
    };
    PersonalInfoComponent.prototype.setFirstNameReading = function (d) {
        this.reserveForm.set('customer', d, 'temporary_reservation_customer_information', 'first_name_reading');
    };
    PersonalInfoComponent.prototype.setCountry = function (d) {
        console.log(this.phone);
        if (/^\+/.test(this.phone)) {
            try {
                var code = this.countriesService.getCountry(d).dialCode;
                var c = this.intl.util.parseAndKeepRawInput(this.phone, d);
                var nnumber = c.getNationalNumber();
                this.phone = "+" + code + nnumber;
                this.cdRef.detectChanges();
            }
            catch (e) { }
        }
        this.reserveForm.set('customer', d, 'temporary_reservation_customer_information', 'iso2');
    };
    PersonalInfoComponent.prototype.setPhone = function (d) {
        if (this.isGlobal) {
            try {
                var c = this.intl.util.parseAndKeepRawInput(d, this.phoneCountry);
                var country = this.intl.util.getRegionCodeForNumber(c);
                this.phoneCountry = country.toLocaleLowerCase();
                this.cdRef.detectChanges();
            }
            catch (e) { }
        }
        this.phone = d;
        this.reserveForm.set('customer', d, 'temporary_reservation_customer_information', 'phone');
    };
    PersonalInfoComponent.prototype.setEmail = function (d) {
        this.reserveForm.set('customer', d, 'temporary_reservation_customer_information', 'email');
    };
    PersonalInfoComponent.prototype.changeSaveBrowser = function (d) {
        this.saveBrowser = d;
        this.reserveForm.set('customer', d, 'temporary_reservation_customer_information', 'save_browser');
    };
    PersonalInfoComponent.prototype.checkKana = function (d) {
        var cs = d.value.replace(/[\u30a1-\u30f6]/g, function (match) {
            var chr = match.charCodeAt(0) - 0x60;
            return String.fromCharCode(chr);
        });
        if (d.name === 'lastNameReading') {
            this.lastNameReading = d.value;
            this.cdRef.detectChanges();
            this.lastNameReading = cs;
            this.setLastNameReading(cs);
        }
        if (d.name === 'firstNameReading') {
            this.firstNameReading = d.value;
            this.cdRef.detectChanges();
            this.firstNameReading = cs;
            this.setFirstNameReading(cs);
        }
    };
    PersonalInfoComponent.prototype.onClick = function (d) {
        var _this = this;
        if (this._clicked) {
            return false;
        }
        else {
            this._clicked = true;
        }
        var ctr = this.reserveService.api$.subscribe(function (data) {
            ctr.unsubscribe();
            _this._clicked = false;
            console.log(data);
            _this.reserveForm.set('created_customer', true);
            setTimeout(function () {
                _this.router.navigate(['/reserve-confirm']);
            });
        }, function (err) {
            var e = err.json();
            _this._clicked = false;
            if (e.error_code) {
                _this.errorMessage = 'ERROR.OTHER';
            }
            else {
                _this.errorMessage = 'ERROR.FAIL';
            }
        });
        var rf = this.reserveForm.reserveFormCollection$.subscribe(function (rdata) {
            console.log(rdata);
            rf.unsubscribe();
            var data = rdata.customer.temporary_reservation_customer_information;
            var customerData = {
                restaurant_id: rdata.restaurant_id,
                temporary_reservation_customer_information: {
                    last_name: data.last_name,
                    first_name: data.first_name,
                    last_name_reading: data.last_name_reading,
                    first_name_reading: data.first_name_reading,
                    phone: data.phone,
                    email: data.email
                }
            };
            if (_this.isGlobal) {
                var iso2 = data.iso2 || _this.restaurantCountry;
                var country = _this.countriesService.getCountry(iso2);
                if (country.dialCode) {
                    customerData.temporary_reservation_customer_information['country_code'] = +country.dialCode;
                }
            }
            if (_this.saveBrowser && _this.storeEnabled) {
                var ci = objectAssign({}, customerData.temporary_reservation_customer_information);
                if (_this.isGlobal || _this.phoneCountry) {
                    ci['iso2'] = data.iso2 || _this.phoneCountry || _this.restaurantCountry;
                }
                localStorage.setItem('customer_info', JSON.stringify(ci));
            }
            else if (_this.storeEnabled) {
                localStorage.removeItem('customer_info');
            }
            if (_this.createdCustomer) {
                _this.reserveService.updateTempReservationCustomer(rdata.payment.reservation_id, customerData);
            }
            else {
                _this.reserveService.createTempReservationCustomer(rdata.payment.reservation_id, customerData);
            }
        });
        this.reserveForm.get();
    };
    return PersonalInfoComponent;
}());
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], PersonalInfoComponent.prototype, "isTest2", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Subject_1.Subject)
], PersonalInfoComponent.prototype, "sendPersonalInfo", void 0);
PersonalInfoComponent = PersonalInfoComponent_1 = __decorate([
    core_1.Component({
        selector: 'trt-personal-info',
        styles: [require('./personal-info.component.css')],
        template: require('./personal-info.component.html'),
        providers: [{ provide: parent_interface_1.Parent, useExisting: core_1.forwardRef(function () { return PersonalInfoComponent_1; }) }]
    }),
    __metadata("design:paramtypes", [reserve_form_service_1.ReserveFormService, router_1.Router, reserve_service_1.ReserveService, restaurant_module_1.RestaurantService, person_validation_service_1.PersonValidationService, core_1.ChangeDetectorRef, core_2.TranslateService, app_model_1.AppType, intl_tel_service_1.IntlTelService, countries_service_1.CountriesService])
], PersonalInfoComponent);
exports.PersonalInfoComponent = PersonalInfoComponent;
var PCPersonalInfoComponent = PCPersonalInfoComponent_1 = (function (_super) {
    __extends(PCPersonalInfoComponent, _super);
    function PCPersonalInfoComponent(reserveForm, router, reserveService, restaurantService, personValidationService, cdRef, translate, appType, intl, countriesService) {
        var _this = _super.call(this, reserveForm, router, reserveService, restaurantService, personValidationService, cdRef, translate, appType, intl, countriesService) || this;
        _this.reserveForm = reserveForm;
        _this.router = router;
        _this.reserveService = reserveService;
        _this.restaurantService = restaurantService;
        _this.personValidationService = personValidationService;
        _this.cdRef = cdRef;
        _this.translate = translate;
        _this.appType = appType;
        _this.intl = intl;
        _this.countriesService = countriesService;
        return _this;
    }
    return PCPersonalInfoComponent;
}(PersonalInfoComponent));
PCPersonalInfoComponent = PCPersonalInfoComponent_1 = __decorate([
    core_1.Component({
        selector: 'trt-personal-info',
        styles: [require('./personal-info.pc.component.css')],
        template: require('./personal-info.pc.component.html'),
        providers: [{ provide: parent_interface_1.Parent, useExisting: core_1.forwardRef(function () { return PCPersonalInfoComponent_1; }) }]
    }),
    __metadata("design:paramtypes", [reserve_form_service_1.ReserveFormService, router_1.Router, reserve_service_1.ReserveService, restaurant_module_1.RestaurantService, person_validation_service_1.PersonValidationService, core_1.ChangeDetectorRef, core_2.TranslateService, app_model_1.AppType, intl_tel_service_1.IntlTelService, countries_service_1.CountriesService])
], PCPersonalInfoComponent);
exports.PCPersonalInfoComponent = PCPersonalInfoComponent;
var PersonalInfoComponent_1, PCPersonalInfoComponent_1;
