"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var WeekDayService = (function () {
    function WeekDayService() {
        this._days = [
            'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'
        ];
    }
    WeekDayService.prototype.get = function (idx) {
        return this._days[idx];
    };
    return WeekDayService;
}());
exports.WeekDayService = WeekDayService;
