"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("core-js/es6/symbol");
require("core-js/es6/object");
require("core-js/es6/function");
require("core-js/es6/parse-int");
require("core-js/es6/parse-float");
require("core-js/es6/number");
require("core-js/es6/math");
require("core-js/es6/string");
require("core-js/es6/date");
require("core-js/es6/array");
require("core-js/es6/regexp");
require("core-js/es6/map");
require("core-js/es6/set");
require("core-js/es6/weak-map");
require("core-js/es6/weak-set");
require("core-js/es6/typed");
require("core-js/es6/reflect");
require("core-js/es7/reflect");
require("zone.js/dist/zone");
require("ts-helpers");
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var bootloader_1 = require("@angularclass/bootloader");
core_1.enableProdMode();
var client_module_ngfactory_1 = require("./modules/client.module.ngfactory");
var client_pc_module_ngfactory_1 = require("./modules/client.pc.module.ngfactory");
exports.platformRef = platform_browser_1.platformBrowser();
exports._ua = function () {
    var u = '';
    u = window.navigator.userAgent.toLowerCase();
    return {
        Tablet: (u.indexOf('windows') !== -1 && u.indexOf('touch') !== -1 && u.indexOf('tablet pc') === -1)
            || u.indexOf('ipad') !== -1
            || (u.indexOf('android') !== -1 && u.indexOf('mobile') === -1)
            || (u.indexOf('firefox') !== -1 && u.indexOf('tablet') !== -1)
            || u.indexOf('kindle') !== -1
            || u.indexOf('silk') !== -1
            || u.indexOf('playbook') !== -1,
        Mobile: (u.indexOf('windows') !== -1 && u.indexOf('phone') !== -1)
            || u.indexOf('iphone') !== -1
            || u.indexOf('ipod') !== -1
            || (u.indexOf('android') !== -1 && u.indexOf('mobile') !== -1)
            || (u.indexOf('firefox') !== -1 && u.indexOf('mobile') !== -1)
            || u.indexOf('blackberry') !== -1
    };
};
function main() {
    var mview = exports._ua().Mobile;
    try {
        mview = !window.parent.document || exports._ua().Mobile;
    }
    catch (e) {
        mview = true;
    }
    if (mview) {
        return exports.platformRef.bootstrapModuleFactory(client_module_ngfactory_1.ClientModuleNgFactory);
    }
    else {
        return exports.platformRef.bootstrapModuleFactory(client_pc_module_ngfactory_1.PCClientModuleNgFactory);
    }
}
exports.main = main;
bootloader_1.bootloader(main);
