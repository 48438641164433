"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var OptinConfirmComponent = (function () {
    function OptinConfirmComponent(translate, _ref) {
        var _this = this;
        this.translate = translate;
        this._ref = _ref;
        this.checkOptin = new core_1.EventEmitter();
        this._trans = translate.onLangChange.subscribe(function (evt) {
            console.log(evt.translations);
            _this.setBusinessCompany();
            try {
                _this._ref.detectChanges();
            }
            catch (e) { }
        });
    }
    OptinConfirmComponent.prototype.ngOnInit = function () {
        this.setBusinessCompany();
    };
    OptinConfirmComponent.prototype.checkOptinHandler = function (event) {
        this.checkOptin.next(event);
    };
    OptinConfirmComponent.prototype.setBusinessCompany = function () {
        var business_company = "RESERVE_CONFIRMATION.BUSINESS_COMPANY_JP";
        var toreta_asia = ["sg", "vn", "th", "my", "id", "ph", "kh", "hk"];
        var toreta_taiwan = ["tw"];
        if (toreta_asia.indexOf(this.country) > -1) {
            business_company = "RESERVE_CONFIRMATION.BUSINESS_COMPANY_SG";
        }
        else if (toreta_taiwan.indexOf(this.country) > -1) {
            business_company = "RESERVE_CONFIRMATION.BUSINESS_COMPANY_TW";
        }
        else {
            business_company = "RESERVE_CONFIRMATION.BUSINESS_COMPANY_JP";
        }
        var translatedMessage = this.translate.instant(business_company);
        this.business_company = translatedMessage;
    };
    OptinConfirmComponent.prototype.ngOnDestroy = function () {
        this._trans.unsubscribe();
    };
    return OptinConfirmComponent;
}());
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], OptinConfirmComponent.prototype, "country", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], OptinConfirmComponent.prototype, "optinStatus", void 0);
__decorate([
    core_1.Output(),
    __metadata("design:type", Object)
], OptinConfirmComponent.prototype, "checkOptin", void 0);
OptinConfirmComponent = __decorate([
    core_1.Component({
        selector: "trt-optin-confirm",
        template: "\n    <div class=\"optinConfirm\">\n      <ty-checkbox\n        tyLabel=\"{{\n          'RESERVE_CONFIRMATION.DM_OPTIN'\n            | translate: { business_company: business_company }\n        }}\"\n        tyName=\"optinConfirm\"\n        (changeData)=\"checkOptinHandler($event)\"\n        [tyData]=\"optinStatus\"\n      ></ty-checkbox>\n    </div>\n  ",
        styles: [require('./optin-confirm.component.css')],
    }),
    __metadata("design:paramtypes", [core_2.TranslateService,
        core_1.ChangeDetectorRef])
], OptinConfirmComponent);
exports.OptinConfirmComponent = OptinConfirmComponent;
