"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var toreta_time_service_1 = require("./toreta-time.service");
var TRTDateService = (function () {
    function TRTDateService(translate, trtTime) {
        this.translate = translate;
        this.trtTime = trtTime;
    }
    TRTDateService.prototype.getDate = function (date, time) {
        var _this = this;
        if (time === void 0) { time = null; }
        this.lang = this.translate.currentLang;
        console.log(date);
        var d = new Date(date);
        if (time) {
            if (time > (24 * 60 * 60)) {
                d = new Date(d.getTime() + (24 * 60 * 60 * 1000));
            }
        }
        var months = [];
        var weeks = [];
        return this.translate.get('MONTHS').forEach(function (v) {
            console.log(v);
            months = v;
            return true;
        }).then(function () {
            console.log(d.getMonth());
            _this.year = '' + d.getFullYear();
            _this.month = months[d.getMonth()];
            console.log(_this.month);
            _this.date = ('0' + d.getDate()).slice(-2);
            _this.day = weeks[d.getDay()];
            console.warn(_this.date);
            return true;
        }).then(function () {
            return _this.translate.get('YEAR_MONTH_DATE', { year: _this.year, month: _this.month, date: _this.date }).forEach(function (v) {
                console.log(v);
                _this.ymd = v;
                return true;
            });
        }).then(function () {
            console.log(time);
            if (time >= -1 && time !== null) {
                return _this.trtTime.transform(time);
            }
            else {
                return null;
            }
        }).then(function (time) {
            if (_this.lang === 'ja') {
                _this.ymd = date;
            }
            return {
                date: _this.ymd,
                time: time
            };
        });
    };
    return TRTDateService;
}());
TRTDateService = __decorate([
    core_1.Injectable(),
    __metadata("design:paramtypes", [core_2.TranslateService, toreta_time_service_1.TRTTimeService])
], TRTDateService);
exports.TRTDateService = TRTDateService;
