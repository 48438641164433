"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var can_activate_guard_service_1 = require("../common/services/can-activate-guard.service");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var reserve_info_component_1 = require("./components/reserve-info.component");
var can_deactivate_guard_service_1 = require("../common/services/can-deactivate-guard.service");
var routes = [
    { path: 'reserve-info',
        component: reserve_info_component_1.ReserveInfoComponent,
        canActivate: [can_activate_guard_service_1.CanActivateGuard],
        canDeactivate: [can_deactivate_guard_service_1.CanDeactivateGuard] }
];
var ReserveInfoRoutingModule = (function () {
    function ReserveInfoRoutingModule() {
    }
    return ReserveInfoRoutingModule;
}());
ReserveInfoRoutingModule = __decorate([
    core_1.NgModule({
        imports: [router_1.RouterModule.forChild(routes)]
    })
], ReserveInfoRoutingModule);
exports.ReserveInfoRoutingModule = ReserveInfoRoutingModule;
var pcroutes = [
    { path: 'reserve-info',
        component: reserve_info_component_1.PCReserveInfoComponent,
        canActivate: [can_activate_guard_service_1.CanActivateGuard],
        canDeactivate: [can_deactivate_guard_service_1.CanDeactivateGuard] }
];
var PCReserveInfoRoutingModule = (function () {
    function PCReserveInfoRoutingModule() {
    }
    return PCReserveInfoRoutingModule;
}());
PCReserveInfoRoutingModule = __decorate([
    core_1.NgModule({
        imports: [router_1.RouterModule.forChild(pcroutes)]
    })
], PCReserveInfoRoutingModule);
exports.PCReserveInfoRoutingModule = PCReserveInfoRoutingModule;
