"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var table_category_service_1 = require("./services/table-category.service");
var dist_1 = require("angulartics2/dist");
var common_1 = require("@angular/common");
var router_1 = require("@angular/router");
var form_module_1 = require("../form/form.module");
var days_service_1 = require("./services/days.service");
var course_service_1 = require("./services/course.service");
var reserve_info_routes_1 = require("./reserve-info.routes");
var common_module_1 = require("../common/common.module");
var core_1 = require("@angular/core");
var reserve_info_component_1 = require("./components/reserve-info.component");
var core_2 = require("@ngx-translate/core");
var table_smoking_service_1 = require("./services/table-smoking.service");
var ReserveInfoModule = (function () {
    function ReserveInfoModule() {
    }
    return ReserveInfoModule;
}());
ReserveInfoModule = __decorate([
    core_1.NgModule({
        imports: [
            form_module_1.TRTFormModule,
            common_1.CommonModule,
            router_1.RouterModule,
            core_2.TranslateModule,
            common_module_1.TRTCommonModule,
            reserve_info_routes_1.ReserveInfoRoutingModule,
            dist_1.Angulartics2Module
        ],
        exports: [reserve_info_component_1.ReserveInfoComponent],
        declarations: [reserve_info_component_1.ReserveInfoComponent],
        providers: [
            days_service_1.DaysService, course_service_1.CourseService, table_category_service_1.TableCategoriesService, table_smoking_service_1.TableSmokingService
        ],
    })
], ReserveInfoModule);
exports.ReserveInfoModule = ReserveInfoModule;
var PCReserveInfoModule = (function () {
    function PCReserveInfoModule() {
    }
    return PCReserveInfoModule;
}());
PCReserveInfoModule = __decorate([
    core_1.NgModule({
        imports: [
            form_module_1.TRTFormModule,
            common_1.CommonModule,
            router_1.RouterModule,
            core_2.TranslateModule,
            common_module_1.TRTCommonModule,
            reserve_info_routes_1.PCReserveInfoRoutingModule,
            dist_1.Angulartics2Module
        ],
        exports: [reserve_info_component_1.PCReserveInfoComponent],
        declarations: [reserve_info_component_1.PCReserveInfoComponent],
        providers: [
            days_service_1.DaysService, course_service_1.CourseService, table_category_service_1.TableCategoriesService, table_smoking_service_1.TableSmokingService
        ],
    })
], PCReserveInfoModule);
exports.PCReserveInfoModule = PCReserveInfoModule;
