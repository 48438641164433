"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var a_pipe_1 = require("./a.pipe");
exports.APipe = a_pipe_1.APipe;
var br_pipe_1 = require("./br.pipe");
exports.BrPipe = br_pipe_1.BrPipe;
var day_pipe_1 = require("./day.pipe");
exports.DayPipe = day_pipe_1.DayPipe;
var time_pipe_1 = require("./time.pipe");
exports.TimePipe = time_pipe_1.TimePipe;
exports.TRT_COMMON_PIPES = [
    a_pipe_1.APipe,
    br_pipe_1.BrPipe,
    day_pipe_1.DayPipe,
    time_pipe_1.TimePipe
];
