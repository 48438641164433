"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./textarea.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./textarea.component");
var i3 = require("@angular/forms");
var i4 = require("../services/form.service");
var styles_TextareaComponent = [i0.styles];
exports.RenderType_TextareaComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_TextareaComponent, data: {} });
function View_TextareaComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 8, 'div', [['class',
                'ty-textarea']], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, [['box', 1]], null, 5, 'textarea', [['type', 'text']], [[8, 'placeholder', 0], [2, 'ng-untouched', null],
            [2, 'ng-touched', null], [2, 'ng-pristine', null], [2, 'ng-dirty',
                null], [2, 'ng-valid', null], [2, 'ng-invalid', null],
            [2, 'ng-pending', null]], [[null, 'ngModelChange'], [null,
                'input'], [null, 'blur'], [null, 'compositionstart'], [null,
                'compositionend']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('input' === en)) {
                var pd_0 = (i1.ɵnov(_v, 3)._handleInput($event.target.value) !== false);
                ad = (pd_0 && ad);
            }
            if (('blur' === en)) {
                var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
                ad = (pd_1 && ad);
            }
            if (('compositionstart' === en)) {
                var pd_2 = (i1.ɵnov(_v, 3)._compositionStart() !== false);
                ad = (pd_2 && ad);
            }
            if (('compositionend' === en)) {
                var pd_3 = (i1.ɵnov(_v, 3)._compositionEnd($event.target.value) !== false);
                ad = (pd_3 && ad);
            }
            if (('ngModelChange' === en)) {
                var pd_4 = ((_co.tyData = $event) !== false);
                ad = (pd_4 && ad);
            }
            if (('ngModelChange' === en)) {
                var pd_5 = (_co.setData(i1.ɵnov(_v, 2).value) !== false);
                ad = (pd_5 && ad);
            }
            return ad;
        }, null, null)), i1.ɵdid(16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) {
            return [p0_0];
        }, [i3.DefaultValueAccessor]), i1.ɵdid(671744, null, 0, i3.NgModel, [[8, null],
            [8, null], [8, null], [2, i3.NG_VALUE_ACCESSOR]], { name: [0, 'name'],
            model: [1, 'model'] }, { update: 'ngModelChange' }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(16384, null, 0, i3.NgControlStatus, [i3.NgControl], null, null), (_l()(), i1.ɵted(null, ['\n    ']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_8 = _co.tyName;
        var currVal_9 = _co.tyData;
        _ck(_v, 5, 0, currVal_8, currVal_9);
    }, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = i1.ɵinlineInterpolate(1, '', _co.tyPlaceholder, '');
        var currVal_1 = i1.ɵnov(_v, 7).ngClassUntouched;
        var currVal_2 = i1.ɵnov(_v, 7).ngClassTouched;
        var currVal_3 = i1.ɵnov(_v, 7).ngClassPristine;
        var currVal_4 = i1.ɵnov(_v, 7).ngClassDirty;
        var currVal_5 = i1.ɵnov(_v, 7).ngClassValid;
        var currVal_6 = i1.ɵnov(_v, 7).ngClassInvalid;
        var currVal_7 = i1.ɵnov(_v, 7).ngClassPending;
        _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7);
    });
}
exports.View_TextareaComponent_0 = View_TextareaComponent_0;
function View_TextareaComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 2, 'ty-textarea', [], null, null, null, View_TextareaComponent_0, exports.RenderType_TextareaComponent)), i1.ɵprd(512, null, i4.FormService, i4.FormService, []), i1.ɵdid(638976, null, 0, i2.TextareaComponent, [i4.FormService], null, null)], function (_ck, _v) {
        _ck(_v, 2, 0);
    }, null);
}
exports.View_TextareaComponent_Host_0 = View_TextareaComponent_Host_0;
exports.TextareaComponentNgFactory = i1.ɵccf('ty-textarea', i2.TextareaComponent, View_TextareaComponent_Host_0, { tyName: 'tyName', tyPlaceholder: 'tyPlaceholder',
    tyData: 'tyData', required: 'required' }, { changeData: 'changeData' }, []);
