"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var params_service_1 = require("../../common/services/params.service");
var common_1 = require("@angular/common");
var restaurant_module_1 = require("../../common/services/restaurant/restaurant.module");
var router_1 = require("@angular/router");
var app_model_1 = require("../../app/models/app.model");
var reserve_form_service_1 = require("../../common/services/reserve-form.service");
var core_1 = require("@angular/core");
var PaymentSelectComponent = (function () {
    function PaymentSelectComponent(reserveFormService, appType, router, restaurantService, location, paramService) {
        this.reserveFormService = reserveFormService;
        this.appType = appType;
        this.router = router;
        this.restaurantService = restaurantService;
        this.location = location;
        this.paramService = paramService;
        this.docomo = '';
        this.deposit_amount = 10;
        this.country = 'jp';
        this.restaurantLang = 'ja';
    }
    PaymentSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.reserveFormService.read(this.restaurantService.get().restaurant.key);
        console.log(this.location.pathname);
        if (+this.paramService.params.failed_reason) {
            if (+this.paramService.params.failed_reason === 7) {
                this.errorMessage = 'ERROR.TIMEOUT';
            }
            else {
                this.errorMessage = 'ERROR.OTHER';
            }
        }
        this.paramService.params.failed_reason = null;
        var restaurant = this.restaurantService.get().restaurant;
        this.country = restaurant.restaurant_country;
        this.restaurantLang = restaurant.restaurant_language;
        var r = this.reserveFormService.reserveFormCollection$.subscribe(function (data) {
            r.unsubscribe();
            _this.deposit_amount = data.deposit_amount;
            var temporaryReservationId = data.payment && data.payment.reservation_id;
            var continueUri = PAGE_URL + "/" + _this.appType.getPublicKey() + "/";
            _this.docomo = D_API_URL + "/openid/docomo/begin?continue_uri=" + encodeURIComponent(continueUri) + "&temporary_reservation_id=" + temporaryReservationId;
        });
        this.reserveFormService.get();
    };
    PaymentSelectComponent.prototype.clickPayment = function () {
        this.router.navigate(['payment']);
    };
    PaymentSelectComponent.prototype.clickDocomo = function (evt) {
        this.reserveFormService.store();
        this.restaurantService.store();
        if (this.docomo) {
            this.location.pushState(null, '', this.location.pathname);
            location.href = this.docomo;
        }
    };
    return PaymentSelectComponent;
}());
PaymentSelectComponent = __decorate([
    core_1.Component({
        selector: 'trt-payment-select',
        template: require('./payment-select.component.html'),
        styles: [require('./payment-select.component.css')]
    }),
    __metadata("design:paramtypes", [reserve_form_service_1.ReserveFormService, app_model_1.AppType, router_1.Router, restaurant_module_1.RestaurantService, common_1.PlatformLocation, params_service_1.ParamsService])
], PaymentSelectComponent);
exports.PaymentSelectComponent = PaymentSelectComponent;
var PCPaymentSelectComponent = (function (_super) {
    __extends(PCPaymentSelectComponent, _super);
    function PCPaymentSelectComponent(reserveFormService, appType, router, restaurantService, location, paramService) {
        var _this = _super.call(this, reserveFormService, appType, router, restaurantService, location, paramService) || this;
        _this.reserveFormService = reserveFormService;
        _this.appType = appType;
        _this.router = router;
        _this.restaurantService = restaurantService;
        _this.location = location;
        _this.paramService = paramService;
        return _this;
    }
    return PCPaymentSelectComponent;
}(PaymentSelectComponent));
PCPaymentSelectComponent = __decorate([
    core_1.Component({
        selector: 'trt-payment-select',
        template: require('./payment-select.pc.component.html'),
        styles: [require('./payment-select.pc.component.css')]
    }),
    __metadata("design:paramtypes", [reserve_form_service_1.ReserveFormService, app_model_1.AppType, router_1.Router, restaurant_module_1.RestaurantService, common_1.PlatformLocation, params_service_1.ParamsService])
], PCPaymentSelectComponent);
exports.PCPaymentSelectComponent = PCPaymentSelectComponent;
