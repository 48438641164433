"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var days_service_1 = require("../../reserve-info/services/days.service");
var dist_1 = require("angulartics2/dist");
var common_module_1 = require("../../common/common.module");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var restaurant_module_1 = require("../../common/services/restaurant/restaurant.module");
var app_model_1 = require("../../app/models/app.model");
var reserve_form_service_1 = require("../../common/services/reserve-form.service");
var reserve_service_1 = require("../../common/services/reserve.service");
var params_service_1 = require("../../common/services/params.service");
var from_service_1 = require("../../common/services/from.service");
var web_hooks_service_1 = require("../../common/services/web-hooks.service");
var HomeComponent = (function () {
    function HomeComponent(router, restaurantService, appType, reserveFormService, cdRef, reserveService, paramsService, elementRef, webhooks, fromService, angulartics2, daysService) {
        this.router = router;
        this.restaurantService = restaurantService;
        this.appType = appType;
        this.reserveFormService = reserveFormService;
        this.cdRef = cdRef;
        this.reserveService = reserveService;
        this.paramsService = paramsService;
        this.elementRef = elementRef;
        this.webhooks = webhooks;
        this.fromService = fromService;
        this.angulartics2 = angulartics2;
        this.daysService = daysService;
        this.conf = false;
        this.ipadPreview = false;
        this.previewBackgroundImage = null;
        this.bgHeight = '145px';
        this.initMessageHeight = 0;
        this.fromRakuten = false;
        this.init = false;
        this.isTest = false;
        this.isTest2 = false;
        this.errorMessage = '';
        this.paramsService.searchParams(location.search);
        this.paramsService.searchParams(location.hash);
        this.key = this.appType.getPublicKey();
        this.bgHeight = (document.body.clientWidth * 320 / 720) + 'px';
        this.reserveFormService.release();
        this.restaurantService.release();
        this.fromService.init(this.key);
        if (this.paramsService.params.contact_from) {
            this.fromService.add('contact_from', this.paramsService.params.contact_from);
            this.fromService.remove('contact_from_key');
        }
        else {
            if (this.fromService.get('contact_from')) {
                this.paramsService.params.contact_from = this.fromService.get('contact_from');
            }
        }
        if (this.paramsService.params.contact_from_key) {
            this.fromService.add('contact_from_key', this.paramsService.params.contact_from_key);
        }
        else {
            if (this.fromService.get('contact_from_key')) {
                this.paramsService.params.contact_from_key = this.fromService.get('contact_from_key');
            }
        }
        this.fromRakuten = this.paramsService.params.contact_from === 'rakuten';
        try {
            this.referrer = document.referrer;
            if (/^https:\/\/www\.google\.co(m|\.[a-z]{2})/.test(this.referrer)) {
                this.paramsService.params.contact_from = this.paramsService.params.contact_from || 'google';
                console.log(this.paramsService.params.contact_from);
                this.fromService.add('contact_from', this.paramsService.params.contact_from);
            }
        }
        catch (e) { }
    }
    HomeComponent.prototype.resizeBg = function (evt) {
        this.bgHeight = (document.body.clientWidth * 320 / 720) + 'px';
        this.cdRef.detectChanges();
    };
    HomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.init = false;
        this.conf = false;
        var restaurant;
        console.log(common_module_1.isPc);
        console.log(this.key);
        console.log(this.init);
        this.restaurant = restaurant = this.restaurantService.get().restaurant;
        this.name = restaurant.restaurant_name || '';
        this.address = restaurant.restaurant_address || '';
        this.tel = restaurant.restaurant_tel || '';
        this.url = restaurant.restaurant_url || '';
        console.log(this.restaurant);
        if (this.appType.useLocalStorage() && !!sessionStorage && !!sessionStorage.getItem('t')) {
            this.changeBgImage();
        }
        this.backgroundUrl = this.backgroundUrl || "url('" + restaurant.background_image_url + "')";
        this.headerMessage = restaurant.web_reservation_config.header_message || '';
        this.key = restaurant.restaurant_key;
        if (this.appType.isBrowser() && !this.headerMessage) {
            this.init = true;
        }
        var rf = this.reserveFormService.reserveFormCollection$.subscribe(function (data) {
            rf.unsubscribe();
            if (data.ref && data.ref.contact_from) {
                _this.paramsService.params.contact_from = data.ref.contact_from;
            }
            if (data.ref && data.ref.referrer) {
                _this.referrer = data.ref.referrer;
            }
            if (data.ref && data.ref.contact_from_key) {
                _this.paramsService.params.contact_from_key = data.ref.contact_from;
            }
            if (data.payment && data.payment.reservation_id) {
                var rs_1 = _this.reserveService.api$.subscribe(function () {
                    rs_1.unsubscribe();
                });
                _this.reserveService.deleteTempReservationInfo(data.payment.reservation_id);
            }
            _this.reserveFormService.clear();
            _this.cdRef.detectChanges();
        });
        this.reserveFormService.get();
        this.webhooks.init();
        if (this.paramsService.params.test === 'b' || window['variation'] === 1) {
            this.elementRef.nativeElement.classList.add('test');
            this.isTest = true;
            this.setFormData();
        }
        if (this.paramsService.params.test === 'c' || window['variation2'] === 1) {
            this.elementRef.nativeElement.classList.add('test');
            this.isTest = true;
            this.isTest2 = (this.fromRakuten) ? false : true;
            this.setFormData();
        }
    };
    HomeComponent.prototype.ngAfterViewChecked = function () {
        if (this.appType.isBrowser() && this.initMessageHeight === 0) {
            this.initMessageHeight = this.elementRef.nativeElement.querySelector('.headerMessage').offsetHeight;
            if (this.initMessageHeight > 0) {
                this.init = true;
                this.cdRef.detectChanges();
            }
        }
        if (this.appType.useLocalStorage() && !!sessionStorage && !!sessionStorage.getItem('t')) {
            this.changeBgImage();
        }
    };
    HomeComponent.prototype.changeBgImage = function () {
        this.backgroundUrl = sessionStorage.getItem('t');
        this.ipadPreview = true;
        sessionStorage.removeItem('t');
    };
    HomeComponent.prototype.showConf = function () {
        this.messageHeight = this.initMessageHeight + "px";
        this.conf = true;
        this.angulartics2.eventTrack.next({ action: 'click', properties: { category: 'home', label: 'Note' } });
    };
    HomeComponent.prototype.onClick = function (evt) {
        this.move();
    };
    HomeComponent.prototype.move = function () {
        var _this = this;
        var rf = this.reserveFormService.reserveFormCollection$.subscribe(function (data) {
            console.log(data);
            rf.unsubscribe();
            _this.router.navigate(['/reserve-info']);
        });
        this.setFormData();
    };
    HomeComponent.prototype.setFormData = function () {
        this.appType.started = true;
        this.reserveFormService.set('restaurant_id', this.restaurant.key);
        if (this.restaurant.web_reservation_config.accept_children) {
            this.reserveFormService.set('seats_adults', this.paramsService.params.adults_seats);
            this.reserveFormService.set('seats_children', this.paramsService.params.children_seats);
            this.reserveFormService.set('seats', this.paramsService.params.seats);
        }
        else {
            this.reserveFormService.set('seats', this.paramsService.params.seats);
        }
        this.reserveFormService.set('go_date', this.paramsService.params.date);
        this.reserveFormService.set('start_time', this.paramsService.params.start_time);
        console.log(!!this.paramsService.params.date && !this.paramsService.params.seats);
        if (!!this.paramsService.params.date && !this.paramsService.params.seats) {
            console.log(this.restaurant);
            if (this.restaurant.web_reservation_config.accept_children) {
                this.reserveFormService.set('seats_adults', +this.restaurant.restaurant_min_seats);
                this.reserveFormService.set('seats', +this.restaurant.restaurant_min_seats);
            }
            else {
                this.reserveFormService.set('seats', +this.restaurant.restaurant_min_seats);
            }
        }
        this.reserveFormService.set('customer', decodeURIComponent(this.paramsService.params.last_name || ''), 'temporary_reservation_customer_information', 'last_name');
        this.reserveFormService.set('customer', decodeURIComponent(this.paramsService.params.first_name || ''), 'temporary_reservation_customer_information', 'first_name');
        this.reserveFormService.set('customer', decodeURIComponent(this.paramsService.params.last_name_reading || ''), 'temporary_reservation_customer_information', 'last_name_reading');
        this.reserveFormService.set('customer', decodeURIComponent(this.paramsService.params.first_name_reading || ''), 'temporary_reservation_customer_information', 'first_name_reading');
        this.reserveFormService.set('customer', decodeURIComponent(this.paramsService.params.phone || ''), 'temporary_reservation_customer_information', 'phone');
        this.reserveFormService.set('customer', decodeURIComponent(this.paramsService.params.email || ''), 'temporary_reservation_customer_information', 'email');
        this.reserveFormService.set('ref', { contact_from: this.paramsService.params.contact_from, contact_from_key: this.paramsService.params.contact_from_key, referrer: this.referrer });
        if (this.paramsService.params.addInfo) {
            this.reserveFormService.set('addInfo', decodeURIComponent(this.paramsService.params.addInfo));
        }
    };
    return HomeComponent;
}());
HomeComponent = __decorate([
    core_1.Component({
        selector: 'trt-home',
        styles: [require('./home.component.css')],
        template: require('./home.component.html')
    }),
    __metadata("design:paramtypes", [router_1.Router, restaurant_module_1.RestaurantService, app_model_1.AppType, reserve_form_service_1.ReserveFormService, core_1.ChangeDetectorRef, reserve_service_1.ReserveService, params_service_1.ParamsService, core_1.ElementRef, web_hooks_service_1.WebHooksService, from_service_1.FromService, dist_1.Angulartics2, days_service_1.DaysService])
], HomeComponent);
exports.HomeComponent = HomeComponent;
var PCHomeComponent = (function (_super) {
    __extends(PCHomeComponent, _super);
    function PCHomeComponent(router, restaurantService, appType, reserveFormService, cdRef, reserveService, paramsService, elementRef, webhooks, fromService, angulartics2, daysService) {
        var _this = _super.call(this, router, restaurantService, appType, reserveFormService, cdRef, reserveService, paramsService, elementRef, webhooks, fromService, angulartics2, daysService) || this;
        _this.router = router;
        _this.restaurantService = restaurantService;
        _this.appType = appType;
        _this.reserveFormService = reserveFormService;
        _this.cdRef = cdRef;
        _this.reserveService = reserveService;
        _this.paramsService = paramsService;
        _this.elementRef = elementRef;
        _this.webhooks = webhooks;
        _this.fromService = fromService;
        _this.angulartics2 = angulartics2;
        _this.daysService = daysService;
        return _this;
    }
    return PCHomeComponent;
}(HomeComponent));
PCHomeComponent = __decorate([
    core_1.Component({
        selector: 'trt-home',
        styles: [require('./home.pc.component.css')],
        template: require('./home.pc.component.html')
    }),
    __metadata("design:paramtypes", [router_1.Router, restaurant_module_1.RestaurantService, app_model_1.AppType, reserve_form_service_1.ReserveFormService, core_1.ChangeDetectorRef, reserve_service_1.ReserveService, params_service_1.ParamsService, core_1.ElementRef, web_hooks_service_1.WebHooksService, from_service_1.FromService, dist_1.Angulartics2, days_service_1.DaysService])
], PCHomeComponent);
exports.PCHomeComponent = PCHomeComponent;
