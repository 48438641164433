"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./footer.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("@ngx-translate/core/src/translate.pipe");
var i4 = require("@ngx-translate/core/src/translate.service");
var i5 = require("./footer.component");
var i6 = require("./footer.pc.component.css.shim.ngstyle");
var styles_FooterComponent = [i0.styles];
exports.RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_FooterComponent, data: {} });
function View_FooterComponent_1(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'a', [['target',
                '_blank']], [[8, 'href', 4]], null, null, null, null)),
        (_l()(), i1.ɵted(null, ['特定商取引法']))], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = i1.ɵinlineInterpolate(1, '/tokushoho.html?id=', _co.inquiryId, '');
        _ck(_v, 0, 0, currVal_0);
    });
}
function View_FooterComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 15, 'footer', [], null, null, null, null, null)), i1.ɵdid(278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef,
            i1.Renderer], { ngClass: [0, 'ngClass'] }, null), i1.ɵpod(['asc']), (_l()(),
            i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵeld(0, null, null, 2, 'a', [['target', '_blank']], [[1, 'href', 4]], null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i3.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(0, null, null, 2, 'a', [['target', '_blank']], [[1, 'href', 4]], null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i3.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_1)), i1.ɵdid(16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null), (_l()(), i1.ɵeld(0, null, null, 2, 'a', [['target',
                '_blank']], [[1, 'href', 4]], null, null, null, null)),
        (_l()(), i1.ɵted(null, ['powered by '])), (_l()(), i1.ɵeld(0, null, null, 0, 'span', [['class', 'icon-logo']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _ck(_v, 2, 0, _co.isPay);
        _ck(_v, 1, 0, currVal_0);
        var currVal_5 = _co.isPay;
        _ck(_v, 11, 0, currVal_5);
    }, function (_ck, _v) {
        var _co = _v.component;
        var currVal_1 = _co.privacyUrl;
        _ck(_v, 4, 0, currVal_1);
        var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform('PRIVACY_POLICY'));
        _ck(_v, 5, 0, currVal_2);
        var currVal_3 = _co.termsUrl;
        _ck(_v, 7, 0, currVal_3);
        var currVal_4 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform('TERMS_OF_SERVICE'));
        _ck(_v, 8, 0, currVal_4);
        var currVal_6 = _co.siteUrl;
        _ck(_v, 12, 0, currVal_6);
    });
}
exports.View_FooterComponent_0 = View_FooterComponent_0;
function View_FooterComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'trt-footer', [], null, null, null, View_FooterComponent_0, exports.RenderType_FooterComponent)),
        i1.ɵdid(114688, null, 0, i5.FooterComponent, [i4.TranslateService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) {
        _ck(_v, 1, 0);
    }, null);
}
exports.View_FooterComponent_Host_0 = View_FooterComponent_Host_0;
exports.FooterComponentNgFactory = i1.ɵccf('trt-footer', i5.FooterComponent, View_FooterComponent_Host_0, { country: 'country', isPay: 'isPay',
    inquiryId: 'inquiryId' }, {}, []);
var styles_PCFooterComponent = [i6.styles];
exports.RenderType_PCFooterComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_PCFooterComponent, data: {} });
function View_PCFooterComponent_1(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'a', [['target',
                '_blank']], [[8, 'href', 4]], null, null, null, null)),
        (_l()(), i1.ɵted(null, ['特定商取引法']))], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = i1.ɵinlineInterpolate(1, '/tokushoho.html?id=', _co.inquiryId, '');
        _ck(_v, 0, 0, currVal_0);
    });
}
function View_PCFooterComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 15, 'footer', [], null, null, null, null, null)), i1.ɵdid(278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef,
            i1.Renderer], { ngClass: [0, 'ngClass'] }, null), i1.ɵpod(['asc']), (_l()(),
            i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵeld(0, null, null, 2, 'a', [['target', '_blank']], [[1, 'href', 4]], null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i3.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(0, null, null, 2, 'a', [['target', '_blank']], [[1, 'href', 4]], null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i3.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PCFooterComponent_1)), i1.ɵdid(16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null), (_l()(), i1.ɵeld(0, null, null, 2, 'a', [['target',
                '_blank']], [[1, 'href', 4]], null, null, null, null)),
        (_l()(), i1.ɵted(null, ['powered by '])), (_l()(), i1.ɵeld(0, null, null, 0, 'span', [['class', 'icon-logo']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _ck(_v, 2, 0, _co.isPay);
        _ck(_v, 1, 0, currVal_0);
        var currVal_5 = _co.isPay;
        _ck(_v, 11, 0, currVal_5);
    }, function (_ck, _v) {
        var _co = _v.component;
        var currVal_1 = _co.privacyUrl;
        _ck(_v, 4, 0, currVal_1);
        var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform('PRIVACY_POLICY'));
        _ck(_v, 5, 0, currVal_2);
        var currVal_3 = _co.termsUrl;
        _ck(_v, 7, 0, currVal_3);
        var currVal_4 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform('TERMS_OF_SERVICE'));
        _ck(_v, 8, 0, currVal_4);
        var currVal_6 = _co.siteUrl;
        _ck(_v, 12, 0, currVal_6);
    });
}
exports.View_PCFooterComponent_0 = View_PCFooterComponent_0;
function View_PCFooterComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'trt-footer', [], null, null, null, View_PCFooterComponent_0, exports.RenderType_PCFooterComponent)),
        i1.ɵdid(114688, null, 0, i5.PCFooterComponent, [i4.TranslateService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) {
        _ck(_v, 1, 0);
    }, null);
}
exports.View_PCFooterComponent_Host_0 = View_PCFooterComponent_Host_0;
exports.PCFooterComponentNgFactory = i1.ɵccf('trt-footer', i5.PCFooterComponent, View_PCFooterComponent_Host_0, { country: 'country', isPay: 'isPay',
    inquiryId: 'inquiryId' }, {}, []);
