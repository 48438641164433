"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
var dist_1 = require("angulartics2/dist");
var parent_interface_1 = require("../../common/interfaces/parent.interface");
var core_1 = require("@angular/core");
var Observable_1 = require("rxjs/Observable");
var form_service_1 = require("../services/form.service");
var input_component_1 = require("./input.component");
var InputTextComponent = (function (_super) {
    __extends(InputTextComponent, _super);
    function InputTextComponent(_formService, _cdRef, parent, angulartics2) {
        var _this = _super.call(this, _formService) || this;
        _this._formService = _formService;
        _this._cdRef = _cdRef;
        _this.parent = parent;
        _this.angulartics2 = angulartics2;
        _this.type = 'text';
        _this.changeData = new core_1.EventEmitter();
        _this.tyBlur = new core_1.EventEmitter();
        _this.disabled = false;
        return _this;
    }
    InputTextComponent.prototype.onBlur = function (v) {
        this.tyBlur.next({
            name: this.tyName,
            value: v
        });
    };
    InputTextComponent.prototype.onFocus = function () {
        this.angulartics2.eventTrack.next({ action: this.parent.name, properties: { category: 'input', label: this.tyName } });
    };
    InputTextComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (!this._obs) {
            if (this.tyChange) {
                this._obs = this.tyChange.subscribe(function (d) {
                    _this.setData(d);
                });
            }
        }
        for (var propName in changes) {
            var prop = changes[propName];
            var cur = prop.currentValue;
            var prev = JSON.stringify(prop.previousValue);
            if (propName === 'tyData' && cur && (prev !== '{}')) {
                console.log(cur);
                this.setData(cur);
            }
        }
    };
    return InputTextComponent;
}(input_component_1.InputComponent));
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], InputTextComponent.prototype, "tyName", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], InputTextComponent.prototype, "tyPlaceholder", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], InputTextComponent.prototype, "tyData", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Number)
], InputTextComponent.prototype, "tyMaxlength", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Observable_1.Observable)
], InputTextComponent.prototype, "tyChange", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], InputTextComponent.prototype, "type", void 0);
__decorate([
    core_1.Output(),
    __metadata("design:type", core_1.EventEmitter)
], InputTextComponent.prototype, "changeData", void 0);
__decorate([
    core_1.Output(),
    __metadata("design:type", core_1.EventEmitter)
], InputTextComponent.prototype, "tyBlur", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], InputTextComponent.prototype, "disabled", void 0);
InputTextComponent = __decorate([
    core_1.Component({
        selector: 'ty-input-text',
        template: "<div class=\"ty-input\" [ngClass]=\"{disabled: disabled}\">\n    <ty-input-placeholder  [disabled]=\"disabled\"  [labelTitle]=\"tyPlaceholder\" [tyInputName]=\"tyName\" [tyInputData]=\"tyData\" (click)=\"box.focus()\"></ty-input-placeholder>\n    <input #box [type]=\"type\" [name]=\"tyName\" [(ngModel)]=\"tyData\" (ngModelChange)=\"setData(box.value)\" [ngClass]=\"{ondata: (!!tyData), 'no-placeholder': !!!tyPlaceholder}\" [attr.maxlength]=\"tyMaxlength\" (blur)=\"onBlur(box.value)\" (focus)=\"onFocus()\">\n    </div>",
        styles: [require('./input.component.css')],
        providers: [form_service_1.FormService],
    }),
    __param(2, core_1.Optional()),
    __metadata("design:paramtypes", [form_service_1.FormService, core_1.ChangeDetectorRef, parent_interface_1.Parent, dist_1.Angulartics2])
], InputTextComponent);
exports.InputTextComponent = InputTextComponent;
