"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var form_module_1 = require("../form/form.module");
var common_1 = require("@angular/common");
var finish_component_1 = require("./components/finish.component");
var common_module_1 = require("../common/common.module");
var core_1 = require("@angular/core");
var finish_routes_1 = require("./finish.routes");
var core_2 = require("@ngx-translate/core");
var FinishModule = (function () {
    function FinishModule() {
    }
    return FinishModule;
}());
FinishModule = __decorate([
    core_1.NgModule({
        imports: [
            core_2.TranslateModule,
            common_1.CommonModule,
            form_module_1.TRTFormModule,
            common_module_1.TRTCommonModule,
            finish_routes_1.FinishRoutingModule
        ],
        exports: [finish_component_1.FinishComponent],
        declarations: [finish_component_1.FinishComponent],
        providers: [],
    })
], FinishModule);
exports.FinishModule = FinishModule;
var PCFinishModule = (function () {
    function PCFinishModule() {
    }
    return PCFinishModule;
}());
PCFinishModule = __decorate([
    core_1.NgModule({
        imports: [
            core_2.TranslateModule,
            common_1.CommonModule,
            form_module_1.TRTFormModule,
            common_module_1.TRTCommonModule,
            finish_routes_1.PCFinishRoutingModule
        ],
        exports: [finish_component_1.PCFinishComponent],
        declarations: [finish_component_1.PCFinishComponent],
        providers: [],
    })
], PCFinishModule);
exports.PCFinishModule = PCFinishModule;
