"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var timers_1 = require("timers");
var core_1 = require("@angular/core");
var InputComponent = (function () {
    function InputComponent(_formService) {
        var _this = this;
        this._formService = _formService;
        this.changeData = new core_1.EventEmitter();
        this._formService.collection$.subscribe(function (collection) {
            var col = collection.get(_this.tyName);
            if (col) {
                _this.tyData = col.get();
            }
        });
        this._formService.changeCollection$.subscribe(function (collection) {
            var col = collection.get(_this.tyName);
            if (col) {
                _this.tyData = col.get();
                console.log(collection);
                console.log(_this.tyData);
                timers_1.setTimeout(function (d) {
                    console.log(_this.tyData);
                    _this.changeData.next(_this.tyData);
                }, 0);
            }
        });
    }
    InputComponent.prototype.ngOnInit = function () {
        console.log(this.tyName);
        this._formService.add(this.tyName, this.tyData);
        this._formService.load();
    };
    InputComponent.prototype.setData = function (v) {
        console.log(this.tyName);
        this._formService.set(this.tyName, v);
    };
    return InputComponent;
}());
__decorate([
    core_1.Output(),
    __metadata("design:type", core_1.EventEmitter)
], InputComponent.prototype, "changeData", void 0);
exports.InputComponent = InputComponent;
