"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var home_component_1 = require("./components/home.component");
var restaurant_resolver_1 = require("../common/services/restaurant.resolver");
var routes = [
    { path: '', component: home_component_1.HomeComponent,
        resolve: {
            restaurant: restaurant_resolver_1.RestaurantResolver
        }
    }
];
var HomeRoutingModule = (function () {
    function HomeRoutingModule() {
    }
    return HomeRoutingModule;
}());
HomeRoutingModule = __decorate([
    core_1.NgModule({
        imports: [router_1.RouterModule.forChild(routes)]
    })
], HomeRoutingModule);
exports.HomeRoutingModule = HomeRoutingModule;
var pcroutes = [
    { path: '', component: home_component_1.PCHomeComponent,
        resolve: {
            restaurant: restaurant_resolver_1.RestaurantResolver
        }
    }
];
var PCHomeRoutingModule = (function () {
    function PCHomeRoutingModule() {
    }
    return PCHomeRoutingModule;
}());
PCHomeRoutingModule = __decorate([
    core_1.NgModule({
        imports: [router_1.RouterModule.forChild(pcroutes)]
    })
], PCHomeRoutingModule);
exports.PCHomeRoutingModule = PCHomeRoutingModule;
