"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var input_placeholder_component_1 = require("./input-placeholder.component");
exports.InputPlaceholderComponent = input_placeholder_component_1.InputPlaceholderComponent;
var input_text_component_1 = require("./input-text.component");
exports.InputTextComponent = input_text_component_1.InputTextComponent;
var input_tel_component_1 = require("./input-tel.component");
exports.InputTelComponent = input_tel_component_1.InputTelComponent;
var select_component_1 = require("./select.component");
exports.SelectComponent = select_component_1.SelectComponent;
var checkbox_component_1 = require("./checkbox.component");
exports.CheckboxComponent = checkbox_component_1.CheckboxComponent;
var text_component_1 = require("./text.component");
exports.TextComponent = text_component_1.TextComponent;
var button_component_1 = require("./button.component");
exports.ButtonComponent = button_component_1.ButtonComponent;
var toggle_component_1 = require("./toggle.component");
exports.ToggleComponent = toggle_component_1.ToggleComponent;
var textarea_component_1 = require("./textarea.component");
exports.TextareaComponent = textarea_component_1.TextareaComponent;
var input_time_component_1 = require("./input-time.component");
exports.InputTimeComponent = input_time_component_1.InputTimeComponent;
var input_country_component_1 = require("./input-country.component");
exports.InputCountryComponent = input_country_component_1.InputCountryComponent;
