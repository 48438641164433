"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var angulartics2_1 = require("angulartics2");
var core_1 = require("@angular/core");
var form_service_1 = require("../services/form.service");
var input_component_1 = require("./input.component");
var SelectComponent = (function (_super) {
    __extends(SelectComponent, _super);
    function SelectComponent(_formService, elRef, cdRef, angulartics2) {
        var _this = _super.call(this, _formService) || this;
        _this._formService = _formService;
        _this.elRef = elRef;
        _this.cdRef = cdRef;
        _this.angulartics2 = angulartics2;
        _this.tyPlaceholder = '';
        _this.tyData = '';
        _this.tyDefaultData = '';
        _this.disabled = false;
        _this.open = false;
        _this.optg = false;
        _this.top = false;
        _this.bottom = false;
        _this.left = false;
        _this.right = false;
        _this.partyPrice = false;
        _this.onName = false;
        _this.eventLabel = '';
        _this.changeData = new core_1.EventEmitter(true);
        _this.label = '';
        _this.label = _this.tyData;
        console.log(_this.tyData);
        return _this;
    }
    SelectComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        var opt = this.tyOptions.filter(function (o) {
            return '' + o['value'] === '' + _this.tyData;
        });
        if (opt.length > 0) {
            this.label = !!opt[0]['value'] ? '' + opt[0]['label'] : '';
            this.name = '' + opt[0]['name'];
            this.price = this.price * 1.0;
            console.log(!!this.label);
            console.log(this.price === 0);
        }
        var _loop_1 = function (propName) {
            var prop = changes[propName];
            var cur = prop.currentValue ? '' + prop.currentValue : prop.currentValue;
            var prev = prop.previousValue ? '' + prop.previousValue : prop.previousValue;
            if (propName === 'tyData' && (cur !== undefined && cur !== null) && (JSON.stringify(prev) !== '{}')) {
                var option = this_1.tyOptions.filter(function (o) {
                    return '' + o['value'] === '' + cur;
                });
                console.log(prop.previousValue, typeof prop.previousValue);
                console.log(cur, prev);
                if (((Boolean(prop.currentValue) || Boolean(prop.previousValue)) || cur === 0) && cur != prev) {
                    console.log(cur, prev);
                    this_1.changeSelect(option[0]);
                }
                else {
                    console.log(cur);
                }
                this_1.cdRef.detectChanges();
            }
        };
        var this_1 = this;
        for (var propName in changes) {
            _loop_1(propName);
        }
    };
    SelectComponent.prototype.changeSelect = function (d, fromTemplate) {
        console.log(d);
        if (this.eventLabel && fromTemplate) {
            this.angulartics2.eventTrack.next({ action: 'click', properties: { catrgory: 'search', label: this.eventLabel } });
        }
        if (d) {
            this.label = d.value ? d.label : '';
            this.name = d.name;
            this.setData(d.value);
        }
        else {
            this.label = '';
            this.name = '';
            this.setData('');
        }
    };
    return SelectComponent;
}(input_component_1.InputComponent));
__decorate([
    core_1.Input(),
    __metadata("design:type", Array)
], SelectComponent.prototype, "tyOptions", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], SelectComponent.prototype, "tyName", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], SelectComponent.prototype, "tyPlaceholder", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], SelectComponent.prototype, "tyData", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], SelectComponent.prototype, "tyDefaultData", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], SelectComponent.prototype, "disabled", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], SelectComponent.prototype, "open", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], SelectComponent.prototype, "optg", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], SelectComponent.prototype, "top", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], SelectComponent.prototype, "bottom", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], SelectComponent.prototype, "left", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], SelectComponent.prototype, "right", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Number)
], SelectComponent.prototype, "price", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], SelectComponent.prototype, "formatedPrice", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], SelectComponent.prototype, "partyPrice", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], SelectComponent.prototype, "onName", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], SelectComponent.prototype, "eventLabel", void 0);
__decorate([
    core_1.Output(),
    __metadata("design:type", core_1.EventEmitter)
], SelectComponent.prototype, "changeData", void 0);
SelectComponent = __decorate([
    core_1.Component({
        selector: 'ty-select',
        template: "<div class=\"ty-select open\" [ngClass]=\"{disabled: disabled, open: open, top: top, bottom: bottom, left: left, right: right, partyPrice: partyPrice}\">\n    <ty-input-placeholder  [disabled]=\"disabled\" [labelTitle]=\"tyPlaceholder\" [tyInputName]=\"tyName\" [tyInputData]=\"tyData\" (click)=\"box.click()\"></ty-input-placeholder>\n    <p *ngIf=\"!partyPrice && !onName\"><span class=\"label\">{{ label }}</span></p>\n    <p *ngIf=\"onName\"><span class=\"name\">{{ name }}</span></p>\n    <p *ngIf=\"partyPrice && (price === undefined || label === '')\"><span class=\"partyPrice\"></span></p>\n    <p *ngIf=\"partyPrice && price > 0 && !!label\"><span class=\"partyPrice\">{{ 'RESERVE_INFO.IN_TAX' | translate:{price:formatedPrice, seats:label} }}</span></p>\n    <p *ngIf=\"partyPrice && price === 0 && !!label\"><span class=\"partyPrice\">{{ 'RESERVE_INFO.FREE' | translate:{seats:label} }}</span></p>\n    <select #box [name]=\"tyName\" [value]=\"tyData\" (change)=\"changeSelect(box.querySelector('option:checked'), true)\" [ngClass]=\"{ondata: !!tyData}\" [disabled]=\"disabled\">\n      <option *ngFor=\"let opt of tyOptions; let idx=index\" [selected]=\"opt.value == tyData\" [attr.value]=\"opt['value']\" [attr.label]=\"opt['label']\">{{opt['label']}}</option>\n      <optgroup *ngIf=\"optg\" label=\"\"></optgroup>\n    </select>\n  </div>",
        styles: [require('./select.component.css')],
        providers: [form_service_1.FormService],
    }),
    __metadata("design:paramtypes", [form_service_1.FormService, core_1.ElementRef, core_1.ChangeDetectorRef, angulartics2_1.Angulartics2])
], SelectComponent);
exports.SelectComponent = SelectComponent;
