"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var LineButtonComponent = (function () {
    function LineButtonComponent(elRef, renderer) {
        this.elRef = elRef;
        this.renderer = renderer;
    }
    LineButtonComponent.prototype.ngOnChanges = function () {
        var msg = encodeURIComponent(this.msg);
        this.url = "http://line.me/R/msg/text/?" + msg;
        var $a = this.elRef.nativeElement.querySelector('a');
        if ($a) {
            this.renderer.setElementStyle(this.elRef.nativeElement.querySelector('a'), 'backgroundImage', "url('" + S3_URL + "/dist/client/assets/img/linebutton.png')");
        }
    };
    return LineButtonComponent;
}());
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], LineButtonComponent.prototype, "msg", void 0);
LineButtonComponent = __decorate([
    core_1.Component({
        selector: 'trt-linebutton',
        styles: [require('./linebutton.component.css')],
        template: "<a [attr.href]=\"url\"><p>{{ 'FINISH.SHARE_LINE' | translate }}</p></a>"
    }),
    __metadata("design:paramtypes", [core_1.ElementRef, core_1.Renderer])
], LineButtonComponent);
exports.LineButtonComponent = LineButtonComponent;
