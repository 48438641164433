"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./text.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./input-placeholder.component.ngfactory");
var i4 = require("./input-placeholder.component");
var i5 = require("../services/form.service");
var i6 = require("./text.component");
var i7 = require("angulartics2/dist/core/angulartics2");
var styles_TextComponent = [i0.styles];
exports.RenderType_TextComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_TextComponent, data: {} });
function View_TextComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 16, 'div', [['class',
                'ty-text']], null, null, null, null, null)),
        i1.ɵdid(278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers,
            i1.ElementRef, i1.Renderer], { klass: [0, 'klass'], ngClass: [1, 'ngClass'] }, null),
        i1.ɵpod(['disabled']), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 1, 'ty-input-placeholder', [], null, null, null, i3.View_InputPlaceholderComponent_0, i3.RenderType_InputPlaceholderComponent)),
        i1.ɵdid(573440, null, 0, i4.InputPlaceholderComponent, [i5.FormService,
            i1.ElementRef], { labelTitle: [0, 'labelTitle'], tyInputName: [1, 'tyInputName'],
            tyInputData: [2, 'tyInputData'], disabled: [3, 'disabled'] }, null), (_l()(),
            i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, [['input', 1]], null, 0, 'input', [['type', 'text']], [[8, 'disabled', 0]], [[null, 'focus']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('focus' === en)) {
                i1.ɵnov(_v, 7).blur();
                var pd_0 = (_co.onClick($event) !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), (_l()(), i1.ɵted(null, ['\n    '])),
        (_l()(), i1.ɵeld(0, null, null, 6, 'p', [], null, [[null, 'click']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('click' === en)) {
                var pd_0 = (_co.onClick($event) !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), i1.ɵdid(278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer], { ngClass: [0,
                'ngClass'] }, null), i1.ɵpod(['no-placeholder', 'open', 'date', 'no-background']),
        (_l()(), i1.ɵeld(0, null, null, 3, 'span', [], null, null, null, null, null)), i1.ɵdid(278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef,
            i1.Renderer], { ngClass: [0, 'ngClass'] }, null), i1.ɵpod(['mark']),
        (_l()(), i1.ɵted(null, ['', ''])), (_l()(), i1.ɵted(null, ['\n    ']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = 'ty-text';
        var currVal_1 = _ck(_v, 2, 0, _co.disabled);
        _ck(_v, 1, 0, currVal_0, currVal_1);
        var currVal_2 = _co.tyPlaceholder;
        var currVal_3 = _co.tyName;
        var currVal_4 = _co.tyData;
        var currVal_5 = _co.disabled;
        _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5);
        var currVal_7 = _ck(_v, 11, 0, !!!_co.tyPlaceholder, _co.open, _co.date, _co.noBackground);
        _ck(_v, 10, 0, currVal_7);
        var currVal_8 = _ck(_v, 14, 0, _co.isMark);
        _ck(_v, 13, 0, currVal_8);
    }, function (_ck, _v) {
        var _co = _v.component;
        var currVal_6 = _co.disabled;
        _ck(_v, 7, 0, currVal_6);
        var currVal_9 = _co.tyData;
        _ck(_v, 15, 0, currVal_9);
    });
}
exports.View_TextComponent_0 = View_TextComponent_0;
function View_TextComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 2, 'ty-text', [], null, null, null, View_TextComponent_0, exports.RenderType_TextComponent)),
        i1.ɵprd(512, null, i5.FormService, i5.FormService, []), i1.ɵdid(638976, null, 0, i6.TextComponent, [i5.FormService, i7.Angulartics2], null, null)], function (_ck, _v) {
        _ck(_v, 2, 0);
    }, null);
}
exports.View_TextComponent_Host_0 = View_TextComponent_Host_0;
exports.TextComponentNgFactory = i1.ɵccf('ty-text', i6.TextComponent, View_TextComponent_Host_0, { tyName: 'tyName', tyPlaceholder: 'tyPlaceholder',
    tyData: 'tyData', open: 'open', date: 'date', isMark: 'isMark', disabled: 'disabled',
    noBackground: 'noBackground', eventLabel: 'eventLabel' }, { changeData: 'changeData',
    clickText: 'clickText' }, []);
