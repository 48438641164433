"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./linebutton.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core/src/translate.pipe");
var i3 = require("@ngx-translate/core/src/translate.service");
var i4 = require("./linebutton.component");
var styles_LineButtonComponent = [i0.styles];
exports.RenderType_LineButtonComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_LineButtonComponent, data: {} });
function View_LineButtonComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 3, 'a', [], [[1, 'href', 4]], null, null, null, null)), (_l()(),
            i1.ɵeld(0, null, null, 2, 'p', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _co.url;
        _ck(_v, 0, 0, currVal_0);
        var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform('FINISH.SHARE_LINE'));
        _ck(_v, 2, 0, currVal_1);
    });
}
exports.View_LineButtonComponent_0 = View_LineButtonComponent_0;
function View_LineButtonComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'trt-linebutton', [], null, null, null, View_LineButtonComponent_0, exports.RenderType_LineButtonComponent)), i1.ɵdid(573440, null, 0, i4.LineButtonComponent, [i1.ElementRef, i1.Renderer], null, null)], null, null);
}
exports.View_LineButtonComponent_Host_0 = View_LineButtonComponent_Host_0;
exports.LineButtonComponentNgFactory = i1.ɵccf('trt-linebutton', i4.LineButtonComponent, View_LineButtonComponent_Host_0, { msg: 'msg' }, {}, []);
