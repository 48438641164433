"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var personal_info_module_1 = require("../personal-info/personal-info.module");
var reserve_info_module_1 = require("../reserve-info/reserve-info.module");
var router_1 = require("@angular/router");
var common_1 = require("@angular/common");
var form_module_1 = require("../form/form.module");
var home_routes_1 = require("./home.routes");
var common_module_1 = require("../common/common.module");
var core_1 = require("@angular/core");
var home_component_1 = require("./components/home.component");
var core_2 = require("@ngx-translate/core");
var ab_test_component_1 = require("./components/ab-test.component");
var HomeModule = (function () {
    function HomeModule() {
    }
    return HomeModule;
}());
HomeModule = __decorate([
    core_1.NgModule({
        imports: [
            personal_info_module_1.PersonalInfoModule,
            reserve_info_module_1.ReserveInfoModule,
            form_module_1.TRTFormModule,
            common_1.CommonModule,
            router_1.RouterModule,
            core_2.TranslateModule,
            common_module_1.TRTCommonModule,
            home_routes_1.HomeRoutingModule
        ],
        exports: [home_component_1.HomeComponent, ab_test_component_1.ABTestComponent],
        declarations: [home_component_1.HomeComponent, ab_test_component_1.ABTestComponent],
        providers: [],
    })
], HomeModule);
exports.HomeModule = HomeModule;
var PCHomeModule = (function () {
    function PCHomeModule() {
    }
    return PCHomeModule;
}());
PCHomeModule = __decorate([
    core_1.NgModule({
        imports: [
            reserve_info_module_1.PCReserveInfoModule,
            personal_info_module_1.PCPersonalInfoModule,
            form_module_1.TRTFormModule,
            common_1.CommonModule,
            router_1.RouterModule,
            core_2.TranslateModule,
            common_module_1.TRTCommonModule,
            home_routes_1.PCHomeRoutingModule
        ],
        exports: [home_component_1.PCHomeComponent, ab_test_component_1.PCABTestComponent],
        declarations: [home_component_1.PCHomeComponent, ab_test_component_1.PCABTestComponent],
        providers: [],
    })
], PCHomeModule);
exports.PCHomeModule = PCHomeModule;
