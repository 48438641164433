"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@ngx-translate/core");
var core_2 = require("@angular/core");
var TableSmokingComponent = (function () {
    function TableSmokingComponent(translate, cdRef) {
        var _this = this;
        this.translate = translate;
        this.cdRef = cdRef;
        this.tableSmoking = [];
        this.selectSmoking = new core_2.EventEmitter();
        this.isPc = false;
        this.selectedSmokingStr = '';
        this.tableSmokingOptions = [];
        this.freeTimes = [];
        this.translate.onLangChange.subscribe(function (data) {
            console.log(data);
            if (_this.selectedSmoking) {
                _this.selectedSmokingStr = _this.translate.instant("TABLE_SMOKING." + _this.selectedSmoking);
            }
        });
        this.tableSmokingOptions = [];
    }
    TableSmokingComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log(this.obs);
        this.obs = this.freeTable.collection$.subscribe(function (freeTables) {
            _this.tableSmokingOptions = [];
            console.log(freeTables);
            _this.freeTimes = [];
            console.log(_this.tableSmoking);
            _this.tableSmoking.forEach(function (table) {
                var freeTable = freeTables.filter(function (ft) {
                    return ft.keyNames.indexOf(table.key_name) >= 0;
                });
                if (freeTable.length >= 1) {
                    console.log(freeTable);
                    _this.freeTimes.push({
                        keyName: table.key_name,
                        endTime: freeTable[0].endTime,
                        endAt: freeTable[0].endAt
                    });
                    _this.tableSmokingOptions.push({
                        value: table.key_name,
                        name: _this.translate.instant("TABLE_SMOKING." + table.key_name),
                        label: _this.translate.instant("TABLE_SMOKING." + table.key_name)
                    });
                }
            });
            if (_this.tableSmokingOptions.length >= 2) {
                if (_this.tableSmokingOptions.filter(function (table) { return table.value === 'none'; }).length === 0) {
                    _this.freeTimes.unshift({
                        keyName: 'none',
                        endTime: freeTables[0].endTime,
                        endAt: freeTables[0].endAt
                    });
                    _this.tableSmokingOptions.unshift({
                        value: 'none',
                        name: _this.translate.instant("TABLE_SMOKING.none"),
                        label: _this.translate.instant("TABLE_SMOKING.none")
                    });
                }
                if (!_this.selectedSmoking) {
                    _this.select('none');
                }
            }
            else {
                _this.tableSmokingOptions = [];
            }
        });
    };
    TableSmokingComponent.prototype.select = function (d) {
        this.selectedSmoking = d;
        var endTime = this.freeTimes.filter(function (ft) {
            return ft.keyName === d;
        });
        endTime = (endTime.length) ? endTime : this.freeTimes;
        console.log(endTime);
        this.selectSmoking.next({
            keyName: d,
            endTime: endTime[0].endTime,
            endAt: endTime[0].endAt
        });
    };
    TableSmokingComponent.prototype.ngOnDestroy = function () {
        this.obs.unsubscribe();
    };
    return TableSmokingComponent;
}());
__decorate([
    core_2.Input(),
    __metadata("design:type", Object)
], TableSmokingComponent.prototype, "tableSmoking", void 0);
__decorate([
    core_2.Input(),
    __metadata("design:type", Object)
], TableSmokingComponent.prototype, "freeTable", void 0);
__decorate([
    core_2.Output(),
    __metadata("design:type", core_2.EventEmitter)
], TableSmokingComponent.prototype, "selectSmoking", void 0);
__decorate([
    core_2.Input(),
    __metadata("design:type", Boolean)
], TableSmokingComponent.prototype, "isPc", void 0);
__decorate([
    core_2.Input(),
    __metadata("design:type", String)
], TableSmokingComponent.prototype, "selectedSmoking", void 0);
TableSmokingComponent = __decorate([
    core_2.Component({
        selector: 'table-smoking',
        template: require('./table-smoking.component.html'),
        styles: [require('./table-smoking.component.css')]
    }),
    __metadata("design:paramtypes", [core_1.TranslateService, core_2.ChangeDetectorRef])
], TableSmokingComponent);
exports.TableSmokingComponent = TableSmokingComponent;
