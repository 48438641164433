"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var form_service_1 = require("../services/form.service");
var InputPlaceholderComponent = (function () {
    function InputPlaceholderComponent(_formService, _el) {
        var _this = this;
        this._formService = _formService;
        this._el = _el;
        this.labelTitle = '';
        this.tyInputName = '';
        this.tyInputData = null;
        this.disabled = false;
        this._prevData = false;
        this._on = false;
        this._formService.collection$.subscribe(function (collection) {
            var curData = _this.tyInputData;
            if (!!!curData) {
                _this._on = false;
            }
            if (_this._prevData !== curData) {
                _this._on = curData;
            }
            _this._prevData = curData;
        });
    }
    InputPlaceholderComponent.prototype.ngOnChanges = function (changes) {
        for (var propName in changes) {
            var prop = changes[propName];
            var cur = prop.currentValue ? '' + prop.currentValue : prop.currentValue;
            var prev = prop.previousValue ? '' + prop.previousValue : prop.previousValue;
            if (propName === 'tyInputData') {
                if ((cur !== undefined && cur !== null) && (JSON.stringify(prev) !== '{}')) {
                    this._formService.load();
                }
            }
        }
    };
    return InputPlaceholderComponent;
}());
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], InputPlaceholderComponent.prototype, "labelTitle", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], InputPlaceholderComponent.prototype, "tyInputName", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Object)
], InputPlaceholderComponent.prototype, "tyInputData", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], InputPlaceholderComponent.prototype, "disabled", void 0);
InputPlaceholderComponent = __decorate([
    core_1.Component({
        selector: 'ty-input-placeholder',
        template: "<label class=\"ty-input-label\" [ngClass]=\"{disabled: disabled, on: _on, off: !_on}\">{{labelTitle}}</label>",
        styles: [require('./input-placeholder.component.css')]
    }),
    __metadata("design:paramtypes", [form_service_1.FormService, core_1.ElementRef])
], InputPlaceholderComponent);
exports.InputPlaceholderComponent = InputPlaceholderComponent;
