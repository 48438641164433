"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var common_1 = require("@angular/common");
var core_2 = require("@ngx-translate/core");
var form_component_1 = require("./components/form.component");
var TRTFormModule = (function () {
    function TRTFormModule() {
    }
    return TRTFormModule;
}());
TRTFormModule = __decorate([
    core_1.NgModule({
        imports: [common_1.CommonModule, forms_1.FormsModule, core_2.TranslateModule],
        declarations: [
            form_component_1.ButtonComponent,
            form_component_1.CheckboxComponent,
            form_component_1.InputPlaceholderComponent,
            form_component_1.InputTelComponent,
            form_component_1.InputTextComponent,
            form_component_1.InputTimeComponent,
            form_component_1.SelectComponent,
            form_component_1.TextComponent,
            form_component_1.TextareaComponent,
            form_component_1.ToggleComponent,
            form_component_1.InputCountryComponent
        ],
        exports: [
            form_component_1.ButtonComponent,
            form_component_1.CheckboxComponent,
            form_component_1.InputPlaceholderComponent,
            form_component_1.InputTelComponent,
            form_component_1.InputTextComponent,
            form_component_1.InputTimeComponent,
            form_component_1.SelectComponent,
            form_component_1.TextComponent,
            form_component_1.TextareaComponent,
            form_component_1.ToggleComponent,
            form_component_1.InputCountryComponent
        ],
        providers: [],
    })
], TRTFormModule);
exports.TRTFormModule = TRTFormModule;
var form_component_2 = require("./components/form.component");
exports.ButtonComponent = form_component_2.ButtonComponent;
exports.CheckboxComponent = form_component_2.CheckboxComponent;
exports.InputPlaceholderComponent = form_component_2.InputPlaceholderComponent;
exports.InputTelComponent = form_component_2.InputTelComponent;
exports.InputTextComponent = form_component_2.InputTextComponent;
exports.InputTimeComponent = form_component_2.InputTimeComponent;
exports.SelectComponent = form_component_2.SelectComponent;
exports.TextComponent = form_component_2.TextComponent;
exports.TextareaComponent = form_component_2.TextareaComponent;
exports.ToggleComponent = form_component_2.ToggleComponent;
