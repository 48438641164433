"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var FromService = (function () {
    function FromService() {
        this.storeEnabled = true;
    }
    FromService.prototype.init = function (public_key) {
        this.pubKey = public_key;
        var dummy_key = '9jafeAWr4o0raejfp3';
        var dummy_val = '' + Math.random();
        try {
            sessionStorage.setItem(dummy_key, dummy_val);
            this.storeEnabled = (sessionStorage.getItem(dummy_key) === dummy_val);
            sessionStorage.removeItem(dummy_key);
        }
        catch (e) {
            this.storeEnabled = false;
        }
    };
    FromService.prototype.add = function (key, val) {
        if (this.storeEnabled) {
            var d = new Date().getTime() + 1800000;
            var data = {
                value: val,
                expire: d
            };
            sessionStorage.setItem(this.pubKey + "_" + key, JSON.stringify(data));
        }
    };
    FromService.prototype.get = function (key) {
        var res = null;
        if (this.storeEnabled) {
            var d = new Date().getTime();
            var vals = sessionStorage.getItem(this.pubKey + "_" + key);
            if (vals) {
                var val = JSON.parse(vals);
                if (val.expire >= d) {
                    res = val.value;
                }
                else {
                    sessionStorage.removeItem(this.pubKey + "_" + key);
                }
            }
        }
        return res;
    };
    FromService.prototype.remove = function (key) {
        if (this.storeEnabled) {
            sessionStorage.removeItem(this.pubKey + "_" + key);
        }
    };
    return FromService;
}());
FromService = __decorate([
    core_1.Injectable(),
    __metadata("design:paramtypes", [])
], FromService);
exports.FromService = FromService;
