"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var form_module_1 = require("../form/form.module");
var common_1 = require("@angular/common");
var term_module_1 = require("../term/term.module");
var reserve_confirmation_routes_1 = require("./reserve-confirmation.routes");
var common_module_1 = require("../common/common.module");
var core_1 = require("@angular/core");
var reserve_confirmation_component_1 = require("./components/reserve-confirmation.component");
var core_2 = require("@ngx-translate/core");
var ReserveConfirmationModule = (function () {
    function ReserveConfirmationModule() {
    }
    return ReserveConfirmationModule;
}());
ReserveConfirmationModule = __decorate([
    core_1.NgModule({
        imports: [
            form_module_1.TRTFormModule,
            common_1.CommonModule,
            router_1.RouterModule,
            common_module_1.TRTCommonModule,
            core_2.TranslateModule,
            reserve_confirmation_routes_1.ReserveConfirmationRoutingModule,
            term_module_1.TermModule
        ],
        exports: [reserve_confirmation_component_1.ReserveConfirmationComponent],
        declarations: [reserve_confirmation_component_1.ReserveConfirmationComponent],
        providers: [],
    })
], ReserveConfirmationModule);
exports.ReserveConfirmationModule = ReserveConfirmationModule;
var PCReserveConfirmationModule = (function () {
    function PCReserveConfirmationModule() {
    }
    return PCReserveConfirmationModule;
}());
PCReserveConfirmationModule = __decorate([
    core_1.NgModule({
        imports: [
            form_module_1.TRTFormModule,
            common_1.CommonModule,
            router_1.RouterModule,
            common_module_1.TRTCommonModule,
            core_2.TranslateModule,
            reserve_confirmation_routes_1.PCReserveConfirmationRoutingModule,
            term_module_1.TermModule
        ],
        exports: [reserve_confirmation_component_1.PCReserveConfirmationComponent],
        declarations: [reserve_confirmation_component_1.PCReserveConfirmationComponent],
        providers: [],
    })
], PCReserveConfirmationModule);
exports.PCReserveConfirmationModule = PCReserveConfirmationModule;
