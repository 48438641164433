"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./optin-confirm.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./optin-confirm.component");
var i3 = require("../../form/components/checkbox.component.ngfactory");
var i4 = require("../../form/services/form.service");
var i5 = require("../../form/components/checkbox.component");
var i6 = require("@ngx-translate/core/src/translate.pipe");
var i7 = require("@ngx-translate/core/src/translate.service");
var styles_OptinConfirmComponent = [i0.styles];
exports.RenderType_OptinConfirmComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_OptinConfirmComponent, data: {} });
function View_OptinConfirmComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 7, 'div', [['class', 'optinConfirm']], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n      '])),
        (_l()(), i1.ɵeld(0, null, null, 4, 'ty-checkbox', [['tyName', 'optinConfirm']], null, [[null, 'changeData']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('changeData' === en)) {
                var pd_0 = (_co.checkOptinHandler($event) !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i3.View_CheckboxComponent_0, i3.RenderType_CheckboxComponent)), i1.ɵprd(512, null, i4.FormService, i4.FormService, []), i1.ɵdid(114688, null, 0, i5.CheckboxComponent, [i4.FormService, i1.ElementRef], { tyLabel: [0,
                'tyLabel'], tyName: [1, 'tyName'], tyData: [2, 'tyData'] }, { changeData: 'changeData' }),
        i1.ɵpod(['business_company']), i1.ɵpid(131072, i6.TranslatePipe, [i7.TranslateService,
            i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(),
            i1.ɵted(null, ['\n  ']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = i1.ɵinlineInterpolate(1, '', i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 7).transform('RESERVE_CONFIRMATION.DM_OPTIN', _ck(_v, 6, 0, _co.business_company))), '');
        var currVal_1 = 'optinConfirm';
        var currVal_2 = _co.optinStatus;
        _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2);
    }, null);
}
exports.View_OptinConfirmComponent_0 = View_OptinConfirmComponent_0;
function View_OptinConfirmComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'trt-optin-confirm', [], null, null, null, View_OptinConfirmComponent_0, exports.RenderType_OptinConfirmComponent)), i1.ɵdid(245760, null, 0, i2.OptinConfirmComponent, [i7.TranslateService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) {
        _ck(_v, 1, 0);
    }, null);
}
exports.View_OptinConfirmComponent_Host_0 = View_OptinConfirmComponent_Host_0;
exports.OptinConfirmComponentNgFactory = i1.ɵccf('trt-optin-confirm', i2.OptinConfirmComponent, View_OptinConfirmComponent_Host_0, { country: 'country',
    optinStatus: 'optinStatus' }, { checkOptin: 'checkOptin' }, []);
