"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var form_service_1 = require("../services/form.service");
var input_component_1 = require("./input.component");
var InputTimeComponent = (function (_super) {
    __extends(InputTimeComponent, _super);
    function InputTimeComponent(_formService) {
        var _this = _super.call(this, _formService) || this;
        _this._formService = _formService;
        _this.changeData = new core_1.EventEmitter();
        return _this;
    }
    InputTimeComponent.prototype.ngOnChanges = function (changes) {
        for (var propName in changes) {
            var prop = changes[propName];
            var cur = prop.currentValue;
            var prev = JSON.stringify(prop.previousValue);
            if (propName === 'tyData' && cur && (prev != '{}')) {
                this.setData(cur);
            }
        }
    };
    return InputTimeComponent;
}(input_component_1.InputComponent));
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], InputTimeComponent.prototype, "tyName", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], InputTimeComponent.prototype, "tyPlaceholder", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], InputTimeComponent.prototype, "tyData", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], InputTimeComponent.prototype, "required", void 0);
__decorate([
    core_1.Output(),
    __metadata("design:type", core_1.EventEmitter)
], InputTimeComponent.prototype, "changeData", void 0);
InputTimeComponent = __decorate([
    core_1.Component({
        selector: 'ty-input-time',
        template: "<div class=\"ty-time\">\n    <ty-input-placeholder [labelTitle]=\"tyPlaceholder\" [tyInputName]=\"tyName\" [tyInputData]=\"tyData\" (click)=\"box.focus()\"></ty-input-placeholder>\n    <input #box type=\"time\" [name]=\"tyName\" [(ngModel)]=\"tyData\" (input)=\"setData(box.value)\" [ngClass]=\"{ondata: !!tyData, 'no-placeholder': !!!tyPlaceholder}\">\n    </div>",
        styles: [require('./input.component.css')],
        providers: [form_service_1.FormService],
    }),
    __metadata("design:paramtypes", [form_service_1.FormService])
], InputTimeComponent);
exports.InputTimeComponent = InputTimeComponent;
