"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var params_service_1 = require("../../common/services/params.service");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var core_2 = require("@ngx-translate/core");
var restaurant_module_1 = require("../../common/services/restaurant/restaurant.module");
var reserve_form_service_1 = require("../../common/services/reserve-form.service");
var from_service_1 = require("../../common/services/from.service");
var app_model_1 = require("../../app/models/app.model");
var web_hooks_service_1 = require("../../common/services/web-hooks.service");
var toreta_date_service_1 = require("../../common/services/toreta-date.service");
var toreta_time_service_1 = require("../../common/services/toreta-time.service");
var intl_tel_service_1 = require("../../common/services/intl-tel.service");
var FinishComponent = (function () {
    function FinishComponent(router, restaurantService, reserveFormService, cdRef, translate, applicationRef, appType, webhook, trtDate, trtTime, intl, fromService, renderer, elRef, paramsService) {
        var _this = this;
        this.router = router;
        this.restaurantService = restaurantService;
        this.reserveFormService = reserveFormService;
        this.cdRef = cdRef;
        this.translate = translate;
        this.applicationRef = applicationRef;
        this.appType = appType;
        this.webhook = webhook;
        this.trtDate = trtDate;
        this.trtTime = trtTime;
        this.intl = intl;
        this.fromService = fromService;
        this.renderer = renderer;
        this.elRef = elRef;
        this.paramsService = paramsService;
        this.restaurantName = '';
        this.seats = 0;
        this.adultsSeats = 0;
        this.childrenSeats = 0;
        this.courses = [];
        this.goDate = '';
        this.timeLabel = '';
        this.request = '';
        this.lastName = '';
        this.firstName = '';
        this.lastNameReading = '';
        this.firstNameReading = '';
        this.phone = '';
        this.email = '';
        this.url = '';
        this.reserveNo = '';
        this.accept_children = false;
        this.is_deposit = false;
        this.deposit_amount = '0';
        this.amount = 0;
        this.fromRakuten = false;
        this.isUR = false;
        this.selected_table_category = '';
        this.selected_table_smoking = '';
        translate.getTranslation(translate.currentLang).subscribe(function (d) {
            console.log(d);
            _this.label = d;
        });
        translate.onLangChange.subscribe(function (evt) {
            _this.label = evt.translations;
            if (_this.restaurant && _this.data) {
                _this.createLink();
                if (_this.data.ref) {
                    console.log(_this.data.ref.contact_from);
                    _this.isUR = /hitosara|prmrsv/.test(_this.data.ref.contact_from);
                }
            }
        });
        if (typeof this.restaurantService.get() === 'undefined') {
            this.router.navigate(['/']);
        }
        else {
            this.restaurant = this.restaurantService.get().restaurant;
            this.accept_children = this.restaurant.web_reservation_config.accept_children;
            this.restaurantName = this.restaurant.restaurant_name;
            this.message_success = this.restaurant.web_reservation_config.message_success;
            this.url = this.restaurant.restaurant_url;
            this.key = this.restaurant.restaurant_key;
            this.show_request_form = this.restaurant.web_reservation_config.show_request_form;
            var ss_1 = this.reserveFormService.reserveFormCollection$.subscribe(function (data) {
                ss_1.unsubscribe();
                _this.data = data;
                _this.data.reserve_no = _this.data.reserve_no || _this.paramsService.params.reservation_no;
                if (_this.restaurant && _this.data) {
                    _this.createLink();
                    console.log(data);
                    if (_this.data.ref) {
                        console.log(_this.data.ref.contact_from);
                        _this.isUR = /hitosara|prmrsv/.test(_this.data.ref.contact_from);
                        if (_this.isUR) {
                            var urScript = _this.renderer.createElement(_this.elRef.nativeElement, 'script');
                            _this.renderer.setElementAttribute(urScript, 'src', '//s.yjtag.jp/tag.js#site=Ifo6zP1');
                            _this.renderer.setElementAttribute(urScript, 'async', 'true');
                        }
                    }
                }
                _this.fromService.init(_this.appType.getPublicKey());
                _this.fromService.remove('contact_from');
                _this.fromService.remove('contact_from_key');
                _this.reserveFormService.release();
                _this.restaurantService.release();
                _this.reserveFormService.clear();
                _this.restaurantService.clear();
            });
            this.reserveFormService.get();
            this.webhook.finish();
            setTimeout(function () {
                _this.cdRef.detectChanges();
            }, 100);
        }
    }
    FinishComponent.prototype.createLink = function () {
        var _this = this;
        var isGlobal = !/jp/.test(this.restaurant.restaurant_country);
        this.goDate = this.data.go_date;
        this.start_at = this.data.start_at;
        this.end_at = this.data.end_at;
        this.start_time = this.data.start_time;
        this.end_time = this.data.end_time;
        this.timeLabel = this.data.time_label;
        this.request = this.data.request;
        this.is_deposit = this.data.is_deposit;
        this.deposit_amount = this.data.deposit_amount;
        this.request = (this.data.addInfo) ? this.data.addInfo + "\n" + (this.data.note || '') : this.data.note;
        this.selected_table_category = this.data.table_category;
        this.selected_table_smoking = this.data.table_smoking;
        var ci;
        if (this.data.customer && this.data.customer.temporary_reservation_customer_information) {
            ci = this.data.customer.temporary_reservation_customer_information;
            this.lastName = ci.last_name;
            this.firstName = ci.first_name;
            this.lastNameReading = ci.last_name_reading;
            this.firstNameReading = ci.first_name_reading;
            if (ci.iso2 && isGlobal) {
                var phonenumber = this.intl.util.parseAndKeepRawInput(ci.phone, ci.iso2);
                if (ci.iso2 !== this.restaurant.restaurant_country) {
                    this.phone = "+" + phonenumber.getCountryCode() + " " + ci.phone.replace(/^0{1}/, '');
                }
                else {
                    this.phone = ci.phone;
                }
            }
            else {
                this.phone = ci.phone;
            }
            this.email = ci.email;
        }
        this.reservationNo = this.data.reserve_no;
        this.courses = [];
        if (this.data.courses) {
            var courses_1 = [];
            var _c_1 = {};
            this.data.courses.forEach(function (c) {
                if (!_c_1[c.course_id]) {
                    _c_1[c.course_id] = {
                        name: c.name,
                        price: c.price,
                        seats: 0
                    };
                }
                _c_1[c.course_id].seats += +c.count;
            });
            Object.keys(_c_1).forEach(function (key) {
                if (_c_1[key].seats > 0) {
                    courses_1.push(_c_1[key]);
                }
            });
            if (courses_1.length) {
                this.courses = courses_1;
            }
        }
        this.seats = this.data.seats || 0;
        this.adultsSeats = this.data.seats_adults || 0;
        this.childrenSeats = this.data.seats_children || 0;
        this.accept_courses = this.data.accept_courses;
        if (this.data.ref) {
            this.fromRakuten = this.data.ref.contact_from === 'rakuten';
        }
        var course = '';
        if (this.courses.length) {
            course = this.courses.map(function (c) {
                return c.name + " " + c.price + " \u00D7 " + c.seats;
            }).join(', ');
        }
        var start_time;
        var end_time = '';
        this.trtDate.getDate(this.goDate, this.start_time).then(function (startTime) {
            start_time = startTime;
        }).then(function () {
            if (_this.end_time) {
                return _this.trtTime.transform(_this.end_time).then(function (endTime) {
                    console.log(endTime);
                    end_time = " " + endTime;
                });
            }
            else {
                Promise.resolve('');
            }
        }).then(function () {
            var note = [
                (_this.label['FINISH.RESERVE_NO'] || _this.label['FINISH']['RESERVE_NO']) + ": #" + _this.data.reserve_no,
                _this.label['RESTAURANT_NAME'] + ": " + _this.restaurant.restaurant_name,
                _this.label['DATE_TIME'] + ": " + start_time.date + " " + start_time.time + " " + _this.label['IN'] + " " + end_time + " " + (end_time ? _this.label['OUT'] : ''),
                _this.label['ADDRESS'] + ": " + _this.restaurant.restaurant_address,
                _this.label['TEL'] + ": " + _this.restaurant.restaurant_tel,
                _this.label['WEB'] + ": " + _this.restaurant.restaurant_url,
                _this.label['PARTY_SIZE'] + ": " + _this.seats + " " + _this.label['PEOPLE'],
                _this.selected_table_category ? _this.label['TABLE_CAETGORY'] + ": " + _this.label['TABLE_CATEGORIES'][_this.selected_table_category] : '',
                _this.selected_table_smoking ? _this.label['SELECT_SMOKING'] + ": " + _this.label['TABLE_SMOKING'][_this.selected_table_smoking] : '',
                _this.accept_courses ? _this.label['COURSE'] + ": " + ((_this.courses.length) ? course : _this.label['NO_COURSES']) : '',
                _this.request ? _this.label['REQUEST'] + ": " + _this.request : ''
            ].join('\n').replace(/\n+/, '\n');
            var tz = (new Date()).getTimezoneOffset() * 60;
            var start_at = _this.data.start_at + _this.data.timezone_offset + tz;
            var end_at = _this.data.end_at ? _this.data.end_at : start_at + (2 * 60 * 60);
            _this.googleCalendar = 'https://www.google.com/calendar/event?' +
                'action=' + 'TEMPLATE' +
                '&text=' + encodeURIComponent(_this.restaurant.restaurant_name) +
                '&details=' + encodeURIComponent(note) +
                '&location=' + encodeURIComponent(_this.restaurant.restaurant_address) +
                '&dates=' + (_this.utcDateString(new Date(start_at * 1000)) + "/" + _this.utcDateString(new Date(end_at * 1000))) +
                '&trp=' + 'false' +
                '&sprop=' + encodeURIComponent("website:" + window.location.href.replace(/#.*$/, '')) +
                '&sprop=' + ("name:" + encodeURIComponent(document.title));
            _this.lineMsg = note;
            _this.cdRef.detectChanges();
        });
    };
    FinishComponent.prototype.ngOnInit = function () {
        console.log('init');
    };
    FinishComponent.prototype.utcDateString = function (date) {
        return [
            date.getUTCFullYear(),
            this.zerofill(date.getUTCMonth() + 1),
            this.zerofill(date.getUTCDate()),
            'T',
            this.zerofill(date.getUTCHours()),
            this.zerofill(date.getUTCMinutes()),
            this.zerofill(date.getUTCSeconds()),
            'Z'
        ].join('');
    };
    FinishComponent.prototype.zerofill = function (num) {
        return ('0' + num).slice(-2);
    };
    FinishComponent.prototype.onClick = function (evt) {
        if (this.url) {
            window.location.replace(this.url);
        }
        else {
            window.location.replace("/" + this.appType.getPublicKey());
        }
    };
    return FinishComponent;
}());
FinishComponent = __decorate([
    core_1.Component({
        selector: 'trt-finish',
        template: require('./finish.component.html'),
        styles: [require('./finish.component.css')],
    }),
    __metadata("design:paramtypes", [router_1.Router, restaurant_module_1.RestaurantService, reserve_form_service_1.ReserveFormService, core_1.ChangeDetectorRef, core_2.TranslateService, core_1.ApplicationRef, app_model_1.AppType, web_hooks_service_1.WebHooksService, toreta_date_service_1.TRTDateService, toreta_time_service_1.TRTTimeService, intl_tel_service_1.IntlTelService, from_service_1.FromService, core_1.Renderer, core_1.ElementRef, params_service_1.ParamsService])
], FinishComponent);
exports.FinishComponent = FinishComponent;
var PCFinishComponent = (function (_super) {
    __extends(PCFinishComponent, _super);
    function PCFinishComponent(router, restaurantService, reserveFormService, cdRef, translate, applicationRef, appType, webhook, trtDate, trtTime, intl, fromService, renderer, elRef, paramsService) {
        var _this = _super.call(this, router, restaurantService, reserveFormService, cdRef, translate, applicationRef, appType, webhook, trtDate, trtTime, intl, fromService, renderer, elRef, paramsService) || this;
        _this.router = router;
        _this.restaurantService = restaurantService;
        _this.reserveFormService = reserveFormService;
        _this.cdRef = cdRef;
        _this.translate = translate;
        _this.applicationRef = applicationRef;
        _this.appType = appType;
        _this.webhook = webhook;
        _this.trtDate = trtDate;
        _this.trtTime = trtTime;
        _this.intl = intl;
        _this.fromService = fromService;
        _this.renderer = renderer;
        _this.elRef = elRef;
        _this.paramsService = paramsService;
        return _this;
    }
    return PCFinishComponent;
}(FinishComponent));
PCFinishComponent = __decorate([
    core_1.Component({
        selector: 'trt-finish',
        template: require('./finish.pc.component.html'),
        styles: [require('./finish.pc.component.css')],
    }),
    __metadata("design:paramtypes", [router_1.Router, restaurant_module_1.RestaurantService, reserve_form_service_1.ReserveFormService, core_1.ChangeDetectorRef, core_2.TranslateService, core_1.ApplicationRef, app_model_1.AppType, web_hooks_service_1.WebHooksService, toreta_date_service_1.TRTDateService, toreta_time_service_1.TRTTimeService, intl_tel_service_1.IntlTelService, from_service_1.FromService, core_1.Renderer, core_1.ElementRef, params_service_1.ParamsService])
], PCFinishComponent);
exports.PCFinishComponent = PCFinishComponent;
