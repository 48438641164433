"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var PersonValidationService = (function () {
    function PersonValidationService() {
    }
    PersonValidationService.prototype.isValid = function (person, isJa, isJp) {
        if (isJa === void 0) { isJa = true; }
        if (isJp === void 0) { isJp = true; }
        return this.isValidEmail(person.email) && this.isValidName(person.last_name, person.first_name) && this.isValidNameReading(person.last_name_reading, person.first_name_reading, isJa, isJp) && this.isValidPhone(person.phone);
    };
    PersonValidationService.prototype.isValidEmail = function (str) {
        if (str === void 0) { str = ''; }
        return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.exec(str);
    };
    PersonValidationService.prototype.isValidPhone = function (str) {
        if (str === void 0) { str = ''; }
        return /^[-+()0-9ー＋（）０-９]{6,32}$/.exec(str.replace(/\s+/g, ''));
    };
    PersonValidationService.prototype.isValidName = function (last_name, first_name) {
        if (last_name === void 0) { last_name = ''; }
        if (first_name === void 0) { first_name = ''; }
        return last_name !== '' && first_name !== '';
    };
    PersonValidationService.prototype.isValidNameReading = function (last_name_reading, first_name_reading, isJa, isJp) {
        if (last_name_reading === void 0) { last_name_reading = ''; }
        if (first_name_reading === void 0) { first_name_reading = ''; }
        if (isJa === void 0) { isJa = true; }
        if (isJp === void 0) { isJp = true; }
        return (last_name_reading !== '' && first_name_reading !== '') || !(isJa && isJp);
    };
    return PersonValidationService;
}());
PersonValidationService = __decorate([
    core_1.Injectable(),
    __metadata("design:paramtypes", [])
], PersonValidationService);
exports.PersonValidationService = PersonValidationService;
