"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./button.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./button.component");
var styles_ButtonComponent = [i0.styles];
exports.RenderType_ButtonComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_ButtonComponent, data: {} });
function View_ButtonComponent_1(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 3, 'span', [], null, null, null, null, null)), i1.ɵdid(278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef,
            i1.Renderer], { ngClass: [0, 'ngClass'] }, null), i1.ɵpod(['small']), (_l()(),
            i1.ɵted(null, ['', '']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _ck(_v, 2, 0, _co.tyDisabledSmall);
        _ck(_v, 1, 0, currVal_0);
    }, function (_ck, _v) {
        var _co = _v.component;
        var currVal_1 = _co.tyDisabledText;
        _ck(_v, 3, 0, currVal_1);
    });
}
function View_ButtonComponent_2(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 3, 'span', [], null, null, null, null, null)), i1.ɵdid(278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef,
            i1.Renderer], { ngClass: [0, 'ngClass'] }, null), i1.ɵpod(['small']), (_l()(),
            i1.ɵted(null, ['', '']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _ck(_v, 2, 0, _co.tySmall);
        _ck(_v, 1, 0, currVal_0);
    }, function (_ck, _v) {
        var _co = _v.component;
        var currVal_1 = _co.tyText;
        _ck(_v, 3, 0, currVal_1);
    });
}
function View_ButtonComponent_3(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 2, 'span', [['class',
                'loading']], null, null, null, null, null)),
        (_l()(), i1.ɵeld(0, null, null, 1, 'i', [['class', 'icon']], null, null, null, null, null)), i1.ɵdid(278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef,
            i1.Renderer], { klass: [0, 'klass'], ngClass: [1, 'ngClass'] }, null)], function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = 'icon';
        var currVal_1 = _co.icon;
        _ck(_v, 2, 0, currVal_0, currVal_1);
    }, null);
}
function View_ButtonComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 14, 'button', [], [[8, 'disabled', 0]], [[null, 'click']], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (('click' === en)) {
                var pd_0 = (_co.onClick($event) !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), i1.ɵdid(278528, null, 0, i2.NgClass, [i1.IterableDiffers,
            i1.KeyValueDiffers, i1.ElementRef, i1.Renderer], { ngClass: [0, 'ngClass'] }, null),
        i1.ɵpod(['loading', 'border']), i1.ɵdid(278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers,
            i1.ElementRef, i1.Renderer], { ngStyle: [0, 'ngStyle'] }, null), i1.ɵpod(['color',
            'background-color', 'border-color']), (_l()(), i1.ɵted(null, ['\n    '])),
        (_l()(), i1.ɵeld(0, null, null, 7, 'p', [], null, null, null, null, null)), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonComponent_1)), i1.ɵdid(16384, null, 0, i2.NgIf, [i1.ViewContainerRef,
            i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n      '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonComponent_2)), i1.ɵdid(16384, null, 0, i2.NgIf, [i1.ViewContainerRef,
            i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵted(null, ['\n  '])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonComponent_3)), i1.ɵdid(16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, 'ngIf'] }, null), (_l()(), i1.ɵted(null, ['\n']))], function (_ck, _v) {
        var _co = _v.component;
        var currVal_1 = _ck(_v, 2, 0, _co.loading, !!_co.borderColor);
        _ck(_v, 1, 0, currVal_1);
        var currVal_2 = _ck(_v, 4, 0, _co.color, _co.bgColor, _co.borderColor);
        _ck(_v, 3, 0, currVal_2);
        var currVal_3 = _co.tyDisabled;
        _ck(_v, 9, 0, currVal_3);
        var currVal_4 = !_co.tyDisabled;
        _ck(_v, 12, 0, currVal_4);
        var currVal_5 = _co.loading;
        _ck(_v, 16, 0, currVal_5);
    }, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _co.tyDisabled;
        _ck(_v, 0, 0, currVal_0);
    });
}
exports.View_ButtonComponent_0 = View_ButtonComponent_0;
function View_ButtonComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'ty-button', [], null, null, null, View_ButtonComponent_0, exports.RenderType_ButtonComponent)),
        i1.ɵdid(114688, null, 0, i3.ButtonComponent, [], null, null)], function (_ck, _v) {
        _ck(_v, 1, 0);
    }, null);
}
exports.View_ButtonComponent_Host_0 = View_ButtonComponent_Host_0;
exports.ButtonComponentNgFactory = i1.ɵccf('ty-button', i3.ButtonComponent, View_ButtonComponent_Host_0, { tyDisabledText: 'tyDisabledText',
    tyText: 'tyText', tyDisabled: 'tyDisabled', bgColor: 'bgColor', color: 'color', borderColor: 'borderColor',
    tyDisabledSmall: 'tyDisabledSmall', tySmall: 'tySmall', icon: 'icon', withLoading: 'withLoading',
    loading: 'loading' }, { tyClick: 'tyClick' }, []);
