"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ButtonComponent = (function () {
    function ButtonComponent() {
        this.tyDisabled = false;
        this.tyClick = new core_1.EventEmitter();
        this.icon = 'icon_card';
        this.withLoading = false;
        this.loading = false;
    }
    ButtonComponent.prototype.ngOnInit = function () { };
    ButtonComponent.prototype.onClick = function ($event) {
        this.tyClick.next($event);
    };
    return ButtonComponent;
}());
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], ButtonComponent.prototype, "tyDisabledText", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], ButtonComponent.prototype, "tyText", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], ButtonComponent.prototype, "tyDisabled", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], ButtonComponent.prototype, "bgColor", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], ButtonComponent.prototype, "color", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], ButtonComponent.prototype, "borderColor", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], ButtonComponent.prototype, "tyDisabledSmall", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], ButtonComponent.prototype, "tySmall", void 0);
__decorate([
    core_1.Output(),
    __metadata("design:type", core_1.EventEmitter)
], ButtonComponent.prototype, "tyClick", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", String)
], ButtonComponent.prototype, "icon", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], ButtonComponent.prototype, "withLoading", void 0);
__decorate([
    core_1.Input(),
    __metadata("design:type", Boolean)
], ButtonComponent.prototype, "loading", void 0);
ButtonComponent = __decorate([
    core_1.Component({
        selector: 'ty-button',
        styles: [require('./button.component.css')],
        template: "<button [ngClass]=\"{loading: loading, border:!!borderColor}\" [disabled]=\"tyDisabled\" (click)=\"onClick($event)\" [ngStyle]=\"{'color': color, 'background-color': bgColor, 'border-color': borderColor}\">\n    <p>\n      <span *ngIf=\"tyDisabled\" [ngClass]=\"{small: tyDisabledSmall}\">{{tyDisabledText}}</span>\n      <span *ngIf=\"!tyDisabled\" [ngClass]=\"{small: tySmall}\">{{tyText}}</span>\n    </p>\n  </button><span class=\"loading\" *ngIf=\"loading\"><i class=\"icon\" [ngClass]=\"icon\"></i></span>\n"
    }),
    __metadata("design:paramtypes", [])
], ButtonComponent);
exports.ButtonComponent = ButtonComponent;
