"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./client.pc.module");
var i2 = require("../app/components/app.component");
var i3 = require("../reserve-info/components/reserve-info.component.ngfactory");
var i4 = require("../personal-info/components/personal-info.component.ngfactory");
var i5 = require("../home/components/home.component.ngfactory");
var i6 = require("../reserve-confirmation/components/reserve-confirmation.component.ngfactory");
var i7 = require("../payment-docomo/components/payment-docomo.component.ngfactory");
var i8 = require("../payment-select/components/payment-select.component.ngfactory");
var i9 = require("../payment/components/payment.component.ngfactory");
var i10 = require("../finish/components/finish.component.ngfactory");
var i11 = require("../app/components/app.component.ngfactory");
var i12 = require("@angular/http");
var i13 = require("@angular/common");
var i14 = require("@angular/platform-browser");
var i15 = require("@angular/forms");
var i16 = require("../common/services/restaurant/src/restaurant.service");
var i17 = require("../common/services/api.service");
var i18 = require("../app/models/app.model");
var i19 = require("../reserve-info/services/days.service");
var i20 = require("../reserve-info/services/course.service");
var i21 = require("../reserve-info/services/table-category.service");
var i22 = require("../reserve-info/services/table-smoking.service");
var i23 = require("../personal-info/services/person-validation.service");
var i24 = require("../term/services/term.service");
var i25 = require("../payment/services/payment.service");
var i26 = require("angulartics2/dist/core/angulartics2");
var i27 = require("@angular/router");
var i28 = require("angulartics2/dist/providers/ga/angulartics2-ga");
var i29 = require("@ngx-translate/core/src/translate.loader");
var i30 = require("@ngx-translate/core/src/translate.parser");
var i31 = require("@ngx-translate/core/src/missing-translation-handler");
var i32 = require("@ngx-translate/core/src/translate.store");
var i33 = require("@ngx-translate/core/src/translate.service");
var i34 = require("../common/services/countries.service");
var i35 = require("../common/services/from.service");
var i36 = require("../common/services/intl-tel.service");
var i37 = require("../common/services/params.service");
var i38 = require("../common/services/reserve.service");
var i39 = require("../common/services/restaurant.resolver");
var i40 = require("../common/services/toreta-time.service");
var i41 = require("../common/services/toreta-date.service");
var i42 = require("../common/services/web-hooks.service");
var i43 = require("../common/services/week-day.service");
var i44 = require("../common/services/reserve-form.service");
var i45 = require("../common/services/can-deactivate-guard.service");
var i46 = require("../common/services/can-activate-guard.service");
var i47 = require("../admin/services/config.service");
var i48 = require("@ngx-translate/core/index");
var i49 = require("../common/services/restaurant/restaurant.module");
var i50 = require("../form/form.module");
var i51 = require("../common/common.module");
var i52 = require("../reserve-info/components/reserve-info.component");
var i53 = require("../personal-info/components/personal-info.component");
var i54 = require("../home/components/home.component");
var i55 = require("../reserve-confirmation/components/reserve-confirmation.component");
var i56 = require("../payment-docomo/components/payment-docomo.component");
var i57 = require("../payment-select/components/payment-select.component");
var i58 = require("../payment/components/payment.component");
var i59 = require("../finish/components/finish.component");
var i60 = require("../header/header.module");
var i61 = require("../footer/footer.module");
var i62 = require("../reserve-info/reserve-info.routes");
var i63 = require("angulartics2/dist/index");
var i64 = require("../reserve-info/reserve-info.module");
var i65 = require("../personal-info/personal-info.routes");
var i66 = require("../personal-info/personal-info.module");
var i67 = require("../home/home.routes");
var i68 = require("../home/home.module");
var i69 = require("../reserve-confirmation/reserve-confirmation.routes");
var i70 = require("../term/term.module");
var i71 = require("../reserve-confirmation/reserve-confirmation.module");
var i72 = require("../payment-docomo/payment-docomo.routes");
var i73 = require("../payment-docomo/payment-docomo.module");
var i74 = require("../payment-select/payment-select.routes");
var i75 = require("../payment-select/payment-select.module");
var i76 = require("../payment/payment.routes");
var i77 = require("../payment/payment.module");
var i78 = require("../finish/finish.routes");
var i79 = require("../finish/finish.module");
var i80 = require("../app/app.routes");
var i81 = require("../app/app.module");
exports.PCClientModuleNgFactory = i0.ɵcmf(i1.PCClientModule, [i2.PCAppComponent], function (_l) {
    return i0.ɵmod([i0.ɵmpd(512, i0.ComponentFactoryResolver, i0.ɵCodegenComponentFactoryResolver, [[8, [i3.PCReserveInfoComponentNgFactory, i4.PCPersonalInfoComponentNgFactory,
                    i5.PCHomeComponentNgFactory, i6.PCReserveConfirmationComponentNgFactory,
                    i7.PCPaymentDocomoComponentNgFactory, i8.PCPaymentSelectComponentNgFactory,
                    i9.PCPaymentComponentNgFactory, i10.PCFinishComponentNgFactory, i11.PCAppComponentNgFactory]],
            [3, i0.ComponentFactoryResolver], i0.NgModuleRef]), i0.ɵmpd(4608, i12.BrowserXhr, i12.BrowserXhr, []), i0.ɵmpd(4608, i12.ResponseOptions, i12.BaseResponseOptions, []), i0.ɵmpd(5120, i12.XSRFStrategy, i12.ɵb, []), i0.ɵmpd(4608, i12.XHRBackend, i12.XHRBackend, [i12.BrowserXhr, i12.ResponseOptions, i12.XSRFStrategy]),
        i0.ɵmpd(4608, i12.RequestOptions, i12.BaseRequestOptions, []), i0.ɵmpd(5120, i12.Http, i12.ɵc, [i12.XHRBackend, i12.RequestOptions]), i0.ɵmpd(5120, i0.LOCALE_ID, i0.ɵm, [[3, i0.LOCALE_ID]]), i0.ɵmpd(4608, i13.NgLocalization, i13.NgLocaleLocalization, [i0.LOCALE_ID]), i0.ɵmpd(5120, i0.APP_ID, i0.ɵf, []), i0.ɵmpd(5120, i0.IterableDiffers, i0.ɵk, []), i0.ɵmpd(5120, i0.KeyValueDiffers, i0.ɵl, []), i0.ɵmpd(4608, i14.DomSanitizer, i14.ɵe, [i14.DOCUMENT]),
        i0.ɵmpd(6144, i0.Sanitizer, null, [i14.DomSanitizer]), i0.ɵmpd(4608, i14.HAMMER_GESTURE_CONFIG, i14.HammerGestureConfig, []), i0.ɵmpd(5120, i14.EVENT_MANAGER_PLUGINS, function (p0_0, p1_0, p2_0, p2_1) {
            return [new i14.ɵDomEventsPlugin(p0_0), new i14.ɵKeyEventsPlugin(p1_0),
                new i14.ɵHammerGesturesPlugin(p2_0, p2_1)];
        }, [i14.DOCUMENT, i14.DOCUMENT, i14.DOCUMENT, i14.HAMMER_GESTURE_CONFIG]),
        i0.ɵmpd(4608, i14.EventManager, i14.EventManager, [i14.EVENT_MANAGER_PLUGINS,
            i0.NgZone]), i0.ɵmpd(135680, i14.ɵDomSharedStylesHost, i14.ɵDomSharedStylesHost, [i14.DOCUMENT]), i0.ɵmpd(4608, i14.ɵDomRendererFactory2, i14.ɵDomRendererFactory2, [i14.EventManager, i14.ɵDomSharedStylesHost]), i0.ɵmpd(6144, i0.RendererFactory2, null, [i14.ɵDomRendererFactory2]), i0.ɵmpd(6144, i14.ɵSharedStylesHost, null, [i14.ɵDomSharedStylesHost]), i0.ɵmpd(4608, i0.Testability, i0.Testability, [i0.NgZone]), i0.ɵmpd(4608, i14.Meta, i14.Meta, [i14.DOCUMENT]),
        i0.ɵmpd(4608, i14.Title, i14.Title, [i14.DOCUMENT]), i0.ɵmpd(4608, i15.ɵi, i15.ɵi, []), i0.ɵmpd(5120, i16.RestaurantLoader, i1.restaurantLoaderFactory, [i12.Http]), i0.ɵmpd(4608, i17.ApiService, i17.ApiService, [i12.Http]), i0.ɵmpd(5120, i18.AppType, i1.appFactory, []), i0.ɵmpd(4608, i16.RestaurantService, i16.RestaurantService, [i12.Http, i16.RestaurantLoader, i17.ApiService,
            i18.AppType]), i0.ɵmpd(4608, i19.DaysService, i19.DaysService, [i12.Http,
            i16.RestaurantService, i17.ApiService]), i0.ɵmpd(4608, i20.CourseService, i20.CourseService, [i16.RestaurantService, i17.ApiService]), i0.ɵmpd(4608, i21.TableCategoriesService, i21.TableCategoriesService, [i17.ApiService]),
        i0.ɵmpd(4608, i22.TableSmokingService, i22.TableSmokingService, [i17.ApiService]),
        i0.ɵmpd(4608, i23.PersonValidationService, i23.PersonValidationService, []),
        i0.ɵmpd(4608, i24.TermService, i24.TermService, [i12.Http]), i0.ɵmpd(4608, i25.PaymentService, i25.PaymentService, [i17.ApiService]), i0.ɵmpd(4608, i26.Angulartics2, i26.Angulartics2, [i13.Location, i27.Router]), i0.ɵmpd(4608, i28.Angulartics2GoogleAnalytics, i28.Angulartics2GoogleAnalytics, [i26.Angulartics2]), i0.ɵmpd(5120, i29.TranslateLoader, i1.translateFactory, [i12.Http]), i0.ɵmpd(4608, i30.TranslateParser, i30.TranslateDefaultParser, []), i0.ɵmpd(4608, i31.MissingTranslationHandler, i31.FakeMissingTranslationHandler, []), i0.ɵmpd(4608, i32.TranslateStore, i32.TranslateStore, []),
        i0.ɵmpd(4608, i33.TranslateService, i33.TranslateService, [i32.TranslateStore,
            i29.TranslateLoader, i30.TranslateParser, i31.MissingTranslationHandler,
            i33.USE_STORE]), i0.ɵmpd(4608, i34.CountriesService, i34.CountriesService, []), i0.ɵmpd(4608, i35.FromService, i35.FromService, []),
        i0.ɵmpd(4608, i36.IntlTelService, i36.IntlTelService, []), i0.ɵmpd(4608, i37.ParamsService, i37.ParamsService, []), i0.ɵmpd(4608, i38.ReserveService, i38.ReserveService, [i17.ApiService]), i0.ɵmpd(4608, i39.RestaurantResolver, i39.RestaurantResolver, [i16.RestaurantService, i18.AppType]), i0.ɵmpd(4608, i40.TRTTimeService, i40.TRTTimeService, [i33.TranslateService]), i0.ɵmpd(4608, i41.TRTDateService, i41.TRTDateService, [i33.TranslateService, i40.TRTTimeService]),
        i0.ɵmpd(4608, i42.WebHooksService, i42.WebHooksService, [i18.AppType]), i0.ɵmpd(4608, i43.WeekDayService, i43.WeekDayService, []), i0.ɵmpd(4608, i44.ReserveFormService, i44.ReserveFormService, [i18.AppType]), i0.ɵmpd(4608, i45.CanDeactivateGuard, i45.CanDeactivateGuard, []), i0.ɵmpd(5120, i27.ActivatedRoute, i27.ɵf, [i27.Router]), i0.ɵmpd(4608, i46.CanActivateGuard, i46.CanActivateGuard, [i16.RestaurantService, i18.AppType, i27.Router, i27.ActivatedRoute]), i0.ɵmpd(4608, i27.NoPreloading, i27.NoPreloading, []), i0.ɵmpd(6144, i27.PreloadingStrategy, null, [i27.NoPreloading]), i0.ɵmpd(135680, i27.RouterPreloader, i27.RouterPreloader, [i27.Router, i0.NgModuleFactoryLoader, i0.Compiler,
            i0.Injector, i27.PreloadingStrategy]), i0.ɵmpd(4608, i27.PreloadAllModules, i27.PreloadAllModules, []), i0.ɵmpd(5120, i27.ROUTER_INITIALIZER, i27.ɵi, [i27.ɵg]), i0.ɵmpd(5120, i0.APP_BOOTSTRAP_LISTENER, function (p0_0) {
            return [p0_0];
        }, [i27.ROUTER_INITIALIZER]), i0.ɵmpd(4608, i47.ConfigService, i47.ConfigService, []), i0.ɵmpd(512, i12.HttpModule, i12.HttpModule, []),
        i0.ɵmpd(512, i13.CommonModule, i13.CommonModule, []), i0.ɵmpd(512, i0.ErrorHandler, i1.TRExceptionHandler, []), i0.ɵmpd(1024, i0.NgProbeToken, function () {
            return [i27.ɵb()];
        }, []), i0.ɵmpd(512, i27.ɵg, i27.ɵg, [i0.Injector]), i0.ɵmpd(1024, i0.APP_INITIALIZER, function (p0_0, p0_1, p1_0) {
            return [i14.ɵc(p0_0, p0_1), i27.ɵh(p1_0)];
        }, [[2, i14.NgProbeToken], [2, i0.NgProbeToken], i27.ɵg]), i0.ɵmpd(512, i0.ApplicationInitStatus, i0.ApplicationInitStatus, [[2, i0.APP_INITIALIZER]]), i0.ɵmpd(131584, i0.ɵe, i0.ɵe, [i0.NgZone, i0.ɵConsole, i0.Injector, i0.ErrorHandler, i0.ComponentFactoryResolver,
            i0.ApplicationInitStatus]), i0.ɵmpd(2048, i0.ApplicationRef, null, [i0.ɵe]), i0.ɵmpd(512, i0.ApplicationModule, i0.ApplicationModule, [i0.ApplicationRef]),
        i0.ɵmpd(512, i14.BrowserModule, i14.BrowserModule, [[3, i14.BrowserModule]]),
        i0.ɵmpd(512, i48.TranslateModule, i48.TranslateModule, []), i0.ɵmpd(512, i49.RestaurantModule, i49.RestaurantModule, []), i0.ɵmpd(512, i15.ɵba, i15.ɵba, []), i0.ɵmpd(512, i15.FormsModule, i15.FormsModule, []), i0.ɵmpd(512, i50.TRTFormModule, i50.TRTFormModule, []),
        i0.ɵmpd(512, i51.TRTCommonModule, i51.TRTCommonModule, []), i0.ɵmpd(1024, i27.ɵa, i27.ɵd, [[3, i27.Router]]), i0.ɵmpd(512, i27.UrlSerializer, i27.DefaultUrlSerializer, []), i0.ɵmpd(512, i27.ChildrenOutletContexts, i27.ChildrenOutletContexts, []), i0.ɵmpd(512, i13.LocationStrategy, i13.HashLocationStrategy, [i13.PlatformLocation, [2, i13.APP_BASE_HREF]]), i0.ɵmpd(512, i13.Location, i13.Location, [i13.LocationStrategy]), i0.ɵmpd(512, i0.Compiler, i0.Compiler, []), i0.ɵmpd(512, i0.NgModuleFactoryLoader, i0.SystemJsNgModuleLoader, [i0.Compiler, [2, i0.SystemJsNgModuleLoaderConfig]]), i0.ɵmpd(1024, i27.ROUTES, function () {
            return [[{ path: 'reserve-info', component: i52.PCReserveInfoComponent,
                        canActivate: [i46.CanActivateGuard], canDeactivate: [i45.CanDeactivateGuard] }],
                [{ path: 'personal-info', component: i53.PCPersonalInfoComponent, canActivate: [i46.CanActivateGuard],
                        canDeactivate: [i45.CanDeactivateGuard] }], [{ path: '', component: i54.PCHomeComponent,
                        resolve: { restaurant: i39.RestaurantResolver } }], [{ path: 'reserve-confirm',
                        component: i55.PCReserveConfirmationComponent, canActivate: [i46.CanActivateGuard],
                        canDeactivate: [i45.CanDeactivateGuard] }], [{ path: 'payment-docomo',
                        component: i56.PCPaymentDocomoComponent, canActivate: [i46.CanActivateGuard],
                        canDeactivate: [i45.CanDeactivateGuard], resolve: i39.RestaurantResolver }],
                [{ path: 'payment-select', component: i57.PCPaymentSelectComponent,
                        canActivate: [i46.CanActivateGuard], canDeactivate: [i45.CanDeactivateGuard],
                        resolve: i39.RestaurantResolver }], [{ path: 'payment', component: i58.PCPaymentComponent,
                        canActivate: [i46.CanActivateGuard], canDeactivate: [i45.CanDeactivateGuard] }],
                [{ path: 'finish', component: i59.PCFinishComponent, canActivate: [i46.CanActivateGuard],
                        canDeactivate: [i45.CanDeactivateGuard] }], [{ path: '**', redirectTo: '' }],
                []];
        }, []), i0.ɵmpd(256, i27.ROUTER_CONFIGURATION, {}, []),
        i0.ɵmpd(1024, i27.Router, i27.ɵe, [i0.ApplicationRef, i27.UrlSerializer, i27.ChildrenOutletContexts,
            i13.Location, i0.Injector, i0.NgModuleFactoryLoader, i0.Compiler, i27.ROUTES,
            i27.ROUTER_CONFIGURATION, [2, i27.UrlHandlingStrategy], [2, i27.RouteReuseStrategy]]),
        i0.ɵmpd(512, i27.RouterModule, i27.RouterModule, [[2, i27.ɵa], [2, i27.Router]]),
        i0.ɵmpd(512, i60.PCHeaderModule, i60.PCHeaderModule, []), i0.ɵmpd(512, i61.PCFooterModule, i61.PCFooterModule, []), i0.ɵmpd(512, i62.PCReserveInfoRoutingModule, i62.PCReserveInfoRoutingModule, []), i0.ɵmpd(1024, i63.ANGULARTICS2_FORROOT_GUARD, i63.provideForRootGuard, [[3, i26.Angulartics2]]), i0.ɵmpd(512, i63.Angulartics2Module, i63.Angulartics2Module, [[2, i63.ANGULARTICS2_FORROOT_GUARD]]), i0.ɵmpd(512, i64.PCReserveInfoModule, i64.PCReserveInfoModule, []), i0.ɵmpd(512, i65.PCPersonalInfoRoutingModule, i65.PCPersonalInfoRoutingModule, []),
        i0.ɵmpd(512, i66.PCPersonalInfoModule, i66.PCPersonalInfoModule, []),
        i0.ɵmpd(512, i67.PCHomeRoutingModule, i67.PCHomeRoutingModule, []),
        i0.ɵmpd(512, i68.PCHomeModule, i68.PCHomeModule, []), i0.ɵmpd(512, i69.PCReserveConfirmationRoutingModule, i69.PCReserveConfirmationRoutingModule, []), i0.ɵmpd(512, i70.TermModule, i70.TermModule, []),
        i0.ɵmpd(512, i71.PCReserveConfirmationModule, i71.PCReserveConfirmationModule, []), i0.ɵmpd(512, i72.PCPaymentDocomoRoutingModule, i72.PCPaymentDocomoRoutingModule, []), i0.ɵmpd(512, i73.PCPaymentDocomoModule, i73.PCPaymentDocomoModule, []), i0.ɵmpd(512, i74.PCPaymentSelectRoutingModule, i74.PCPaymentSelectRoutingModule, []), i0.ɵmpd(512, i75.PCPaymentSelectModule, i75.PCPaymentSelectModule, []), i0.ɵmpd(512, i76.PCPaymentRoutingModule, i76.PCPaymentRoutingModule, []), i0.ɵmpd(512, i77.PCPaymentModule, i77.PCPaymentModule, []),
        i0.ɵmpd(512, i78.PCFinishRoutingModule, i78.PCFinishRoutingModule, []),
        i0.ɵmpd(512, i79.PCFinishModule, i79.PCFinishModule, []), i0.ɵmpd(512, i80.AppRoutingModule, i80.AppRoutingModule, []), i0.ɵmpd(512, i81.PCAppModule, i81.PCAppModule, []), i0.ɵmpd(512, i1.PCClientModule, i1.PCClientModule, []), i0.ɵmpd(256, i33.USE_STORE, undefined, [])]);
});
