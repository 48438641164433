"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var Observable_1 = require("rxjs/Observable");
require("rxjs/add/operator/share");
require("rxjs/add/operator/map");
var api_service_1 = require("../../common/services/api.service");
var PaymentService = (function () {
    function PaymentService(api) {
        this.api = api;
    }
    PaymentService.prototype.createTransaction = function (restaurant_id, reservation_id, payment_token) {
        var _this = this;
        this.restaurant_id = restaurant_id;
        this.reservation_id = reservation_id;
        return Observable_1.Observable.create(function (obs) {
            _this.api.postDeposit(restaurant_id, reservation_id, payment_token).subscribe(function (res) {
                obs.next(res);
            }, function (err) {
                obs.error(err);
            }, function () {
                obs.complete();
            });
        });
    };
    PaymentService.prototype.checkTransaction = function (transaction_id) {
        var _this = this;
        this.transaction_id = transaction_id;
        return Observable_1.Observable.create(function (obs) {
            var ds = _this.api.createDepositCheck();
            ds.observer.subscribe(function (d) {
                console.log(d);
                if (d.status < 3) {
                    setTimeout(function () {
                        ds.check(_this.restaurant_id, _this.reservation_id, _this.transaction_id);
                    }, 300);
                }
                else if (d.status == 3) {
                    obs.next(d);
                    obs.complete();
                }
                else {
                    obs.error(d);
                    obs.complete();
                }
            }, function (err) { return obs.error(err); });
            ds.check(_this.restaurant_id, _this.reservation_id, _this.transaction_id);
        });
    };
    return PaymentService;
}());
PaymentService = __decorate([
    core_1.Injectable(),
    __metadata("design:paramtypes", [api_service_1.ApiService])
], PaymentService);
exports.PaymentService = PaymentService;
