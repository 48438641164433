"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./google-calendar.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core/src/translate.pipe");
var i3 = require("@ngx-translate/core/src/translate.service");
var i4 = require("./google-calendar.component");
var styles_GoogleCalendarComponent = [i0.styles];
exports.RenderType_GoogleCalendarComponent = i1.ɵcrt({ encapsulation: 0,
    styles: styles_GoogleCalendarComponent, data: {} });
function View_GoogleCalendarComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 7, 'a', [['target',
                '_blank']], [[1, 'href', 4]], null, null, null, null)),
        (_l()(), i1.ɵted(null, ['\n    '])), (_l()(), i1.ɵeld(0, null, null, 4, 'p', [], null, null, null, null, null)), (_l()(), i1.ɵeld(0, null, null, 0, 'i', [['class',
                'icon icon_calendar']], null, null, null, null, null)), (_l()(), i1.ɵeld(0, null, null, 2, 'span', [], null, null, null, null, null)),
        (_l()(), i1.ɵted(null, ['', ''])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslateService,
            i1.ChangeDetectorRef]), (_l()(), i1.ɵted(null, ['\n    ']))], null, function (_ck, _v) {
        var _co = _v.component;
        var currVal_0 = _co.href;
        _ck(_v, 0, 0, currVal_0);
        var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform('FINISH.ADD_GOOGLE_CALENDAR'));
        _ck(_v, 5, 0, currVal_1);
    });
}
exports.View_GoogleCalendarComponent_0 = View_GoogleCalendarComponent_0;
function View_GoogleCalendarComponent_Host_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, null, null, 1, 'trt-google-calendar', [], null, null, null, View_GoogleCalendarComponent_0, exports.RenderType_GoogleCalendarComponent)), i1.ɵdid(114688, null, 0, i4.GoogleCalendarComponent, [], null, null)], function (_ck, _v) {
        _ck(_v, 1, 0);
    }, null);
}
exports.View_GoogleCalendarComponent_Host_0 = View_GoogleCalendarComponent_Host_0;
exports.GoogleCalendarComponentNgFactory = i1.ɵccf('trt-google-calendar', i4.GoogleCalendarComponent, View_GoogleCalendarComponent_Host_0, { href: 'href' }, {}, []);
