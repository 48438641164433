"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var app_model_1 = require("../../app/models/app.model");
var WebHooksService = (function () {
    function WebHooksService(appType) {
        this.appType = appType;
        if (this.appType.isBrowser()) {
            this.window = window || {};
            this.window.hooks = {
                loaded_days: undefined
            };
        }
    }
    WebHooksService.prototype.init = function () {
        if (this.appType.isBrowser() && (typeof (this.window.hooks_init) === 'function')) {
            try {
                this.window.hooks_init(this.window.hooks);
            }
            catch (e) {
                Bugsnag.releaseStage = ENV;
                Bugsnag.notifyException(e, 'PageJavaScriptError');
                console.error(e);
            }
        }
    };
    WebHooksService.prototype.finish = function () {
        if (this.appType.isBrowser() && !!this.window.hooks && (typeof (this.window.hooks.finished_reservation) === 'function')) {
            try {
                this.window.hooks.finished_reservation();
            }
            catch (e) {
                Bugsnag.releaseStage = ENV;
                Bugsnag.notifyException(e, 'PageJavaScriptError');
                console.error(e);
            }
        }
    };
    return WebHooksService;
}());
WebHooksService = __decorate([
    core_1.Injectable(),
    __metadata("design:paramtypes", [app_model_1.AppType])
], WebHooksService);
exports.WebHooksService = WebHooksService;
